<form [formGroup]="vipForm" [ngClass]="{ form__submitted: formHasBeenSubmitted }">
  <div class="header">
    <div>
      <p class="text-xl font-semibold text-gray-1 lh-16 my-0">VIP</p>
    </div>

    <div class="flex justify-content-end switch-wrapper">
      <label for="vipEnabled" class="label-switch">Habilitar</label>
      <div class="switch-container">
        <p-inputSwitch
          name="vipEnabled"
          formControlName="vipEnabled"
          [disabled]="fromAdmin"
        ></p-inputSwitch>
      </div>
    </div>
  </div>

  <div class="general-content">
    <div class="grid content-background">
      <div class="col pr-4">
        <label for="name" class="mb-16">Descuento*
          <i
            class="pi pi-info-circle"
            pTooltip="Descuento aplicado para VIPs"
            tooltipPosition="top"
          ></i
        ></label>
        <div class="field__item field--number">
          <div class="p-float-label">
            <p-inputNumber
              [disabled]="fromAdmin"
              id="vipDiscount"
              formControlName="vipDiscount"
              [showButtons]="true"
              buttonLayout="horizontal"
              spinnerMode="horizontal"
              decrementButtonClass="p-button-decrement mr-2"
              incrementButtonClass="p-button-increment ml-2"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              mode="decimal"
              suffix=" %"
              min="0"
              max="{{ maxVipDiscount }}"
              step="1"
              locale="es-AR"
            ></p-inputNumber>
            <app-show-errors
              [page]="'configurations'"
              [ctrl]="vipForm.get('vipDiscount')"
              [formHasBeenSubmitted]="formHasBeenSubmitted"
            ></app-show-errors>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-vip-list [fromAdmin]="true"></app-vip-list>

  <p-toast></p-toast>
</form>
