<div class="detail-store">
  <div class="wrapper">
    <div class="info-store">
      <div class="info-store__config">
        <div class="flex align-items-center">
          <div class="btn__back" (click)="back()">
            <i class="fa-xl fa-regular fa-arrow-left text-primary mr-2"></i> Volver a Comercios
          </div>
        </div>
        <div class="flex align-items-center justify-content-end">
          <button pButton class="btn btn__primary btn__primary--bold btn__size--xs margin-right-16"
                  (click)="unlockStore()" *ngIf="storeData?.blocked" label="Desbloquear comercio">
          </button>
          <div class="container-icon-menu" (click)="menu.toggle($event)">
            <i class="fal fa-ellipsis-vertical text-primary font-size-24 lh-16"></i>
          </div>
          <p-menu #menu styleClass="dots-menu menu-user" appendTo="body" [popup]="true" [model]="items"
                  (click)="menu.toggle($event)"></p-menu>
        </div>
      </div>

      <div class="info-store__data" *ngIf="storeData">
        <div class="col__pic" [ngClass]="storeData?.blocked ? 'store-blocked' : ''">
          <img [src]="getUrlImage(logo)" class="pic" alt=""/>
        </div>
        <div class="col__info">
          <div class="info__head">
            <p class="title" [ngClass]="storeData?.blocked ? 'store-blocked' : ''">{{storeData?.name}}</p>
            <div class="tag-tenant margin-left-8 margin-right-8" *ngIf="tenantSelected"
                 [ngClass]="storeData?.blocked ? 'store-blocked' : ''">
              <i class="fa-regular fa-store text-xxs text-gray-2 margin-right-4"></i>
              {{tenantSelected.name}}
            </div>
            <div class="tag-confirm margin-left-8" *ngIf="storeData?.accountVerified && !storeData?.blocked">
              VERIFICADO
            </div>
            <div class="tag-warning margin-left-8" *ngIf="!storeData.accountVerified && !storeData?.blocked">
              SIN VERIFICAR
            </div>
            <div class="tag-invalid margin-left-8" *ngIf="storeData?.blocked">BLOQUEADO</div>
          </div>
          <div class="info__body" [ngClass]="storeData?.blocked ? 'store-blocked' : ''">
            <div class="flex align-items-center justify-content-start flex-wrap">
              <div class="tag-info" *ngIf="storeData?.address"><i
                class="fa-solid fa-location-dot text-gray-35 margin-right-8"></i>{{shortCity(storeData?.address)}}</div>
              <div class="tag-info" *ngIf="storeData?.phone"><i
                class="fa-solid fa-phone text-gray-35 margin-right-8"></i>{{storeData?.phone}}</div>
              <div class="tag-info cursor-pointer" *ngIf="storeData?.email" (click)="changeEmail(storeData)">
                <i class="fa-solid fa-envelope text-gray-35 margin-right-8 cursor-pointer"></i>
                {{storeData?.email}}
                <i class="fa-light fa-check-circle fa text-success margin-left-8 margin-right-8"
                   *ngIf="storeData?.enabled"></i>
                <i class="fa-light fa-pen-to-square text-primary margin-left-8"></i>
              </div>
              <div class="tag-info cursor-pointer" *ngIf="storeData?.taxIdentifier" (click)="changeCuit(storeData)"><i
                class="fa-solid fa-id-card text-gray-35 margin-right-8"></i>{{storeData?.taxIdentifier}} <i
                class="fa-light fa-pen-to-square text-primary margin-left-8"></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog key="confirmVerificacion" [style]="{ width: '450px' }" acceptLabel="Aceptar" rejectLabel="Cancelar"
                 rejectButtonStyleClass="btn btn__secondary btn__secondary--bold"
                 acceptButtonStyleClass="btn btn__primary">
</p-confirmDialog>

<p-confirmDialog key="removeStore" [style]="{ width: '450px' }" acceptLabel="Cancelar" rejectLabel="Eliminar comercio"
                 rejectButtonStyleClass="btn btn__secondary btn__secondary--red"
                 acceptButtonStyleClass="btn btn__primary btn__size--lg">
  <ng-template pTemplate="footer">
    <button class="btn btn__secondary btn__secondary--red" type="button" pButton label="Eliminar comercio"
            (click)="deleteStore()"></button>
    <button class="btn btn__primary btn__size--lg" type="button" pButton label="Cancelar"
            (click)="closeModal()"></button>
  </ng-template>
</p-confirmDialog>
