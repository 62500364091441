import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {RequestErrorTriggerService} from '../../services/request-error-trigger.service';
import {SpinnerService} from '../../services/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private errorService: RequestErrorTriggerService,
    private spinnerService: SpinnerService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((err) => {
        this.spinnerService.loadSpinner.next(false);
        if (err.status === 500) {
          this.errorService.updateShowError({
            showError: true,
            message: 'Ocurrio un error al recuperar la información.'
          });
          return EMPTY;
        }        
        return throwError(() => err);
      })
    );
  }

}

