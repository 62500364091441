import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {AuthService} from 'src/app/services/auth.service';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {LoginForm} from 'src/app/core/models/LoginForm';
import {Router} from '@angular/router';
import {SpinnerService} from 'src/app/services/spinner.service';
import {Subscription} from 'rxjs';
import {UserService} from 'src/app/services/user.service';
import jwt_decode from 'jwt-decode';
import {Admin} from "../../../core/models/Admin";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  loginForm: UntypedFormGroup;
  hashPassword: boolean = true;
  loading: boolean = false;
  txtButton: string;
  displayDialog: boolean;
  contentDialog: string;
  showError: boolean;
  contentError: string;
  loginSub: Subscription = null;
  formHasBeenSubmitted: boolean = false;
  isReadOnly: boolean = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private router: Router,
    private userSrv: UserService,
    private validateInputService: InputValidationService
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    const logged = localStorage.getItem('isLoggedIn');
    const isAdmin = this.authService.isAdminRole();
    const isSuperAdmin = this.authService.isSuperAdminRole();
    if (logged && (isAdmin || isSuperAdmin)) {
      this.router.navigate(['/home']);
    }
    setTimeout(() => {
      this.isReadOnly = false;
    }, 2000);

    this.loginForm.valueChanges.subscribe(() => {
      this.formHasBeenSubmitted = false;
      this.showError = false;
    })
  }

  ngOnDestroy(): void {
    if (this.loginSub) {
      this.loginSub.unsubscribe();
    }
  }

  submitLogin(): void {
    this.formHasBeenSubmitted = true;
    this.spinnerService.loadSpinner.next(true);
    this.showError = false;
    const form: LoginForm = this.loginForm.value;
    this.loading = true;
    this.loginSub = this.authService.login(form).subscribe({
      next: (res: Admin) => {
        localStorage.setItem('BE-JWT', res.jwt);
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('role', this.setRole(res.jwt));
        this.userSrv.updateAdmin(res);
        const lastPage = localStorage.getItem('lastPage');
        if (lastPage) {
          this.router.navigateByUrl(lastPage);
          localStorage.removeItem('lastPage');
        } else {
          this.router.navigateByUrl('/home');
        }
        this.loading = false;
        this.spinnerService.loadSpinner.next(false);
      },
      error: (err) => {
        this.loading = false;
        this.spinnerService.loadSpinner.next(false);
        this.txtButton = 'Ingresar';
        this.showError = true;
        if (err.status === 403) {
          this.contentError = 'Credenciales inválidas. Intentá de nuevo.';
        } else if (err.status === 404) {
          this.contentError = 'Lo sentimos, su cuenta ha sido eliminada. Por favor contactese con el administrador.';
        } else if (err.status === 409) {
          this.contentError = 'Su cuenta ha sido bloqueada. Contactese con soporte.';
        }
      }
    });
  }

  displayError(): void {
    this.showError = true;
  }

  setRole(jwt: string): string {
    const decoded = jwt_decode(jwt);
    const role = decoded['authorities'][0];
    return role;
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  close() {
    this.showError = false;
  }
}
