import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card-wide',
  templateUrl: './card-wide.component.html',
  styleUrls: ['./card-wide.component.scss']
})
export class CardWideComponent implements OnInit {

  @Input() description: string;
  @Input() value: number;
  @Input() valueClass = 'text-gray-1';
  @Input() icon: string;
  @Input() prefixValue: string;

  constructor() { }

  ngOnInit(): void {
  }

}
