import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ScheduleConfig } from 'src/app/core/models/Configurations';

@Component({
  selector: 'app-config-schedule',
  templateUrl: './config-schedule.component.html',
  styleUrls: ['./config-schedule.component.scss'],
  providers: [MessageService],
})
export class ConfigScheduleComponent implements OnInit, OnDestroy {
  @Output() validFormState = new EventEmitter();
  @Output() updateOriginalData = new EventEmitter();
  @Input() scheduleConfigData: ScheduleConfig = null;
  @Input() configurationId: number;
  @Input() fromAdmin: boolean;
  @Input() canWrite: boolean;

  horariosForm: UntypedFormGroup;
  thereAreChanges = true;
  originalData;
  switch: boolean;
  switch2: boolean;
  switch3: boolean;
  subs: Subscription[] = [];
  formateLanguague: string = '24';
  formHasBeenSubmitted = false;
  errorHour = '';

  public labels = {
    active: 'Cortado',
    desactive: 'Corrido',
  };

  public days = [
    {
      label: 'Lunes',
      control: 'monday',
    },
    {
      label: 'Martes',
      control: 'tuesday',
    },
    {
      label: 'Miércoles',
      control: 'wednesday',
    },
    {
      label: 'Jueves',
      control: 'thursday',
    },
    {
      label: 'Viernes',
      control: 'friday',
    },
    {
      label: 'Sábado',
      control: 'saturday',
    },
    {
      label: 'Domingo',
      control: 'sunday',
    },
  ];

  inputsConfig = [
    {
      control: 'scheduleAvailable',
      name: '',
      disableable: false,
    },
    {
      control: 'scheduleId',
      name: '',
      disableable: false,
    },
    {
      control: 'holidaysOpen',
      name: '',
      disableable: true,
    },
    {
      control: 'cutSchedule',
      name: '',
      disableable: true,
    },
    {
      control: 'mondayStart1',
      name: '',
      disableable: true,
    },
    {
      control: 'tuesdayStart1',
      name: '',
      disableable: true,
    },
    {
      control: 'wednesdayStart1',
      name: '',
      disableable: true,
    },
    {
      control: 'thursdayStart1',
      name: '',
      disableable: true,
    },
    {
      control: 'fridayStart1',
      name: '',
      disableable: true,
    },
    {
      control: 'saturdayStart1',
      name: '',
      disableable: true,
    },
    {
      control: 'sundayStart1',
      name: '',
      disableable: true,
    },
    {
      control: 'mondayEnd1',
      name: '',
      disableable: true,
    },
    {
      control: 'tuesdayEnd1',
      name: '',
      disableable: true,
    },
    {
      control: 'wednesdayEnd1',
      name: '',
      disableable: true,
    },
    {
      control: 'thursdayEnd1',
      name: '',
      disableable: true,
    },
    {
      control: 'wednesdayEnd1',
      name: '',
      disableable: true,
    },
    {
      control: 'thursdayEnd1',
      name: '',
      disableable: true,
    },
    {
      control: 'thursdayEnd1',
      name: '',
      disableable: true,
    },
    {
      control: 'fridayEnd1',
      name: '',
      disableable: true,
    },
    {
      control: 'saturdayEnd1',
      name: '',
      disableable: true,
    },
    {
      control: 'sundayEnd1',
      name: '',
      disableable: true,
    },
    {
      control: 'mondayStart2',
      name: '',
      disableable: true,
    },
    {
      control: 'tuesdayStart2',
      name: '',
      disableable: true,
    },
    {
      control: 'wednesdayStart2',
      name: '',
      disableable: true,
    },
    {
      control: 'thursdayStart2',
      name: '',
      disableable: true,
    },
    {
      control: 'fridayStart2',
      name: '',
      disableable: true,
    },
    {
      control: 'saturdayStart2',
      name: '',
      disableable: true,
    },
    {
      control: 'sundayStart2',
      name: '',
      disableable: true,
    },
    {
      control: 'mondayEnd2',
      name: '',
      disableable: true,
    },
    {
      control: 'tuesdayEnd2',
      name: '',
      disableable: true,
    },
    {
      control: 'wednesdayEnd2',
      name: '',
      disableable: true,
    },
    {
      control: 'thursdayEnd2',
      name: '',
      disableable: true,
    },
    {
      control: 'fridayEnd2',
      name: '',
      disableable: true,
    },
    {
      control: 'saturdayEnd2',
      name: '',
      disableable: true,
    },
    {
      control: 'sundayEnd2',
      name: '',
      disableable: true,
    },
  ];

  constructor(private formBuilder: UntypedFormBuilder) {
    const regHourValidation = "^(('')|((2[0-3]|[0-1][0-9]):[0-5][0-9]))$";

    this.horariosForm = this.formBuilder.group({
      cutSchedule: [false],
      holidaysOpen: [false],
      mondayAvailable: [false],
      tuesdayAvailable: [false],
      wednesdayAvailable: [false],
      thursdayAvailable: [false],
      fridayAvailable: [false],
      saturdayAvailable: [false],
      sundayAvailable: [false],
      mondayStart1: [null, Validators.pattern(regHourValidation)],
      tuesdayStart1: [null, Validators.pattern(regHourValidation)],
      wednesdayStart1: [null, Validators.pattern(regHourValidation)],
      thursdayStart1: [null, Validators.pattern(regHourValidation)],
      fridayStart1: [null, Validators.pattern(regHourValidation)],
      saturdayStart1: [null, Validators.pattern(regHourValidation)],
      sundayStart1: [null, Validators.pattern(regHourValidation)],
      mondayEnd1: [null, Validators.pattern(regHourValidation)],
      tuesdayEnd1: [null, Validators.pattern(regHourValidation)],
      wednesdayEnd1: [null, Validators.pattern(regHourValidation)],
      thursdayEnd1: [null, Validators.pattern(regHourValidation)],
      fridayEnd1: [null, Validators.pattern(regHourValidation)],
      saturdayEnd1: [null, Validators.pattern(regHourValidation)],
      sundayEnd1: [null, Validators.pattern(regHourValidation)],
      mondayStart2: [null, Validators.pattern(regHourValidation)],
      tuesdayStart2: [null, Validators.pattern(regHourValidation)],
      wednesdayStart2: [null, Validators.pattern(regHourValidation)],
      thursdayStart2: [null, Validators.pattern(regHourValidation)],
      fridayStart2: [null, Validators.pattern(regHourValidation)],
      saturdayStart2: [null, Validators.pattern(regHourValidation)],
      sundayStart2: [null, Validators.pattern(regHourValidation)],
      mondayEnd2: [null, Validators.pattern(regHourValidation)],
      tuesdayEnd2: [null, Validators.pattern(regHourValidation)],
      wednesdayEnd2: [null, Validators.pattern(regHourValidation)],
      thursdayEnd2: [null, Validators.pattern(regHourValidation)],
      fridayEnd2: [null, Validators.pattern(regHourValidation)],
      saturdayEnd2: [null, Validators.pattern(regHourValidation)],
      sundayEnd2: [null, Validators.pattern(regHourValidation)],
      scheduleAvailable: [false],
    });
  }

  ngOnInit(): void {
    this.checkLanguage();

    if (this.scheduleConfigData) {
      this.loadData();
      this.originalData = this.scheduleConfigData;
    }
  }

  get cutScheduleField(): AbstractControl {
    return this.horariosForm.get('cutSchedule');
  }

  checkLanguage(): void {
    let ln = window.navigator.language;
    this.formateLanguague = ln && ln.indexOf('en') !== -1 ? '12' : '24';
  }

  loadData(): void {
    this.horariosForm.patchValue({
      cutSchedule: this.scheduleConfigData.schedule.cutSchedule,
      scheduleAvailable: this.scheduleConfigData.scheduleAvailable,
      scheduleId: this.scheduleConfigData.scheduleId,
      holidaysOpen: this.scheduleConfigData.schedule.holidaysOpen,
      mondayStart1: this.scheduleConfigData.schedule.mondayStart1,
      tuesdayStart1: this.scheduleConfigData.schedule.tuesdayStart1,
      wednesdayStart1: this.scheduleConfigData.schedule.wednesdayStart1,
      thursdayStart1: this.scheduleConfigData.schedule.thursdayStart1,
      fridayStart1: this.scheduleConfigData.schedule.fridayStart1,
      saturdayStart1: this.scheduleConfigData.schedule.saturdayStart1,
      sundayStart1: this.scheduleConfigData.schedule.sundayStart1,
      mondayEnd1: this.scheduleConfigData.schedule.mondayEnd1,
      tuesdayEnd1: this.scheduleConfigData.schedule.tuesdayEnd1,
      wednesdayEnd1: this.scheduleConfigData.schedule.wednesdayEnd1,
      thursdayEnd1: this.scheduleConfigData.schedule.thursdayEnd1,
      fridayEnd1: this.scheduleConfigData.schedule.fridayEnd1,
      saturdayEnd1: this.scheduleConfigData.schedule.saturdayEnd1,
      sundayEnd1: this.scheduleConfigData.schedule.sundayEnd1,
      mondayStart2: this.scheduleConfigData.schedule.mondayStart2,
      tuesdayStart2: this.scheduleConfigData.schedule.tuesdayStart2,
      wednesdayStart2: this.scheduleConfigData.schedule.wednesdayStart2,
      thursdayStart2: this.scheduleConfigData.schedule.thursdayStart2,
      fridayStart2: this.scheduleConfigData.schedule.fridayStart2,
      saturdayStart2: this.scheduleConfigData.schedule.saturdayStart2,
      sundayStart2: this.scheduleConfigData.schedule.sundayStart2,
      mondayEnd2: this.scheduleConfigData.schedule.mondayEnd2,
      tuesdayEnd2: this.scheduleConfigData.schedule.tuesdayEnd2,
      wednesdayEnd2: this.scheduleConfigData.schedule.wednesdayEnd2,
      thursdayEnd2: this.scheduleConfigData.schedule.thursdayEnd2,
      fridayEnd2: this.scheduleConfigData.schedule.fridayEnd2,
      saturdayEnd2: this.scheduleConfigData.schedule.saturdayEnd2,
      sundayEnd2: this.scheduleConfigData.schedule.sundayEnd2,
    });

    this.days.forEach((el) => {
      if (
        this.scheduleConfigData.schedule[el.control + 'Start1'] ||
        this.scheduleConfigData.schedule[el.control + 'Start2']
      ) {
        this.horariosForm.controls[el.control + 'Start1'].enable();
        this.horariosForm.controls[el.control + 'End1'].enable();
        this.horariosForm.controls[el.control + 'Start2'].enable();
        this.horariosForm.controls[el.control + 'End2'].enable();
      } else {
        this.horariosForm.controls[el.control + 'Start1'].disable();
        this.horariosForm.controls[el.control + 'End1'].disable();
        this.horariosForm.controls[el.control + 'Start2'].disable();
        this.horariosForm.controls[el.control + 'End2'].disable();
      }

      if (
        (this.scheduleConfigData.schedule[el.control + 'Start1'] !== '' &&
          this.scheduleConfigData.schedule[el.control + 'Start1'] !== null) ||
        (this.scheduleConfigData.schedule[el.control + 'Start2'] !== '' &&
          this.scheduleConfigData.schedule[el.control + 'Start2'] !== null)
      ) {
        this.horariosForm.controls[el.control + 'Available'].patchValue(true);
      } else {
        this.horariosForm.controls[el.control + 'Available'].patchValue(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => {
      s.unsubscribe();
    });
  }
}
