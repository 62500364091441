<button
  pButton
  class="btn btn__secondary btn__secondary--bold btn__size--sm"
  [ngClass]="{ 'big-button': sizeClass === 'big' }"
  (click)="displayQR()"
  label="Ver código"
></button>

<p-dialog [(visible)]="qrDialog" [modal]="true" [draggable]="false" [resizable]="false" class="bid-modal bid-modal__small">
  <ng-template pTemplate="header" class="bid-modal__header">
    <div class="filling-space"></div>
    <p class="bid-modal__header--title">{{headerTitle}}</p>
    <div class="filling-space"></div>
  </ng-template>
  <ng-template pTemplate="content" class="bid-modal__content">
    <div class="field__group display-flex flex-direction-column align-items-center justify-content-center">
      <div class="qr__container">
        <div id="{{ type }}" class="qr-custom-code"></div>
      </div>
      <div class="leyenda-container" *ngIf="leyenda !== ''">
        <div class="leyenda">
          <span class="text-sm font-light lh-20 text-gray-3">{{ leyenda }}</span>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer" class="bid-modal__footer">
    <button
      pButton
      label="Cerrar"
      class="btn btn__secondary"
      (click)="hideQrDialog()"
    ></button>
    <button
      pButton
      label="Imprimir QR"
      class="btn btn__primary"
      (click)="printQR()"
    ></button>
  </ng-template>
</p-dialog>

<div id="qr"></div>
