import {Component, OnDestroy, OnInit} from '@angular/core';

import {AuthService} from 'src/app/services/auth.service';
import {ConfirmationService} from 'primeng/api';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  navbarOpen = false;
  currentUrl: string;
  showHome: boolean;
  submitted: boolean;
  displayLoading = false;
  url: string;
  isHome: boolean;
  messages = [];
  subs: Subscription[] = [];
  isLogged: any;
  maxLengthName: number;
  maxLengthEmail: number;
  formHasBeenSubmitted: boolean = false;

  constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    private authSrv: AuthService
  ) {
  }

  ngOnInit(): void {
    this.url = environment.landingUrl;
    this.isLoggedIn();
  }

  toggleNavbar(): void {
    this.navbarOpen = !this.navbarOpen;
  }

  public isLoggedIn(): boolean {
    this.isLogged = localStorage.getItem('isLoggedIn');
    const currentPath = this.router.url;
    if (this.isLogged && currentPath === '/login') {
      localStorage.clear();
    }
    if (this.isLogged) {
      this.isHome = true;
      return true;
    } else {
      this.isHome = false;
      return false;
    }
  }

  logout(): void {
    this.confirmationService.confirm({
      message:
        '<span class="topheader no-subheader">¿Deseas cerrar sesión?</span>',
      icon: 'fa-light fa-triangle-exclamation text-yellow text-12xl',
      key: 'confirmLogout',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        if (this.authSrv.getCanLogout()) {
          localStorage.clear();
        }
        this.router.navigate(['/login']);
      },
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => {
      s.unsubscribe();
    });
  }
}
