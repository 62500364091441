import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventGuestDataToExport } from '../core/models/events/EventGuestDataToExport';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventGuestService {

  constructor(private httpC: HttpClient) { }

  public getDataGuestsToExport(eventId: number): Observable<EventGuestDataToExport[]> {
    return this.httpC.get<EventGuestDataToExport[]>(environment.billboardAGUrl + `/cartelera/guests/to_export/event/${eventId}`);
  }
}
