import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminGuard implements CanActivate {
  private roleSuperAdmin: string = 'ROLE_SUPER_ADMIN';

  public static isLoggedIn(): boolean {
    return localStorage.getItem('isLoggedIn') === 'true';
  }

  public isSuperAdminRole(): boolean {
    return localStorage.getItem('role') === this.roleSuperAdmin;
  }

  constructor(private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.verifyLoginAndRole();
  }

  verifyLoginAndRole(): boolean {
    const logged = SuperAdminGuard.isLoggedIn();
    const superAdminRole = this.isSuperAdminRole();

    if (!logged || !superAdminRole) {
      this.router.navigate(['/login']);
    }
    return true;
  }

}
