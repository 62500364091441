<section class="stats" [ngClass]="{ 'lateral-open': isLateralOpen }">

  <div class="stats__header">

    <div class="title">Estadísticas</div>
    <header class="navigation">
      <button class="flat-button margin-right-8" (click)="goTo('event')"
              [ngClass]="{'selected': selectedBtn === 'event'}"><i class="fa-solid fa-calendar-star"></i>Eventos
      </button>
      <button class="flat-button" (click)="goTo('booking')" [ngClass]="{'selected': selectedBtn === 'booking'}"><i
        class="fa-solid fa-calendar-check"></i>Reservas
      </button>
      <!--<p-tabMenu [model]="items" [activeItem]="activeItem"></p-tabMenu>-->
    </header>
    <div class="skeleton__dropdown margin-top-16 margin-bottom-32" *ngIf="loading"></div>
    <div class="dropdown-container margin-top-16 margin-bottom-32" *ngIf="!loading && selectedBtn === 'event'">
      <p-dropdown [options]="events" [(ngModel)]="eventSelected"
                  [placeholder]="events.length > 0 ? '' : 'No hay eventos'"
                  (onChange)="onEventChange($event.value)" optionLabel="eventName" optionValue="idEvent"
                  class="dropdown" [disabled]="events.length === 0"></p-dropdown>
      <span class="dropdown-info margin-left-16">
        <i class="text-base fa-regular fa-circle-info" pTooltip="Solo se visualizarán los eventos ya finalizados." tooltipPosition="top"></i>
      </span>
    </div>
  </div>

  <div class="stats__wrapper">
    <div class="stats__container">
      <ng-container *ngIf="loading">
        <div class="skeleton__header"></div>
        <div class="skeleton__container margin-top-48">
          <div class="skeleton__long margin-right-16"></div>
          <div class="skeleton__long"></div>
        </div>
        <div class="skeleton__container margin-top-64">
          <div class="w-100 margin-right-16">
            <div class="skeleton__tini margin-bottom-16"></div>
            <div class="skeleton__long"></div>
          </div>
          <div class="w-100">
            <div class="skeleton__tini margin-bottom-16"></div>
            <div class="skeleton__long"></div>
          </div>
        </div>
      </ng-container>
      <router-outlet *ngIf="!loading"></router-outlet>
    </div>
  </div>
</section>
