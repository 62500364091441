import {isPlatformBrowser} from '@angular/common';
import {Component, Inject, Input, NgZone, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {EventForStore} from 'src/app/core/models/EventForStore';
import {StoreStatistics} from 'src/app/core/models/StoreStatistics';
import {EventsService} from 'src/app/services/events.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  showSuccessModal = false;
  showMenu: boolean = true;
  @Input() hideLateralMenu: boolean;
  idStore: number;
  config: any;
  stats: StoreStatistics;
  isLateralOpen: boolean;
  eventSelected: EventForStore;
  idEventSelected: number;
  statistics: StoreStatistics = null;
  statType: string = 'Eventos';
  items: Array<MenuItem>;
  activeItem: MenuItem;
  events: Array<EventForStore> = [];
  emptyEvent: EventForStore;
  selectedBtn: string;
  loading = false;
  eventName: string;
  currentRoute: string;
  urlEvent: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private zone: NgZone,
    private eventSrv: EventsService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.idStore = Number(sessionStorage.getItem('storeId'));
    this.loading = true;
    if (this.hideLateralMenu) {
      this.showMenu = false;
    }
    this.checkTabSelected(this.router.url);
    this.eventSrv.getOldEvents(this.idStore).subscribe((res) => {
      this.events = res;
      if (res.length > 0) {
        this.onEventChange(this.events.reverse()[0].idEvent);
      }
    });
  }

  checkTabSelected(url) {
    this.selectedBtn = '';
    if (url.includes('event') || url.includes('booking')) {
      this.selectedBtn = this.router.url.includes('event') ? 'event' : 'booking';
    }
    if (this.selectedBtn === 'event') {
      this.eventSrv.getOldEvents(this.idStore).subscribe((res) => {
        this.events = res;
        if (res.length > 0) {
          this.onEventChange(this.events.reverse()[0].idEvent);
        } else {
          this.loading = false;
          this.router.navigate(['store', this.idStore, 'stats', 'event']);
        }
      });
      this.loading = false;
    } else if (this.selectedBtn === 'booking') {
      this.loading = false;
      this.router.navigate(['store', this.idStore, 'stats', 'booking']);
    }
  }

  selectedTabMenu(e) {
    this.statType = e.item.label;
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  statChanged(e) {
    this.statType = e.target.defaultValue;
  }

  onEventChange(e) {
    this.idEventSelected = e;
    this.redirect(this.idEventSelected);
    this.loading = false;
  }

  redirect(idEvent: number) {
    this.router.navigate(['store', this.idStore, 'stats', 'event', idEvent]);
    this.loading = false;
  }

  goTo(url: string) {
    this.selectedBtn = url;
    this.loading = true;
    if (this.selectedBtn === 'event') {
      this.eventSrv.getOldEvents(this.idStore).subscribe((res) => {
        this.events = res.reverse();
        if (res.length > 0) {
          if (this.idEventSelected) {
            this.redirect(this.idEventSelected);
          } else {
            this.redirect(this.events.reverse()[0].idEvent);
          }
        } else {
          this.loading = false;
          this.router.navigate(['store', this.idStore, 'stats', 'event']);
        }
      });
    } else {
      this.router.navigate(['store', this.idStore, 'stats', 'booking']);
      this.loading = false;
    }
  }

}
