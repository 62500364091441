import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TableConfig } from 'src/app/core/models/Configurations';

@Component({
  selector: 'app-config-mesas',
  templateUrl: './config-mesas.component.html',
  styleUrls: ['./config-mesas.component.scss'],
})
export class ConfigMesasComponent implements OnInit, OnDestroy {
  @Output() validFormState = new EventEmitter();
  @Input() tableConfigData: TableConfig = null;
  @Input() configurationId: number;
  @Input() fromAdmin: boolean;

  mesasForm: UntypedFormGroup;
  thereAreChanges: boolean = false;
  originalData;
  tooltipMessage: string;
  switch: boolean;
  subs: Subscription[] = [];
  @Input() canWrite: boolean;
  tableNumer: number = 0;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.mesasForm = this.formBuilder.group({
      tableAvailable: [false],
    });
  }

  ngOnInit(): void {
    if (this.tableConfigData) {
      this.originalData = this.tableConfigData;
    }
    this.getTooltipMessage(this.tableAvailableField.value);
  }

  getTooltipMessage(val): void {
    this.tooltipMessage = val
      ? 'Al escanear el QR de la mesa, tus clientes podrán ver el menú dinámicamente y hacer el pedido directamente desde la App'
      : 'Tus clientes podrán ver el menú dinámicamente, pero no podrán hacer el pedido desde la App.';
  }

  onTableNumerChange(e: number): void {
    this.tableNumer = e;
  }

  get tableAvailableField(): AbstractControl {
    return this.mesasForm.get('tableAvailable');
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => {
      s.unsubscribe();
    });
  }
}
