import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-card-vertical',
  templateUrl: './card-vertical.component.html',
  styleUrls: ['./card-vertical.component.scss']
})
export class CardVerticalComponent implements OnInit {

  @Input() description: string;
  @Input() value: number;
  @Input() valueClass = 'text-gray-1';
  @Input() icon: string;
  @Input() prefixValue: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
