<div class="modal__body">
  <div class="modal__body-configured">
    <div *ngIf="accountCollectors?.length > 0">
      <div *ngFor="let accountCol of accountCollectors" class="account_collector-option"
           [ngClass]="accountCol?.mpUserId === accountSelected?.mpUserId ? 'active' : ''">
        <p-checkbox binary="true"
                    class="field-checkbox-rounded cursor-pointer"
                    [ngModel]="valueCheck(accountCol)" (onChange)="checkAccount($event.checked, accountCol)"></p-checkbox>
        <label [for]="accountCol.id" class="display-flex">
          <p
            class="text-base font-weight-600 font-poppins lh-24 text-gray-2 align-self-center my-0 ml-3">
            {{accountCol?.accountCollectorName}}
          </p>
          <p class="text-sm font-weight-400 lh-20 text-gray-35 align-self-center my-0 ml-3">
            #{{accountCol?.mpUserId}}
          </p>
        </label>

      </div>
    </div>
    <form [formGroup]="mercadoPagoForm" autocomplete="off" class="w-100 margin-top-16">
      <div class="col-12 p-0">
        <div class="field__item field--modal" [ngClass]="
            validateInputClass(mercadoPagoForm, 'publicKey', formHasBeenSubmitted)
          ">
          <div class="p-float-label">
            <input #publicKey formControlName="publicKey" id="publicKey" type="text" pInputText
                   [readonly]="accountSelected !== null"/>
            <label for="publicKey" class="title-float">Public Key*</label>
            <app-show-errors [page]="'paymentsCredentials'" [ctrl]="mercadoPagoForm.get('publicKey') | formControl"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
        </div>
      </div>

      <div class="col-12 p-0">
        <div class="field__item field--modal" [ngClass]="
            validateInputClass(mercadoPagoForm, 'accessToken', formHasBeenSubmitted)
          ">
          <div class="p-float-label">
            <input #accessToken formControlName="accessToken" id="accessToken" type="text" pInputText
                   [readonly]="accountSelected !== null"/>
            <label for="accessToken" class="title-float">Access Token*</label>
            <app-show-errors [page]="'paymentsCredentials'" [ctrl]="mercadoPagoForm.get('accessToken') | formControl"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
        </div>
      </div>

      <div class="col-12 p-0">
        <div class="field__item field--modal" [ngClass]="
            validateInputClass(mercadoPagoForm, 'clientId', formHasBeenSubmitted)
          ">
          <div class="p-float-label">
            <input #clientId formControlName="clientId" id="clientId" type="text" pInputText
                   [readonly]="accountSelected !== null"/>
            <label for="clientId" class="title-float">User ID*</label>
            <app-show-errors [page]="'paymentsCredentials'" [ctrl]="mercadoPagoForm.get('clientId') | formControl"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
        </div>
      </div>
    </form>

    <div class="footer">
      <div class="col p-0 mr-2">
        <button pButton class="btn btn__secondary btn__secondary--bold w-100" (click)="cancel()"
                label="Cancelar"></button>
      </div>
      <div class="col p-0 ml-2">
        <button pButton (click)="submitChange()" [disabled]="!mercadoPagoForm.valid || !isWriter"
                class="btn btn__primary btn__primary--bold w-100" label="Guardar"></button>
      </div>
    </div>
  </div>
</div>


<app-modal [config]="modalConfig" [widthModal]="'432px'" (modalResponse)="onModalResponse($event)"
           [showModal]="showModalConfirm">
</app-modal>
