<div class="container-main mesas-container">
  <div class="internal-body">
    <p-toast></p-toast>

    <div class="table__container">
      <p-table #dt [value]="vipUsers" [rows]="10" [paginator]="true"
               [globalFilterFields]="['dni', 'name', 'lastName', 'email']" [(selection)]="selectedVipUsers"
               [rowHover]="true" class="table__internals"
               dataKey="id" sortField="name" sortOrder="1" [rowsPerPageOptions]="[10,25,50]"
               currentPageReportTemplate="Mostrar" [showCurrentPageReport]="true">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-between pb-4">
            <div class="flex align-items-center justify-content-start">
              <p class="text-base text-gray-1 my-0">Usuarios VIP</p>
              <i class="fa-solid fa-arrows-rotate text-primary cursor-pointer fa-lg ml-3 mr-3"
                 [ngClass]="{ 'spin': refresh }" (click)="onRefresh()"></i>
              <div class="box__total">
                Total {{ vipUsers ? vipUsers.length : 0 }}
              </div>
            </div>
            <div class="flex align-items-center justify-content-end">
              <div class="search-container">
                <div class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input
                    pInputText
                    type="text"
                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Buscar"
                  />
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="document">
              Documento
              <p-sortIcon field="document"></p-sortIcon>
            </th>
            <th pSortableColumn="name">
              Nombre
              <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="lastName">
              Apellido
              <p-sortIcon field="lastName"></p-sortIcon>
            </th>
            <th pSortableColumn="email">
              Email
              <p-sortIcon field="email"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-vip>
          <tr>
            <td>
              <p class="text-sm lh-16 my-0 text-gray-1 font-semibold">{{ vip.document }}</p>
            </td>
            <td>
              <p class="text-sm lh-16 my-0 text-gray-1">{{ vip.name }}</p>
            </td>
            <td>
              <p class="text-sm lh-16 my-0 text-gray-1">{{ vip.lastName }}</p>
            </td>
            <td>
              <p class="text-sm lh-16 my-0 text-gray-1">{{ vip.email }}</p>
            </td>
          </tr>
          <div class="my-2"></div>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
