import {ActivatedRoute, Router} from '@angular/router';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';

import {StoreData} from 'src/app/core/models/StoreData';
import {StoreService} from 'src/app/services/store.service';
import {PaymentProviders} from '../../../../../../../../../core/models/PaymentProviders';
import {PaymentService} from '../../../../../../../../../services/payment.service';
import {SpinnerService} from '../../../../../../../../../services/spinner.service';
import {PaymentProvider} from '../../../../../../../../../shared/enums/PaymentProvider';
import {SalesChannels} from '../../../../../../../../../shared/enums/SalesChannels';
import {SetProviderForChannelRequest} from '../../../../../../../../../core/models/SetProviderForChannelRequest';
import { ModalConfig } from 'src/app/core/models/ModalConfig';

@Component({
  selector: 'app-sales-channels',
  templateUrl: './sales-channels.component.html',
  styleUrls: ['./sales-channels.component.scss']
})
export class SalesChannelsComponent implements OnInit {

  @Output() changeTabEmitter = new EventEmitter();
  mobbexWeb: boolean = false;
  mpWeb: boolean = false;
  pagoUnoWeb: boolean = false;
  stripeWeb: boolean = false;
  mobbexMobile: boolean = false;
  mpMobile: boolean = false;
  pagoUnoMobile: boolean = false;
  stripeMobile: boolean = false;
  storeId: number;
  storeData: StoreData;
  paymentProviders: PaymentProviders[] = [];
  status: boolean = false;
  providerToSet: PaymentProvider = null;
  channel: SalesChannels = null;
  mobbexAvailable: boolean = false;
  mpAvailable: boolean = false;
  pagoUnoAvailable: boolean = false;
  stripeAvailable: boolean = false;
  modalConfig: ModalConfig = {
    headerText: 'Ha ocurrido un error en la carga de datos',
    content: 'Por favor intente mas tarde',
    buttons: 'single',
  };
  showModalConfirm = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storeService: StoreService,
    private paymentSrv: PaymentService,
    private spinnerSrv: SpinnerService,
  ) {
  }

  ngOnInit(): void {
    this.spinnerSrv.loadSpinner.next(true);
    this.storeId = Number(sessionStorage.getItem('storeId'));
    this.getPaymentProvidersConfiguration();    
  }

  getPaymentProvidersConfiguration() {
    this.paymentSrv.getPaymentProvidersConfiguration(this.storeId).subscribe({
      next: res => {
        this.paymentProviders = res;
        this.checkAvailablePlatforms();
        this.spinnerSrv.loadSpinner.next(false);
      },
      error: err => {
        this.spinnerSrv.loadSpinner.next(false);
        console.log(err);
      }
    });
  }

  checkAvailablePlatforms() {
    if (this.paymentProviders.length > 0) {
      const mobbexProvider = this.paymentProviders.filter(pp => pp.provider === PaymentProvider.MOBBEX);
      if (mobbexProvider.length > 0) {
        this.mobbexAvailable = true;
        this.mobbexWeb = mobbexProvider[0].enabledWeb;
        this.mobbexMobile = mobbexProvider[0].enabledApp;
      }
      const mpProvider = this.paymentProviders.filter(pp => pp.provider === PaymentProvider.MERCADOPAGO);
      if (mpProvider.length > 0) {
        this.mpAvailable = true;
        this.mpWeb = mpProvider[0].enabledWeb;
        this.mpMobile = mpProvider[0].enabledApp;
      }
      const pagoUnoProvider = this.paymentProviders.filter(pp => pp.provider === PaymentProvider.PAGOUNO);
      if (pagoUnoProvider.length > 0) {
        this.pagoUnoAvailable = true;
        this.pagoUnoWeb = pagoUnoProvider[0].enabledWeb;
        this.pagoUnoMobile = pagoUnoProvider[0].enabledApp;
      }
      const stripeProvider = this.paymentProviders.filter(pp => pp.provider === PaymentProvider.STRIPE);
      if (stripeProvider.length > 0) {
        this.stripeAvailable = true;
        this.stripeWeb = stripeProvider[0].enabledWeb;
        this.stripeMobile = stripeProvider[0].enabledApp;
      }
    }
  }

  setMobbexForWeb(event) {
    this.mobbexWeb = event.checked;
    this.status = event.checked;
    this.providerToSet = PaymentProvider.MOBBEX;
    this.channel = SalesChannels.BILLBOARDWEB;
    this.save();
  }

  setMercadoPagoForWeb(event) {
    this.mpWeb = event.checked;
    this.status = event.checked;
    this.providerToSet = PaymentProvider.MERCADOPAGO;
    this.channel = SalesChannels.BILLBOARDWEB;
    this.save();
  }

  setPagoUnoForWeb(event) {
    this.pagoUnoWeb = event.checked;
    this.status = event.checked;
    this.providerToSet = PaymentProvider.PAGOUNO;
    this.channel = SalesChannels.BILLBOARDWEB;
    this.save();
  }

  setStripeForWeb(event) {
    this.stripeWeb = event.checked;
    this.status = event.checked;
    this.providerToSet = PaymentProvider.STRIPE;
    this.channel = SalesChannels.BILLBOARDWEB;
    this.save();
  }

  setMobbexForMobile(event) {
    this.mobbexMobile = event.checked;
    this.status = event.checked;
    this.providerToSet = PaymentProvider.MOBBEX;
    this.channel = SalesChannels.BILLBOARDMOBILE;
    this.save();
  }

  setMercadoPagoForMobile(event) {
    this.mpMobile = event.checked;
    this.status = event.checked;
    this.providerToSet = PaymentProvider.MERCADOPAGO;
    this.channel = SalesChannels.BILLBOARDMOBILE;
    this.save();
  }

  setPagoUnoForMobile(event) {
    this.pagoUnoMobile = event.checked;
    this.status = event.checked;
    this.providerToSet = PaymentProvider.PAGOUNO;
    this.channel = SalesChannels.BILLBOARDMOBILE;
    this.save();
  }

  setStripeForMobile(event) {
    this.stripeMobile = event.checked;
    this.status = event.checked;
    this.providerToSet = PaymentProvider.STRIPE;
    this.channel = SalesChannels.BILLBOARDMOBILE;
    this.save();
  }

  disableProviderOutsideArgentina() {
    if(SalesChannels.BILLBOARDMOBILE === this.channel) {
      this.pagoUnoMobile = false;
      this.mobbexMobile = false;
    } else {
      this.pagoUnoWeb = false;
      this.mobbexWeb = false;
    }
  }

  configPlatform() {
    this.changeTabEmitter.emit();
  }

  someAvailableOnWeb() {
    return this.mobbexWeb || this.mpWeb || this.pagoUnoWeb;
  }

  someAvailableOnMobile() {
    return this.mobbexMobile || this.mpMobile || this.pagoUnoMobile;
  }

  save() {
    this.spinnerSrv.loadSpinner.next(true);
    const setProviderForChannelRequest = new SetProviderForChannelRequest();
    setProviderForChannelRequest.status = this.status;
    setProviderForChannelRequest.paymentProvider = this.providerToSet;
    if (this.channel === SalesChannels.BILLBOARDMOBILE) {
      this.paymentSrv.setPaymentProviderForMobile(this.storeId, setProviderForChannelRequest).subscribe({
        next: () => {
          this.spinnerSrv.loadSpinner.next(false);
        },
        error: err => {
          if (err.status === 404) {
            this.modalConfig = {
              headerText: 'Error',
              buttons: 'single',
              content:
                'El comercio ya no existe.',
            };
          } else if (err.status === 400) {
            this.modalConfig = {
              headerText: 'Error',
              buttons: 'single',
              content:
                'Este proveedor de pago no es válido para comercios que no sean de Argentina.',
            };
          }
          this.disableProviderOutsideArgentina();
          this.showModalConfirm = true;
          this.spinnerSrv.loadSpinner.next(false);
        }
      });
    } else {
      this.paymentSrv.setPaymentProviderForWeb(this.storeId, setProviderForChannelRequest).subscribe({
        next: () => {
          this.spinnerSrv.loadSpinner.next(false);
        },
        error: err => {
          if (err.status === 404) {
            this.modalConfig = {
              headerText: 'Error',
              buttons: 'single',
              content:
                'El comercio ya no existe.',
            };
          } else if (err.status === 400) {
            this.modalConfig = {
              headerText: 'Error',
              buttons: 'single',
              content:
                'Este proveedor de pago no es válido para comercios que no sean de Argentina.',
            };
          }
          this.disableProviderOutsideArgentina();
          this.showModalConfirm = true;
          this.spinnerSrv.loadSpinner.next(false);
        }
      });
    }
  }

  onModalResponse(e: boolean): void {
    this.showModalConfirm = false;
  }
}
