<p-dialog [(visible)]="categoryDialog" [closeOnEscape]="false" [draggable]="false" (onHide)="hideDialog()"
  [style]="{ width: '450px' }" header="{{ headerPopup }}" [modal]="true">
  <ng-template pTemplate="content">
    <form [formGroup]="categoryForm" class="w-100">
      <div class="w-100 flex justify-content-end pt-3 pb-3" *ngIf="isEditting">
        <button class="btn__remove-product" (click)="deleteCategory()">
          <i class="fa-light fa-trash-can text-error mr-1"></i> Eliminar
          categoría
        </button>
      </div>

      <div class="field__item field--modal mt-2" [ngClass]="
          validateInputClass(categoryForm, 'name', formHasBeenSubmitted)
        ">
        <div class="p-float-label">
          <input formControlName="name" id="name" type="text" maxlength="{{ maxCategoryNameLength }}" pInputText />
          <label for="email" class="title-float">Nombre*</label>
          <app-show-errors [page]="'new-category'" [ctrl]="categoryForm.get('name')"
            [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
        </div>
      </div>

      <div class="field__item field--modal" [ngClass]="
          validateInputClass(categoryForm, 'description', formHasBeenSubmitted)
        ">
        <div class="p-float-label">
          <textarea pInputTextarea formControlName="description" id="description"
            maxlength="{{ maxCategoryDescriptionLength }}" type="text" cols="30" rows="4"></textarea>
          <label for="description" class="title-float">Descripción</label>
          <app-show-errors [page]="'new-category'" [ctrl]="categoryForm.get('description')"
            [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
        </div>
        <div class="field__item--info">
          <div *ngIf="categoryForm.get('description').valid || categoryForm.get('description').value == null"
            class="field__item--info">
            <!-- this.ctrl.touched && this.ctrl.dirty && this.ctrl.invalid -->
            <span>{{ descriptionField.value ? descriptionField.value.length : 0 }}/{{
              maxCategoryDescriptionLength
              }}</span>
          </div>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="w-100 flex">
      <button pButton class="btn btn__secondary btn__secondary--bold mr-3" (click)="hideDialog()"
        label="Cancelar"></button>
      <button pButton class="btn btn__primary" [class.spinner]="loading" (click)="submitCategory()"
        label="Guardar"></button>
    </div>
  </ng-template>
</p-dialog>

<p-confirmDialog [style]="{ width: '450px' }" acceptLabel="Si" rejectLabel="No"
  acceptButtonStyleClass="btn btn__primary" rejectButtonStyleClass="btn btn__secondary"></p-confirmDialog>
