<div *ngIf="eventData" class="ticket">

    <div class="event__image">
        <img *ngIf="eventData.imageUrl" src="{{ getUrl(eventData.imageUrl) }}" alt="">
        <img *ngIf="!eventData.imageUrl" src="src/assets/event-placeholder.jpg" alt="">
    </div>

    <div class="event__info">
        <div class="first-row margin-bottom-24">
            <div class="margin-right-24" [innerHTML]="getLabelStatus(eventData)"></div>            
            <div class="text-primary cursor-pointer font-size-12 see-description" (click)="openDescription()">
                <i class="fa-light fa-circle-plus margin-right-6"></i> Ver descripción
            </div>
        </div>
        <h2 class="font-size-32 text-gray-1 lh-24 font-weight-600 p-my-0 word-break margin-bottom-24">{{ eventData.eventName }}</h2>
        <div class="icon-container lh-16 font-size-14 text-gray-3">
            <i class="fa-solid fa-calendar-clock text-primary margin-right-8"></i>
            <p class="text__date">Inicio: <span class="font-weight-600 text-gray-1">{{ eventData.dateInit | date: "dd MMM - HH:mm" }}</span> Fin: <span class="font-weight-600 text-gray-1">{{ eventData.dateEnd | date: "dd MMM - HH:mm" }}</span></p>
        </div>
        <div class="icon-container lh-16 font-size-14 text-gray-3">
            <i class="fa-sharp fa-solid fa-location-dot text-primary margin-right-8"></i>
            <p class="text__date">{{ shortAddress(eventData.address) }}</p>
        </div>
    </div>


    <div class="store__info">
    <ul>
        <li class="margin-bottom-16 font-size-14 lh-16"><i class="fa-solid fa-store margin-right-8"></i> <span class="font-poppins font-weight-600">{{ eventData.storeResponse.name }}</span></li>
        <li class="margin-bottom-16 font-size-14 lh-16"><i class="fa-solid fa-phone margin-right-8"></i> {{ eventData.storeResponse.phone }}</li>
        <li class="margin-bottom-16 font-size-14 lh-16"><i class="fa-solid fa-envelope margin-right-8"></i> {{ eventData.storeResponse.email }}</li>        
    </ul>
    </div>
</div>