<p-toast></p-toast>
<section class="event-configuration">
  <div class="text-primary margin-bottom-24 cursor-pointer back" (click)="backToEvent()">
    <i class="fa-sharp fa-solid fa-arrow-left-long margin-right-8"></i> Volver a Eventos
  </div>
  <header>
    <app-ticket-event
      *ngIf="event && eventCategory"
      [eventData]="event"
      [eventCategory]="eventCategory"></app-ticket-event>
  </header>
  <div>
    <app-cards-event-configuration
      *ngIf="event && categoriesEvents && eventCategory"
      [eventData]="event"
      [eventCategory]="eventCategory"
      [categoriesEvents]="categoriesEvents"
      (saveCategory)="onChangeCategory($event)"
      (refreshEventData)="onRefreshEventData()"
    ></app-cards-event-configuration>
  </div>

  <i class="blackid-icons icon-dots icon-md dots-menu" (click)="menu.toggle($event)"></i>
  <p-menu #menu styleClass="dots-menu" appendTo="body" [popup]="true" [model]="itemsEventMenu"></p-menu>
</section>

<section class="event-stats" *ngIf="event">
  <app-cards-number-stats *ngIf="event && eventSalesInfo" [event]="event"
                          [static]="eventSalesInfo"></app-cards-number-stats>
  <app-cards-generals-stats *ngIf="event && eventSalesInfo" [event]="event"
                            [static]="eventSalesInfo"></app-cards-generals-stats>
</section>

<p-confirmDialog id="settlement" key="settlement" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<app-reschedule-event *ngIf="event && showEditEventModal"
                      [eventData]="event"
                      (closeModal)="onCloseRescheduleEventModal($event)">
</app-reschedule-event>
