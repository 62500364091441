<form [formGroup]="reservasForm" [ngClass]="{ form_submitted: formHasBeenSubmitted }">
  <p-toast key="toast"></p-toast>
  <div class="header">
    <p class="text-xl font-semibold text-gray-1 lh-16 my-0">RESERVAS</p>
    <div class="flex justify-content-end">
      <div class="switch-wrapper">
        <label for="bookingAvailable" class="label-switch"> Habilitar </label>
        <div class="switch-container">
          <p-inputSwitch
            name="bookingAvailable"
            formControlName="bookingAvailable"
            [disabled]="fromAdmin"
          >
          </p-inputSwitch>
        </div>
      </div>
    </div>
  </div>

  <div class="grid mt-4 general-content">
    <div class="content-background">
      <div class="col-5">
        <div class="flex align-items-center mb-3">
          <label for="peopleAdmittedPerHour" class="mr-2">Capacidad máxima por turno*</label>
          <div class="tooltip-cursor" pTooltip="Te recomendamos no disponibilizar el 100% de la capacidad de tu comercio."
            tooltipPosition="top">
            <i class="fa-light fa-circle-info"></i>
          </div>
        </div>
        <div class="field__item field--number">
          <div class="p-float-label">
            <p-inputNumber
              id="peopleAdmittedPerHour"
              formControlName="peopleAdmittedPerHour"
              [showButtons]="true"
              buttonLayout="horizontal"
              spinnerMode="horizontal"
              decrementButtonClass="p-button-decrement mr-2"
              incrementButtonClass="p-button-increment ml-2"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              mode="decimal"
              [min]="reservasForm.controls.maxPerTable.value"
              max="{{ maxPeopleAdmittedPerHour }}"
              [disabled]="fromAdmin"
              locale="es-AR"
            ></p-inputNumber>
            <app-show-errors
              [page]="'configurations'"
              [ctrl]="reservasForm.get('peopleAdmittedPerHour')"
              [formHasBeenSubmitted]="formHasBeenSubmitted"
            ></app-show-errors>
          </div>
        </div>
      </div>

      <div class="col-5 offset-1">
        <div class="flex align-items-center mb-3">
          <label for="previousMinutes" class="mr-2">Cupo máximo por reserva*</label>
          <div class="tooltip-cursor" pTooltip="Cantidad máxima permitida de personas por reserva."
            tooltipPosition="top">
            <i class="fa-light fa-circle-info"></i>
          </div>
        </div>
        <div class="field__item field--number">
          <div class="p-float-label">
            <p-inputNumber
              [disabled]="fromAdmin"
              id="maxPerTable"
              formControlName="maxPerTable"
              [showButtons]="true"
              buttonLayout="horizontal"
              spinnerMode="horizontal"
              decrementButtonClass="p-button-decrement mr-2"
              incrementButtonClass="p-button-increment ml-2"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              mode="decimal"
              min="1"
              max="{{ maxPeoplePerBooking }}"
              locale="es-AR"
            ></p-inputNumber>
            <app-show-errors
              [page]="'configurations'"
              [ctrl]="reservasForm.get('maxPerTable')"
              [formHasBeenSubmitted]="formHasBeenSubmitted"
            ></app-show-errors>
          </div>
        </div>
      </div>
      <hr class="separator">
      <div class="col-5">
        <div class="switch-wrapper">
          <label for="autoConfirm" class="label-switch-autoconfirm"> Confirmación de reservas automática </label>
          <div class="switch-container">
            <p-inputSwitch
              name="autoConfirm"
              formControlName="autoConfirm"
              [disabled]="fromAdmin"
            >
            </p-inputSwitch>
            <div class="tooltip-cursor ml-3" pTooltip="Al activar tus reservas de forma automáticas, la prioridad será otorgada a los usuarios de Black iD." tooltipPosition="right" tooltipStyleClass="autoconfirm-tooltip">
              <i class="fa-light fa-circle-info"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span class="input-required col-8 pr-4">* campos obligatorios</span>

    <div class="col-12 p-0">
      <app-shifts-list [shifts]="shifts" [fromAdmin]="true" (reloadShifts)="loadShifts($event)"></app-shifts-list>
    </div>
  </div>

  <p-toast key="save-message"></p-toast>
</form>
