import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StoreStatistics } from 'src/app/core/models/StoreStatistics';
import { SpinnerService } from 'src/app/services/spinner.service';
import { StatsService } from 'src/app/services/stats.service';

@Component({
  selector: 'app-booking-stats',
  templateUrl: './booking-stats.component.html',
  styleUrls: ['./booking-stats.component.scss']
})
export class BookingStatsComponent implements OnInit {

  @Input() stats: StoreStatistics = null;
  selectedValues: string[] = ['checkDelivery', 'checkTakeAway', 'checkTable'];
  openFilter = false;
  checked = false;
  type: string = 'currentWeek';
  leftDataColumns = [];
  leftDataColumnsString = 'Reservas'
  rightDataColumns = [];
  rightDataColumnsString = 'Personas';
  loading = false;
  storeId: number;

  leftDataCategories: Array<string> = [];

  constructor(
    private statsService: StatsService,
    private spinnerService: SpinnerService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    //this.spinnerService.loadSpinner.next(true);
    //this.loading = true;
    this.loadData();
    this.createCategoriesData();
  }

  createCategoriesData() {
    for (let i=7; i>=1; i--) {
      this.leftDataCategories.push(this.statsService.dayDisplay(i));
    }
  }

  loadData() {
    this.storeId = Number(sessionStorage.getItem('storeId'));
    this.statsService.getBookingStats(this.storeId).subscribe((data) => {
      this.stats = data;
      this.leftDataColumns = [[
        this.leftDataColumnsString,
        this.stats.totalBookingsCurrentWeek ? this.stats.totalBookingsCurrentWeek[0] : 0,
        this.stats.totalBookingsCurrentWeek ? this.stats.totalBookingsCurrentWeek[1] : 0,
        this.stats.totalBookingsCurrentWeek ? this.stats.totalBookingsCurrentWeek[2] : 0,
        this.stats.totalBookingsCurrentWeek ? this.stats.totalBookingsCurrentWeek[3] : 0,
        this.stats.totalBookingsCurrentWeek ? this.stats.totalBookingsCurrentWeek[4] : 0,
        this.stats.totalBookingsCurrentWeek ? this.stats.totalBookingsCurrentWeek[5] : 0,
        this.stats.totalBookingsCurrentWeek ? this.stats.totalBookingsCurrentWeek[6] : 0
      ]],
        this.rightDataColumns = [[
          this.rightDataColumnsString,
          this.stats.peopleByBookingCurrentWeek ? this.stats.peopleByBookingCurrentWeek[0] : 0,
          this.stats.peopleByBookingCurrentWeek ? this.stats.peopleByBookingCurrentWeek[1] : 0,
          this.stats.peopleByBookingCurrentWeek ? this.stats.peopleByBookingCurrentWeek[2] : 0,
          this.stats.peopleByBookingCurrentWeek ? this.stats.peopleByBookingCurrentWeek[3] : 0,
          this.stats.peopleByBookingCurrentWeek ? this.stats.peopleByBookingCurrentWeek[4] : 0,
          this.stats.peopleByBookingCurrentWeek ? this.stats.peopleByBookingCurrentWeek[5] : 0,
        ]];
      //this.spinnerService.loadSpinner.next(false);
      this.loading = false;
    });
  }

  openFilters() {
    if (this.openFilter === false) {
      this.openFilter = true;
    } else {
      this.openFilter = false;
    }
  }

  segmentChanged(ev: any) {
    this.type = ev.target.value;
    this.checked = false;
    if (this.type === 'currentWeek') {
      this.checked = false;
      this.leftDataColumns = [[
        this.leftDataColumnsString,
        this.stats.totalBookingsCurrentWeek ? this.stats.totalBookingsCurrentWeek[0] : 0,
        this.stats.totalBookingsCurrentWeek ? this.stats.totalBookingsCurrentWeek[1] : 0,
        this.stats.totalBookingsCurrentWeek ? this.stats.totalBookingsCurrentWeek[2] : 0,
        this.stats.totalBookingsCurrentWeek ? this.stats.totalBookingsCurrentWeek[3] : 0,
        this.stats.totalBookingsCurrentWeek ? this.stats.totalBookingsCurrentWeek[4] : 0,
        this.stats.totalBookingsCurrentWeek ? this.stats.totalBookingsCurrentWeek[5] : 0,
        this.stats.totalBookingsCurrentWeek ? this.stats.totalBookingsCurrentWeek[6] : 0,
      ]];

      this.rightDataColumns = [[
        this.rightDataColumnsString,
        this.stats.peopleByBookingCurrentWeek ? this.stats.peopleByBookingCurrentWeek[0] : 0,
        this.stats.peopleByBookingCurrentWeek ? this.stats.peopleByBookingCurrentWeek[1] : 0,
        this.stats.peopleByBookingCurrentWeek ? this.stats.peopleByBookingCurrentWeek[2] : 0,
        this.stats.peopleByBookingCurrentWeek ? this.stats.peopleByBookingCurrentWeek[3] : 0,
        this.stats.peopleByBookingCurrentWeek ? this.stats.peopleByBookingCurrentWeek[4] : 0,
        this.stats.peopleByBookingCurrentWeek ? this.stats.peopleByBookingCurrentWeek[5] : 0,
      ]];

    } else {
      this.checked = true;
      this.leftDataColumns = [[
        this.leftDataColumnsString,
        this.stats.totalBookingsLastWeek ? this.stats.totalBookingsLastWeek[0] : 0,
        this.stats.totalBookingsLastWeek ? this.stats.totalBookingsLastWeek[1] : 0,
        this.stats.totalBookingsLastWeek ? this.stats.totalBookingsLastWeek[2] : 0,
        this.stats.totalBookingsLastWeek ? this.stats.totalBookingsLastWeek[3] : 0,
        this.stats.totalBookingsLastWeek ? this.stats.totalBookingsLastWeek[4] : 0,
        this.stats.totalBookingsLastWeek ? this.stats.totalBookingsLastWeek[5] : 0,
        this.stats.totalBookingsLastWeek ? this.stats.totalBookingsLastWeek[6] : 0,
      ]],
        this.rightDataColumns = [[
          this.rightDataColumnsString,
          this.stats.peopleByBookingLastWeek ? this.stats.peopleByBookingLastWeek[0] : 0,
          this.stats.peopleByBookingLastWeek ? this.stats.peopleByBookingLastWeek[1] : 0,
          this.stats.peopleByBookingLastWeek ? this.stats.peopleByBookingLastWeek[2] : 0,
          this.stats.peopleByBookingLastWeek ? this.stats.peopleByBookingLastWeek[3] : 0,
          this.stats.peopleByBookingLastWeek ? this.stats.peopleByBookingLastWeek[4] : 0,
          this.stats.peopleByBookingLastWeek ? this.stats.peopleByBookingLastWeek[5] : 0,
        ]];
    }
  }

}
