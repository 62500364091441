import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NewMenu } from '../core/models/Menu';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private httpC: HttpClient, private authService: AuthService) {}

  // MENU
  public getMenu(): Observable<any> {
    const { id } = this.authService.getLocalUserData();
    return this.httpC.get(environment.apiAGUrl + '/menu/' + id);
  }

  public createMenu(form: NewMenu): Observable<NewMenu> {
    const { id } = this.authService.getLocalUserData();
    return this.httpC.post<NewMenu>(environment.apiAGUrl + '/menu/' + id, form);
  }
}
