import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Attribute,
  AttributeValue,
  NewAttribute,
  NewAttributeValue,
} from '../core/models/Attribute';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AttributesService {
  constructor(private httpC: HttpClient, private authService: AuthService) {}

  public getAttributes(): Observable<Attribute[]> {
    const { id } = this.authService.getLocalUserData();
    return this.httpC.get<Attribute[]>(
      environment.apiAGUrl + `/attributes/${id}`
    );
  }

  public createAttribute(form: NewAttribute): Observable<NewAttribute> {
    const { id } = this.authService.getLocalUserData();
    return this.httpC.post<NewAttribute>(
      environment.apiAGUrl + `/attributes/${id}`,
      form
    );
  }

  public deleteAttribute(idAttribute, force?): Observable<void> {
    const { id } = this.authService.getLocalUserData();
    return this.httpC.delete<void>(
      environment.apiAGUrl +
        `/attributes/${idAttribute}/${id}${force ? '?force=true' : ''}`
    );
  }

  public editAttribute(idAttribute, form: NewAttribute): Observable<void> {
    return this.httpC.put<void>(
      environment.apiAGUrl + `/attributes/${idAttribute}`,
      form
    );
  }

  public addAttributeValues(
    idAttribute,
    form: NewAttributeValue
  ): Observable<void> {
    return this.httpC.post<void>(
      environment.apiAGUrl + `/attributes/add-attribute-value/${idAttribute}`,
      form
    );
  }

  public deleteAttributeValue(idAttibuteValue): Observable<void> {
    return this.httpC.delete<void>(
      environment.apiAGUrl +
        `/attributes/delete-attribute-value/${idAttibuteValue}`
    );
  }

  public editAttributeValues(
    idAttibuteValue,
    form: NewAttributeValue
  ): Observable<void> {
    return this.httpC.put<void>(
      environment.apiAGUrl + `/attributes/attribute-value/${idAttibuteValue}`,
      form
    );
  }

  public getAttributeValues(idAttibute): Observable<AttributeValue[]> {
    return this.httpC.get<AttributeValue[]>(
      environment.apiAGUrl + `/attributes/by_id/${idAttibute}`
    );
  }
}
