import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Table } from 'primeng/table';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Configurations } from '../core/models/Configurations';
import { Shift } from '../core/models/Shitf';
import { VipUser } from '../core/models/VipUser';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(private httpC: HttpClient, private authSrv: AuthService) {}

  public getConfigurations(idStore: number): Observable<Configurations> {
    return this.httpC.get<Configurations>(
      environment.apiAGUrl + '/stores/configuration/' + idStore
    );
  }

  public updateConfigurations(payload, id): Observable<any> {
    return this.httpC.patch<any>(
      environment.apiAGUrl + '/stores/configuration/' + id,
      payload,
      { observe: 'response' }
    );
  }

  objectsHaveDifferences(first, second): boolean {
    for (const key in first) {
      if (second.hasOwnProperty(key)) {
        if (first[key] !== second[key]) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  public updatePassword(form): Observable<void> {
    const { id } = this.authSrv.getLocalUserData();
    return this.httpC.post<void>(
      environment.apiAGUrl + '/store/changePasswordConfiguration/' + id,
      form
    );
  }

  getTables(): Observable<Table[]> {
    const { id } = this.authSrv.getLocalUserData();
    return this.httpC.get<Table[]>(environment.apiAGUrl + `/tables/${id}`);
  }

  getShifts(): Observable<Shift[]> {
    const { id } = this.authSrv.getLocalUserData();
    return this.httpC.get<Shift[]>(
      `${environment.apiAGUrl}/shifts/by_store/${id}`
    );
  }

  public getVipUsers(): Observable<VipUser[]> {
    const { id } = this.authSrv.getLocalUserData();
    return this.httpC.get<VipUser[]>(environment.apiAGUrl + `/vipClient/${id}`);
  }
}
