<article>
  <div class="display-flex flex-column justify-content-center align-items-start box__porcent">
    <div class="display-flex flex-column align-items-start justify-content-start">
      <div class="circle-icon--big">
        <i class="fas font-w-300 {{ icon }} fa-xl text-gray-1"></i>
      </div>
      <p class="font-size-32 font-semibold margin-top-16 mb-0 {{ valueClass ? valueClass : ''}}">
        <span class="mr-2" *ngIf="prefixValue">{{ prefixValue }}</span>{{ value | number: '1.0-0' }}
      </p>
    </div>
    <p class="font-size-18 text-gray-1 margin-top-8 mb-0 lh-24 font-light">{{ description }}</p>
  </div>
</article>
