import {Component, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {Subscription} from 'rxjs';
import {CommissionRequest, CommissionResponse} from 'src/app/core/models/PaymentsConfigurations/CommissionConfig';
import {SpinnerService} from 'src/app/services/spinner.service';
import {StoreService} from 'src/app/services/store.service';

@Component({
  selector: 'app-porcents',
  templateUrl: './porcents.component.html',
  styleUrls: ['./porcents.component.scss']
})
export class PorcentsComponent implements OnInit {

  splitModel: boolean = true;
  subs: Subscription[] = [];
  storeId: number;
  accessPercentage: number;
  tablePercentage: number;
  percentageForUs: number;
  percentageForStore: number;

  constructor(
    private storeService: StoreService,
    private spinnerService: SpinnerService,
    private messageService: MessageService
  ) {
  }

  ngOnInit(): void {
    this.storeId = Number(sessionStorage.getItem('storeId'));
    this.getCommission();
  }

  getCommission() {
    this.spinnerService.loadSpinner.next(true);
    this.subs.push(
      this.storeService.getCommissionConfig(this.storeId).subscribe({
        next: (response: CommissionResponse) => {
          this.splitModel = response.isSplitCommission;
          this.accessPercentage = response.accessPercentage;
          this.tablePercentage = response.tablePercentage;
          this.percentageForUs = response.percentageForUs;
          this.percentageForStore = response.percentageForStore;
          this.spinnerService.loadSpinner.next(false);
        },
        error: (error) => {
          this.spinnerService.loadSpinner.next(false);
        }
      })
    );
  }

  onPercentageForAccessChange(event) {
    if (event.value > 100) {
      this.accessPercentage = 100;
    } else if (!event.value || event.value < 0) {
      this.accessPercentage = 0;
    } else {
      this.accessPercentage = event.value;
    }
  }


  onPercentageForTableChange(event) {
    if (event.value > 100) {
      this.tablePercentage = 100;
    } else if (!event.value || event.value < 0) {
      this.tablePercentage = 0;
    } else {
      this.tablePercentage = event.value;
    }
  }

  onPercentageStoreChange(event) {
    if (event.value > 100) {
      this.percentageForStore = 100;
      this.percentageForUs = 0;
    } else if (!event.value || event.value < 0) {
      this.percentageForStore = 0;
      this.percentageForUs = 100;
    } else {
      this.percentageForStore = event.value;
      this.percentageForUs = 100 - event.value;
    }

  }

  splitChange(event) {
    this.splitModel = event.checked;
  }

  cancel() {
    this.getCommission();
  }

  save() {
    this.spinnerService.loadSpinner.next(true);
    const payload: CommissionRequest = {
      isSplitCommission: this.splitModel,
      percentageForUs: this.percentageForUs,
      percentageForStore: this.percentageForStore,
      accessPercentage: this.accessPercentage,
      tablePercentage: this.tablePercentage
    };
    this.subs.push(
      this.storeService.setCommissionConfig(this.storeId, payload).subscribe({
        next: () => {
          this.showMessageOk();
          this.spinnerService.loadSpinner.next(false);

        },
        error: () => {
          this.spinnerService.loadSpinner.next(false);
        }
      })
    );
  }

  showMessageOk(): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Datos guardados con éxito.',
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => {
      s.unsubscribe();
    });
  }
}
