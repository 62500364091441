<div class="">
  <p-toast></p-toast>

  <div class="table__container">
    <p-table #dt [value]="products" [rows]="10" [paginator]="true" selectionMode="multiple" [loading]="loading"
             [globalFilterFields]="[
        'name',
        'description',
        'categoryName',
        'available',
        'tablePrice',
        'deliveryPrice'
      ]" sortField="name" sortOrder="1" [(selection)]="selectedProducts" [rowHover]="true" dataKey="id"
             [rowsPerPageOptions]="[10,25,50]" currentPageReportTemplate="Mostrar" [showCurrentPageReport]="true">
      <ng-template pTemplate="caption">
        <div class="flex align-items-start md:align-items-center justify-content-between table-header first-line pb-3">
          <div class="flex justify-content-start align-items-center">
            <i class="fa-light fa-burger-soda fa-2x"></i>
            <p class="my-0 text-xl ml-2">
              PRODUCTOS
              <span class="ml-2 text-xs font-normal text-primary">
                {{ products ? products.length : 0 }} productos en total
              </span>
            </p>
          </div>
          <div class="flex justify-content-end align-items-center mt-3 md:mt-0">
            <button pButton label="Descargar PDF" class="btn btn__primary btn__primary--bold btn__size--xs mr-2"
                    (click)="downloadPDF()" *ngIf="canDownloadPDF"></button>

            <button pButton label="Agregar" icon="fa-regular fa-circle-plus fa-lg"
                    class="btn btn__primary btn__primary--bold btn__size--xs mr-2" (click)="openNew()"
                    [disabled]="canWrite !== undefined && !canWrite"></button>

            <button pButton label="Borrar" icon="fa-light fa-trash-can fa-lg" class="btn btn__secondary btn__size--xs"
                    (click)="deleteSelectedProducts()" [disabled]="
                !selectedProducts ||
                selectedProducts.length === 0 ||
                (canWrite !== undefined && !canWrite)
              "></button>
          </div>
        </div>
        <div class="table-header pt-3 pb-0">
          <div class="flex align-items-center justify-content-start">
            <p-tableHeaderCheckbox [disabled]="canWrite !== undefined && !canWrite"></p-tableHeaderCheckbox>
            <p class="text-sm font-normal text-gray-3 lh-16 my-0 mt-1 ml-2">
              Seleccionar todo
            </p>
          </div>
          <div class="search-container mt-3 md:mt-0">
            <div class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText class="search-text" type="text"
                     (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Buscar"/>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 1rem"></th>
          <th>Imagen</th>
          <th pSortableColumn="name">
            <div class="flex justify-content-start align-items-center">
              Nombre
              <p-sortIcon field="name"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="description">
            <div class="flex justify-content-start align-items-center">
              Descripción
              <p-sortIcon field="description"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="categoryName">
            <div class="flex justify-content-start align-items-center">
              Categoría
              <p-sortIcon field="categoryName"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="tablePrice">
            <div class="flex justify-content-start align-items-center">
              <span style="width: 65px">Precio</span>
              <p-sortIcon field="tablePrice"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="discount">
            <div class="flex justify-content-start align-items-center">
              Descuento
              <p-sortIcon field="discount"></p-sortIcon>
            </div>
          </th>
          <!-- <th style="min-width: 110px">
          </th> -->
        </tr>
        <tr>
          <td class="space"></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr class="table-row">
          <td>
            <div class="product__checkbox">
              <p-tableCheckbox (onChange)="onChangeCheck($event)" [value]="product"
                               [disabled]="canWrite !== undefined && !canWrite"></p-tableCheckbox>
            </div>
          </td>
          <td (click)="editProduct(product)">
            <img *ngIf="product.imageUrl" [src]="getUrlImage(product.imageUrl)" [alt]="product.name" width="64"
                 class="image-product-td"/>
            <div class="no-image" *ngIf="!product.imageUrl">
              <img src="../../../../../../../../assets/placeholder-product.svg" alt="no-image-product" width="37"/>
            </div>
          </td>
          <td (click)="editProduct(product)" class="text-sm lh-16 font-semibold text-gray-1">
            {{ product.name }}
          </td>
          <td (click)="editProduct(product)" class="text-xs lh-16 font-normal text-gray-1">
            {{ product.description }}
          </td>
          <td (click)="editProduct(product)" class="text-sm lh-16 font-normal text-gray-1">
            {{ product.categoryName }}
          </td>
          <td (click)="editProduct(product)" class="text-sm lh-16 font-semibold">
            <span *ngIf="product.tablePrice" [ngClass]="product.available ? 'type-available' : 'type-disabled'">{{
              product.tablePrice | currency: "$" }}
            </span>
            <span *ngIf="!product.tablePrice" [ngClass]="product.available ? 'type-available' : 'type-disabled'">{{ 0 |
              currency: "$" }}
            </span>
          </td>
          <td (click)="editProduct(product)" class="text-sm lh-16 font-normal text-gray-1">
            {{ product.discount }} %
          </td>
        </tr>
        <div class="my-2"></div>
      </ng-template>
    </p-table>
  </div>

  <p-dialog [(visible)]="productDialog" [draggable]="false" appendTo="body" [style]="{ width: '550px' }"
            header="{{ headerPopup }}" [modal]="true" styleClass="dialog-product" (onHide)="hideDialog()">
    <ng-template pTemplate="content">
      <div class="w-100 flex justify-content-end align-items-center pt-3 mb-3">
        <button *ngIf="isEditting" class="btn__remove-product" [disabled]="canWrite !== undefined && !canWrite"
                (click)="deleteProduct()">
          <i class="fa-light fa-trash-can text-error mr-1"></i> Eliminar producto
        </button>
      </div>

      <form class="form" [formGroup]="productForm" [ngClass]="{ form__submitted: submitted }">
        <div class="field__item field--modal pt-3" [ngClass]="
            validateInputClass(productForm, 'name', formHasBeenSubmitted)
          ">
          <div class="p-float-label">
            <input formControlName="name" id="name" type="text" maxlength="{{ maxProductNameLength }}" pInputText/>
            <label for="name" class="title-float">Nombre*</label>
            <app-show-errors [page]="'contact'" [ctrl]="productForm.get('name') | formControl"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
          <div
            *ngIf="formService.shoudShowInputCount(productForm, 'name', formHasBeenSubmitted)"
            class="field__item--info">
            <span>{{
              productForm.get("name").value
                ? productForm.get("name").value.length
                : 0
              }}/{{ maxProductNameLength }}</span>
          </div>
        </div>

        <div class="field__item field--modal" [ngClass]="
            validateInputClass(productForm, 'description', formHasBeenSubmitted)
          ">
          <div class="p-float-label">
            <textarea pInputTextarea formControlName="description" id="description"
                      maxlength="{{ maxProductDescriptionLength }}" type="text" cols="30" rows="4"></textarea>
            <label for="description" class="title-float">Descripción*</label>
            <app-show-errors [page]="'products'" [ctrl]="productForm.get('description') | formControl"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
          <div *ngIf="formService.shoudShowInputCount(productForm, 'description', formHasBeenSubmitted)"
               class="field__item--info">
            <span>{{
              productForm.get("description").value
                ? productForm.get("description").value.length
                : 0
              }}/{{ maxProductDescriptionLength }}</span>
          </div>
        </div>

        <div class="field__item field--modal field--dropdown" [ngClass]="
            validateInputClass(
              productForm,
              'categoryProductId',
              formHasBeenSubmitted
            )
          ">
          <div class="p-float-label">
            <p-dropdown [options]="categories" dataKey="selectedCategory" formControlName="categoryProductId"
                        id="categories" optionLabel="name" [filter]="true" (onChange)="onChange($event)"
                        placeholder="Categoría*">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center" *ngIf="selectedCategory">
                  <div>{{ selectedCategory.name }}</div>
                </div>
              </ng-template>
              <ng-template let-category pTemplate="item">
                <div [ngClass]="
                    category.id === selectedCategory.id ? 'text-primary' : ''
                  " class="flex align-items-center">
                  <div>{{ category.name }}</div>
                </div>
              </ng-template>
            </p-dropdown>
            <label for="description" class="title-float">Categoría*</label>
            <app-show-errors [page]="'products'" [ctrl]="productForm.get('categoryProductId') | formControl"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
        </div>

        <div>
          <div class="mt-3 mb-8">
            <a class="btn__add-new" (click)="openNewCategory()">
              <i class="fa-light fa-circle-plus text-primary mr-2"></i> Nueva
              categoría
            </a>
          </div>

          <app-new-category-modal (loadCategories)="loadCategories($event)" [showNewCategory]="showNewCategory"
                                  [categoryForm]="categoryForm"
                                  (categoryDialogOut)="hideCategoryDialog($event)"></app-new-category-modal>

        </div>

        <div class="field__item field--modal">
          <div class="p-float-label">
            <p-multiSelect [options]="getValidAttributes(attributes)" (onChange)="selectAttribute($event)"
                           [(ngModel)]="selectedAttributes" optionLabel="name" [ngModelOptions]="{ standalone: true }"
                           selectedItemsLabel="{selectedAttributes.length} opciones seleccionadas"
                           class="p-multiselect">
              <ng-template let-value pTemplate="selectedAttributes">
                <div *ngFor="let attr of selectedAttributes">
                  <div>{{ attr.name }}</div>
                </div>
              </ng-template>
              <ng-template let-attr pTemplate="item">
                <div>
                  <div>
                    {{ attr.name }}
                    <span *ngIf="attr.description" style="font-size: 14px; color: darkgray !important">({{
                      attr.description }})</span>
                  </div>
                </div>
              </ng-template>
            </p-multiSelect>
            <label for="description" class="title-float">Opcionales</label>
          </div>
        </div>

        <div class="col-12 available-for">
          <p>Disponible para</p>
        </div>

        <div class="grid disp-check">
          <div class="p-field col">
            <p-checkbox label="Disponible" binary="true" [formControl]="productForm.controls['available'] | formControl"
                        class="field-checkbox"></p-checkbox>
            <div class="field__item field--number field--modal">
              <div class="p-float-label">
                <p-inputNumber [disabled]="!productForm.controls['available'].value" formControlName="tablePrice"
                               class="p-input-number" id="tablePrice" prefix="$ " [min]="0" max="{{ maxProductPrice }}"
                               type="number"
                               locale="es-AR">
                </p-inputNumber>
                <label for="description" class="title-float">Precio</label>
              </div>
            </div>
          </div>
        </div>

        <div class="field__item field--number field--modal">
          <span class="p-float-label">
            <p-inputNumber formControlName="discount" id="discount" [min]="0" max="{{ maxDiscount }}" suffix="%"
                           type="number" locale="es-AR">
            </p-inputNumber>
            <label for="description" class="title-float">Descuento</label>
          </span>
        </div>

        <div class="col-12" *ngIf="
            isEditting &&
            productForm &&
            productForm.controls['image'] &&
            productImageSelected !== null
          " style="text-align: center">
          <span class="custom-input">
            <div class="container__image">
              <img id="image" *ngIf="productImageSelected !== null" [src]="currentImage" width="50%"
                   class="shadow-4 image-product"/>
              <div class="block-buttons px-2">
                <button pButton class="btn btn__primary btn__size--sm mr-3" (click)="changeImage()"
                        label="Cambiar"></button>
                <button pButton class="btn btn__secondary btn__size--sm" (click)="removeImage()"
                        label="Quitar"></button>
              </div>
            </div>
            <app-load-img-cropp style="width: 50%" [hide]="true" [title]="titleLoadImage" formControlName="image"
                                (removeEmit)="onRemoveThumbnail($event)" [cancelBtn]="'Cancelar'"
                                (imageHasBeenSelected)="onImageHasBeenSelected($event)">
            </app-load-img-cropp>
          </span>
        </div>

        <div class="w-100" *ngIf="isEditting && productImageSelected === null">
          <button pButton class="btn btn__primary btn__size--sm" label="Seleccionar imagen" (click)="changeImage()"
                  *ngIf="productForm.controls['image'].value === null"></button>
          <app-load-img-cropp style="width: 50%" [hide]="true" [title]="titleLoadImage" formControlName="image"
                              (removeEmit)="onRemoveThumbnail($event)" [cancelBtn]="'Cancelar'"
                              (imageHasBeenSelected)="onImageHasBeenSelected($event)">
          </app-load-img-cropp>
        </div>

        <div class="image__container" *ngIf="!isEditting">
          <p class="text-lg text-gray-1 font-semibold">Cargar imagen</p>
          <div class="upload__image">
            <button pButton class="btn btn__secondary btn__size--sm" label="Seleccionar archivo" (click)="changeImage()"
                    *ngIf="productForm.controls['image'].value === null"></button>
          </div>
          <app-load-img-cropp style="width: 50%" [hide]="true" [title]="titleLoadImage" formControlName="image"
                              (removeEmit)="onRemoveThumbnail($event)" [cancelBtn]="'Cancelar'"
                              (imageHasBeenSelected)="onImageHasBeenSelected($event)">
          </app-load-img-cropp>
        </div>
      </form>
    </ng-template>

    <ng-template pTemplate="footer">
      <button pButton label="Cancelar" class="btn btn__secondary btn__secondary--bold mr-3"
              (click)="hideDialog()"></button>
      <button pButton label="Guardar producto" class="btn btn__primary" (click)="submitProduct()"></button>
    </ng-template>
  </p-dialog>

  <p-confirmDialog [style]="{ width: '450px' }" acceptLabel="Eliminar" rejectLabel="Cancelar"
                   acceptButtonStyleClass="btn btn__primary"
                   rejectButtonStyleClass="btn btn__secondary btn__secondary--bold mr-3">
  </p-confirmDialog>
</div>

<app-menu-to-pdf class="p-d-none" [menuData]="newMenuData" [downloadPdf]="canDownloadPDF"></app-menu-to-pdf>
