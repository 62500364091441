import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product, NewProduct } from '../core/models/Product';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private httpC: HttpClient, private authService: AuthService) {}

  public getProducts(idStore): Observable<Product[]> {
    return this.httpC.get<Product[]>(
      environment.apiAGUrl + '/products/' + idStore
    );
  }

  public createProduct(form: NewProduct): Observable<NewProduct> {
    const { id } = this.authService.getLocalUserData();
    return this.httpC.post<NewProduct>(environment.apiAGUrl + `/products/${id}`, {
      ...form,
    });
  }

  public deleteProduct(idProduct: number): Observable<void> {
    return this.httpC.delete<void>(
      environment.apiAGUrl + `/products/${idProduct}`
    );
  }

  public editProduct(idProduct: number, form: NewProduct): Observable<void> {
    return this.httpC.put<void>(
      environment.apiAGUrl + `/products/${idProduct}`,
      form
    );
  }
}
