<article>
  <div class="display-flex justify-content-center align-item-center box__porcent">
    <div class="display-flex align-item-center justify-content-start icon">
      <div class="circle-icon--big">
        <i class="fa-light {{ icon }} fa-xl text-gray-1"></i>
      </div>
    </div>
    <div class="display-flex align-item-center">
      <span class="value font-size-28 font-w-300 {{ colorClass }}">
        <span class="mr-2" *ngIf="prefixValue">{{ prefixValue }}</span>{{ value | number: '1.0-0' }}
      </span>
      <span class="font-size-18 text-gray-2 lh-16 label">{{ label }}</span>
    </div>
  </div>
</article>
