import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DynamicStatsCard } from 'src/app/core/models/DynamicStats';

@Component({
  selector: 'app-billboard-stats',
  templateUrl: './billboard-stats.component.html',
  styleUrls: ['./billboard-stats.component.scss']
})
export class BillboardStatsComponent implements OnInit, OnChanges {

  @Input() stats;
  dataCategories: Array<string> = [];
  graphicIngressHour: Array<Array<number>> = [[]];
  ticketsData: Array<DynamicStatsCard> = [];
  tablesData: Array<DynamicStatsCard> = [];

  constructor() {
  }
  

  ngOnInit(): void {
    if(this.stats) {
      this.ticketsData = [
        {
          label: 'Vendidos',
          value: this.stats.soldTickets,
          icon: 'fa-regular fa-hand-holding-dollar',
          valueClass:'text-gray-1'
        },{
          label: 'Monto total',
          value: this.stats.amountTickets,
          icon: 'fa-regular fa-badge-dollar',
          prefixValue: '$',
          valueClass:'text-success'
        }
      ];
      this.tablesData = [
        {
          label: 'Vendidos',
          value: this.stats.soldTable,
          icon: 'fa-regular fa-hand-holding-dollar',
          valueClass:'text-gray-1'
        },{
          label: 'Monto total',
          value: this.stats.amountTable,
          icon: 'fa-regular fa-badge-dollar',
          prefixValue: '$',
          valueClass:'text-success'
        }
      ]
    }
  }

  
  ngOnChanges(changes: SimpleChanges): void {    
    if(this.stats) {
      this.ticketsData = [
        {
          label: 'Vendidos',
          value: this.stats.soldTickets,
          icon: 'fa-regular fa-hand-holding-dollar',
          valueClass:'text-gray-1'
        },{
          label: 'Monto total',
          value: this.stats.amountTickets,
          icon: 'fa-regular fa-badge-dollar',
          prefixValue: '$',
          valueClass:'text-success'
        }
      ];
      this.tablesData = [
        {
          label: 'Vendidos',
          value: this.stats.soldTable,
          icon: 'fa-regular fa-hand-holding-dollar',
          valueClass:'text-gray-1'
        },{
          label: 'Monto total',
          value: this.stats.amountTable,
          icon: 'fa-regular fa-badge-dollar',
          prefixValue: '$',
          valueClass:'text-success'
        }
      ]
    }    
  }

}
