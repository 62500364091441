import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable} from 'rxjs';
import jwt_decode from 'jwt-decode';
import {ConfirmationService} from 'primeng/api';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  jwtPrev: string;
  jwt: string;
  lo7161: string = "d033e22ae348aeb5660fc2140aec35850c4da997";

  constructor(
    private confirmationService: ConfirmationService,
    private router: Router,
    private authSrv: AuthService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.jwt = localStorage.getItem('BE-JWT');
    const logged = localStorage.getItem('isLoggedIn');
    let request = req;

    if (this.jwt) {
      const decoded = jwt_decode(this.jwt);
      const exp = decoded['exp'];
      if (Date.now() >= exp * 1000) {
        this.confirm();
      } else if (this.jwtPrev !== null && this.jwtPrev !== undefined && this.jwt !== this.jwtPrev && logged) {
        this.jwt = 'blackid';
        this.confirmReload();
      } else {
        this.jwtPrev = this.jwt;
      }
      request = req.clone({
        setHeaders: {
          authorization: this.jwt
        },
      });
    } else {
      this.jwtPrev = null;
    }
    request = request.clone({
      setHeaders: {
        'x-o7161': this.lo7161
      },
    });

    return next.handle(request);
  }

  confirm(): void {
    this.confirmationService.confirm({
      message: 'Tu sesión expiró. Volvé a iniciar sesión para continuar.',
      icon: 'icon-info icon-3xl margin-bottom-24',
      rejectVisible: false,
      key: 'jwtExpired',
      closeOnEscape: false,
      acceptButtonStyleClass: 'btn btn__primary',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        if (this.authSrv.getCanLogout()) {
          localStorage.clear();
        }
        localStorage.setItem('lastPage', this.router.url);
        this.router.navigate(['/login']);
      },
      reject: () => {
        this.confirm();
      },
    });
  }

  confirmReload(): void {
    this.confirmationService.confirm({
      message: 'Su sesión ha cambiado. Se debe recargar la página.',
      rejectVisible: false,
      key: 'changeAccount',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        location.reload();
      },
    });
  }
}
