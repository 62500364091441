import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {MessageService} from 'primeng/api';
import {ModalConfig} from 'src/app/core/models/ModalConfig';
import {SecretData} from 'src/app/core/models/SecretData';
import {SpinnerService} from 'src/app/services/spinner.service';
import {Subscription} from 'rxjs';
import {UserService} from 'src/app/services/user.service';
import {PaymentService} from '../../../../../../../../../services/payment.service';
import {MobbexCredentialRequest} from '../../../../../../../../../core/models/MobbexCredentialRequest';
import {StoreData} from '../../../../../../../../../core/models/StoreData';
import {CredentialDto} from "../../../../../../../../../core/models/CredentialDto";
import {PaymentProvider} from "../../../../../../../../../shared/enums/PaymentProvider";
import {AuthService} from "../../../../../../../../../services/auth.service";

@Component({
  selector: 'app-payments-mobbex-config-dialog',
  templateUrl: './payments-mobbex-config-dialog.component.html',
  styleUrls: ['./payments-mobbex-config-dialog.component.scss'],
})
export class PaymentsMobbexConfigDialogComponent implements OnInit, OnDestroy {
  storeData: StoreData;
  idStore: number;
  mobbexForm: UntypedFormGroup;
  secretData: SecretData;
  subs: Subscription[] = [];
  isSuperAdmin: boolean;
  isReader: boolean;
  isWriter: boolean;
  formHasBeenSubmitted: boolean = false;
  modalConfig: ModalConfig = {
    headerText: 'Ha ocurrido un error en la carga de datos',
    content: 'Por favor intente mas tarde',
    buttons: 'single',
  };
  showModalConfirm = false;
  isEditing = true;
  splitTransactions = false;
  accountCollectors: CredentialDto[] = null;
  accountTenant: CredentialDto = null;
  accountSelected: CredentialDto = null;
  currentCuit: string = null;
  currentEntityUid: string = null;
  tenantDontHaveCredentials: boolean = false;
  modalNoTenantCredentialConfig: ModalConfig = {
    headerText: 'Error al cargar cuentas originantes',
    content: 'Verificar las credenciales asociadas a la marca.',
    buttons: 'single',
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private spinnerService: SpinnerService,
    private authSrv: AuthService,
    private userSrv: UserService,
    private validateInputService: InputValidationService,
    private messageService: MessageService,
    public ref: DynamicDialogRef,
    private paymentSrv: PaymentService
  ) {
    this.mobbexForm = this.formBuilder.group({
      cuit: [''],
      splitTrx: [false, [Validators.required]],
      entityUid: ['']
    });
  }

  ngOnInit(): void {
    this.spinnerService.loadSpinner.next(true);
    this.idStore = Number(sessionStorage.getItem('storeId'));
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    this.isReader = this.userSrv.isReader();
    this.isWriter = this.userSrv.isWriter();
    this.checkIsWriter();
    this.getCredential();
    // this.getAccountCollectors();
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  getCredential() {
    this.subs.push(
      this.paymentSrv.getStoreCuitSecrets(this.idStore).subscribe({
        next: res => {
          if (res !== null) {
            this.currentCuit = res.cuit;
            this.currentEntityUid = res.entityUid;
            this.splitTransactions = res.splitTrx;
            this.spinnerService.loadSpinner.next(false);
            this.mobbexForm.patchValue({
              cuit: res.cuit,
              splitTrx: res.splitTrx,
              entityUid: res.entityUid
            });
          }
          this.getAccountCollectors();
        },
        error: err => {
          if (err.status === 404) {
            this.mobbexForm.reset();
          }
          this.spinnerService.loadSpinner.next(false);
        }
      })
    );
  }

  getAccountCollectors() {
    this.accountSelected = null;
    this.subs.push(
      this.paymentSrv.getPaymentProvidersCollectors(this.idStore, PaymentProvider.MOBBEX.toLowerCase()).subscribe({
        next: res => {
          this.accountCollectors = res;
          this.tenantDontHaveCredentials = this.accountCollectors.length === 0;
          this.accountTenant = this.accountCollectors.find(a => a.mainAccount);
          this.accountCollectors.forEach((account: CredentialDto, index: number) => {
            if (account.mainAccount) {
              this.accountCollectors.splice(index, 1);
            }
          });
          const noCuit = this.mobbexForm.controls.cuit.value === null || this.mobbexForm.controls.cuit.value === '';
          const noEntityUid = this.mobbexForm.controls.entityUid.value === null || this.mobbexForm.controls.entityUid.value === ''
          if (noCuit && noEntityUid) {
            this.mobbexForm.patchValue({
              cuit: this.accountTenant.mobbexTaxId,
              splitTrx: false,
              entityUid: this.accountTenant.mobbexEntityUid
            })
          }

          this.checkIsAccountCollector();
        },
        error: () => {
          this.spinnerService.loadSpinner.next(false);
        }
      })
    );
  }

  toggleSwitchSplitTransactions(ev) {
    if (ev.checked) {
      this.mobbexForm.patchValue({splitTrx: true, cuit: this.accountSelected?.mobbexTaxId, entityUid: this.accountSelected?.mobbexEntityUid});
    } else {
      this.mobbexForm.patchValue({splitTrx: false, cuit: this.accountTenant.mobbexTaxId, entityUid: this.accountTenant.mobbexEntityUid});
    }
  }

  checkAccount(event, accountCol) {
    if (event.checked) {
      this.accountSelected = accountCol;
      this.mobbexForm.controls.cuit.patchValue(this.accountSelected.mobbexTaxId);
      this.mobbexForm.controls.entityUid.patchValue(this.accountSelected.mobbexEntityUid);
    } else {
      this.accountSelected = null;
      this.mobbexForm.controls.cuit.patchValue(null);
      this.mobbexForm.controls.entityUid.patchValue(null);
    }
  }

  checkIsAccountCollector() {
    const account = this.accountCollectors.find(a => (a.mobbexTaxId === this.currentCuit) || (a.mobbexEntityUid && a.mobbexEntityUid === this.currentEntityUid));
    if (account !== undefined && account !== null) {
      this.accountSelected = account;
      this.splitTransactions = (account.mobbexTaxId !== this.accountTenant.mobbexTaxId) /*|| (account.mobbexEntityUid !== this.accountTenant.mobbexEntityUid)*/;
    }
    this.spinnerService.loadSpinner.next(false);
  }

  setSelectedAccount(account: CredentialDto) {
    this.accountSelected = account;
    this.mobbexForm.controls.cuit.patchValue(this.accountSelected.mobbexTaxId);
    this.mobbexForm.controls.entityUid.patchValue(this.accountSelected.mobbexEntityUid);
  }

  checkIsWriter(): void {
    if (!this.isWriter) {
      this.mobbexForm.controls.cuit.disable();
      this.mobbexForm.controls.entityUid.disable();
    }
  }

  valueCheck(account) {    
    return this.accountSelected && account === this.accountSelected;
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  onModalResponse(e: boolean): void {
    this.showModalConfirm = false;
  }

  onModalNoTenantCredentialResponse(e: boolean): void {
    this.cancel();
  }

  submitChange(): void {
    this.formHasBeenSubmitted = true;
    const cuit = this.mobbexForm.value.cuit;
    const splitTrx = this.mobbexForm.value.splitTrx;
    const entityUid = this.mobbexForm.value.entityUid;
    if (splitTrx && this.mobbexForm.controls.cuit.value === this.accountTenant.mobbexTaxId) {
      this.mobbexForm.controls['cuit'].setErrors({'SameTaxThanMainAccount': true});
      return;
    }
    if (splitTrx && this.accountTenant.mobbexEntityUid && this.mobbexForm.controls.entityUid.value === this.accountTenant.mobbexEntityUid) {
      this.mobbexForm.controls['entityUid'].setErrors({'SameUidThanMainAccount': true});
      return;
    }
    this.spinnerService.loadSpinner.next(true);
    const mobbexCredentialRequest = new MobbexCredentialRequest();
    mobbexCredentialRequest.cuit = cuit;
    mobbexCredentialRequest.splitTrx = splitTrx;
    mobbexCredentialRequest.entityUid = entityUid;
    this.subs.push(
      this.paymentSrv.setStoreCuitSecrets(this.idStore, mobbexCredentialRequest).subscribe({
        next: v => {
          this.spinnerService.loadSpinner.next(false);
          this.showMessageOk();
          this.ref.close(true);
        },
        error: err => {
          if (err.status === 404) {
            this.modalConfig = {
              headerText: 'Error',
              buttons: 'single',
              content:
                'La configuración del comercio no es válida. Por favor configurar el comercio y reintentar.',
            };
          } else if (err.status === 409) {
            this.modalConfig = {
              headerText: 'Error',
              buttons: 'single',
              content:
                'En este momento no se pueden actualizar las credenciales, intente más tarde.',
            };
          }
          this.showModalConfirm = true;
          this.spinnerService.loadSpinner.next(false);
        }
      })
    );
  }

  showMessageOk(): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Credenciales guardadas con éxito.',
    });
  }

  cancel() {
    this.ref.close(false);
  }
}
