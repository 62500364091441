import { Injectable, NgZone } from '@angular/core';
import { FormatedGoogleResult } from '../core/models/FormatedGoogleResult';
declare var google;

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  GoogleAutocomplete: any;
  constructor(private zone: NgZone) {
    this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
  }

  async getPredictions(input): Promise<any[]> {
    return new Promise<any[]>(async (resolve, rejects) => {
      let autocompleteItems: any[] = [];
      this.GoogleAutocomplete.getPlacePredictions(
        { input },
        (predictions, status) => {
          autocompleteItems = [];
          for (const prediction of predictions) {
            autocompleteItems.push(prediction);
          }
          resolve(autocompleteItems);
        }
      );
    });
  }

  findData(param, result): any {
    let dato = result.address_components.find(
      (r) => r.types[r.types.indexOf(param)] === param
    );
    if (dato) {
      return dato.long_name;
    }
    const obj = {
      locality: 'sublocality',
    };
    dato = result.address_components.find(
      (r) => r.types[r.types.indexOf(obj[param])] === obj[param]
    );
    if (dato) {
      return dato.long_name;
    }

    return '';
  }

  getGeocodedResult(fullAddress, complete): Promise<any> {
    return new Promise<FormatedGoogleResult>((resolve, reject) => {
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: fullAddress }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const lat = results[0].geometry.location.lat();
          const long = results[0].geometry.location.lng();
          resolve({
            latitude: parseFloat(lat),
            longitude: parseFloat(long),
            city: this.findData('locality', results[0]),
            address: !complete
              ? `${this.findData('route', results[0])} ${this.findData(
                  'street_number',
                  results[0]
                )}`
              : `${this.findData('route', results[0])} ${this.findData(
                  'street_number',
                  results[0]
                )}, ${this.findData('locality', results[0])}`,
          });
        }
      });
    });
  }
}
