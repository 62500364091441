import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, LazyLoadEvent} from 'primeng/api';
import {BookingService} from 'src/app/services/booking.service';
import {Pageable} from "../../../core/models/Pageable";
import {PaginationService} from 'src/app/services/pagination.service';
import {ReservationForAdmin} from 'src/app/core/models/ReservationForAdmin';
import {ReservationStatus} from 'src/app/shared/enums/ReservationStatus';
import {Router} from '@angular/router';
import {SpinnerService} from 'src/app/services/spinner.service';
import {Subscription} from 'rxjs';
import {Table} from 'primeng/table';
import {UserService} from 'src/app/services/user.service';
import {environment} from 'src/environments/environment';
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
})
export class BookingsComponent implements OnInit {
  @ViewChild('dt') table: Table;
  bookings: ReservationForAdmin[];
  statuses = ReservationStatus;
  bookingSelected: ReservationForAdmin;
  bookingDetailsDialog = false;
  fieldsFilter: Array<string> = [
    'customerData.name',
    'storeData.name',
    'storeData.email',
    'customerData.email',
  ];
  loading = false;
  refresh: boolean;
  isSuperAdmin: boolean;
  isReader: boolean;
  isWriter: boolean;
  eventTable = null;
  subs: Subscription[] = [];
  totalRecords: number;
  pageSize: number = 50;
  searchTerm = null;

  constructor(
    private bookingSrv: BookingService,
    private router: Router,
    private authSrv: AuthService,
    private userSrv: UserService,
    private spinnerSrv: SpinnerService,
    private confirmationService: ConfirmationService,
    private paginationSrv: PaginationService
  ) {
  }

  ngOnInit(): void {
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    this.isReader = this.userSrv.isReader();
    this.isWriter = this.userSrv.isWriter();
  }

  loadBookings(e: LazyLoadEvent): void {
    if (this.isReader === true || this.isSuperAdmin) {
      this.spinnerSrv.loadSpinner.next(true);
      this.eventTable = e;
      const pageNumber = e.first / e.rows;
      const multiSortFields = this.paginationSrv.getMultiSortObject(e);
      this.pageSize = e.rows;
      this.searchTerm = e.globalFilter;
      this.subs.push(
        this.bookingSrv.getAllReservationsAdmin(pageNumber, this.pageSize, multiSortFields, e.globalFilter).subscribe({
          next: (data: Pageable) => {
            this.bookings = data.content;
            this.totalRecords = data.totalElements;
            this.loading = false;
            this.spinnerSrv.loadSpinner.next(false);
          },
          error: err => {
            this.loading = false;
            this.spinnerSrv.loadSpinner.next(false);
          }
        })
      );
    } else {
      this.alertNotAuthorized();
    }
  }

  onRefresh(): void {
    this.loadBookings(this.eventTable);
    this.refresh = true;
    setTimeout(() => {
      this.refresh = false;
    }, 2000);
  }

  back(): void {
    this.router.navigate(['/home']);
  }

  showBookingDetails(booking: ReservationForAdmin): void {
    this.bookingDetailsDialog = true;
    this.bookingSelected = booking;
  }

  getImageUrl(img: string): string {
    return environment.mediaUrl + img;
  }

  alertNotAuthorized(): void {
    this.confirmationService.confirm({
      message:
        'Lo sentimos, no dispones de los permisos para ver esta información.',
      icon: 'fa-light fa-warning text-yellow text-12xl',
      rejectVisible: false,
      key: 'notAuthorized',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        this.back();
      },
    });
  }

  closeDialog(): void {
    this.bookingDetailsDialog = false;
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  public doGlobalFilter(text: string) {
    this.table.filterGlobal(text, 'contains');
  }
}
