<article>
  <div class="display-flex justify-content-center align-item-baseline box__porcent">
    <div class="display-flex align-item-center justify-content-start">
      <div class="circle-icon--big">
        <i class="fas font-w-300 {{ icon }} fa-xl text-gray-1"></i>
      </div>
      <p class="font-size-22 text-gray-1 my-0 margin-left-16 lh-24 font-normal">{{ description }}</p>
    </div>
    <p class="font-size-32 font-semibold my-0 {{ valueClass ? valueClass : ''}}">
      <span *ngIf="prefixValue">{{ prefixValue }}</span>{{ value | number: '1.0-0' }}
    </p>
  </div>
</article>
