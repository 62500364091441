<div class="cards-number-stats">
  <div class="card transaccions">
    <div class="card__number success">
      {{this.currency}}{{static?.totalTransacted | number:'1.0-2'}}
    </div>
    <div class="card__description">
      Total transaccionado
    </div>
  </div>

  <div class="card sold">
    <div class="card__number">
      {{this.currency}}{{static?.totalTicketTableSold | number:'1.0-2'}}
    </div>
    <div class="card__description">
      Total vendido
    </div>
  </div>

  <div class="card">
    <div class="card__number">
      {{this.currency}}{{static?.totalTicketsServiceCharge + static?.totalTablesServiceCharge | number:'1.0-2'}}
    </div>
    <div class="card__description">
      Service Charge
    </div>
  </div>

  <div class="card charge operators">
    <div class="card__number">
      {{this.currency}}{{static?.totalReintegratedForOperators | number:'1.0-2'}}
    </div>
    <div class="card__description">
      Reintegro operadores
    </div>
  </div>

  <div class="card neto">
    <div class="card__number">
      {{this.currency}}{{static?.tenantClean | number:'1.0-2'}}
    </div>
    <div class="card__description">
      Neto Black iD
    </div>
  </div>
</div>
