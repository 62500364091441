import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Pageable} from '../core/models/Pageable';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(private httpC: HttpClient) {
  }

  getAllReservationsAdmin(
    pageNumber?: number,
    pageSize?: number,
    multiSortFields?: any[],
    globalFilter?: string
  ): Observable<Pageable> {
    const httpHeaders = new HttpHeaders().set('Accept', 'application/json');
    let httpParams = new HttpParams();
    let sortFields = {sortFieldsWithOrder: []};
    if (!multiSortFields || multiSortFields.length < 1) {
      const initialSortField = 'reservationDate:DESC';
      sortFields.sortFieldsWithOrder.push(`${initialSortField}`);
    } else {
      let i = 0;
      while (i < multiSortFields.length) {
        let nameField = multiSortFields[i]['name'];
        let order = multiSortFields[i]['order'] > 0 ? 'ASC' : 'DESC';
        sortFields.sortFieldsWithOrder.push(`${nameField}:${order}`);
        i++;
      }
    }
    const pageNro = pageNumber ? pageNumber : 0;
    httpParams = httpParams.set('pageNumber', pageNro.toString());
    httpParams = httpParams.set('size', pageSize);
    httpParams = httpParams.set('sortFields', sortFields.sortFieldsWithOrder.join(','));
    httpParams = httpParams.set('search', globalFilter);

    return this.httpC.get<Pageable>(
      environment.apiAGUrl + `/bookings/from-admin`,
      {headers: httpHeaders, params: httpParams}
    );
  }
}
