<main>
  <app-store-details [storeId]="storeId"></app-store-details>

  <section class="store">
    <div class="wrapper">
      <div class="navigation">
        <button class="btn__detail margin-right-16" (click)="goTo('configurations')"
                [ngClass]="selectedBtn === 'configurations' ? 'active' : ''"><i
          class="fa-solid fa-gear text-primary margin-right-8"></i>Configuración
        </button>
        <button class="btn__detail margin-right-16" (click)="goTo('stats')"
                [ngClass]="selectedBtn === 'stats' ? 'active' : ''"><i
          class="fa-solid fa-chart-line-up text-primary margin-right-8"></i>Estadísticas
        </button>
        <button class="btn__detail margin-right-16" (click)="goTo('menu')"
                [ngClass]="selectedBtn === 'menu' ? 'active' : ''"><i
          class="fa-solid fa-utensils text-primary margin-right-8"></i>Gestión de menú
        </button>
        <button class="btn__detail margin-right-16" (click)="goTo('transactions')"
                [ngClass]="selectedBtn === 'transactions' ? 'active' : ''"><i
          class="fa-solid fa-magnifying-glass-dollar text-primary margin-right-8"></i>Transacciones
        </button>
        <button class="btn__detail margin-right-16" (click)="goTo('payments')"
                [ngClass]="selectedBtn === 'payments' ? 'active' : ''"><i
          class="fa-solid fa-money-check-dollar-pen text-primary margin-right-8"></i>Medios de pago
        </button>
      </div>
    </div>
    <div class="wrapper router-content" [ngClass]="selectedBtn === 'stats' ? 'stats' : '' ">
      <div class="store-router">
        <router-outlet></router-outlet>
      </div>
    </div>
  </section>
</main>
