import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NavigationEnd, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AuthService } from './services/auth.service';
import { RequestErrorTriggerService } from './services/request-error-trigger.service';
import { SpinnerService } from './services/spinner.service';
import {ErrorRequest} from "./core/models/ErrorRequest";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'BlackID - Admin';
  showRequestError: boolean;
  errorMessage: string;
  modalTitle: string;
  showSpinner = false;
  logged: boolean;
  showLateralMenu: boolean = false;

  constructor(
    private requestErrService: RequestErrorTriggerService,
    private spinnerService: SpinnerService,
    private router: Router,
    private config: PrimeNGConfig,
    private authService: AuthService,
    public breakpointObserver: BreakpointObserver
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.logged = !!localStorage.getItem('isLoggedIn');
      }
    });

    this.authService.userIsLoggedIn$.subscribe((res) => {
      this.logged = res;
    });
    this.breakpointObserver
      .observe(['(min-width: 1025px)'])
      .subscribe((state: BreakpointState) => {
        this.showLateralMenu = state.matches;
      });
  }

  ngOnInit(): void {
    this.translate();
    this.requestErrService.showError.subscribe((errorData: ErrorRequest) => {
      this.showRequestError = errorData.showError;
      this.errorMessage = errorData.message;
      this.modalTitle = errorData.title;
    });

    this.spinnerService.loadSpinner.subscribe((currentState: boolean) => {
      Promise.resolve(null).then(() => {
        this.showSpinner = currentState;
      });
    });
  }

  hideDialogError(): void {
    this.requestErrService.updateShowError({ showError: false, message: '' });
  }

  translate(): void {
    this.config.setTranslation({
      startsWith: 'Comienza con',
      contains: 'Contiene',
      notContains: 'No contiene',
      endsWith: 'Termina con',
      equals: 'Igual',
      notEquals: 'Diferente',
      noFilter: 'Sin Filtro',
      lt: 'Menor a',
      lte: 'Menor o igual a',
      gt: 'Mayor a',
      gte: 'Mayor o igual a',
      is: 'es',
      isNot: 'No es',
      before: 'Antes',
      after: 'Después',
      clear: 'Limpiar',
      apply: 'Aplicar',
      matchAll: 'Coincidan todos',
      matchAny: 'Coincida alguno',
      addRule: 'Agregar Regla',
      removeRule: 'Quitar Regla',
      accept: 'Si',
      reject: 'No',
      choose: 'Elegir',
      upload: 'Subir',
      cancel: 'Cancelar',
      dayNames: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      monthNamesShort: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ],
      today: 'Hoy',
      weekHeader: 'Sem',
      weak: 'Semana',
      medium: 'Medio',
      strong: 'Fuerte',
      passwordPrompt: 'Ingrese una contraseña',
      // emptyMessage: 'No se han encontrado resultados',
      // emptyFilterMessage: 'No se han encontrado resultados'
    });
  }
}
