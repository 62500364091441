<div class="container-main">
  <div class="internal-body">
    <div class="flex justify-content-between">
      <div class="flex align-items-center">
        <div class="text-base lh-24 text-primary font-semibold flex align-items-center cursor-pointer" (click)="back()">
          <i class="fa-xl fa-regular fa-arrow-left text-primary mr-2"></i> Volver a Home
        </div>
      </div>
      <div>
        <!-- <button pButton label="Cerrar sesiones" class="btn btn__primary" (click)="closeAllSessions()"></button> -->
      </div>
    </div>
    <div class="table__container">
      <p-table #dt [value]="customers" [lazy]="true" (onLazyLoad)="loadCustomers($event)" [rows]="pageSize"
               [paginator]="true" [totalRecords]="totalRecords" sortMode="multiple" dataKey="id"
               stateStorage="session" stateKey="users-table" [rowsPerPageOptions]="[10,25,50,100]"
               currentPageReportTemplate="Mostrar" [showCurrentPageReport]="true" class="table" [loading]="loading"
               [rowHover]="true" [(contextMenuSelection)]="selectedCustomer">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-between table-header first-line">
            <div class="flex justify-content-start align-items-center">
              <i class="fa-light fa-user-group fa-lg mr-2"></i>
              <p class="my-0 text-xl ml-3 font-semibold">USUARIOS REGISTRADOS</p>
              <i class="fa-solid fa-arrows-rotate text-primary cursor-pointer fa-lg ml-3 mr-3"
                 [ngClass]="{ 'spin': refresh }" (click)="onRefresh()"></i>
              <div class="box__total">
                Total <span class="text-primary">{{ totalRecords | number:'1.0-2' }}</span>
              </div>
            </div>
            <div class="search-container">
              <div class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                       placeholder="Buscar usuario" [(ngModel)]="searchTerm"/>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="id_customer" style="width: 5%">
              <div class="flex justify-content-between align-items-center">
                ID
                <p-sortIcon field="id_customer"></p-sortIcon>
              </div>
            </th>
            <th style="width: 5%">
            </th>
            <th pSortableColumn="name" style="width: 15%">
              <div class="flex justify-content-between align-items-center">
                Nombre
                <p-sortIcon field="name"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="lastName" style="width: 15%">
              <div class="flex justify-content-between align-items-center">
                Apellido
                <p-sortIcon field="lastName"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="email" style="width: 15%">
              <div class="flex justify-content-between align-items-center">
                Email
                <p-sortIcon field="email"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="document" style="width: 10%">
              <div class="flex justify-content-between align-items-center">
                Documento
                <p-sortIcon field="document"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="country" style="width: 5%">
              <div class="flex justify-content-between align-items-center">
                País
                <p-sortIcon field="country"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="createdAt" style="width: 8%">
              <div class="flex justify-content-between align-items-center">
                Fecha registro
                <p-sortIcon field="createdAt"></p-sortIcon>
              </div>
            </th>
            <th style="width: 5%">
              <div class="flex justify-content-between align-items-center">
                Acciones
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer>
          <tr class="table-row" [pContextMenuRow]="customer">
            <td
              [ngClass]="customer.deleted || customer.blocked ? 'text-line-through': ''">{{ customer.id_customer }}</td>
            <td>
              <span *ngIf="customer.image !== null">
                <img class="user-image" [src]="getImageUrl(customer.image)" alt="avatar-user-{{customer.id_customer}}"/>
              </span>
              <span *ngIf="customer === null || customer.image === null">
                <div class="icon-placeholder">
                  <i class="fa-light fa-user text-gray-4"></i>
                </div>
              </span>
            </td>
            <td [ngClass]="customer.deleted || customer.blocked ? 'text-line-through': ''">{{ customer.name }}</td>
            <td [ngClass]="customer.deleted || customer.blocked ? 'text-line-through': ''">{{ customer.lastName }}</td>
            <td [ngClass]="customer.deleted || customer.blocked ? 'text-line-through': ''">{{ customer.email }}
              <span [ngClass]="customer.deleted || customer.blocked ? 'bid-badge-overshadowed' : ''"
                    *ngIf="customer?.emailVerified"
                    class="ml-1">
                <i class="fa-solid fa-check-circle fa text-success"></i>
              </span>
            </td>
            <td>
              <span [ngClass]="customer.deleted || customer.blocked ? 'text-line-through': ''"
                    *ngIf="customer.document !== '0' && customer.document !== null">
                (<strong>{{customer.idType}}</strong>)
                {{customer.document}}
              </span>
              <span [ngClass]="customer.deleted || customer.blocked ? 'bid-badge-overshadowed' : ''"
                    *ngIf="customer.document === '0' || customer.document === null"
                    class="bid-badge bid-badge--orange">SIN VERIFICAR</span>
            </td>
            <td [ngClass]="customer.deleted || customer.blocked ? 'text-line-through': ''">{{customer.country}}</td>
            <td [ngClass]="customer.deleted || customer.blocked ? 'text-line-through': ''">
              {{customer.createdAt | date: 'dd/MM/YYYY HH:mm'}}
            </td>
            <td class="option-table">
              <i class="fal fa-ellipsis text-primary font-size-18"
                 [ngClass]="customer?.id_customer === currentCustomer?.id_customer ? 'customer-selected' : ''"
                 (click)="menu.toggle($event)"></i>
              <p-menu #menu styleClass="dots-menu menu-user" appendTo="body" [popup]="true" [model]="items"
                      (onShow)="setCurrentCustomer(customer)" (onHide)="cleanCurrentCustomer()"></p-menu>
            </td>
          </tr>
          <div class="my-2"></div>
        </ng-template>
      </p-table>
    </div>
  </div>
  <p-confirmDialog key="confirmBlock" [style]="{ width: '432px' }" acceptLabel="Aceptar"
                   rejectLabel="Cancelar" rejectButtonStyleClass="btn btn__secondary btn__secondary--bold"
                   acceptButtonStyleClass="btn btn__primary">
  </p-confirmDialog>

  <p-confirmDialog key="confirmDelete" class="modal-delete-user" [style]="{ width: '432px' }"
                   acceptLabel="Eliminar usuario"
                   rejectLabel="No eliminar" rejectButtonStyleClass="btn btn__primary"
                   acceptButtonStyleClass="btn btn__secondary btn__secondary--red">
  </p-confirmDialog>

  <p-confirmDialog key="notAuthorized" styleClass="confirm-dialog-custom" [style]="{width: '424px'}" (onHide)="back()"
                   icon="pi pi-exclamation-triangle"
                   acceptLabel="Regresar"></p-confirmDialog>
</div>
<p-toast></p-toast>
