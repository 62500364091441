<form [formGroup]="horariosForm" [ngClass]="{ form__submitted: formHasBeenSubmitted }">
  <div class="header">
    <div class="flex align-items-center">
      <p class="text-xl font-semibold text-gray-1 lh-16 my-0"> HORARIOS <span class="hour-format">Formato de horario {{ formateLanguague }}hs</span></p>
      <div class="tooltip-cursor ml-2" pTooltip="Si el horario de cierre de tu comercio es posterior a las 00:00 hs, habilitá el Horario Cortado (desde el switch) hasta las 23:59 y configurá el cierre en el primer turno desde las 00:00 del dia siguiente." tooltipPosition="right">
        <i class="fa-light fa-circle-info"></i>
      </div>
    </div>

    <div class="flex justify-content-end">
      <div class="switch-wrapper">
        <label for="bookingAvailable" class="label-switch">
          Habilitar horario cortado
        </label>
        <div class="switch-container">
          <p-inputSwitch name="cutSchedule" formControlName="cutSchedule" [disabled]="true"></p-inputSwitch>
        </div>
      </div>
    </div>
  </div>

  <div class="schedule-container">
    <div class="input-container inline config-day" [ngClass]="{
        'disabled-day': !horariosForm.controls[day.control + 'Available'].value
      }" *ngFor="let day of days">
      <div class="header-day">
        <label for="scheduleId" class="text-lg font-semibold mr-2">{{
          day.label
          }}</label>
        <div class="switch-close">
          <p-inputSwitch formControlName="{{ day.control }}Available" [disabled]="true"></p-inputSwitch>
        </div>
      </div>

      <div class="container-hours">
        <div class="hour">
          <p class="text-base text-gray-1 lh-24 mb-0">Desde</p>
          <div class="field__item field--hour">
            <div class="p-float-label">
              <input type="time" color="dark" formControlName="{{ day.control }}Start1" [readonly]="true"
                class="no-border" />
            </div>
          </div>
        </div>
        <div class="hour">
          <p class="text-base text-gray-1 lh-24 mb-0">Hasta</p>
          <div class="field__item field--hour">
            <div class="p-float-label">
              <input type="time" formControlName="{{ day.control }}End1" [readonly]="true" />
            </div>
          </div>
        </div>
      </div>

      <div class="container-hours cut" *ngIf="horariosForm.controls.cutSchedule.value">
        <div class="divider" *ngIf="horariosForm.controls.cutSchedule.value"></div>
        <div class="hour">
          Desde
          <div class="field__item field--hour">
            <div class="p-float-label">
              <input type="time" formControlName="{{ day.control }}Start2" [readonly]="true" />
            </div>
          </div>
        </div>
        <div class="hour">
          Hasta
          <div class="field__item field--hour">
            <div class="p-float-label">
              <input type="time" formControlName="{{ day.control }}End2" [readonly]="true" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <p class="general-error ml-3 mt-2" *ngIf="formHasBeenSubmitted && errorHour !== ''">
      {{ errorHour }}
    </p>
  </div>

  <p-toast></p-toast>
</form>
