import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { MessageService, ConfirmationService, Message } from 'primeng/api';
import { Subscription } from 'rxjs';
import {
  NewAttribute,
  AttributeValue,
  Attribute,
} from 'src/app/core/models/Attribute';
import { StoreData } from 'src/app/core/models/StoreData';
import { AttributesService } from 'src/app/services/attributes.service';
import { InputValidationService } from 'src/app/services/input-validation.service';
import { RequestErrorTriggerService } from 'src/app/services/request-error-trigger.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { AttributeType } from 'src/app/shared/enums/AttributeType';
import { AttributeValueType } from 'src/app/shared/enums/AttributeValueType';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['./attributes.component.scss'],
  providers: [MessageService, ConfirmationService],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0,
        })
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1,
        })
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ]),
  ],
})
export class AttributesComponent implements OnInit, OnDestroy {
  editId: number;
  isEditting = false;
  attributeForm = new UntypedFormGroup({});
  attributeValueForm = new UntypedFormGroup({});
  attributes: Attribute[];
  selectedAttributes: Attribute[];
  attribute: NewAttribute;
  comercio: StoreData;
  loading = false;
  attributeSelected: Attribute;
  attributeDialog: boolean;
  attributeValueDialog: boolean;
  submitted: boolean;
  headerPopup: string;
  headerPopupValue: string;
  attributeIdToEdit: number;
  forceDelete: boolean;
  @Input() hideLateralMenu: boolean;
  @Input() canWrite: boolean;
  showMenu = true;
  ATTRIBUTE_TYPE = AttributeType;
  ATTRIBUTE_VALUE_TYPE = AttributeValueType;
  formHasBeenSubmitted = false;
  errorMessage: Array<Message> = [];
  subs: Subscription[] = [];
  maxAttributeNameLength: number;
  maxAttributeDescriptionLength: number;
  maxPriceAtributte: number;

  attributeTypeValue: string = '';

  constructor(
    private atrributeSrv: AttributesService,
    private confirmationService: ConfirmationService,
    private formBuilder: UntypedFormBuilder,
    private errorService: RequestErrorTriggerService,
    private spinnerService: SpinnerService,
    private validateInputService: InputValidationService
  ) {
    this.maxAttributeNameLength = environment.maxAttributeNameLength;
    this.maxAttributeDescriptionLength =
      environment.maxAttributeDescriptionLength;
    this.maxPriceAtributte = environment.maxPriceAtributte;
    this.attributeForm = this.formBuilder.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(this.maxAttributeNameLength),
        ],
      ],
      description: [
        '',
        [Validators.maxLength(this.maxAttributeDescriptionLength)],
      ],
      attributeType: ['', Validators.required],
      minSelectAmount: [null, [Validators.maxLength(4)]],
      maxSelectAmount: [null, [Validators.maxLength(4)]],
      fixedAmount: [null, [Validators.maxLength(4)]],
    });

    this.attributeValueForm = this.formBuilder.group({
      valueName: [
        '',
        [
          Validators.required,
          Validators.maxLength(this.maxAttributeNameLength),
        ],
      ],
      valueDescription: [
        '',
        Validators.maxLength(this.maxAttributeDescriptionLength),
      ],
      attributeValueType: ['', Validators.required],
      available: [true],
      maxSelection: [],
      price: [0, Validators.max(this.maxPriceAtributte)],
    });

    this.subs.push(
      this.attributeForm.controls.attributeType.valueChanges.subscribe(() => {
        this.attributeForm.patchValue({
          minSelectAmount: null,
          maxSelectAmount: null,
          fixedAmount: null,
        });
      })
    );

    this.subs.push(
      this.attributeValueForm.controls.attributeValueType.valueChanges.subscribe(
        () => {
          this.attributeValueForm.patchValue({
            price: null,
          });
        }
      )
    );
  }

  get descriptionField(): AbstractControl {
    return this.attributeForm.get('description');
  }

  get attributeTypeField(): AbstractControl {
    return this.attributeForm.get('attributeType');
  }

  get minSelectAmountField(): AbstractControl {
    return this.attributeForm.get('minSelectAmount');
  }

  get maxSelectAmountField(): AbstractControl {
    return this.attributeForm.get('maxSelectAmount');
  }

  get fixedAmountField(): AbstractControl {
    return this.attributeForm.get('fixedAmount');
  }

  get nameValueField(): AbstractControl {
    return this.attributeValueForm.get('valueName');
  }

  get descriptionValueField(): AbstractControl {
    return this.attributeValueForm.get('valueDescription');
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => {
      s.unsubscribe();
    });
  }

  ngOnInit(): void {
    if (this.hideLateralMenu) {
      this.showMenu = false;
    }
    this.loading = true;
    this.attributes = [];
    this.comercio = JSON.parse(localStorage.getItem('storeData'));
    this.loadAttributes();
  }

  loadAttributes(): void {
    this.spinnerService.loadSpinner.next(true);
    this.subs.push(
      this.atrributeSrv.getAttributes().subscribe((data) => {
        this.attributes = data;
        this.loading = false;
        this.spinnerService.loadSpinner.next(false);
      })
    );
  }

  openNew(): void {
    this.headerPopup = 'Agregar opcional';
    this.isEditting = false;
    this.attributeForm.reset();
    this.attributeValueForm.reset();
    this.submitted = false;
    this.attributeDialog = true;
  }

  deleteSelectedAttributes(oneAttribute?): void {
    if (oneAttribute) {
      this.selectedAttributes = [];
      this.selectedAttributes.push(oneAttribute);
    }

    this.confirmationService.confirm({
      message:
        '¿Deseas eliminar los atributos seleccionados? <span class="subheader">Esta acción no se puede deshacer.</span>',
      header: '',
      icon: 'icon-warning icon-4xl icon-yellow',
      key: 'confirmDelete',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: async () => {
        this.spinnerService.loadSpinner.next(true);
        if (oneAttribute) {
          await this.deleteCurrentAttribute(oneAttribute);
        } else {
          for (const attribute of this.selectedAttributes) {
            await this.deleteCurrentAttribute(attribute);
          }
        }
        this.loadAttributes();
        this.forceDelete = false;
      },
      reject: () => {},
    });
  }

  private deleteCurrentAttribute(attribute: Attribute): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.forceDelete) {
        this.subs.push(
          this.atrributeSrv.deleteAttribute(attribute.id).subscribe(
            () => {
              resolve();
            },
            (err) => {
              if (err.status === 409) {
                this.confirmationService.confirm({
                  message: `Algunas opciones seleccionadas contienen productos asociados. ¿Desea eliminarlas igualmente?`,
                  header: '',
                  icon: 'icon-warning icon-4xl icon-yellow',
                  key: 'confirmDeleteWithProduct',
                  rejectIcon: 'none',
                  acceptIcon: 'none',
                  accept: () => {
                    this.forceDelete = true;
                    this.subs.push(
                      this.atrributeSrv
                        .deleteAttribute(attribute.id, true)
                        .subscribe(() => {
                          resolve();
                        })
                    );
                  },
                });
              }
              if (err.status === 400 || err.status === 404) {
                this.handleErrorMessage(err.status, 'attribute');
              }
            }
          )
        );
      } else {
        this.subs.push(
          this.atrributeSrv.deleteAttribute(attribute.id, true).subscribe(
            () => {
              resolve();
            },
            (err) => {
              if (err.status === 400 || err.status === 404) {
                this.handleErrorMessage(err.status, 'attribute');
              }
            }
          )
        );
      }
    });
  }

  private handleErrorMessage(status, type): void {
    let message: string;
    if (type === 'attribute') {
      switch (status) {
        case 400:
          message = 'Atributo no valido.';
          break;
        case 404:
          message = 'Atributo inexistente.';
          break;
      }
    }
    this.errorService.updateShowError({
      showError: true,
      message,
    });
  }

  addAttributeValue(attribute): void {
    this.headerPopupValue = 'Agregar items en ' + attribute.name;
    this.isEditting = false;
    this.attributeValueForm.reset();
    this.submitted = false;
    this.attributeSelected = attribute;
    this.attributeValueDialog = true;
  }

  editAttribute(attribute): void {
    this.editId = attribute.id;
    this.isEditting = true;
    this.headerPopup = attribute.name;
    const {
      name,
      description,
      attributeType,
      fixedAmount,
      maxSelectAmount,
      minSelectAmount,
    } = attribute;
    this.attributeForm.patchValue({
      name,
      description,
      attributeType,
      fixedAmount,
      maxSelectAmount,
      minSelectAmount,
    });
    this.submitted = false;
    this.attributeDialog = true;
  }

  onEditAttributeValue(attributeValue): void {
    this.headerPopupValue = attributeValue.valueName;
    this.editId = attributeValue.id;
    this.attributeIdToEdit = attributeValue.id;
    this.isEditting = true;
    this.headerPopup = attributeValue.valueName;
    const { valueName, valueDescription, attributeValueType, price } =
      attributeValue;
    this.attributeValueForm.patchValue({
      valueName,
      valueDescription,
      attributeValueType,
      price,
    });
    this.submitted = false;
    this.attributeValueDialog = true;
  }

  deleteAttribute(attribute): void {
    this.confirmationService.confirm({
      message:
        '¿Deseas eliminar el atributo seleccionado? <span class="subheader">Esta acción no se puede deshacer.</span>',
      header: '',
      icon: 'icon-warning icon-4xl icon-yellow',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        this.spinnerService.loadSpinner.next(true);
        this.subs.push(
          this.atrributeSrv.deleteAttribute(attribute.id).subscribe(
            () => {
              this.loadAttributes();
              this.selectedAttributes = [];
            },
            (err) => {
              if (err.status === 400 || err.status === 404) {
                this.handleErrorMessage(err.status, 'attribute');
                this.spinnerService.loadSpinner.next(false);
              }
            }
          )
        );
      },
    });
  }

  onDeleteAttributeValue(objAttribute: {
    attributeValues: AttributeValue;
    attribute: Attribute;
  }): void {
    const attributeValue = objAttribute.attributeValues;
    const attribute = objAttribute.attribute;

    this.confirmationService.confirm({
      message: `¿Deseas eliminar ${attributeValue.valueName} de ${
        attribute.name
      }? \n ${
        attribute.attributeValues && attribute.attributeValues.length === 1
          ? 'Se eliminara tambien el grupo de opciones asociado a esta opción'
          : ''
      } <span class="subheader">Esta acción no se puede deshacer.</span>`,
      header: '',
      icon: 'icon-warning icon-4xl icon-yellow',
      key: 'confirmDelete',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        this.spinnerService.loadSpinner.next(true);
        this.subs.push(
          this.atrributeSrv.deleteAttributeValue(attributeValue.id).subscribe(
            () => {
              this.loadAttributes();
              this.selectedAttributes = [];
            },
            (err) => {
              if (err.status === 400 || err.status === 404) {
                this.handleErrorMessage(err.status, 'attribute');
                this.spinnerService.loadSpinner.next(false);
              }
            }
          )
        );
      },
    });
  }

  hideDialog(): void {
    this.attributeDialog = false;
    this.attributeValueDialog = false;
    this.submitted = false;
    this.formHasBeenSubmitted = false;
    this.isEditting = false;
    this.errorMessage = [];
  }

  validFieldsForAttributeValueType(attType: AttributeType): boolean {
    if (attType === this.ATTRIBUTE_TYPE.FIXED) {
      if (this.fixedAmountField.value === null) {
        this.errorMessage.push({
          severity: 'error',
          summary: 'Error',
          detail: 'Debe seleccionar un valor fijo.',
        });
        return false;
      }
    } else if (attType === this.ATTRIBUTE_TYPE.MIN_AND_MAX) {
      if (
        this.minSelectAmountField.value === null &&
        this.maxSelectAmountField.value === null
      ) {
        this.errorMessage.push({
          severity: 'error',
          summary: 'Error',
          detail: 'Debe ingresar un valor mínimo y uno máximo.',
        });
        return false;
      }
    }
    return true;
  }

  submitAttribute(): void {
    this.submitted = true;
    this.formHasBeenSubmitted = true;

    if (
      this.attributeForm.valid &&
      this.validFieldsForAttributeValueType(this.attributeTypeField.value)
    ) {
      this.spinnerService.loadSpinner.next(true);
      if (!this.isEditting) {
        this.subs.push(
          this.atrributeSrv
            .createAttribute(this.attributeForm.value)
            .subscribe((id) => {
              this.loadAttributes();
              this.hideDialog();
            })
        );
      } else {
        this.subs.push(
          this.atrributeSrv
            .editAttribute(this.editId, this.attributeForm.value)
            .subscribe(
              (id) => {
                this.loadAttributes();
                this.hideDialog();
                this.isEditting = false;
              },
              (err) => {
                if (err.status === 400 || err.status === 404) {
                  this.handleErrorMessage(err.status, 'attribute');
                  this.spinnerService.loadSpinner.next(false);
                }
              }
            )
        );
      }
    }
  }

  submitAttributeValue(): void {
    if (this.attributeValueForm.valid) {
      if (
        this.attributeValueForm.controls.attributeValueType.value ===
        this.ATTRIBUTE_VALUE_TYPE.FREE
      ) {
        this.attributeValueForm.patchValue({
          price: 0,
        });
      }
      this.spinnerService.loadSpinner.next(true);
      if (!this.isEditting) {
        this.subs.push(
          this.atrributeSrv
            .addAttributeValues(
              this.attributeSelected.id,
              this.attributeValueForm.value
            )
            .subscribe(
              (id) => {
                this.loadAttributes();
                this.hideDialog();
                this.isEditting = false;
              },
              (err) => {
                if (err.status === 400 || err.status === 404) {
                  this.handleErrorMessage(err.status, 'attribute');
                }
              }
            )
        );
      } else {
        this.subs.push(
          this.atrributeSrv
            .editAttributeValues(
              this.attributeIdToEdit,
              this.attributeValueForm.value
            )
            .subscribe(
              (id) => {
                this.loadAttributes();
                this.hideDialog();
                this.isEditting = false;
              },
              (err) => {
                if (err.status === 400 || err.status === 404) {
                  this.handleErrorMessage(err.status, 'attribute');
                  this.spinnerService.loadSpinner.next(false);
                }
              }
            )
        );
      }
    }
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }
}
