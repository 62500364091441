import * as c3 from 'c3';

import {Component, Input, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-donut-graphic',
  templateUrl: './donut-graphic.component.html',
  styleUrls: ['./donut-graphic.component.scss']
})
export class DonutGraphicComponent implements OnInit {

  @Input() idChart: string;
  @Input() columns;
  @Input() colors;
  @Input() isEmpty: boolean;
  //@Input() categories;
  //graphisData = [];

  constructor() {
  }

  ngOnInit(): void {
    this.generateChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.generateChart();
  }

  generateChart() {
    const colorsData = this.isEmpty ? {
      Pagos: '#1C1C1C',
      'Sin cargo': '#1C1C1C'
    } : this.colors;
    const columnData = this.isEmpty ? [['Pagos', 1]] : this.columns;

    setTimeout(() => {
      var chart = c3.generate({
        bindto: `#${this.idChart}`,
        data: {
          columns: columnData,
          colors: colorsData,
          type: 'donut',
        },
        size: {
          width: 250,
          height: 250
        },
        donut: {
          title: '',
          label: {
            format: function (value, ratio, id) {
              return '';
            }
          },
          expand: false,
          padAngle: 0,
        }
      });

      /*setTimeout(function () {
        chart.load({
            columns: this.columns;
        });
    }, 1500);*/

      /*setTimeout(function () {
          chart.unload({
              ids: 'data1'
          });
          chart.unload({
              ids: 'data2'
          });
      }, 2500);*/
      return chart;
    }, 500);

  }

}
