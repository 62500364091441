import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {EventForStore} from 'src/app/core/models/EventForStore';
import {EventRescheduleRequest} from 'src/app/core/models/events/EventRescheduleRequest';
import {EventsService} from 'src/app/services/events.service';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {SpinnerService} from 'src/app/services/spinner.service';
import {ConfirmationService, MessageService} from "primeng/api";
import {DialogService} from "primeng/dynamicdialog";

@Component({
  selector: 'app-reschedule-event',
  templateUrl: './reschedule-event.component.html',
  styleUrls: ['./reschedule-event.component.scss'],
  providers: [MessageService, DialogService, ConfirmationService],
})
export class RescheduleEventComponent implements OnInit {

  @Input() eventData: EventForStore;
  @Output() closeModal = new EventEmitter();
  showModal = true;
  rescheduleForm: FormGroup;
  formHasBeenSubmitted = false;
  isValidRangeDate = true;
  initialData: EventForStore;
  now: Date = new Date();

  constructor(
    private validateInputService: InputValidationService,
    private spinnerSrv: SpinnerService,
    private eventSrv: EventsService,
    private messageService: MessageService
  ) {
    this.rescheduleForm = new FormGroup({
      dateInit: new FormControl('', Validators.required),
      dateEnd: new FormControl('', Validators.required),
      notify: new FormControl(false)
    });
  }

  get dateInitField(): AbstractControl {
    return this.rescheduleForm.get('dateInit');
  }

  get dateEndField(): AbstractControl {
    return this.rescheduleForm.get('dateEnd');
  }

  get notifyField(): AbstractControl {
    return this.rescheduleForm.get('notify');
  }

  ngOnInit(): void {
    this.initialData = this.eventData;
    this.setForm(this.eventData);
    this.verifyDatetimeSelected();
  }

  setForm(data) {
    this.rescheduleForm.patchValue({
      dateInit: new Date(data.dateInit),
      dateEnd: new Date(data.dateEnd),
      notify: false
    });
  }

  validateInputClass(
    form: FormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  verifyDatetimeSelected() {
    this.rescheduleForm.controls.dateInit.valueChanges.subscribe({
      next: () => {
        this.checkDateTime();
      }
    });

    this.rescheduleForm.controls.dateEnd.valueChanges.subscribe({
      next: () => {
        this.checkDateTime();
      }
    });
  }

  checkDateTime() {
    this.isValidRangeDate = true;
    if (this.dateInitField.value !== '' && this.dateInitField.value !== null) {
      if ((this.dateEndField.value !== '' && this.dateEndField.value !== null) && this.dateEndField.value <= this.dateInitField.value) {
        this.isValidRangeDate = false;
      }
    }
  }

  save() {
    this.formHasBeenSubmitted = true;
    if (this.isValidRangeDate && this.rescheduleForm.valid) {
      this.spinnerSrv.loadSpinner.next(true);
      const data = this.rescheduleForm.value;
      /* Date.parse is transforming the date to local hour */
      const payload: EventRescheduleRequest = {
        newDateEnd: data.dateEnd.getTime(),
        newDateInit: data.dateInit.getTime(),
        notifyToGuests: data.notify
      };
      this.eventSrv.rescheduleEvent(this.eventData.idEvent, payload).subscribe({
        next: () => {
          this.spinnerSrv.loadSpinner.next(false);
          this.closeModal.emit(true);
        },
        error: (err) => {
          this.spinnerSrv.loadSpinner.next(false);
          this.handleErrorMessage(err.status);
        }
      })
    }
  }

  private handleErrorMessage(status): void {
    let message: string;
    switch (status) {
      case 404:
        message = 'Evento no encontrado.';
        break;
      case 409:
        message = 'Solo se pueden reprogramar eventos en estado SIN INICIAR, FINALIZADO o CANCELADO.';
        break;
    }
    this.messageService.add({
      sticky: false,
      closable: false,
      severity: 'error',
      summary: message,
      detail: '',
      life: 5000
    })
  }

  cancel() {
    this.formHasBeenSubmitted = false;
    this.setForm(this.initialData);
    this.closeModal.emit(false);
  }

  onHide() {
    this.formHasBeenSubmitted = false;
    this.closeModal.emit(false);
  }

}
