import {ScheduleConfig} from './Configurations';

export class StoreData {
  accountVerified: boolean;
  address: string;
  city: string;
  taxIdentifier: string;
  enabled: boolean;
  deliveryDiscount: number;
  delivery_delay_time: number;
  distance: number;
  email: string;
  header: string;
  headerUrl: string;
  id: number;
  latitude: number;
  logo: string;
  logoUrl: string;
  longitude: number;
  maxPerTableBooking: number;
  name: string;
  notificationSound: boolean;
  phone: number;
  priceMinForDelivery: number;
  priceOfDelivery: number;
  schedule: ScheduleConfig;
  storeDescription: string;
  tableOrderAvailable: boolean;
  takeAwayDiscount: number;
  take_away_delay_time: number;
  vipDiscount: number;
  country?: string;
  tenant: number;
  isTenantOwner: boolean;
  appPaymentProvider: string;
  blocked: boolean;
  blockedAt: number;
}
