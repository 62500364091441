<form class="lista-espera-container" [formGroup]="listaEsperaForm"
      [ngClass]="{ form__submitted: formHasBeenSubmitted }">
  <div class="header">
    <div>
      <p class="text-xl font-semibold text-gray-1 lh-16 my-0">LISTA DE ESPERA</p>
    </div>

    <div class="flex justify-content-end">
      <div class="switch-wrapper">
        <label for="bookingAvailable" class="label-switch"> Habilitar </label>
        <div class="switch-container">
          <p-inputSwitch
            name="waitingListAvailable"
            formControlName="waitingListAvailable"
            [disabled]="fromAdmin"
          >
          </p-inputSwitch>
        </div>
      </div>
    </div>
  </div>

  <div class="grid mt-16 general-content">
    <div class="col-5 pr-4">
      <div class="flex align-items-center mb-3">
        <label for="maximumPerTable" class="mr-2">Cupo máximo por mesa*</label>
        <div class="tooltip-cursor" pTooltip="Cantidad máxima de personas admitidas por grupo." tooltipPosition="top">
          <i class="fa-light fa-circle-info"></i>
        </div>
      </div>
      <div class="field__item field--number">
        <div class="p-float-label">
          <p-inputNumber
            id="maximumPerTable"
            formControlName="maximumPerTable"
            [showButtons]="true"
            buttonLayout="horizontal"
            spinnerMode="horizontal"
            decrementButtonClass="p-button-decrement mr-2"
            incrementButtonClass="p-button-increment ml-2"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            mode="decimal"
            min="1"
            max="{{ maxCapacityPerTable }}"
            locale="es-AR"
          ></p-inputNumber>
          <app-show-errors
            [page]="'configurations'"
            [ctrl]="listaEsperaForm.get('maximumPerTable')"
            [formHasBeenSubmitted]="formHasBeenSubmitted"
          ></app-show-errors>
        </div>
      </div>
    </div>

    <div class="col-6 offset-1 pr-2">
      <div class="flex align-items-center mb-3">
        <label for="peopleAdmittedPerHour" class="mr-2">Código de lista de espera</label>
        <div class="tooltip-cursor" pTooltip="Colocá este QR en el ingreso de tu comercio para que tus clientes puedan
          registrarse en la lista de espera virtual, evitando que hagan filas innecesarias.Tus clientes deberán tener
          instalada la App, donde serán notificados cuando sea su turno para ingresar." tooltipPosition="top">
          <i class="fa-light fa-circle-info"></i>
        </div>
      </div>
      <div class="how-qr">
        <app-show-qr
          [qrDataString]="createDataToQR()"
          [headerTitle]="'Colocá este QR en el ingreso de tu comercio'"
          [leyenda]="'Tus clientes deberán escanearlo con BlackID App, para registrarse en la lista de espera virtual y evitar filas innecesarias. Serán notificados a través de la app cuando sea su turno de ingreso.'"
          [type]="'WaitingList'"
          [sizeClass]="'big'"
        ></app-show-qr>
      </div>
    </div>
  </div>

  <p-toast></p-toast>
</form>
