import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MessageService } from 'primeng/api';
import {
  CroppImgConfig,
  aspectRadioMap,
} from 'src/app/core/models/CroppImgConfig';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-load-img-cropp',
  templateUrl: './load-img-cropp.component.html',
  styleUrls: ['./load-img-cropp.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LoadImgCroppComponent),
      multi: true,
    },
    MessageService,
  ],
})
export class LoadImgCroppComponent implements OnInit, ControlValueAccessor {
  @ViewChild('myInput') myInputVariable: ElementRef;
  @Input() title = '';
  @Input() hide = false;
  @Input() file: File;
  @Input() cancelBtn: string;
  @Input() showToastError = true;
  @Input() showImageFinal = true;
  @Output() imageHasBeenSelected = new EventEmitter();
  @Output() imageHasBeenSelectedAndSave = new EventEmitter();
  @Output() removeEmit = new EventEmitter();
  @Output() imageError = new EventEmitter();
  @Input() onlyShowImageName = false;
  @Input() config: CroppImgConfig = {
    aspectRatio: aspectRadioMap.square,
    cropperMinWidth: '200',
    cropperMaxWidth: '1200',
    cropperMinHeight: '200',
    cropperMaxHeight: '1200',
    resizeToHeight: '200',
    resizeToWidth: '200',
    minHeightImgSize: 200,
    minWidthImgSize: 200,
    maxSize: 1000000,
    maintainAspectRatio: true,
  };
  hideImgContainer = false;
  showImageCropper: boolean;
  imageChangedEvent: any;
  uploadedFiles;
  croppedImage;
  thumbnail = [];
  modalConfig;
  showModalConfirm = false;
  thumbnailValid = false;
  fileName = '';
  originalFileName: string;
  

  value: string;
  isDisabled: boolean;

  onChange = (_: any) => {};
  onTouch = () => {};

  constructor(private messageService: MessageService) {}

  ngOnInit(): void {}

  onInput(value: string): void {
    this.value = value;
    this.onTouch();
    this.onChange(this.value);
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value || '';
    } else {
      this.value = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  fileChangeEvent(event: any): void {
    this.imageHasBeenSelected.emit(true);

    if (this.onlyShowImageName) {
      this.originalFileName = this.myInputVariable.nativeElement.files[0].name;
      this.imageHasBeenSelected.emit(true);
    }

    this.hideImgContainer = false;
    this.showImageCropper = true;
    this.imageChangedEvent = event;
  }

  selectPartImage(): void {
    this.showImageCropper = false;
    this.imageHasBeenSelectedAndSave.emit(true);
  }

  removeThumbnail(): void {
    this.hideImgContainer = false;
    this.uploadedFiles = null;
    this.croppedImage = null;
    this.myInputVariable.nativeElement.value = '';
    this.value = '';
    this.thumbnail = [];
    this.removeEmit.emit(true);
  }

  changeImage(): void {
    this.myInputVariable.nativeElement.click();
  }

  hideImg(): void {
    this.hideImgContainer = true;
  }

  showImg(): void {
    this.hideImgContainer = false;
  }

  cleanCroppedImage(): void {
    this.croppedImage = null;
  }

  async imageCropped(event: ImageCroppedEvent): Promise<boolean> {
    if (
      event.imagePosition.x2 < this.config.minHeightImgSize ||
      event.imagePosition.y2 < this.config.minWidthImgSize
    ) {
      this.showImageCropper = false;
      const errorMessage = `El tamaño de archivo no es válido, mínimo ${this.config.minHeightImgSize}px de alto y ${this.config.minWidthImgSize}px de ancho`;

      if (this.showToastError) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error el tamaño de archivo no es válido',
          detail: errorMessage,
        });
      }

      this.imageError.emit(errorMessage);
      this.hideImg();
      this.showModalConfirm = true;

      return false;
    }

    this.croppedImage = event.base64;

    const file = await this.dataUrlToFile(
      this.croppedImage,
      this.croppedImage.fileName
    );
    this.fileName = this.croppedImage.fileName;
    if (file.size > this.config.maxSize) {
      this.showImageCropper = false;
      const errorMessage =
        'Error el tamaño de la imagen supera el limite permitido (1Mb)';

      if (this.showToastError) {
        this.messageService.add({
          severity: 'error',
          summary: errorMessage,
        });
      }

      this.imageError.emit(errorMessage);
      this.hideImg();
      this.showModalConfirm = true;

      return false;
    }

    const acceptedTypes = ['jpg', 'jpeg', 'png'];
    const isValidType = acceptedTypes.some((el) => {
      return file.type.includes(el);
    });

    if (!isValidType) {
      this.showImageCropper = false;
      const errorMessage =
        'El tipo de archivo no es válido (solo se aceptan formatos .png, .jgp, .jpeg)';

      if (this.showToastError) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error el tipo de archivo no es válido',
          detail: errorMessage,
        });
      }

      this.showModalConfirm = true;
      this.imageError.emit(true);
      this.hideImg();
      return false;
    }

    const logoData = await new Blob([file], {
      type: 'image/png',
    }).arrayBuffer();

    const logoArr = new Uint8Array(logoData);
    const byteArr = Array.from(logoArr);

    this.onTouch();
    this.onChange(byteArr);

    this.thumbnailValid = true;
  }

  async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
  }

  loadImageFailed(): void {
    this.showImageCropper = false;

    const errorMessage =
      'Error el tipo de archivo no válido (solo se aceptan formatos .png, .jgp, .jpeg)';

    if (this.showToastError) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error el tipo de archivo no es válido',
        detail: errorMessage,
      });
    }

    this.showModalConfirm = true;
    this.removeThumbnail();
  }
}
