<app-navbar *ngIf="logged"></app-navbar>
<p-toast></p-toast>
<div class="container-fluid bg-gradient first">
  <p-confirmDialog header="" key="jwtExpired" [style]="{ width: '450px' }" styleClass="confirmJwtExpired"
                   acceptLabel="Iniciar sesión"></p-confirmDialog>
  <p-confirmDialog header="" key="changeAccount" icon="pi pi-exclamation-triangle" styleClass="confirmJwtExpired"
                   acceptLabel="Recargar"></p-confirmDialog>
  <app-spinner [showSpinner]="showSpinner" [type]="4"></app-spinner>
</div>

<div class="app__container" [ngClass]="{ 'container-router': logged }">
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>

<!-- Error for 500 error -->
<p-dialog [(visible)]="showRequestError" appendTo="body" [style]="{ width: '450px' }"
          header="{{ modalTitle ? modalTitle : 'Atención' }}" [modal]="true">
  <ng-template pTemplate="content">
    <span class="flex align-items-center text-center">{{ errorMessage }}</span>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton label="Entendido" class="btn btn__primary" (click)="hideDialogError()"></button>
  </ng-template>
</p-dialog>
