import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class InputValidationService {
  constructor() {}

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    const formCtrl = form.controls[fieldName];

    if (submitRegister) {
      if (formCtrl.valid) {
        if (formCtrl.touched) {
          return 'has-valid';
        }
      } else {
        return 'has-error';
      }
    } else if (formCtrl.dirty && formCtrl.touched) {
      return 'has-typing';
    } else {
      return '';
    }
  }
}
