import {Component, OnInit} from '@angular/core';
import {Tenant} from "../../../../../../../core/models/Tenant";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {UserService} from "../../../../../../../services/user.service";
import {VerifyStoreRequest} from "../../../../../../../core/models/VerifyStoreRequest";
import {SpinnerService} from "../../../../../../../services/spinner.service";
import {StoreService} from "../../../../../../../services/store.service";

@Component({
  selector: 'app-modal-verify-store',
  templateUrl: './modal-verify-store.component.html',
  styleUrls: ['./modal-verify-store.component.scss'],
})
export class ModalVerifyStoreComponent implements OnInit {
  storeName: string = null;
  tenantList: Tenant[] = null;
  tenantSelected: Tenant = null;
  storeVerified: boolean;
  storeId: number;
  isReader: boolean;
  isWriter: boolean;
  isTenantOwner = false;

  constructor(
    public ref: DynamicDialogRef,
    private userSrv: UserService,
    private spinnerSrv: SpinnerService,
    private storeSrv: StoreService,
    public config: DynamicDialogConfig
  ) {
  }

  ngOnInit(): void {
    this.storeName = this.config.data.storeName;
    this.tenantList = this.config.data.tenantList;
    this.tenantSelected = this.config.data.tenantSelected;
    this.storeVerified = this.config.data.storeVerified;
    this.storeId = this.config.data.storeId;
    this.isReader = this.userSrv.isReader();
    this.isWriter = this.userSrv.isWriter();
    this.isTenantOwner = this.checkIfIsTenantOwner();
  }

  checkIfIsTenantOwner(): boolean {
    let tenantFound = null;
    if (this.tenantSelected?.id) {
      tenantFound = this.tenantList.find(te => te.id === this.tenantSelected.id);
    }
    return tenantFound !== null && (tenantFound.storeOwner === this.storeId);
  }

  onChangeTenant($event): void {
    this.tenantSelected = $event.value;
  }

  confirmVerification(event: any): void {
    this.storeVerified = event.checked
  }

  verify(): void {
    this.spinnerSrv.loadSpinner.next(true);
    const verifyStoreRequest = new VerifyStoreRequest();
    verifyStoreRequest.verified = true;
    verifyStoreRequest.tenant = this.tenantSelected.id;
    this.storeSrv.verifyStoreWithTenant(this.storeId, verifyStoreRequest).subscribe({
      next: () => {
        window.location.reload();
        this.spinnerSrv.loadSpinner.next(false);
      },
      error: (err) => {
        this.spinnerSrv.loadSpinner.next(false);
      }
    })
  }

  cancel() {
    this.ref.close(false);
  }

}
