import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators,} from '@angular/forms';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-category-form-modal',
  templateUrl: './category-form-modal.component.html',
  styleUrls: ['./category-form-modal.component.scss'],
  providers: [DialogService]
})
export class CategoryFormModalComponent implements OnInit {
  @Output() categoryFormData = new EventEmitter();
  isEditing: boolean;
  categoriesEventForm: UntypedFormGroup;
  formHasBeenSubmitted = false;
  loading = false;
  headerPopup = 'Category';
  maxNameLength = environment.maxCategoryNameLength;
  positions: number[] = [];
  switchState = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private validateInputService: InputValidationService,
    public externalData: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.categoriesEventForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(this.maxNameLength)]],
      position: [
        this.isEditing
          ? this.externalData.data.categoryEditData
          : this.externalData.data.lastPosition + 1,
      ],
      oldPosition: [null],
      showOnBillboard: [false],
    });
  }

  get nameField(): AbstractControl {
    return this.categoriesEventForm.get('name');
  }

  ngOnInit(): void {
    this.isEditing = this.externalData.data.isEditing;
    if (this.isEditing) {
      this.createPositions(this.externalData.data.lastPosition);
      this.categoriesEventForm.patchValue({
        id: this.externalData.data.categoryEditData.idCategory,
        name: this.externalData.data.categoryEditData.name,
        position: this.externalData.data.categoryEditData.position,
        showOnBillboard:
        this.externalData.data.categoryEditData.showOnBillboard,
      });
    } else {
      this.createPositions(this.externalData.data.lastPosition);
    }
  }


  checkShowOnBillboard(e): void {
    this.categoriesEventForm.controls.showOnBillboard.patchValue(e.checked);
  }

  createPositions(lastPosition: number): void {
    for (
      let i = 1;
      i <= (this.isEditing ? lastPosition : lastPosition + 1);
      i++
    ) {
      this.positions.push(i);
    }
  }

  submitCategory(): void {
    this.ref.close(this.categoriesEventForm.value);
    this.cleanDialog();
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  closeModal() {
    this.ref.close();
    this.cleanDialog();
  }

  cleanDialog(): void {
    this.positions = [];
    this.categoriesEventForm.reset();
  }
}
