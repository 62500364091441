import {Component, Input, OnInit} from '@angular/core';
import {EventComplete} from 'src/app/core/models/EventComplete';
import {SpinnerService} from 'src/app/services/spinner.service';
import * as FileSaver from 'file-saver';
import {GuestForSpreadSheetResponse} from 'src/app/core/models/GuestForSpreadSheetResponse';
import {EventsService} from 'src/app/services/events.service';
import {EventSalesStaticsResponse} from "../../../../../../../core/models/events/EventSalesStaticsResponse";

@Component({
  selector: 'app-cards-generals-stats',
  templateUrl: './cards-generals-stats.component.html',
  styleUrls: ['./cards-generals-stats.component.scss']
})
export class CardsGeneralsStatsComponent implements OnInit {

  @Input() event: EventComplete;
  @Input() static: EventSalesStaticsResponse;
  //Guests
  totalGuestsIngress = 0;
  totalGuestsNotIngress = 0;
  totalGuests = 0;
  //Tickets
  ticketsTotalSold = 0;
  ticketsTotalSoldIncome = 0;
  ticketsTotal = 0;
  ticketsAvailable = 0;
  ticketNames = [];
  //Table
  tablesTotalSold = 0;
  tablesTotalSoldIncome = 0;
  tablesTotal = 0;
  tablesAvailable = 0;
  tableNames = [];
  //Excel
  guestsToExport: GuestForSpreadSheetResponse[] = null;

  constructor(
    private spinnerSrv: SpinnerService,
    private eventSrv: EventsService
  ) {
  }

  ngOnInit(): void {
    this.setGuestsStatics();
    this.setTicketsStatics();
    this.setTablesStatics();
  }

  setGuestsStatics() {
    this.totalGuestsIngress = this.event.totalIngress;
    this.totalGuests = this.event.totalPeople;
    this.totalGuestsNotIngress = this.totalGuests - this.totalGuestsIngress;
  }

  setTicketsStatics() {
    this.ticketsTotalSold = this.event.accessTypeGlobalResponse.totalAccessSold;
    this.ticketsTotal = this.event.accessTypeGlobalResponse.totalAccess;
    this.ticketsAvailable = this.ticketsTotal - this.ticketsTotalSold;
    this.ticketsTotalSoldIncome = this.static.totalTicketSold;
    this.ticketNames = [];
    this.event.accessTypeGlobalResponse.accessTypesList.forEach(ticket => {
      this.ticketNames.push(ticket.name);
    });
  }

  setTablesStatics() {
    this.tablesTotalSold = this.event.tableTypeGlobalResponse.totalTablesSold;
    this.tablesTotal = this.event.tableTypeGlobalResponse.totalTables;
    this.tablesAvailable = this.tablesTotal - this.tablesTotalSold;
    this.tablesTotalSoldIncome = this.static.totalTableSold;
    this.tableNames = [];
    this.event.tableTypeGlobalResponse.tableTypesList.forEach(table => {
      this.tableNames.push(table.name);
    });
  }

  downloadGuestExcel() {
    this.spinnerSrv.loadSpinner.next(true);
    this.eventSrv.getGuestsForSpreadsheet(this.event.idEvent).subscribe({
      next: (res: GuestForSpreadSheetResponse[]) => {
        this.guestsToExport = res;
        this.exportExcel();
        this.spinnerSrv.loadSpinner.next(false);
      },
      error: () => {
        this.spinnerSrv.loadSpinner.next(false);
      }
    });
  }

  exportExcel() {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.guestsToExport);
      const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']};
      const excelBuffer: any = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      this.saveAsExcelFile(excelBuffer, 'Invitados-' + this.event.eventName + '-');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
