import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import pdfFonts from 'pdfmake/build/vfs_fonts'; // fonts provided for pdfmake
import { Canvas, Img, Line, PdfMakeWrapper, Table, Txt } from 'pdfmake-wrapper';
import { Subscription } from 'rxjs';
import { Menu, NewMenu } from 'src/app/core/models/Menu';
import { StoreData } from 'src/app/core/models/StoreData';
import { MenuService } from 'src/app/services/menu.service';
import { PdfHandlerService } from 'src/app/services/pdf-handler.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu-to-pdf',
  templateUrl: './menu-to-pdf.component.html',
  styleUrls: ['./menu-to-pdf.component.scss'],
})
export class MenuToPdfComponent implements OnInit, OnDestroy {
  @ViewChild('content') content: ElementRef;
  // @Output('savePdfSuccess') savePdfSuccess = new EventEmitter();

  _menuData: Menu;
  comercio: StoreData;
  urlLogoStore: string;
  subs: Subscription[] = [];

  get menuData(): Menu {
    return this._menuData;
  }
  @Input() downloadPdf = false;
  // tslint:disable-next-line: adjacent-overload-signatures
  @Input() set menuData(value: Menu) {
    this._menuData = value;
    if (this._menuData) {
      const menuSort = this._menuData.sections.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      if (menuSort !== null) {
        this.generatePDF();
      }
    }
  }
  constructor(
    private pdfHandler: PdfHandlerService,
    private menuSrv: MenuService
  ) {}

  ngOnInit(): void {
    this.comercio = JSON.parse(localStorage.getItem('storeData'));
    this.urlLogoStore =
      environment.mediaUrl + this.comercio.logoUrl;
  }

  // private sendPdf() {
  //   setTimeout(async () => {
  //     const menuHtml = document.getElementById('menupdf');
  //     const menuFile = await this.pdfHandler.htmlToPdf(menuHtml);
  //     const menuForm: NewMenu = { menuFile };
  //     this.subs.push(this.menuSrv.createMenu(menuForm).subscribe());
  //   }, 1000);
  // }

  async generatePDF(): Promise<void> {
    PdfMakeWrapper.setFonts(pdfFonts);
    const pdf = new PdfMakeWrapper();

    pdf.background(
      await new Img('/assets/background-pdf-2.png')
        .alignment('center')
        .width(600)
        .build()
    );

    pdf.defaultStyle({
      color: 'white',
    });

    const gris = '#ddd';

    pdf.add([
      await new Img(this.urlLogoStore).alignment('center').width(100).build(),
      new Txt([this.comercio.name])
        .fontSize(22)
        .margin([0, 20, 0, 0])
        .bold()
        .alignment('center').end,
      await new Img('/assets/menu-title-pdf.png')
        .alignment('center')
        .width(150)
        .build(),
    ]);

    this.menuData.sections.forEach((section) => {
      const productsSort = section.products.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      pdf.add([
        new Txt([section.name.toUpperCase()])
          .bold()
          .fontSize(20)
          .margin([0, 40, 0, 0]).end,
        new Txt([section.description]).margin([0, 0, 0, 20]).color(gris).end,
      ]);

      const cantProducts = section.products.length;
      section.products.forEach((product, index) => {
        const cell = [
          [
            new Txt(product.name.toUpperCase()).bold().margin([0, 0, 0, 5]).end,
            new Txt(product.description).color(gris).end,
          ],
          '',
          new Txt('$ ' + product.tablePrice).fontSize(15).bold().end,
        ];

        pdf.add(
          new Table([cell]).layout('noBorders').margin(10).widths([350, 70, 80])
            .end
        );

        if (index < cantProducts - 1) {
          pdf.add(new Canvas([new Line([0, 0], [500, 0]).color(gris).end]).end);
        }
      });

      pdf.add([
        new Txt('').margin([0, 40, 0, 0]).end,
        new Canvas([new Line([-20, 0], [530, 0]).color('#ccc').end]).end,
      ]);
    });

    if (this.downloadPdf) {
      pdf.create().download();
    }

    this.saveFileApi(pdf);
  }

  saveFileApi(pdf): void {
    pdf.create().getBuffer((buffer) => {
      const pdfU8 = new Uint8Array(buffer);
      const pdfByteArr = Array.from(pdfU8);
      const menu: NewMenu = {
        menuFile: pdfByteArr,
      };
      this.subs.push(
        this.menuSrv.createMenu(menu).subscribe((res) => {
          // this.savePdfSuccess.emit(true);
        })
      );
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => {
      s.unsubscribe();
    });
  }
}
