<div class="donut-card-container">
    <div class="labels">
        <ul class="list">
            <li *ngFor="let column of columns" class="margin-bottom-32">
                <div>
                    <div class="little-box" [ngStyle]="{'background-color': colors[column[0]]}"></div>
                </div>
                
                <div class="margin-left-8">
                    <div class="margin-bottom-8">{{ column[0] }}</div> 
                    <div class="value">{{ column[1] }}</div>
                </div>
            </li>
        </ul>
    </div>
    <div class="donut-container">
        <app-donut-graphic [idChart]="idChart" [columns]="columns" [isEmpty]="emptyChart" [colors]="colors"></app-donut-graphic>
    </div>
</div>  