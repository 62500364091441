<section class="margin-top-16 transatcions-container">
  <app-card-total-stats class="p-col card-total-stats mr-3" [icon]="'font-w-300 fa-users'"
                        [description]="'Total de invitados'"
                        [value]="stats?.totalGuest"
  ></app-card-total-stats>
  <app-card-total-stats class="p-col card-total-stats" [icon]="'font-w-300 fa-user-check'"
                        [description]="'Ingresados'"
                        [value]="stats?.ingressGuests"
                        [valueClass]="'text-success'"
  ></app-card-total-stats>
</section>

<section class="tickets-table-container margin-top-64">
  <div class="column margin-right-16">
    <div class="header-section">
      <span class="row-title margin-bottom-16"><i class="fa-solid fa-user margin-right-8"></i>Invitados</span>
      <div class="switch"><label>Ver solo ingresados</label>
        <p-inputSwitch [(ngModel)]="isShowOnlyIngressGuestDonut" (ngModelChange)="onSwitchTypeGuest()"></p-inputSwitch>
      </div>
    </div>
    <app-card-donut [idChart]="'donutGuest'" [columns]="columnsGuestDonut" [emptyChart]="emptyGuest" [colors]="colorsGuestDonut"></app-card-donut>
  </div>
  <div class="column">
    <div class="header-section">
      <span class="row-title margin-bottom-16"><i class="fa-solid fa-badge-percent margin-right-8"></i>Acceso pago vs Sin cargo</span>
      <div class="switch"><label>Ver solo ingresados</label>
        <p-inputSwitch [(ngModel)]="isShowOnlyVSDonut" (ngModelChange)="onSwitchVsGuest()"></p-inputSwitch>
      </div>
    </div>
    <app-card-donut [idChart]="'donutvs'" [columns]="columnsVsDonut" [emptyChart]="emptyAccess" [colors]="colorsVsDonut"></app-card-donut>
  </div>
</section>

<section class="margin-top-64">
  <span class="row-title margin-bottom-16"><i class="fa-solid fa-user-clock margin-right-8"></i>Ingresos según horario</span>
  <div class="graphic-container" *ngIf="dataCategories.length > 0 else noEvents">
    <app-area-graphic [idChart]="'chartHour'" [columns]="graphicIngressHour"
                      [categories]="dataCategories"></app-area-graphic>
  </div>
  <ng-template #noEvents>
    <div class="no-events">
      <i class="fa-light fa-user-clock"></i>
      <span>Todavía no hay ingresos para mostrar.</span>
    </div>
  </ng-template>
</section>

<section class="margin-top-64">
  <span class="row-title margin-bottom-16"><i class="fa-solid fa-circle-dollar margin-right-8"></i>Invitados con abono</span>
  <div class="flex">
    <app-card-total-stats-short class="p-col card-confirm-canceled-stats margin-right-16 w-100" [icon]="'font-w-300 fa-person'"
                                [label]="'Individuales'" [value]="stats.guestWithTicket.guestsOnPaidAccess"
                                [colorClass]="'text-gray-1'"></app-card-total-stats-short>
    <app-card-total-stats-short class="p-col card-confirm-canceled-stats w-100" [icon]="'font-w-300 fa-champagne-glasses'"
                                [label]="'En Combos / Mesas'" [value]="stats.guestWithTicket.guestsOnPaidTables"
                                [colorClass]="'text-gray-1'"></app-card-total-stats-short>
  </div>
</section>

<section class="margin-top-64">
  <span class="row-title margin-bottom-16"><i class="fa-solid fa-input-text margin-right-8"></i>Carga manual</span>
  <div class="flex">
    <app-card-total-stats-short class="p-col card-confirm-canceled-stats margin-right-16 w-100"
                                [icon]="'font-w-300 fa-champagne-glasses'"
                                [label]="'Combos / Mesas'" [value]="stats.manualLoadingGuests.quantityTables"
                                [colorClass]="'text-gray-1'"></app-card-total-stats-short>
    <app-card-total-stats-short class="p-col card-confirm-canceled-stats w-100" [icon]="'font-w-300 fa-clipboard-list'"
                                [label]="'Listas'" [value]="stats.manualLoadingGuests.quantityLists"
                                [colorClass]="'text-gray-1'"></app-card-total-stats-short>
  </div>
  <div class="flex margin-top-16">
    <app-card-total-stats-short class="p-col card-confirm-canceled-stats margin-right-16 w-100" [icon]="'font-w-300 fa-person'"
                                [label]="'Individuales'" [value]="stats.manualLoadingGuests.quantityIndividuals"
                                [colorClass]="'text-gray-1'"></app-card-total-stats-short>
    <app-card-total-stats-short class="p-col card-confirm-canceled-stats margin-right-16 w-100" [icon]="'font-w-300 fa-person'"
                                [label]="'En Combos / Mesas'" [value]="stats.manualLoadingGuests.guestsOnTable"
                                [colorClass]="'text-gray-1'"></app-card-total-stats-short>
    <app-card-total-stats-short class="p-col card-confirm-canceled-stats w-100" [icon]="'font-w-300 fa-person'"
                                [label]="'En Listas'" [value]="stats.manualLoadingGuests.guestsOnList"
                                [colorClass]="'text-gray-1'"></app-card-total-stats-short>
  </div>
</section>
