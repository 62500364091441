import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators,} from '@angular/forms';
import {AlgoliaProvider, OpenStreetMapProvider} from 'leaflet-geosearch';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConfirmationService, Message, MessageService} from 'primeng/api';

import {AuthService} from 'src/app/services/auth.service';
import {ConfigurationService} from 'src/app/services/configuration.service';
import {FormatedGoogleResult} from 'src/app/core/models/FormatedGoogleResult';
import {GenericConfig} from 'src/app/core/models/Configurations';
import {GoogleService} from 'src/app/services/google.service';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {RequestErrorTriggerService} from 'src/app/services/request-error-trigger.service';
import {SpinnerService} from 'src/app/services/spinner.service';
import {StoreData} from 'src/app/core/models/StoreData';
import {Subscription} from 'rxjs';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-config-generic',
  templateUrl: './config-generic.component.html',
  styleUrls: ['./config-generic.component.scss'],
})
export class ConfigGenericComponent implements OnInit {
  @Output() validFormState = new EventEmitter();
  @Input() genericConfig: GenericConfig = null;
  @Input() configurationId: number;
  @Input() capacityReservationTable: number;
  @Input() fromAdmin: boolean;
  @Input() canWrite: boolean;
  originalData: GenericConfig = null;
  genericConfigForm: UntypedFormGroup;
  passwordForm: UntypedFormGroup;
  availableCommerce: UntypedFormGroup;
  idStore: string;
  thereAreChanges: boolean = false;
  capacityMessage: Array<Message> = [];
  provider1: AlgoliaProvider = new AlgoliaProvider();
  provider2: OpenStreetMapProvider = new OpenStreetMapProvider();
  typingTimer: any;
  address: string;
  suggestedAddresses: any[] = [];
  loadingSpinner: boolean;
  passwordDialog: boolean;
  passwordErrorDialog: boolean;
  passwordError: string;
  urlImage: string;
  urlImageHeader: string = null;
  storeData: StoreData = null;
  accountVerified: boolean;
  setLogo = false;
  setHeader = false;
  isMaxCapacityValid = false;
  maxCapacityCurrent: number = 0;
  maxCapacityCurrentSetted = false;
  subs: Subscription[] = [];
  changingLogo = false;
  changingHeader = false;
  formHasBeenSubmitted = false;
  maxCommerceNameLength: number;
  maxCommerceDescriptionLength: number;
  maxCommercePasswordLength: number;
  minCommercePasswordLength: number;
  imgHeaderHasBeenChanged = false;
  imgLogoHasBeenChanged = false;
  maxPhoneLength: number;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private configurationSrv: ConfigurationService,
    private authSrv: AuthService,
    private errorSrv: RequestErrorTriggerService,
    private spinnerSrv: SpinnerService,
    private confirmationSrv: ConfirmationService,
    private googleSrv: GoogleService,
    private messageSrv: MessageService,
    private validateInputSrv: InputValidationService
  ) {
    this.maxCommerceNameLength = environment.maxCommerceNameLength;
    this.maxCommerceDescriptionLength =
      environment.maxCommerceDescriptionLength;
    this.maxCommercePasswordLength = environment.maxPasswordLength;
    this.minCommercePasswordLength = environment.minPasswordLength;
    this.maxPhoneLength = environment.maxPhoneLength;
    this.genericConfigForm = this.formBuilder.group({
      availableCommerce: [{value: false}],
      description: [
        {value: ''},
        Validators.maxLength(this.maxCommerceDescriptionLength),
      ],
      maxCapacity: [{value: 0}],
      address: [{value: ''}, Validators.required],
      name: [
        {value: ''},
        [Validators.maxLength(this.maxCommerceNameLength), Validators.required],
      ],
      phone: [
        {value: 0},
        [Validators.maxLength(this.maxPhoneLength), Validators.required],
      ],
      latitude: [{value: 0}],
      longitude: [{value: 0}],
      city: [{value: ''}, Validators.required],
      country: [{value: ''}, Validators.required],
      logo: [null],
      logoUrl: [{value: ''}],
      header: [null],
      headerUrl: [{value: ''}],
      accountVerified: [{value: false}],
      notificationSound: [{value: false}]
    });
    this.passwordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      newPasswordRepeat: [
        '',
        [Validators.required, this.passwordsMatchRepeat()],
      ],
    });
  }

  ngOnInit(): void {
    this.checkCanWrite();
    if (this.genericConfig != null) {
      this.originalData = {...this.genericConfig};
      this.loadData();
      this.accountVerified = this.genericConfig.accountVerified;
    }
    this.getIdStore();
    this.verifyChanges();
    this.showWarningMsg();
    this.subs.push(
      this.authSrv.user.subscribe({
        next: (user: StoreData) => {
          this.storeData = user;
          this.urlImage =
            environment.mediaUrl + this.storeData.logoUrl + '?' + Date.now();
          if (this.storeData.headerUrl) {
            this.urlImageHeader =
              environment.mediaUrl + this.storeData.headerUrl + '?' + Date.now();
          }
        }
      })
    );
  }

  get descriptionLabel(): AbstractControl {
    return this.genericConfigForm.get('description');
  }

  get nameLabel(): AbstractControl {
    return this.genericConfigForm.get('name');
  }

  getUrlImage(url: string): string {
    return `${environment.mediaUrl}${url}`;
  }

  onPhoneChange(e) {
    if (e.keyCode !== 8) {
      if (this.genericConfigForm.controls.phone.value && this.genericConfigForm.controls.phone.value.toString().length >= 11) {
        return false;
      }
    }
    return true;
  }

  checkCanWrite(): void {
    if (!this.canWrite) {
      this.genericConfigForm.controls.availableCommerce.disable();
      this.genericConfigForm.controls.name.disable();
      this.genericConfigForm.controls.description.disable();
      this.genericConfigForm.controls.address.disable();
      this.genericConfigForm.controls.phone.disable();
    }
  }

  save(): void {
    this.formHasBeenSubmitted = true;
    if (this.genericConfigForm.valid) {
      this.spinnerSrv.loadSpinner.next(true);
      const payload = {genericConfiguration: this.genericConfigForm.value};
      this.subs.push(
        this.configurationSrv
          .updateConfigurations(payload, this.configurationId)
          .subscribe({
            next: (res) => {
              this.spinnerSrv.loadSpinner.next(false);
              if (res.ok) {
                if (
                  this.genericConfigForm.controls['name'].value !==
                  this.originalData.name ||
                  this.genericConfigForm.controls['logo'].value !==
                  this.originalData.logo ||
                  this.genericConfigForm.controls['header'].value !==
                  this.originalData.header ||
                  this.genericConfigForm.controls['address'].value !==
                  this.originalData.address ||
                  this.genericConfigForm.controls['notificationSound'].value !==
                  this.originalData.address

                ) {
                  this.subs.push(
                    this.authSrv.getCurrentUser().subscribe({
                      next: (user: StoreData) => {
                        this.authSrv.updateUser(user);
                      }
                    })
                  );
                }
                this.originalData = this.genericConfigForm.value;
                this.validFormState.emit({
                  tab: 'General',
                  ok: true,
                  thereAreChanges: false,
                });
                this.thereAreChanges = false;
                this.showMessageOk();
              }
            },
            error: (err) => {
              this.spinnerSrv.loadSpinner.next(false);
              if (err.status === 404 || err.status === 400) {
                this.handleErrorMessage(err.status);
              }
            }
          })
      );
    }
  }

  private handleErrorMessage(status): void {
    let message: string;
    switch (status) {
      case 400:
        message = 'Configuración no valida.';
        break;
      case 404:
        message = 'Configuración inexistente.';
        break;
      case 409:
        message = 'La nueva contraseña no puede ser igual que la anterior';
        break;
      case 401:
        message = 'La contraseña no coincide con la almacenada actualmente';
        break;
    }
    this.errorSrv.updateShowError({
      showError: true,
      message,
    });
  }

  showMessageOk(): void {
    this.messageSrv.add({
      severity: 'success',
      detail: 'Datos guardados con éxito',
      key: 'config-message',
      life: 5000,
      styleClass: 'config-message'
    });
  }

  cancel(): void {
    this.address = this.genericConfig.address;
    this.genericConfigForm.patchValue({
      availableCommerce: this.originalData.availableCommerce,
      description: this.originalData.description,
      maxCapacity: this.originalData.maxCapacity,
      logo: this.originalData.logo,
      logoUrl: this.originalData.logoUrl,
      header: this.originalData.header,
      headerUrl: this.originalData.headerUrl,
      name: this.originalData.name,
      address: this.originalData.address,
      phone: this.originalData.phone,
      latitude: this.originalData.latitude,
      longitude: this.originalData.longitude,
      city: this.originalData.city,
      country: this.genericConfig.country,
      notificationSound: this.originalData.notificationSound,
    });
    this.validFormState.emit({
      tab: 'General',
      ok: true,
      thereAreChanges: false,
    });
    this.thereAreChanges = false;
  }

  verifyChanges(): void {
    this.subs.push(
      this.genericConfigForm.valueChanges.subscribe({
        next: (res) => {
          
          //don't compare currency
          delete this.originalData.currency;
          delete res.header;
          delete res.logo;

          const hasDifference = this.configurationSrv.objectsHaveDifferences(
            JSON.parse(JSON.stringify(this.originalData)),
            JSON.parse(JSON.stringify(res))
          );

          if (hasDifference) {
            this.validFormState.emit({
              tab: 'General',
              ok: false,
              thereAreChanges: true,
            });
            this.thereAreChanges = true;
            console.log('hay cambios')
          } else {
            this.validFormState.emit({
              tab: 'General',
              ok: true,
              thereAreChanges: false,
            });
            this.thereAreChanges = false;
            console.log('no hay cambios')
          }
        }
      })
    );
  }

  getIdStore(): void {
    const {id} = this.authSrv.getLocalUserData();
    this.idStore = id.toString();
  }

  loadData(): void {
    this.address = this.genericConfig.address;
    this.genericConfigForm.patchValue({
      availableCommerce: this.genericConfig.availableCommerce,
      description: this.genericConfig.description,
      maxCapacity: this.genericConfig.maxCapacity,
      phone: this.genericConfig.phone,
      addressComplete: this.genericConfig.address,
      name: this.genericConfig.name,
      logoUrl: this.genericConfig.logoUrl,
      headerUrl: this.genericConfig.headerUrl,
      latitude: this.genericConfig.latitude,
      longitude: this.genericConfig.longitude,
      city: this.genericConfig.city,
      country: this.genericConfig.country,
      accountVerified: this.genericConfig.accountVerified,
      notificationSound: this.genericConfig.notificationSound
    });
  }

  toggleEnabled(val: boolean): void {
    val
      ? this.genericConfigForm.controls['description'].enable()
      : this.genericConfigForm.controls['description'].disable();
    val
      ? this.genericConfigForm.controls['maxCapacity'].enable()
      : this.genericConfigForm.controls['maxCapacity'].disable();
  }

  // Address logic
  startCountDown(): void {
    clearTimeout(this.typingTimer);
    if (this.address) {
      this.typingTimer = setTimeout(() => this.addressChange(), 250);
    }
  }

  async addressChange(): Promise<void> {
    this.suggestedAddresses = await this.googleSrv.getPredictions(this.address);
    if (this.suggestedAddresses.length) {
    }
  }

  async selectSuggestedAddress(result): Promise<void> {
    this.address = result.description;
    const {latitude, longitude, city, address}: FormatedGoogleResult =
      await this.googleSrv.getGeocodedResult(result.description, false);
    this.genericConfigForm.patchValue({
      latitude,
      longitude,
      city,
      address: this.address,
    });
    this.suggestedAddresses = [];
  }

  hideDialogError(): void {
    this.passwordErrorDialog = false;
  }

  openChangePasswordDialog(): void {
    this.passwordDialog = true;
  }

  hideDialog(): void {
    this.passwordDialog = false;
  }

  changePassword(): void {
    this.spinnerSrv.loadSpinner.next(true);
    this.subs.push(
      this.configurationSrv.updatePassword(this.passwordForm.value).subscribe({
        next: () => {
          this.spinnerSrv.loadSpinner.next(false);
          this.passwordErrorDialog = false;
          this.passwordDialog = false;
          this.messageSrv.add({
            severity: 'success',
            detail: 'Contraseña cambiada con éxito',
          });
          this.passwordForm.reset();
        },
        error: (err) => {
          this.spinnerSrv.loadSpinner.next(false);
          if (err.status === 409 || err.status === 401) {
            this.handleErrorMessage(err.status);
          }
        }
      })
    );
  }

  // Custom validations
  passwordsMatchRepeat(): (control: AbstractControl) => {
    [key: string]: boolean;
  } {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return control.value &&
      control.value !== this.passwordForm.get('newPassword').value
        ? {passwordsMatchRepeat: true}
        : null;
    };
  }

  testPasswords(): void {
    this.passwordForm.controls['newPasswordRepeat'].updateValueAndValidity();
  }

  showWarningMsg(): void {
    this.subs.push(
      this.genericConfigForm.controls[
        'availableCommerce'
        ].valueChanges.subscribe({
        next: (isChecked) => {
          if (isChecked !== this.originalData.availableCommerce) {
            if (this.genericConfigForm.valid) {
              const visibleOrNotMessage = isChecked
                ? 'sea visible'
                : 'no sea visible';
              const messageConfirmation = `¿Querés que el comercio ${visibleOrNotMessage} para los clientes de la APP?`;

              this.confirmationSrv.confirm({
                message: messageConfirmation,
                header: '',
                icon: 'icon-warning icon-4xl icon-yellow',
                key: 'avaibleCommerceVerificacion',
                rejectIcon: 'none',
                acceptIcon: 'none',
                accept: () => {
                },
                reject: () => {
                  this.genericConfigForm.patchValue({
                    availableCommerce: this.genericConfig.availableCommerce,
                  });
                },
              });
            } else {
              this.genericConfigForm.patchValue({
                availableCommerce: this.genericConfig.availableCommerce,
              });
              this.messageSrv.add({
                severity: 'error',
                detail: 'Hay campos requeridos sin completar',
              });
            }
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputSrv.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }
}
