import {Injectable} from '@angular/core';
import {SalesAnalyticsRequest} from "../core/models/SalesAnalyticsRequest";
import {Observable} from "rxjs";
import {SalesInfo} from "../core/models/SalesInfo";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {SalesInfoSpreadsheet} from "../core/models/SalesInfoSpreadsheet";

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(
    private httpC: HttpClient
  ) {
  }

  public getSalesAnalytics(payload: SalesAnalyticsRequest): Observable<SalesInfo> {
    return this.httpC.post<SalesInfo>(environment.billboardAGUrl + `/cartelera/sales/analytics`, payload);
  }

  public getSalesAnalyticsForSpreadsheet(payload: SalesAnalyticsRequest): Observable<SalesInfoSpreadsheet> {
    return this.httpC.post<SalesInfoSpreadsheet>(environment.billboardAGUrl + `/cartelera/sales/analytics/spreadsheet`, payload);
  }
}
