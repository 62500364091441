import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-attributes-value',
  templateUrl: './attributes-value.component.html',
  styleUrls: ['./attributes-value.component.scss'],
})
export class AttributesValueComponent implements OnInit {
  @Input() attribute;
  @Input() canWrite;
  @Output() editAttributeValueEmitter = new EventEmitter();
  @Output() deleteAttributeValueEmitter = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  editAttributeValue(attributeValues): void {
    this.editAttributeValueEmitter.emit(attributeValues);
  }

  deleteAttributeValue(attributeValues, attribute): void {
    this.deleteAttributeValueEmitter.emit({
      attributeValues,
      attribute,
    });
  }
}
