import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {MessageService} from 'primeng/api';
import {ModalConfig} from 'src/app/core/models/ModalConfig';
import {SecretData} from 'src/app/core/models/SecretData';
import {SpinnerService} from 'src/app/services/spinner.service';
import {StoreService} from 'src/app/services/store.service';
import {Subscription} from 'rxjs';
import {UserService} from 'src/app/services/user.service';
import {PaymentService} from '../../../../../../../../../services/payment.service';
import {StoreData} from '../../../../../../../../../core/models/StoreData';
import {MercadoPagoCredentialRequest} from 'src/app/core/models/MercadoPagoCredentialRequest';
import {AuthService} from "../../../../../../../../../services/auth.service";
import {CredentialDto} from "../../../../../../../../../core/models/CredentialDto";

@Component({
  selector: 'app-payments-mercadopago-config-dialog',
  templateUrl: './payments-mercadopago-config-dialog.component.html',
  styleUrls: ['./payments-mercadopago-config-dialog.component.scss'],
})
export class PaymentsMercadopagoConfigDialogComponent implements OnInit, OnDestroy {
  storeData: StoreData;
  idStore: number;
  mercadoPagoForm: UntypedFormGroup;
  secretData: SecretData;
  subs: Subscription[] = [];
  isSuperAdmin: boolean;
  isReader: boolean;
  isWriter: boolean;
  formHasBeenSubmitted: boolean = false;
  modalConfig: ModalConfig = {
    headerText: 'Ha ocurrido un error en la carga de datos',
    content: 'Por favor intente mas tarde',
    buttons: 'single',
  };
  showModalConfirm = false;
  isEditting = true;
  hasPermissionRead = false;
  hasPermissionWrite = false;
  hasPermissionOffline = false;
  manualSetting: boolean = false;
  accountCollectors: CredentialDto[] = null;
  accountTenant: CredentialDto = null;
  accountSelected: CredentialDto = null;
  tenantDontHaveCredentials: boolean = false;
  modalNoTenantCredentialConfig: ModalConfig = {
    headerText: 'Error al cargar cuentas originantes',
    content: 'Verificar las credenciales asociadas a la marca.',
    buttons: 'single',
  };
  currentCredential: MercadoPagoCredentialRequest = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private spinnerService: SpinnerService,
    private authSrv: AuthService,
    private userSrv: UserService,
    private storeSrv: StoreService,
    private validateInputService: InputValidationService,
    private messageService: MessageService,
    public ref: DynamicDialogRef,
    private paymentSrv: PaymentService
  ) {
    this.mercadoPagoForm = this.formBuilder.group({
      publicKey: ['', Validators.required],
      accessToken: ['', Validators.required],
      clientId: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.spinnerService.loadSpinner.next(true);
    this.idStore = Number(sessionStorage.getItem('storeId'));
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    this.isWriter = this.userSrv.isWriter();
    this.getCredential();
    this.checkIsWriter();
    // this.getAccountCollectors();
  }

  checkIsWriter(): void {
    if (!this.isWriter) {
      this.mercadoPagoForm.controls.publicKey.disable();
      this.mercadoPagoForm.controls.accessToken.disable();
      this.mercadoPagoForm.controls.clientId.disable();
    }
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => {
      s.unsubscribe();
    });
  }

  getCredential() {
    this.subs.push(
      this.paymentSrv.getStoreMPSecrets(this.idStore).subscribe({
          next: res => {
            this.getAccountCollectors();
            this.currentCredential = res;
            this.mercadoPagoForm.patchValue({
              publicKey: res.publicKey,
              accessToken: res.accessToken,
              clientId: res.clientId
            });
          },
          error: err => {
            if (err.status === 404) {
              this.mercadoPagoForm.reset();
            }
            this.spinnerService.loadSpinner.next(false);
          }
        }
      )
    );
  }

  getAccountCollectors() {
    this.accountSelected = null;
    this.subs.push(
      this.paymentSrv.getPaymentProvidersCollectors(this.idStore, 'mercadoPago').subscribe({
        next: res => {
          this.accountCollectors = res;
          this.tenantDontHaveCredentials = this.accountCollectors.length === 0;
          this.accountTenant = this.accountCollectors.find(a => a.mainAccount);
          this.accountCollectors.forEach((account: CredentialDto, index: number) => {
            if (account.mainAccount) {
              this.accountCollectors.splice(index, 1);
            }
          });
          if (this.currentCredential && this.currentCredential.publicKey === this.accountCollectors[0].mpPublicApiKey) {
            this.checkAccount(true, this.accountCollectors[0]);
            this.valueCheck(this.accountCollectors[0]);
          }
          this.spinnerService.loadSpinner.next(false);
        },
        error: () => {
          this.spinnerService.loadSpinner.next(false);
        }
      })
    );
  }

  valueCheck(account) {
    return this.accountSelected && account === this.accountSelected;
  }

  checkAccount(checked, accountCol) {
    if (checked) {
      this.accountSelected = accountCol;
      this.mercadoPagoForm.patchValue({
        publicKey: this.accountSelected.mpPublicApiKey,
        accessToken: this.accountSelected.mpAccessToken,
        clientId: this.accountSelected.mpUserId
      });
    } else {
      this.accountSelected = null;
      this.mercadoPagoForm.reset();
    }
  }

  onModalResponse(e: boolean): void {
    this.showModalConfirm = false;
  }

  showMessageOk(): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Credenciales guardadas con éxito.',
    });
  }

  cancel() {
    this.ref.close(false);
  }

  submitChange(): void {
    this.formHasBeenSubmitted = true;
    this.spinnerService.loadSpinner.next(true);
    const mercadoPagoCredentialRequest: MercadoPagoCredentialRequest = {
      accessToken: this.mercadoPagoForm.value.accessToken,
      publicKey: this.mercadoPagoForm.value.publicKey,
      clientId: this.mercadoPagoForm.value.clientId
    };
    this.subs.push(
      this.paymentSrv.setStoreMPSecrets(this.idStore, mercadoPagoCredentialRequest).subscribe({
        next: v => {
          this.spinnerService.loadSpinner.next(false);
          this.showMessageOk();
          this.ref.close(true);
        },
        error: err => {
          if (err.status === 400) {
            this.modalConfig = {
              headerText: 'Error',
              buttons: 'single',
              content:
                'La configuración del comercio no es válida. Por favor configurar el comercio y reintentar.',
            };
          } else if (err.status === 409) {
            this.modalConfig = {
              headerText: 'Error',
              buttons: 'single',
              content:
                'En este momento no se puede enviar el email de autorización, intente más tarde.',
            };
          }
          this.showModalConfirm = true;
          this.spinnerService.loadSpinner.next(false);
        }
      })
    );
  }

}
