import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CategoryProduct, NewCategory } from '../core/models/CategoryProduct';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  constructor(private httpC: HttpClient, private authService: AuthService) {}

  public getCategories(idStore): Observable<CategoryProduct[]> {
    return this.httpC.get<CategoryProduct[]>(
      environment.apiAGUrl + `/categories-product/${idStore}`
    );
  }

  public createCategory(form: NewCategory): Observable<NewCategory> {
    const { id } = this.authService.getLocalUserData();
    return this.httpC.post<NewCategory>(
      environment.apiAGUrl + `/categories-product/${id}`,
      form
    );
  }

  public deleteCategory(idStore: number, idCategory: number): Observable<void> {
    return this.httpC.delete<void>(
      environment.apiAGUrl + `/categories-product/${idStore}/${idCategory}`
    );
  }

  public editCategory(idCategory: number, form: NewCategory): Observable<void> {
    return this.httpC.put<void>(
      environment.apiAGUrl + `/categories-product/${idCategory}`,
      form
    );
  }
}
