import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-total-stats-short',
  templateUrl: './card-total-stats-short.component.html',
  styleUrls: ['./card-total-stats-short.component.scss']
})
export class CardTotalStatsShortComponent implements OnInit {

  @Input() icon: string;
  @Input() value: number;
  @Input() label: string;
  @Input() colorClass?: string;
  @Input() prefixValue: string;

  constructor() { }

  ngOnInit(): void {
  }

}
