import {Component, Input, OnInit} from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
import {EventStatus} from 'src/app/shared/enums/EventStatus';
import {environment} from 'src/environments/environment';
import {EventDescriptionModalComponent} from '../event-description-modal/event-description-modal.component';
import {CategoryEventResponse} from 'src/app/core/models/CategoryEventResponse ';
import {EventComplete} from 'src/app/core/models/EventComplete';

@Component({
  selector: 'app-ticket-event',
  templateUrl: './ticket-event.component.html',
  styleUrls: ['./ticket-event.component.scss'],
  providers: [DialogService]
})
export class TicketEventComponent implements OnInit {

  @Input() eventData: EventComplete;
  @Input() eventCategory: CategoryEventResponse;
  statuses = EventStatus;

  constructor(private dialogSrv: DialogService
  ) {

  }

  ngOnInit(): void {
  }

  getLabelStatus(eventData: EventComplete) {

    const now = new Date();
    const dateInit = new Date(eventData.dateInit);
    const dateEnd = new Date(eventData.dateEnd);
    const status: string = eventData.status;

    if (status === EventStatus.CANCELED) {
      return `<div class="bid-badge bid-badge--big bid-badge--bg-red">CANCELADO</div>`;
    }
    if (!eventData.verified) {
      return `<div class="bid-badge bid-badge--big bid-badge--bg-orange">SIN VERIFICAR</div>`;
    } else {
      if (now < dateInit) {
        return `<div class="bid-badge bid-badge--big bid-badge--bg-white">PENDIENTE</div>`;
      } else if (dateInit < now && now < dateEnd) {
        return `<div class="bid-badge bid-badge--big bid-badge--bg-green">EN CURSO</div>`;
      } else if (now > dateEnd) {
        if (status === EventStatus.LIQUIDATED) {
          return `<div class="bid-badge bid-badge--big bid-badge--bg-green">LIQUIDADO</div>`;
        } else {
          return `<div class="bid-badge bid-badge--big bid-badge--bg-grey">FINALIZADO</div>`;
        }
      }
    }
  }

  openDescription() {
    const ref = this.dialogSrv.open(EventDescriptionModalComponent, {
      header: '',
      width: '526px',
      styleClass: 'event-description-modal',
      data: {
        event: this.eventData,
        eventCategory: this.eventCategory
      },
    });

    ref.onClose.subscribe((res) => {
      if (res) {
      }
    });
  }

  shortAddress(address) {
    return address.split(',', 2);
  }

  getUrl(url) {
    return `${environment.mediaUrl}${url}`;
  }

}
