<div class="modal__body">
  <div *ngIf="accountTenant">
    <div class="account_collector-option">
      <label [for]="accountTenant.id" class="display-flex">
        <p
          class="text-base font-weight-600 font-poppins lh-24 text-gray-2 align-self-center my-0">
          {{accountTenant?.accountCollectorName}}
        </p>
        <p class="text-sm font-weight-400 lh-20 text-gray-35 align-self-center my-0 ml-3">
          #{{accountTenant?.mobbexTaxId}}
        </p>
      </label>
    </div>
    <p class="text-xs font-weight-400 lh-20 text-gray-4 mb-2">
      Cuenta utilizada para generar todas las operaciones.
    </p>
  </div>

  <div class="display-flex w-full justify-content-between margin-top-24">
    <div class="display-flex">
      <i class="fas fa-door-open mr-2 align-self-center"></i>
      <span class="text-base lh-24 font-poppins font-weight-600 text-gray-2 align-self-center">
          Splitear operaciones
        </span>
    </div>
    <div>
      <p-inputSwitch (onChange)="toggleSwitchSplitTransactions($event)"
                     [disabled]="!isWriter"
                     [(ngModel)]="splitTransactions">
      </p-inputSwitch>
    </div>
  </div>
  <div *ngIf="splitTransactions" class="margin-top-8">
    <div *ngIf="splitTransactions && accountCollectors?.length > 0">
      <div *ngFor="let accountCol of accountCollectors" class="account_collector-option"
           [ngClass]="accountCol?.mobbexTaxId === accountSelected?.mobbexTaxId ? 'active' : ''">
        <p-checkbox binary="true"
                    class="field-checkbox-rounded cursor-pointer"
                    [ngModel]="valueCheck(accountCol)" (onChange)="checkAccount($event, accountCol)"></p-checkbox>
        <label [for]="accountCol.id" class="display-flex">
          <p
            class="text-base font-weight-600 font-poppins lh-24 text-gray-2 align-self-center my-0 ml-3">
            {{accountCol?.accountCollectorName}}
          </p>
          <p class="text-sm font-weight-400 lh-20 text-gray-35 align-self-center my-0 ml-3">
            #{{accountCol?.mobbexTaxId}}
          </p>
          <p class="text-sm font-weight-400 lh-20 text-gray-35 align-self-center my-0 ml-3">
            UID: {{accountCol?.mobbexEntityUid}}
          </p>
        </label>

      </div>
    </div>
    <form [formGroup]="mobbexForm" autocomplete="off" class="w-full margin-top-16">
      <div class="field__item field--modal" [ngClass]="
          validateInputClass(mobbexForm, 'cuit', formHasBeenSubmitted)
        ">
        <div class="p-float-label">
          <input formControlName="cuit" id="cuit" [readonly]="accountSelected !== null" pInputText
                 (keydown.space)="$event.preventDefault()"/>
          <label for="cuit" class="title-float">Cuit* </label>
          <app-show-errors [page]="'mobbex'" [ctrl]="mobbexForm.get('cuit') | formControl"
                           [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
        </div>
      </div>
      <div class="field__item field--modal" [ngClass]="
          validateInputClass(mobbexForm, 'cuit', formHasBeenSubmitted)
        ">
        <div class="p-float-label">
          <input formControlName="entityUid" id="entityUid" [readonly]="accountSelected !== null" pInputText
                 (keydown.space)="$event.preventDefault()"/>
          <label for="cuit" class="title-float">Entity UID* </label>
          <app-show-errors [page]="'mobbex'" [ctrl]="mobbexForm.get('entityUid') | formControl"
                           [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
        </div>
      </div>      
    </form>
    <p class="text-sm font-weight-400 lh-20 text-gray-35 mt-3 mb-2">
      Al habilitar como cuenta recaudadora externa, el operador utilizará una cuenta de Black iD para poder operar.
    </p>
  </div>

</div>

<div class="footer">
  <div class="col p-0 mr-2">
    <button pButton class="btn btn__secondary btn__secondary--bold w-100" (click)="cancel()"
            label="Cancelar"></button>
  </div>
  <div class="col p-0 ml-2">
    <button pButton (click)="submitChange()"
            [disabled]="(splitTransactions && !mobbexForm.controls.cuit.value) || !isWriter"
            class="btn btn__primary btn__primary--bold w-100" label="Guardar"></button>
  </div>
</div>

<app-modal [config]="modalConfig" [widthModal]="'552px'" (modalResponse)="onModalResponse($event)"
           [showModal]="showModalConfirm">
</app-modal>

<app-modal [config]="modalNoTenantCredentialConfig" [widthModal]="'552px'"
           (modalResponse)="onModalNoTenantCredentialResponse($event)"
           [showModal]="tenantDontHaveCredentials">
</app-modal>
