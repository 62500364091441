<div class="global-container w-100">
  <form [formGroup]="changeEmailForm" autocomplete="off">
    <div class="field__item field--modal disabled pt-3" [ngClass]="
        validateInputClass(changeEmailForm, 'name', formHasBeenSubmitted)
      ">
      <div class="p-float-label">
        <input formControlName="name" id="name" type="text" pInputText class="transparent" disabled/>
        <label for="name" class="title-float">Nombre del {{isStore ? 'comercio' : 'usuario'}}</label>
        <app-show-errors [page]="'admin'" [ctrl]="changeEmailForm.get('name')"
                         [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
      </div>
    </div>
    <div class="field__item field--modal disabled" [ngClass]="
        validateInputClass(changeEmailForm, 'email', formHasBeenSubmitted)
      ">
      <div class="p-float-label">
        <input formControlName="email" id="email" type="email" pInputText class="transparent" disabled/>
        <label for="email" class="title-float">Email actual</label>
        <app-show-errors [page]="'admin'" [ctrl]="changeEmailForm.get('email')"
                         [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
      </div>
    </div>
    <div class="field__item field--modal" [ngClass]="
        validateInputClass(changeEmailForm, 'newEmail', formHasBeenSubmitted)
      ">
      <div class="p-float-label">
        <input formControlName="newEmail" id="newEmail" type="email" pInputText class="transparent"/>
        <label for="newEmail" class="title-float">Nuevo email*</label>
        <app-show-errors [page]="'admin'" [ctrl]="changeEmailForm.get('newEmail')"
                         [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
      </div>
    </div>

    <div *ngIf="emailError" class="field__item field__item--error">
      {{ emailErrorText }}
    </div>
  </form>

  <div class="footer">
    <div class="col margin-right-8">
      <button pButton (click)="onClose()" class="btn btn__secondary btn__secondary--bold"
              label="Cancelar"></button>
    </div>
    <div class="col margin-left-8">
      <button pButton (click)="submitChange()" [disabled]="!changeEmailForm.valid || submitting || !isWriter"
              class="btn btn__primary btn__primary--bold" label="Guardar cambios"></button>
    </div>
  </div>
</div>
