import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, LazyLoadEvent, MenuItem, MessageService} from 'primeng/api';
import {Customer} from 'src/app/core/models/Customer';
import {CustomerService} from 'src/app/services/customer.service';
import {DialogService} from 'primeng/dynamicdialog';
import {Router} from '@angular/router';
import {SpinnerService} from 'src/app/services/spinner.service';
import {Subscription} from 'rxjs';
import {Table} from 'primeng/table';
import {UserService} from 'src/app/services/user.service';
import {PaginationService} from 'src/app/services/pagination.service';
import {Pageable} from "../../../core/models/Pageable";
import {AuthService} from "../../../services/auth.service";
import {RequestErrorTriggerService} from "../../../services/request-error-trigger.service";
import {environment} from "../../../../environments/environment";
import {ChangeEmailComponent} from "../stores/components/change-email/change-email.component";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [MessageService, DialogService],
})
export class UsersComponent implements OnInit, OnDestroy {
  loading = true;
  @ViewChild('dt') table: Table;
  customers: Customer[];
  selectedCustomer: Customer;
  subs: Subscription[] = [];
  isSuperAdmin: boolean;
  isReader: boolean;
  isWriter: boolean;
  refresh: boolean;
  eventTable = null;
  totalRecords: number;
  pageSize: number = 50;
  items: MenuItem[];
  currentCustomer: Customer = null;
  searchTerm = null;
  mediaUrl = null;

  constructor(
    private customerService: CustomerService,
    private confirmationService: ConfirmationService,
    private spinnerSrv: SpinnerService,
    private router: Router,
    private authSrv: AuthService,
    private userSrv: UserService,
    private paginationSrv: PaginationService,
    private messageService: MessageService,
    private errorSrv: RequestErrorTriggerService,
    private dialogSrv: DialogService
  ) {
  }

  ngOnInit(): void {
    this.mediaUrl = environment.mediaUrl;
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    this.isReader = this.userSrv.isReader();
    this.isWriter = this.userSrv.isWriter();
  }

  generateMenu() {
    this.items = [
      {
        tabindex: "1",
        label: 'Editar email',
        icon: 'fa-light fa-envelope fa-xl mr-2 font-size-14',
        disabled: this.currentCustomer?.deleted,
        styleClass: 'font-poppins',
        command: () => {
          this.changeEmail();
        },
      },
      {
        tabindex: "2",
        label: 'Blanquear DNI',
        icon: 'fa-light fa-id-card fa-xl mr-2 font-size-14',
        disabled: !this.currentCustomer?.document,
        styleClass: 'font-poppins',
        command: () => {
          this.confirmClearDocument(this.currentCustomer);
        },
      },
      {
        tabindex: "3",
        label: 'Reenviar verificación de cuenta',
        icon: 'fal fa-envelope-circle-check fa-xl mr-2',
        disabled: this.currentCustomer?.emailVerified || this.currentCustomer?.deleted || this.currentCustomer?.blocked,
        command: () => {
          this.resendAccountVerification(this.currentCustomer?.id_customer);
        }
      },
      {
        tabindex: "4",
        label: 'Enviar recuperación de contraseña',
        icon: 'fa-light fa-key fa-xl mr-2',
        disabled: this.currentCustomer?.deleted || this.currentCustomer?.blocked,
        command: () => {
          this.recoverPassword(this.currentCustomer?.id_customer);
        }
      },
      {
        separator: true
      },
      {
        tabindex: "5",
        label: this.currentCustomer?.blocked ? 'Desbloquear usuario' : 'Bloquear usuario',
        icon: this.currentCustomer?.blocked ? 'fa-light fa-user fa-xl mr-2' : 'fa-light fa-user-slash fa-xl mr-2',
        disabled: this.currentCustomer?.deleted,
        styleClass: null,
        command: () => {
          this.confirmBlock(this.currentCustomer?.blocked, this.currentCustomer)
        }
      },
      {
        separator: true
      },
      {
        tabindex: "6",
        label: 'Eliminar usuario',
        icon: 'fa-light fa-trash fa-xl mr-2',
        styleClass: 'delete-item',
        disabled: this.currentCustomer?.deleted,
        command: () => {
          this.confirmDeleteAccount(this.currentCustomer);
        }
      }
    ];
  }

  setCurrentCustomer(event) {
    this.currentCustomer = event;
    this.generateMenu();
  }

  cleanCurrentCustomer() {
    this.currentCustomer = null;
  }

  alertNotAuthorized(): void {
    this.confirmationService.confirm({
      message:
        'Lo sentimos, no dispones de los permisos para ver esta información.',
      icon: 'fa-light fa-warning text-yellow text-12xl',
      rejectVisible: false,
      key: 'notAuthorized',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: async () => {
        await this.back();
      },
    });
  }

  async back() {
    await this.router.navigate(['home']);
  }

  loadCustomers(e: LazyLoadEvent): void {
    if (this.isReader === true || this.isSuperAdmin) {
      this.spinnerSrv.loadSpinner.next(true);
      this.eventTable = e;
      const pageNumber = e.first / e.rows;
      const multiSortFields = this.paginationSrv.getMultiSortObject(e);
      this.pageSize = e.rows;
      this.searchTerm = e.globalFilter;
      this.subs.push(
        this.customerService.getUsersInfo(pageNumber, this.pageSize, multiSortFields, e.globalFilter).subscribe({
          next: (data: Pageable) => {
            this.customers = data.content;
            this.totalRecords = data.totalElements;
            this.loading = false;
            this.spinnerSrv.loadSpinner.next(false);
          },
          error: () => {
            this.loading = false;
            this.spinnerSrv.loadSpinner.next(false);
          }
        })
      );
    } else {
      this.alertNotAuthorized();
    }
  }

  onRefresh(): void {
    this.loadCustomers(this.eventTable);
    this.refresh = true;
    setTimeout(() => {
      this.refresh = false;
    }, 2000);
  }

  blockUser(block: boolean, id: number): void {
    this.spinnerSrv.loadSpinner.next(true);
    this.subs.push(
      this.customerService.blockUser(id, block).subscribe({
        next: () => {
          this.loadCustomers(this.eventTable);
          this.messageService.add({
            sticky: false,
            closable: false,
            severity: 'success',
            summary: !block ? 'Cuenta bloqueada con éxito' : 'Cuenta desbloqueada con éxito',
            detail: '',
            life: 5000
          })
        },
        error: () => {
          this.spinnerSrv.loadSpinner.next(false);
          this.messageService.add({
            sticky: false,
            closable: false,
            severity: 'error',
            summary: !block ? 'Error al bloquear la cuenta, inténtalo nuevamente' : 'Error al desbloquear la cuenta, inténtalo nuevamente',
            detail: '',
            life: 5000
          })
        }
      })
    );
  }

  resendAccountVerification(idCustomer: number) {
    this.spinnerSrv.loadSpinner.next(true);
    this.subs.push(
      this.customerService.sendEmailToVerifiedAccount(idCustomer).subscribe({
        next: () => {
          this.loadCustomers(this.eventTable);
          this.messageService.add({
            sticky: false,
            closable: false,
            severity: 'success',
            summary: 'Email de verificación de cuenta enviado con éxito',
            detail: '',
            life: 5000
          })
          this.spinnerSrv.loadSpinner.next(false);
        },
        error: () => {
          this.spinnerSrv.loadSpinner.next(false);
          this.messageService.add({
            sticky: false,
            closable: false,
            severity: 'error',
            summary: 'Error al enviar email de verificación, inténtalo nuevamente',
            detail: '',
            life: 5000
          })
        }
      })
    );
  }

  cleanDni(id: number): void {
    this.subs.push(
      this.customerService.cleanDocument(id).subscribe({
        next: () => {
          this.loadCustomers(this.eventTable);
        },
        error: (err) => {
          if (err.status === 404) {
            this.handleErrorMessage('El usuario no existe');
          } else if (err.status === 409) {
            this.handleErrorMessage('Error al tratar de borrar documento de los invitados asociados al usuario');
          }
        }
      })
    );
  }


  private handleErrorMessage(message): void {
    this.errorSrv.updateShowError({
      showError: true,
      message,
    });
  }

  recoverPassword(id: number) {
    this.spinnerSrv.loadSpinner.next(true);
    this.subs.push(
      this.customerService.sendEmailToRecoverPassword(id).subscribe({
        next: () => {
          this.loadCustomers(this.eventTable);
          this.messageService.add({
            sticky: false,
            closable: false,
            severity: 'success',
            summary: 'Email de recuperación de contraseña enviado con éxito',
            detail: '',
            life: 5000
          })
          this.spinnerSrv.loadSpinner.next(false);
        },
        error: () => {
          this.messageService.add({
            sticky: false,
            closable: false,
            severity: 'error',
            summary: 'Error al enviar email de recuperación, inténtalo nuevamente',
            detail: '',
            life: 5000
          })
        }
      })
    );
  }

  deleteAccount(idCustomer: number) {
    this.spinnerSrv.loadSpinner.next(true);
    this.subs.push(
      this.customerService.deleteCustomerAccount(idCustomer).subscribe({
        next: () => {
          this.loadCustomers(this.eventTable);
          this.messageService.add({
            sticky: false,
            closable: false,
            severity: 'success',
            summary: 'Cuenta eliminada con éxito',
            detail: '',
            life: 5000
          })

          this.spinnerSrv.loadSpinner.next(false);
        },
        error: () => {
          this.messageService.add({
            sticky: false,
            closable: false,
            severity: 'error',
            summary: 'Error al eliminar la cuenta, inténtalo nuevamente',
            detail: '',
            life: 5000
          })
        }
      })
    );
  }

  closeAllSessions() {
    this.customerService.closeAllSession().subscribe({
      next: () => {
      }
    });
  }

  getUrl(logoBase64: string):
    string {
    return 'data:image/jpg;base64,' + logoBase64;
  }

  confirmBlock(blocked: boolean, customer: Customer): void {
    const action = blocked ? 'desbloquear' : 'bloquear';
    this.confirmationService.confirm({
      message:
        '<span class="topheader no-subheader">¿Deseas ' +
        action +
        ' a <span class="subtext text-primary">' +
        customer?.name +
        ' ' +
        customer?.lastName +
        '</span>?</span>',
      header: '',
      icon: 'fa-light fa-warning text-yellow text-12xl',
      key: 'confirmBlock',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        this.blockUser(blocked, customer.id_customer);
      },
      reject: () => {
        this.loadCustomers(this.eventTable);
      },
    });
  }

  confirmClearDocument(customer: Customer): void {
    this.confirmationService.confirm({
      message:
        '<span class="topheader no-subheader">¿Deseas blanquearle el DNI a <span class="subtext text-primary">' +
        customer.name +
        ' ' +
        customer.lastName +
        '</span>?</span>',
      header: '',
      icon: 'fa-light fa-warning text-yellow text-12xl',
      key: 'confirmBlock',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        this.cleanDni(customer.id_customer);
      },
    });
  }

  confirmDeleteAccount(customer: Customer): void {
    this.confirmationService.confirm({
      message:
        '<span class="topheader no-subheader font-weight-600">¿Deseas eliminar a ' +
        customer.name +
        ' ' +
        customer.lastName +
        '?</span> \n' +
        '<span class="font-size-14 text-gray-35 font-weight-400">' +
        'Esta acción no se puede deshacer.</span>',
      header: '',
      icon: 'fa-light fa-warning text-yellow text-12xl',
      key: 'confirmDelete',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        this.deleteAccount(customer.id_customer);
      },
      reject: () => {
        this.loadCustomers(this.eventTable);
      },
    });
  }

  changeEmail(): void {
    const ref = this.dialogSrv.open(ChangeEmailComponent, {
      data: {
        id: this.currentCustomer.id_customer,
        name: this.currentCustomer.name,
        email: this.currentCustomer.email,
        isStore: false,
      },
      header: 'Editar email',
      width: '552px !important',
      closable: true,
      styleClass: 'config-dialog__edit-email',
    });
    this.subs.push(
      ref.onClose.subscribe({
        next: (isResultOK: boolean) => {
          if (isResultOK) {
            this.loadCustomers(this.eventTable);
            this.messageService.add({
              severity: 'success',
              summary: 'Éxito',
              detail: 'El cambio de email se realizó correctamente.',
              life: 5000,
              sticky: false
            });
          }
        }
      })
    );
  }

  getImageUrl(img: string) {
    return `${this.mediaUrl}${img}`;
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }
}
