<div class="modal__body">
    <form [formGroup]="stripeForm" autocomplete="off" class="w-full margin-top-16">
        <div class="field__item field--modal" [ngClass]="
            validateInputClass(stripeForm, 'publicKey', formHasBeenSubmitted)
        ">
            <div class="p-float-label">
                <input formControlName="publicKey" id="publicKey" pInputText/>
                <label for="cuit" class="title-float">Public Key* </label>
                <app-show-errors [page]="'paymentsCredentials'" [ctrl]="stripeForm.get('publicKey') | formControl" [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
            </div>
        </div>
        <div class="field__item field--modal" [ngClass]="
            validateInputClass(stripeForm, 'secretKey', formHasBeenSubmitted)
        ">
            <div class="p-float-label">
                <input formControlName="secretKey" id="secretKey" pInputText/>
                <label for="cuit" class="title-float">Secret Key* </label>
                <app-show-errors [page]="'paymentsCredentials'" [ctrl]="stripeForm.get('secretKey') | formControl" [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
            </div>
        </div>
    </form>
</div>

<div class="footer">
    <div class="col p-0 mr-2">
      <button pButton class="btn btn__secondary btn__secondary--bold w-100" (click)="cancel()"
              label="Cancelar"></button>
    </div>
    <div class="col p-0 ml-2">
      <button pButton (click)="submitChange()" [disabled]="!stripeForm.valid || !isWriter"
              class="btn btn__primary btn__primary--bold w-100" label="Guardar"></button>
    </div>
  </div>

<app-modal [config]="modalConfig" [widthModal]="'450px'" (modalResponse)="onModalResponse($event)" [showModal]="showModalConfirm">
</app-modal>