import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-total-stats',
  templateUrl: './card-total-stats.component.html',
  styleUrls: ['./card-total-stats.component.scss']
})
export class CardTotalStatsComponent implements OnInit {

  @Input() description: string;
  @Input() value: number;
  @Input() valueClass: string = 'text-gray-1';
  @Input() icon: string;
  @Input() prefixValue: string;

  constructor() { }

  ngOnInit(): void {
  }

}
