<div class="new-category" *ngIf="showNewCategory">
  <div class="header">
    <form [formGroup]="categoryForm" class="form" (ngSubmit)="submitCategory()">
      <div class="field__item" [ngClass]="
          validateInputClass(categoryForm, 'name', categoryFormHasBeenSubmitted)
        ">
        <div class="p-float-label">
          <input formControlName="name" id="name" type="text" maxlength="{{ maxCategoryNameLength }}" pInputText />
          <label for="name" class="title-float">Nombre*</label>

          <app-show-errors [page]="'new-category'" [ctrl]="categoryForm.get('name')"
            [formHasBeenSubmitted]="categoryFormHasBeenSubmitted"></app-show-errors>
        </div>

        <div
        *ngIf="formService.shoudShowInputCount(categoryForm, 'name', categoryFormHasBeenSubmitted)"
        class="field__item--info">
          <span
            >{{ nameField.value ? nameField.value.length : 0 }}/{{
              maxCategoryNameLength
            }}</span
          >
        </div>
      </div>

      <div class="field__item">
        <div class="p-float-label">
          <textarea pInputTextarea formControlName="description" id="description" type="text" cols="30"
            rows="4"></textarea>
          <label for="description" class="title-float">Descripción</label>
        </div>
        <div class="field__item--info">
          <span>{{ descriptionField.value ? descriptionField.value.length : 0 }}/{{
            maxCategoryDescriptionLength
            }}</span>
        </div>
      </div>
    </form>
  </div>
  <div class="footer">
    <button pButton class="btn btn__secondary btn__secondary--bold btn__size--sm mr-3" (click)="hideDialog()"
      label="Cancelar"></button>
    <button pButton class="btn btn__primary btn__size--sm" [class.spinner]="loading" (click)="submitCategory()"
      label="Guardar"></button>
  </div>
</div>
