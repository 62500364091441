export interface CroppImgConfig {
  aspectRatio?: number;
  cropperMinWidth?: string;
  cropperMaxWidth?: string;
  cropperMinHeight?: string;
  cropperMaxHeight?: string;
  resizeToWidth?: string;
  resizeToHeight?: string;
  minHeightImgSize?: number;
  minWidthImgSize?: number;
  maxSize?: number;
  maintainAspectRatio?: boolean;
}

export const aspectRadioMap = {
  square: 1,
  tv: 1.33333,
  wide: 1.777777,
  event: 0.75,
};
