<div class="container-main">
  <div class="internal-body">
    <p-toast></p-toast>
    <div class="flex align-items-center justify-content-between">
      <div class="text-base lh-16 text-primary font-semibold flex align-items-center cursor-pointer" (click)="back()">
        <i class="fa-xl fa-regular fa-arrow-left text-primary mr-2"></i> Volver al Inicio
      </div>
      <div class="flex">
        <div class="text-base lh-24 text-primary font-semibold flex align-items-center">
          <div>
            <p-dropdown [options]="tenantList" [(ngModel)]="tenantIdSelected" (onChange)="changeTenant($event)"
                        [placeholder]="tenantIdSelected ? tenantSelected?.name : 'Seleccionar Marca'"
                        optionLabel="name" optionValue="id"
                        class="dropdown tenant-dropdown"></p-dropdown>
          </div>
        </div>
        <div class="text-base lh-24 text-primary font-semibold flex align-items-center margin-left-16">
          <div class="cursor-pointer" (click)="downloadExcel()">
            <i class="fa-light fa-download text-base lh-16 text-primary mr-2"></i>
            <span class="text-xs lh-16 text-primary font-weight-400">Descargar Excel</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex align-items-center justify-content-between mt-48">
      <div class="flex align-items-start flex-column">
        <p class="my-0 text-2xl lh-16 font-poppins font-weight-600 text-gray-1">ESTADÍSTICAS & VENTAS
          <span class="text-base lh-16 font-weight-400 text-gray-3" *ngIf="!from && !to"> (Últimos 30 días)</span>
        </p>
        <p
          class="mt-8p mb-0 text-2xl lh-16 font-poppins font-weight-600 text-primary uppercase">{{getStoreNameSelected()}}</p>
      </div>
      <div class="text-base lh-24 text-primary font-semibold flex align-items-center align-self-start">
        <div class="flex" *ngIf="!loading">
          <div *ngIf="from !== null && to !== null" class="chip-filter cursor-pointer" (click)="removeFilter('date')">
            <span class="text-sm lh-16 font-poppins font-weight-300 text-gray-2">
              {{from | date:'dd.MM.yyyy'}} a {{to | date:'dd.MM.yyyy'}}
            </span>
            <i class="margin-left-8 fa-light fa-xmark text-primary"></i>
          </div>
          <div *ngIf="accountOriginatingSelected || accountCollector1Selected || accountCollector2Selected"
               class="chip-filter cursor-pointer"
               (click)="removeFilter('account')">
            <span class="text-sm lh-16 font-poppins font-weight-300 text-gray-2" *ngIf="accountOriginatingSelected">
              Cuenta: Originante
            </span>
            <span class="text-sm lh-16 font-poppins font-weight-300 text-gray-2" *ngIf="accountCollector1Selected">
              Cuenta: Recaudadora #1
            </span>
            <span class="text-sm lh-16 font-poppins font-weight-300 text-gray-2" *ngIf="accountCollector2Selected">
              Cuenta: Recaudadora #2
            </span>
            <i class="margin-left-8 fa-light fa-xmark text-primary"></i>
          </div>
          <div *ngIf="isSelectedApproved || isSelectedRefund" class="chip-filter cursor-pointer"
               (click)="removeFilter('status')">
            <span class="text-sm lh-16 font-poppins font-weight-300 text-gray-2" *ngIf="isSelectedApproved">
              Aprobada
            </span>
            <span class="text-sm lh-16 font-poppins font-weight-300 text-gray-2" *ngIf="isSelectedRefund">
              Devuelta
            </span>
            <i class="margin-left-8 fa-light fa-xmark text-primary"></i>
          </div>
          <div *ngIf="storeSelected !== null" class="chip-filter cursor-pointer" (click)="removeFilter('store')">
            <span class="text-sm lh-16 font-poppins font-weight-300 text-gray-2">
              {{getStoreNameSelected()}}
            </span>
            <i class="margin-left-8 fa-light fa-xmark text-primary"></i>
          </div>

        </div>
        <div class="cursor-pointer btn__filters margin-left-16" (click)="openFilters()"
             [ngClass]="showMenuFilters ? 'clicked' : ''">
          <i class="fa-light fa-sliders-v lh-16 text-primary mr-2"></i>
          <span class="text-xs lh-16 text-primary font-weight-400">Filtrar</span>
        </div>
      </div>
      <div class="option-table" *ngIf="showMenuFilters">
        <ul class="options-dot">
          <li class="cursor-pointer px-0 py-0">
            <div class="container-dropdown">
              <p-dropdown [options]="stores" [optionValue]="" id="storeList" (onChange)="selectStore($event)"
                          [(ngModel)]="storeSelected" optionLabel="name" optionValue="idStore"
                          [filter]="true" filterBy="name" [showClear]="true">
              </p-dropdown>
            </div>
          </li>
          <li class="px-0 py-0">
            <div class="flex mt-4">
              <p-calendar [(ngModel)]="from" [maxDate]="now" [minDate]="minDate" dateFormat="dd M y"
                          appendTo="body" [placeholder]="'Desde'"
                          [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                          class="w-100 mr-2 cursor-pointer"></p-calendar>
              <p-calendar [(ngModel)]="to" [maxDate]="now" [minDate]="from !== null ? from : now" dateFormat="dd M y"
                          appendTo="body" [disabled]="!from" [placeholder]="'Hasta'"
                          [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" class="w-100 cursor-pointer">
              </p-calendar>
            </div>
          </li>
          <li class="px-0 py-0 mt-4 flex-column align-items-start">
            <span class="text-base lh-24 font-poppins font-weight-600 text-gray-1">Cuenta destino</span>
            <div class="flex mt-2">
              <p-checkbox binary="true" class="field-checkbox-rounded checkbox-filter-sales"
                          [(ngModel)]="accountOriginatingSelected"
                          (onChange)="selectAccount($event, 'ao')"></p-checkbox>
              <span class="font-poppins text-base text-gray-1 font-weight-400 lh-18 ml-2">Originante</span>
              <p-checkbox binary="true" class="field-checkbox-rounded checkbox-filter-sales margin-left-16"
                          [(ngModel)]="accountCollector1Selected"
                          (onChange)="selectAccount($event, 'ac1')"></p-checkbox>
              <span class="font-poppins text-base text-gray-1 font-weight-400 lh-18 ml-2">#1</span>
              <p-checkbox binary="true"
                          class="field-checkbox-rounded checkbox-filter-sales margin-left-16"
                          [(ngModel)]="accountCollector2Selected"
                          (onChange)="selectAccount($event, 'ac2')"></p-checkbox>
              <span class="font-poppins text-base text-gray-1 font-weight-400 lh-18 ml-2">#2</span>
            </div>
          </li>
          <li class="px-0 py-0 mt-4 flex-column align-items-start">
            <span class="text-base lh-24 font-poppins font-weight-600 text-gray-1">Status</span>
            <div class="flex mt-2">
              <p-checkbox binary="true" class="field-checkbox-rounded checkbox-filter-sales"
                          [(ngModel)]="isSelectedApproved"
                          (onChange)="checkApproved($event)"></p-checkbox>
              <span class="font-poppins text-base text-gray-1 font-weight-400 lh-18 ml-2">Aprobada</span>
              <p-checkbox binary="true"
                          class="field-checkbox-rounded checkbox-filter-sales margin-left-16"
                          [(ngModel)]="isSelectedRefund"
                          (onChange)="checkRefund($event)"></p-checkbox>
              <span class="font-poppins text-base text-gray-1 font-weight-400 lh-18 ml-2">Devuelta</span>
            </div>
          </li>
          <div class="margin-top-48 flex w-full justify-content-between">
            <button pButton class="btn btn__terciary btn__size--sm" label="Cancelar"
                    (click)="closeFilters()"></button>
            <button pButton class="btn btn__primary btn__size--sm" label="Aplicar filtro"
                    (click)="applyFilter()"></button>
          </div>
        </ul>
      </div>
    </div>
    <div>
      <div class="margin-top-32 main-cards-container">
        <app-card-total-stats class="p-col card-total-stats margin-right-16"
                              [icon]="'fas fa-money-bill-transfer'"
                              [description]="'Total transaccionado'" [prefixValue]="'$'"
                              [value]="salesInfo?.totalTransactions"
                              [valueClass]="'text-success'"
        ></app-card-total-stats>
        <app-card-total-stats class="p-col card-total-stats" [icon]="'fas fa-money-check-dollar-pen'"
                              [description]="'Total Service Charge'" [prefixValue]="'$'"
                              [value]="salesInfo?.totalServiceCharge"

        ></app-card-total-stats>
      </div>
      <div class="margin-top-16 main-cards-container">
        <app-card-wide class="p-col card-total-stats align-items-center mr-0 w-full"
                       [icon]="'fas fa-badge-dollar'"
                       [description]="'Total Neto ' + tenantSelected?.name" [prefixValue]="'$'"
                       [value]="salesInfo?.netTotal" [class]="'justify-content-center'"
                       [valueClass]="'text-success ml-4'"
        ></app-card-wide>
      </div>
    </div>
    <div>
      <div class="margin-top-40 cards-vertical-container">
        <app-card-vertical class="p-col card-total-stats margin-right-16"
                           [icon]="'fas fa-champagne-glasses'"
                           [description]="'Service Charge Combos / Mesas'" [prefixValue]="'$'"
                           [value]="salesInfo?.serviceChargeTables"
        ></app-card-vertical>
        <app-card-vertical class="p-col card-total-stats margin-right-16"
                           [icon]="'fas fa-ticket'"
                           [description]="'Service Charge Tickets'" [prefixValue]="'$'"
                           [value]="salesInfo?.serviceChargeTickets"
        ></app-card-vertical>
        <app-card-vertical class="p-col card-total-stats margin-right-16"
                           [icon]="'fas fa-sack-dollar'"
                           [description]="'Total vendido'" [prefixValue]="'$'"
                           [value]="salesInfo?.totalSold"
        ></app-card-vertical>
        <app-card-vertical class="p-col card-total-stats mr-0"
                           [icon]="'fas fa-hand-holding-dollar'"
                           [description]="'Reintegro operadores'" [prefixValue]="'$'"
                           [value]="salesInfo?.totalReintegrate"
        ></app-card-vertical>
      </div>
    </div>
  </div>
