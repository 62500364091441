<div class="card-general-stats">
  <div class="card guest">
    <div class="card__header">
      <div class="card__title"><i class="fa-solid fa-users"></i> INVITADOS</div>
      <div class="card__header-right">
        <div class="cursor-pointer" (click)="downloadGuestExcel()">
          <i class="fa-light fa-download margin-right-4"></i>
          <span class="text-xs lh-16 text-primary font-weight-400">Descargar</span>
        </div>
      </div>
    </div>
    <div>
      <div class="card__budget">
        <div class="budget">
          <div class="container">Ingresados <span class="number success">{{totalGuestsIngress}}</span></div>
        </div>
      </div>
      <div class="card__budget">
        <div class="budget">
          <div class="container">Por Ingresar <span class="number info">{{totalGuestsNotIngress}}</span></div>
        </div>
      </div>
      <div class="card__budget">
        <div class="budget">
          <div class="container">Total <span class="number">{{totalGuests}}</span></div>
        </div>
      </div>
    </div>

  </div>

  <div class="card tickets">
    <div class="card__header">
      <div class="card__title">
        <i class="fa-solid fa-ticket"></i>
        <div>TICKETS</div>
      </div>
    </div>
    <div>
      <div class="card__budget">
        <div class="budget">
          <div class="container">
            Vendidos
            <span class="number success">{{ticketsTotalSold | number:'1.0-2'}}</span>
            <i class="fa-solid fa-circle-arrow-right"></i> <span
            class="number success">${{ticketsTotalSoldIncome | number:'1.0-2'}}</span>
          </div>
        </div>
      </div>
      <div class="card__budget">
        <div class="budget">
          <div class="container">Disponibles <span class="number info">{{ticketsAvailable}}</span></div>
        </div>
      </div>
      <div class="card__budget">
        <div class="budget">
          <div class="container">Total <span class="number">{{ticketsTotal}}</span></div>
        </div>
      </div>
    </div>
    <div class="card__types" *ngIf="ticketNames.length > 0">
      <div class="subtitle">TIPOS DE TICKET</div>
      <div class="budge" *ngFor="let name of ticketNames">{{ name }}</div>
    </div>

  </div>

  <div class="card tables">
    <div class="card__header">
      <div class="card__title">
        <i class="fa-solid fa-champagne-glasses"></i>
        <div>COMBOS/MESAS</div>
      </div>
    </div>
    <div>
      <div class="card__budget">
        <div class="budget">
          <div class="container">
            Vendidos
            <span class="number success">{{tablesTotalSold | number:'1.0-2'}}</span>
            <i class="fa-solid fa-circle-arrow-right"></i>
            <span class="number success">${{tablesTotalSoldIncome | number:'1.0-2'}}</span>
          </div>
        </div>
      </div>
      <div class="card__budget">
        <div class="budget">
          <div class="container">Disponibles <span class="number info">{{tablesAvailable}}</span></div>
        </div>
      </div>
      <div class="card__budget">
        <div class="budget">
          <div class="container">Total <span class="number">{{tablesTotal}}</span></div>
        </div>
      </div>
    </div>
    <div class="card__types" *ngIf="tableNames.length > 0">
      <div class="subtitle">TIPOS DE COMBOS/MESAS</div>
      <div class="budge" *ngFor="let name of tableNames">{{ name }}</div>
    </div>

  </div>
</div>
