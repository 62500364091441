import {RouterModule, Routes} from '@angular/router';

import {AdministratorsComponent} from './pages/home/administrators/administrators.component';
import {AuthGuard} from './core/guards/auth.guard';
import {
  BookingStatsComponent
} from './pages/home/stores/pages/store/pages/stats/pages/booking-stats/booking-stats.component';
import {BookingsComponent} from './pages/home/bookings/bookings.component';
import {CategoriesEventsComponent} from './pages/home/categories-events/categories-events.component';
import {EventsComponent} from './pages/home/events/events.component';
import {
  EventsStatsComponent
} from './pages/home/stores/pages/store/pages/stats/pages/events-stats/events-stats.component';
import {GlobalTransactionsComponent} from './pages/home/global-transactions/global-transactions.component';
import {HomeComponent} from './pages/home/home.component';
import {LoginComponent} from './pages/sign-in/login/login.component';
import {ManageMenuComponent} from './pages/home/stores/components/manage-menu/manage-menu.component';
import {NgModule} from '@angular/core';
import {NotFoundComponent} from './core/components/not-found/not-found.component';
import {PaymentsComponent} from './pages/home/stores/pages/store/pages/payments/payments.component';
import {StatsComponent} from './pages/home/stores/pages/store/pages/stats/stats.component';
import {StoreComponent} from './pages/home/stores/pages/store/store.component';
import {StoreDetailComponent} from './pages/home/stores/components/store-detail/store-detail.component';
import {StoresComponent} from './pages/home/stores/stores.component';
import {TransactionsComponent} from './pages/home/transactions/transactions.component';
import {UsersComponent} from './pages/home/users/users.component';
import {SalesComponent} from "./pages/home/sales/sales.component";
import {SuperAdminGuard} from "./core/guards/super-admin.guard";
import { EventConfigurationComponent } from './pages/home/events/pages/event-configuration/event-configuration.component';

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'stores',
    component: StoresComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'store/:storeId',
    component: StoreComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'configurations', pathMatch: 'full'},
      {
        path: 'configurations',
        component: StoreDetailComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'stats',
        component: StatsComponent,
        canActivate: [AuthGuard],
        children: [
          {path: '', redirectTo: 'stats/event', pathMatch: 'full'},
          {
            path: 'event',
            component: EventsStatsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'event/:id',
            component: EventsStatsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'booking',
            component: BookingStatsComponent,
            canActivate: [AuthGuard],

          }
        ]
      },
      {
        path: 'menu',
        component: ManageMenuComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'transactions',
        component: TransactionsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'payments',
        component: PaymentsComponent,
        canActivate: [AuthGuard],
      }
    ]
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'transactions',
    component: GlobalTransactionsComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'main-commerce',
  //   component: MainCommerceComponent,
  //   canActivate: [AuthAdmGuard],
  // },
  {
    path: 'events',
    component: EventsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'event-configuration/:id',
    component: EventConfigurationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bookings',
    component: BookingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'categories',
    component: CategoriesEventsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'administrators',
    component: AdministratorsComponent,
    canActivate: [SuperAdminGuard],
  },
  {
    path: 'sales',
    component: SalesComponent,
    canActivate: [SuperAdminGuard],
  },
  {path: '**', pathMatch: 'full', component: NotFoundComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
