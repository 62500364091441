import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {forkJoin} from 'rxjs';
import {EventForStore} from 'src/app/core/models/EventForStore';
import {EventStatisticsTabs} from 'src/app/core/models/EventStatisticsTabs';
import {EventStatistics} from 'src/app/core/models/Statistics/EventStatistics';
import {EventsService} from 'src/app/services/events.service';
import {StatsService} from 'src/app/services/stats.service';

@Component({
  selector: 'app-events-stats',
  templateUrl: './events-stats.component.html',
  styleUrls: ['./events-stats.component.scss']
})
export class EventsStatsComponent implements OnInit {

  events: Array<EventForStore> = [];
  eventSelected: EventForStore;
  idEventSelected: number = null;
  hoursColumns = [];
  storeId: number;
  stats: EventStatistics = null;
  items;
  activeItem;
  tabSelected: EventStatisticsTabs;
  eventName = '';
  EVENT_STATS_TABS = EventStatisticsTabs;
  loading = false;

  constructor(
    private eventSrv: EventsService,
    private statService: StatsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.loading = true;
    this.activatedRoute.paramMap.subscribe(params => {
      const id = Number(params.get('id'));
      if (id) {
        this.idEventSelected = id;
        this.getLastEventsStats(id);
      } else {
        this.loading = false;
      }
    });
  }

  ngOnInit(): void {
    this.tabSelected = this.EVENT_STATS_TABS.BILLBOARD;
  }

  getLastEventsStats(id: number) {
    forkJoin({
      event: this.eventSrv.getEventById(Number(id)),
      stats: this.statService.getEventsStats(Number(id))
    }).subscribe(res => {
      this.eventName = res.event.eventName;
      this.stats = res.stats;
      this.loading = false;
    });

  }

  show(e: EventStatisticsTabs) {
    this.tabSelected = e;
  }

}
