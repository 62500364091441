import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {ConfigurationService} from 'src/app/services/configuration.service';
import {SpinnerService} from 'src/app/services/spinner.service';
import {Subscription} from 'rxjs';
import {VipUser} from 'src/app/core/models/VipUser';
import {StoreData} from '../../../../../../../../core/models/StoreData';

@Component({
  selector: 'app-vip-list',
  templateUrl: './vip-list.component.html',
  styleUrls: ['./vip-list.component.scss'],
})
export class VipListComponent implements OnInit, OnDestroy {
  @Input() fromAdmin: boolean;
  editId: number;
  isEditting = false;
  comercio: StoreData;
  vipUsers: VipUser[];
  selectedVipUsers: VipUser[];
  vipUser: VipUser;
  vipUserDialog: boolean;
  submitted: boolean;
  headerPopup: string;
  oldDocument: string;
  subs: Subscription[] = [];
  showDeleteDialog = false;
  vipUserSelectedToDelete: VipUser;
  formHasBeenSubmitted = false;
  refresh: boolean = false;

  constructor(
    private configSrv: ConfigurationService,
    private spinnerService: SpinnerService
  ) {
  }

  ngOnInit(): void {
    this.spinnerService.loadSpinner.next(true);
    this.vipUsers = [];
    this.comercio = JSON.parse(localStorage.getItem('storeData'));
    this.loadVipUsers();
  }

  loadVipUsers(): void {
    this.subs.push(
      this.configSrv.getVipUsers().subscribe((data) => {
        this.spinnerService.loadSpinner.next(false);
        this.vipUsers = data;
      })
    );
  }

  onRefresh() {
    this.loadVipUsers();
    this.refresh = true;
    setTimeout(() => {
      this.refresh = false;
    }, 2000);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => {
      s.unsubscribe();
    });
  }
}
