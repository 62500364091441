<p-dialog [(visible)]="showModal" (onHide)="onHide()" [draggable]="false" [resizable]="false" [modal]="true"
          class="bid-modal center">
  <ng-template pTemplate="header" class="bid-modal__header">
    <p class="bid-modal__header--title">Reprogramar Evento</p>
  </ng-template>
  <ng-template pTemplate="content" class="bid-modal__content" #scrollEditEvent>
    <form [formGroup]="rescheduleForm">
      <fieldset class="field__group">
        <div>
          <p class="font-poppins font-semibold text-gray-1 font-base lh-24 margin-vertical-0">Día y hora</p>
          <div class="reschedule-container margin-top-24">
            <div class="field__item field__calendar field--calendar" [ngClass]="
              validateInputClass(
                rescheduleForm,
                'dateInit',
                formHasBeenSubmitted
            )">
              <span class="p-float-label">
                <p-calendar id="dateInit" formControlName="dateInit" dateFormat="dd MM yy," appendTo="body"
                            [minDate]="now"
                            [showTransitionOptions]="'0ms'"
                            [hideTransitionOptions]="'0ms'"
                            [showTime]="true"
                            class="w-100">
                </p-calendar>
                <label for="dateInit" class="title-float">Inicio</label>
                <app-show-errors [page]="'events'" [ctrl]="rescheduleForm.get('dateInit')"
                                 [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
              </span>
            </div>
            <div class="field__item field__calendar field--calendar margin-top-24" [ngClass]="
              validateInputClass(
                rescheduleForm,
                'dateEnd',
                formHasBeenSubmitted
              )
            ">
              <span class="p-float-label">
                <!-- [minDate]="minEnd" -->
                <p-calendar id="dateEnd" formControlName="dateEnd" dateFormat="dd MM yy," appendTo="body"
                            [minDate]="now"
                            [showTransitionOptions]="'0ms'"
                            [hideTransitionOptions]="'0ms'"
                            [showTime]="true"
                            class="w-100 custom-calendar">
                </p-calendar>
                <label for="dateEnd" class="title-float">Fin</label>
                <app-show-errors [page]="'events'" [ctrl]="rescheduleForm.get('dateEnd')"
                                 [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
              </span>
            </div>
          </div>
        </div>
        <span *ngIf="isValidRangeDate" class="text-gray-1 font-size-12">No podrán ser reprogramados eventos que estén LIQUIDADOS o CANCELADOS.</span>
        <span *ngIf="!isValidRangeDate" class="field__item--error wrong-range-date">Rango de fecha invalido</span>

        <div class="margin-top-32 flex">
          <p-checkbox binary="true" formControlName="notify"></p-checkbox>
          <span class="font-size-14 lh-24 font-weight-400 text-gray-2 margin-left-8">
            Notificar por email a los invitados
          </span>
        </div>
      </fieldset>
    </form>
  </ng-template>

  <ng-template pTemplate="footer" class="bid-modal__footer">
    <button
      pButton
      label="Cancelar"
      class="btn btn__secondary"
      (click)="cancel()"
    ></button>
    <button
      pButton
      label="Guardar cambios"
      class="btn btn__primary"
      (click)="save()"
    ></button>
  </ng-template>
</p-dialog>
<p-toast></p-toast>
