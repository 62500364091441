export const environment = {
  production: true,

  apiAGUrl: 'https://services.blacktesting.com.ar/qa',
  billboardAGUrl: 'https://services.blacktesting.com.ar/qa',
  mediaUrl: 'https://media.dev.blacktesting.com.ar',

  landingUrl: 'https://www.qa.blacktesting.com.ar/#/',

  maxTablePrice: 1000000,
  maxAccessPrice: 100000,
  maxConsumerCredit: 1000000,
  maxNavbarContactName: 50,
  maxNavbarContactEmail: 50,
  maxConfigDeliveryCoverageRadius: 500,
  maxOrderDelayTime: 1000,
  maxPriceOfDelivery: 10000,
  maxPriceMinForDelivery: 100000,
  maxValueDiscount: 100,
  maxCommerceNameLength: 50,
  maxCommerceDescriptionLength: 255,
  minPasswordLength: 6,
  maxPasswordLength: 30,
  maxCapacityPerTable: 200,
  maxTableNumber: 10000,
  maxPeopleAdmittedPerHour: 1000,
  maxPeoplePerBooking: 1000,
  maxDescriptionShiftLength: 50,
  maxDniLength: 10,
  minDniLength: 8,
  maxPeopleNameLength: 30,
  maxPeopleLastNameLength: 30,
  maxOrderPrepareEstimatedTime: 180,
  maxReasonCancelationLength: 120,
  maxCreditOrderTable: 20000,
  maxTicketNameLength: 20,
  maxTicketDescriptionLength: 120,
  maxTableReferenceLength: 20,
  maxTableEventNameLength: 20,
  maxCapacityPerTableOnEvent: 500,
  maxNameEventLength: 50,
  maxEventDescriptionLength: 1000,
  maxPhoneLength: 12,
  maxUsernameLength: 30,
  maxNotesOnBookingLength: 120,
  maxCategoryNameLength: 35,
  maxCategoryDescriptionLength: 50,
  maxProductNameLength: 30,
  maxProductDescriptionLength: 180,
  maxProductPrice: 1000000,
  maxAttributeNameLength: 30,
  maxAttributeDescriptionLength: 120,
  maxPriceAtributte: 5000,

  mapUrl: 'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',

  firebase: {
    apiKey: 'AIzaSyAFzi1hWZR3FEjqXTxYIRXS44AdmNFdR9s',
    authDomain: 'blackid-1b732.firebaseapp.com',
    databaseURL: 'https://blackid-1b732.firebaseio.com',
    projectId: 'blackid-1b732',
    storageBucket: 'blackid-1b732.appspot.com',
    messagingSenderId: '356588914077',
    appId: '1:356588914077:web:ef3b53d95f6b5c50525b74',
    measurementId: 'G-8VK1L30FG8'
  }
};
