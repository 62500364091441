<div class="container-main mesas-container">
  <div class="internal-body">
    <p-toast></p-toast>

    <div class="table__container pt-4">
      <p-table #dt [value]="tables" [rows]="10" [paginator]="true" [globalFilterFields]="['tableNumber', 'capacity']"
               [rowHover]="true" dataKey="id" sortField="tableNumber" sortOrder="1" [rowsPerPageOptions]="[10,25,50]"
               currentPageReportTemplate="Mostrar" [showCurrentPageReport]="true" class="table__internals">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-between pb-4">
            <div>
              <span class="title-table">Administración de mesas y barras</span>
            </div>
            <div class="top-table-right">
              <div class="search-container">
                <div class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input
                    pInputText
                    type="text"
                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                    placeholder="Buscar"
                  />
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="tableNumber">
              N° de mesa o barra
              <p-sortIcon field="tableNumber"></p-sortIcon>
            </th>
            <th pSortableColumn="capacity">
              Capacidad
              <p-sortIcon field="capacity"></p-sortIcon>
            </th>
            <th>QR</th>
          </tr>
          <tr>
            <td class="space" colspan="5"></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-table class="td-hover">
          <tr>
            <td>
              <p class="text-sm lh-16 my-0 text-gray-1 font-semibold">{{ table.tableNumber }}</p>
            </td>
            <td>
              <p class="text-sm lh-16 my-0 text-gray-1">{{ table.capacity }}</p>
            </td>
            <td>
              <app-show-qr [qrDataString]="displayQR(table)" [headerTitle]="getHeader(table.tableNumber)"
                           [type]="'Tables'">
              </app-show-qr>
            </td>

          </tr>
          <div class="my-2"></div>
        </ng-template>
      </p-table>
    </div>

    <p-confirmDialog class="custom-dialog small" [style]="{ width: '450px' }" acceptLabel="Aceptar"
                     rejectLabel="Cancelar" acceptButtonStyleClass="btn btn__primary btn__primary--bold"
                     rejectButtonStyleClass="btn btn__secondary btn__secondary--bold">
    </p-confirmDialog>
  </div>
</div>
