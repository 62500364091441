import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { EventStatistics } from 'src/app/core/models/Statistics/EventStatistics';
import { IngressByHour } from 'src/app/core/models/Statistics/IngressByHour';

@Component({
  selector: 'app-access-control-stats',
  templateUrl: './access-control-stats.component.html',
  styleUrls: ['./access-control-stats.component.scss']
})
export class AccessControlStatsComponent implements OnInit, OnChanges {

  @Input() stats: EventStatistics = null;
  dataCategories: Array<string> = [];
  graphicIngressHour: Array<Array<number>> = [[]];
  columnsGuestDonut;
  columnsVsDonut;
  colorsGuestDonut;
  colorsVsDonut;
  isShowOnlyIngressGuestDonut = true;
  isShowOnlyVSDonut = true;
  emptyGuest: boolean = false;
  emptyAccess: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
    if (this.stats) {
      this.generateCategories();
      this.initialDonuts();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {    
    if (this.stats) {
      this.generateCategories();
      this.initialDonuts();
    }
  }


  initialDonuts() {
    if (this.stats.guestsByInvitationType.guestsByTicketManualIngress == 0 && this.stats.guestsByInvitationType.guestsByTableIngress == 0 && this.stats.guestsByInvitationType.guestsByListIngress == 0) {
      this.emptyGuest = true;
      this.columnsGuestDonut = [
        ['Ticket / Manual', 0],
        ['Combo / Mesa', 0],
        ['Lista', 0],
      ];
    } else {
      this.emptyGuest = false;
      this.columnsGuestDonut = [
        ['Ticket / Manual', this.stats.guestsByInvitationType.guestsByTicketManualIngress],
        ['Combo / Mesa', this.stats.guestsByInvitationType.guestsByTableIngress],
        ['Lista', this.stats.guestsByInvitationType.guestsByListIngress],
      ];
    }
    this.colorsGuestDonut = {
      'Ticket / Manual': '#10C9BE',
      'Combo / Mesa': '#FF9900',
      'Lista': '#E13333'
    };

    if (this.stats.accessByPayMode.accessPaidIngress == 0 && this.stats.accessByPayMode.accessNoPaidIngress == 0) {
      this.emptyAccess = true;
      this.columnsVsDonut = [
        ['Pagos', 0],
        ['Sin cargo', 0]
      ]
    } else {
      this.emptyAccess = false;
      this.columnsVsDonut = [
        ['Pagos', this.stats.accessByPayMode.accessPaidIngress],
        ['Sin cargo', this.stats.accessByPayMode.accessNoPaidIngress]
      ];
    }
    this.colorsVsDonut = {
      Pagos: '#10C9BE',
      'Sin cargo': '#FF9900'
    };

  }

  onSwitchTypeGuest() {
    if (this.isShowOnlyIngressGuestDonut) {
      this.columnsGuestDonut = [
        ['Ticket / Manual', this.stats.guestsByInvitationType.guestsByTicketManualIngress],
        ['Combo / Mesa', this.stats.guestsByInvitationType.guestsByTableIngress],
        ['Lista', this.stats.guestsByInvitationType.guestsByListIngress],
      ];
    } else {
      this.columnsGuestDonut = [
        ['Ticket / Manual', this.stats.guestsByInvitationType.guestsByTicketManual],
        ['Combo / Mesa', this.stats.guestsByInvitationType.guestsByTable],
        ['Lista', this.stats.guestsByInvitationType.guestsByList],
      ];
    }
  }

  onSwitchVsGuest() {
    if (this.isShowOnlyVSDonut) {
      this.columnsVsDonut = [
        ['Pagos', this.stats.accessByPayMode.accessPaidIngress],
        ['Sin cargo', this.stats.accessByPayMode.accessNoPaidIngress]
      ];
    } else {
      this.columnsVsDonut = [
        ['Pagos', this.stats.accessByPayMode.accessPaid],
        ['Sin cargo', this.stats.accessByPayMode.accessNoPaid]
      ];
    }
  }

  generateCategories() {
    let categories = [];
    let hoursArray = [];
    this.stats.ingressByHours.forEach((el: IngressByHour) => {
      categories.push(el.time);
      hoursArray.push(el.quantity);
    });
    this.dataCategories = categories;
    this.graphicIngressHour = [hoursArray];
  }

}
