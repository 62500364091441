<div class="container-main">
  <div class="internal-body">
    <p-toast></p-toast>
    <div class="flex align-items-center justify-content-between">
      <div class="text-base lh-24 text-primary font-semibold flex align-items-center cursor-pointer" (click)="back()">
        <i class="fa-xl fa-regular fa-arrow-left text-primary mr-2"></i> Volver a Home
      </div>
      <div class="text-base lh-24 text-primary font-semibold flex align-items-center">
        <div class="margin-right-8">
          <p-dropdown [options]="tenantList" [(ngModel)]="tenantIdSelected" (onChange)="changeTenant($event)"
                      [placeholder]="tenantIdSelected ? tenantSelected?.name : 'Seleccionar Marca'"
                      optionLabel="name" optionValue="id"
                      class="dropdown tenant-dropdown"></p-dropdown>
        </div>
        <div class="mr-16">
          <p-dropdown [options]="monthsOrdered" [(ngModel)]="monthSelected"
                      [placeholder]="monthSelected ? monthSelected : 'Service charge: Mes'"
                      optionLabel="name" optionValue="id"
                      class="dropdown month-dropdown"></p-dropdown>
        </div>
        <div class="flex">
          <div [ngClass]="!monthSelected ? 'button-disabled' : 'cursor-pointer'" (click)="downloadExcel()">
            <i class="fal fa-download text-primary margin-right-8"></i>
            <span class="text-xs lh-16 text-primary font-weight-400">SC</span>
          </div>
          <div class=" margin-left-16" [ngClass]="!monthSelected ? 'button-disabled' : 'cursor-pointer'"
               (click)="downloadExcelTransactions()">
            <i class="fal fa-download text-primary margin-right-8"></i>
            <span class="text-xs lh-16 text-primary font-weight-400">Transacciones</span>
          </div>
        </div>
      </div>
    </div>
    <app-transactions class="w-100" [tenantIdSelected]="tenantIdSelected"
                      [tenantName]="tenantSelected?.name" [resetTable]="resetTable"></app-transactions>
  </div>
</div>
