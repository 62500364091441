import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { GlobalStatistics } from '../core/models/GlobalStatistics';
import { StoreStatistics } from '../core/models/StoreStatistics';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { EventStatistics } from '../core/models/Statistics/EventStatistics';

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  constructor(
    private httpC: HttpClient,
    private authService: AuthService
    ) { }

  public getBookingStats(idStore): Observable<StoreStatistics> {
    return this.httpC.get<StoreStatistics>(environment.apiAGUrl + `/stores/stats/${idStore}`);
  }

  public getGlobalStatistics(): Observable<GlobalStatistics> {
    return this.httpC.get<GlobalStatistics>(environment.apiAGUrl + `/stores/global-stats`);
  }

  public getEventsStats(idEvent?: number): Observable<EventStatistics> {
    return this.httpC.get<EventStatistics>(environment.billboardAGUrl + `/cartelera/statistics/event/${idEvent}`);
  }

  dayDisplay(number) {
    const lastWeek = this.historyDay(number);
    const lastWeekMonth = lastWeek.getMonth() + 1;
    const lastWeekDay = lastWeek.getDate();

    const dateDay = lastWeekMonth + '/' + lastWeekDay;

    return dateDay;
  }

  historyDay(number) {
    const today = new Date();
    const lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - number
    );
    return lastWeek;
  }
}
