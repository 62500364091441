import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-save-fixed',
  templateUrl: './save-fixed.component.html',
  styleUrls: ['./save-fixed.component.scss']
})
export class SaveFixedComponent implements OnInit {
  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onCancel() {
    this.cancel.emit();
  }

  onSave() {
    this.save.emit();
  }

}
