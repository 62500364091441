import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { Table, TableService } from 'primeng/table';
import { Subscription } from 'rxjs';
import { StoreData } from 'src/app/core/models/StoreData';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { RequestErrorTriggerService } from 'src/app/services/request-error-trigger.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mesa',
  templateUrl: './mesa.component.html',
  styleUrls: ['./mesa.component.scss'],
})
export class MesaComponent implements OnInit, OnDestroy {
  @Input() fromAdmin: boolean;
  @Output() tableNumer = new EventEmitter<number>();

  editId: number;
  isEditting = false;
  tableForm: UntypedFormGroup;
  tables: Table[];
  comercio: StoreData;
  tableDialog: boolean;
  qrDialog: boolean;
  currentQr = 'Empty';
  headerPopup: string;
  subs: Subscription[] = [];
  formHasBeenSubmitted = false;
  maxTableNumber: number;
  maxCapacityPerTable: number;

  constructor(
    private configSrv: ConfigurationService,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private spinnerService: SpinnerService
  ) {
    this.maxTableNumber = environment.maxTableNumber;
    this.maxCapacityPerTable = environment.maxCapacityPerTable;
    const { id } = this.authService.getLocalUserData();
    this.tableForm = this.formBuilder.group({
      capacity: [0, Validators.required],
      tableNumber: [0, Validators.required],
    });
  }

  ngOnInit(): void {
    this.spinnerService.loadSpinner.next(true);
    this.tables = [];
    this.comercio = JSON.parse(localStorage.getItem('storeData'));
    this.loadTables();
  }

  loadTables(): void {
    this.subs.push(
      this.configSrv.getTables().subscribe((data) => {
        this.spinnerService.loadSpinner.next(false);
        this.tables = data;
        this.tableNumer.emit(this.tables.length);
      })
    );
  }

  displayQR(table): string {
    if (JSON.parse(localStorage.getItem('storeData'))) {
      const { id } = this.authService.getLocalUserData();
      const { name } = this.authService.getLocalUserData();
      this.currentQr =
        environment.landingUrl +
        'app/download-app/' +
        id +
        '/' +
        table.id +
        '/' +
        encodeURIComponent(name);
      return this.currentQr;
    }
  }

  hideQrDialog(): void {
    this.qrDialog = false;
  }

  printQr(): void {
    const html: any = document.querySelector('.qrcode').firstChild;
    html.toBlob((blob) => {
      const contentBlobUrl = URL.createObjectURL(blob);
      const ifrm = document.createElement('iframe');
      ifrm.setAttribute('src', contentBlobUrl);
      ifrm.style.width = '640px';
      ifrm.style.height = '480px';
      ifrm.style.display = 'none';
      document.body.appendChild(ifrm);
      ifrm.onload = () => {
        ifrm.contentWindow.print();
        this.qrDialog = false;
      };
    });
  }

  hideDialog(): void {
    this.tableDialog = false;
    this.isEditting = false;
    this.formHasBeenSubmitted = false;
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => {
      s.unsubscribe();
    });
  }

  getHeader(tableNumber): string {
    return 'Mesa Nº ' + tableNumber;
  }
}
