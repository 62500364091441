<div class="select-image-container">
  <input [ngClass]="{ hidden: hide }" #myInput type="file" [value]="value" [disabled]="isDisabled" class="choose-file"
    (change)="fileChangeEvent($event)" accept=".jpg,.jpeg,.png,.webp" placeholder="Agregar logo" id="chooseImage" />
  <div class="container-image" *ngIf="!hideImgContainer && showImageFinal">
    <img *ngIf="croppedImage" class="thumbnail" [src]="croppedImage" />
  </div>
  <div>
    <button pButton *ngIf="croppedImage && !hideImgContainer"
      class="btn btn__secondary btn__secondary--red btn__size--sm" (click)="removeThumbnail()"
      icon="fa-light fa-trash-can" label="{{ cancelBtn }}"></button>
  </div>
</div>

<p-dialog [(visible)]="showImageCropper">
  <p-header> Seleccione parte de la imagen </p-header>

  <div class="cropp-container">
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
      [aspectRatio]="config.aspectRatio" [cropperMinWidth]="config.cropperMinWidth"
      [cropperMaxWidth]="config.cropperMaxWidth" [cropperMinHeight]="config.cropperMinHeight"
      [cropperMaxHeight]="config.cropperMaxHeight" [resizeToWidth]="config.resizeToWidth"
      [resizeToHeight]="config.resizeToHeight" height="500" format="jpg" (loadImageFailed)="loadImageFailed()"
      (imageCropped)="imageCropped($event)" class="image-logo"></image-cropper>
  </div>

  <p-footer class="w-100 flex justify-content-end">
    <div>
      <button pButton (click)="selectPartImage()" class="btn btn__primary" label="Guardar"></button>
    </div>
  </p-footer>
</p-dialog>

<p-toast></p-toast>
