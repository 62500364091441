import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { BookingConfig } from 'src/app/core/models/Configurations';
import { Shift } from 'src/app/core/models/Shitf';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-config-bookings',
  templateUrl: './config-bookings.component.html',
  styleUrls: ['./config-bookings.component.scss'],
})
export class ConfigBookingsComponent implements OnInit, OnDestroy {
  @Output() validFormState = new EventEmitter();
  @Input() bookingConfigData: BookingConfig = null;
  @Input() configurationId: number;
  @Input() storeMaxCapacity: number;
  @Input() fromAdmin: boolean;
  @Input() canWrite: boolean;

  reservasForm: UntypedFormGroup;
  thereAreChanges = false;
  originalData: BookingConfig;
  switch: boolean;
  isMaxCapacityValid = false;
  shiftDialog: boolean;
  shifts: Shift[] = [];
  shiftForm: UntypedFormGroup;
  shiftEditMode: boolean;
  shiftEditingId: number;
  msgs = [];
  subs: Subscription[] = [];
  headerPopup: string;
  formHasBeenSubmitted = false;
  shiftSameHourError: string = '';
  invalidHourError: string = '';
  maxPeopleAdmittedPerHour: number;
  maxPeoplePerBooking: number;
  maxDescriptionShiftLength: number;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private confSrv: ConfigurationService
  ) {
    this.maxPeopleAdmittedPerHour = environment.maxPeopleAdmittedPerHour;
    this.maxPeoplePerBooking = environment.maxPeoplePerBooking;
    this.maxDescriptionShiftLength = environment.maxDescriptionShiftLength;
    this.reservasForm = this.formBuilder.group({
      bookingAvailable: [false],
      peopleAdmittedPerHour: [
        { value: 0 },
        [Validators.required, Validators.min(1)],
      ],
      maxPerTable: [{ value: 0 }, [Validators.required, Validators.min(1)]],
      autoConfirm: [false],
    });

    this.shiftForm = this.formBuilder.group({
      initTime: ['', Validators.required],
      endTime: ['', Validators.required],
      available: [true],
      moreThanOneDay: [false],
      description: [''],
    });
  }

  ngOnInit(): void {
    if (this.bookingConfigData) {
      this.loadData();
      this.originalData = this.bookingConfigData;
      this.loadShifts();
    }
  }

  loadData(): void {
    this.reservasForm.patchValue({
      bookingAvailable: this.bookingConfigData.bookingAvailable,
      peopleAdmittedPerHour: this.bookingConfigData.peopleAdmittedPerHour,
      maxPerTable: this.bookingConfigData.maxPerTable,
      autoConfirm: this.bookingConfigData.autoConfirm,
    });
  }

  loadShifts(event?): void {
    this.formHasBeenSubmitted = false;
    this.subs.push(
      this.confSrv.getShifts().subscribe((res) => {
        this.shifts = res;
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => {
      s.unsubscribe();
    });
  }
}
