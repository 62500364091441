<form class="generic-container" autocomplete="off" [formGroup]="genericConfigForm"
      [ngClass]="{ form__submitted: formHasBeenSubmitted }">

  <div class="header">
    <p class="text-xl font-semibold text-gray-1 lh-16 my-0 label">GENERALES</p>
    <div class="flex justify-content-end switch">
      <div class="switch-wrapper" *ngIf="accountVerified">
        <div class="flex align-items-center">
          <div class="tooltip-cursor"
               pTooltip="Esto permite que el comercio aparezca en el listado dentro de la App. Aconsejamos completar toda la información antes de habilitarlo!"
               tooltipPosition="top" *ngIf="accountVerified">
            <i class="fa-light fa-circle-info"></i>
          </div>
          <div class="tooltip-cursor"
               pTooltip="El comercio no se encuentra verificado. Cuando la verificación finalice podrá gestionar la habilitación a la App."
               tooltipPosition="top" *ngIf="!accountVerified">
            <i class="fa-light fa-circle-info"></i>
          </div>
          <label class="label-switch">Habilitar</label>
        </div>
        <div class="switch-container">
          <p-inputSwitch name="availableStore" formControlName="availableCommerce"></p-inputSwitch>
        </div>
      </div>
    </div>
  </div>

  <div class="content-generic">
    <div class="grid-container">
      <div class="">
        <div class="col-config margin-top-32">
          <div class="flex align-items-center justify-content-start mr-3 mb-3">
            <p class="text-lg text-gray-1 lh-24 font-semibold my-0 mr-2">Estado de cuenta</p>
            <div *ngIf="accountVerified" class="tooltip-cursor"
                 pTooltip="El comercio ya está verificado." tooltipPosition="top">
              <i class="fa-light fa-circle-info"></i>
            </div>
            <div *ngIf="!accountVerified" class="tooltip-cursor"
                 pTooltip="El comercio aún no ha sido verificado"
                 tooltipPosition="top">
              <i class="fa-light fa-circle-info"></i>
            </div>
          </div>
          <p class="verify success mb-0 font-poppins" *ngIf="accountVerified">
            <i class="fa-solid fa-circle-check fa-lg text-success margin-right-8"></i>
            El comercio se encuentra correctamente verificado.
          </p>
          <p class="verify error mb-0 font-poppins" *ngIf="!accountVerified">
            <i class="pi pi-times-circle"></i> El comercio aún no está verificado.
          </p>
        </div>
      </div>

      <div class="margin-top-48 mb-4">
        <div class="col-config">
          <p class="text-lg text-gray-1 lh-24 font-semibold my-0">Datos del comercio</p>
        </div>
      </div>

      <div class="">
        <div class="col-config">
          <div class="field__item store-name"
               [ngClass]="validateInputClass(genericConfigForm, 'name', formHasBeenSubmitted)">
            <div class="p-float-label">
              <input formControlName="name" id="name" type="text" maxlength="{{ maxCommerceNameLength }}" pInputText/>
              <label for="name" class="title-float">Nombre* </label>
              <app-show-errors [page]="'configurations'" [ctrl]="nameLabel"
                               [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
            </div>
            <div *ngIf="
                genericConfigForm.get('name').valid ||
                genericConfigForm.get('name').untouched
              " class="field__item--info">
              <span>
                {{ nameLabel.value ? nameLabel.value.length : 0 }}/{{
                maxCommerceNameLength
                }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="col-config">
          <div class="p-field field__item mb-0" [ngClass]="
              validateInputClass(
                genericConfigForm,
                'description',
                formHasBeenSubmitted
              )
            ">
            <div class="p-float-label">
              <textarea id="description" formControlName="description" [rows]="4" [cols]="30"
                        maxlength="{{ maxCommerceDescriptionLength }}" pInputTextarea
                        autoResize="autoResize"></textarea>
              <label for="description" class="title-float">Descripción del comercio
              </label>
              <app-show-errors [page]="'configurations'" [ctrl]="genericConfigForm.get('description') | formControl"
                               [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
            </div>
            <div *ngIf="
                genericConfigForm.get('description').valid ||
                genericConfigForm.get('description').untouched
              " class="field__item--info">
              <span>
                {{
                descriptionLabel.value ? descriptionLabel.value.length : 0
                }}/{{ maxCommerceDescriptionLength }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div class="col-config">
          <div class="field__item mt-5" [ngClass]="
            validateInputClass(genericConfigForm, 'address', formHasBeenSubmitted)
          ">
            <div class="p-float-label">
              <input [(ngModel)]="address" formControlName="address" id="address" type="text" pInputText
                     (keyup)="startCountDown()"/>
              <label for="address" class="title-float">Dirección*</label>
              <app-show-errors [page]="'configurations'" [ctrl]="genericConfigForm.get('address') | formControl"
                               [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
            </div>
            <div id="myDropdown" class="dropdown__list" [ngClass]="{
                show: suggestedAddresses.length > 0 && address !== ''
              }">
              <a *ngFor="let result of suggestedAddresses" (click)="selectSuggestedAddress(result)"
                 class="dropdown__items">{{ result.description }}</a>
            </div>
          </div>
        </div>
        <div class="col-config">
          <div class="field__item mt-2 disabled"
               [ngClass]="validateInputClass(genericConfigForm, 'country', formHasBeenSubmitted)">
            <div class="p-float-label disabled">
              <input formControlName="country" id="country" type="text" pInputText disabled/>
              <label for="country" class="title-float disabled">País* </label>
            </div>
            <app-show-errors [page]="'configurations'" [ctrl]="genericConfigForm.get('country') | formControl"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
        </div>
      </div>

      <div class="margin-top-16">
        <div class="col-config">
          <div class="field__item mb-3"
               [ngClass]="validateInputClass(genericConfigForm, 'phone', formHasBeenSubmitted)">
            <div class="p-float-label">
              <input formControlName="phone" id="phone" type="number" pInputText (keydown)="onPhoneChange($event)"/>
              <label for="phone" class="title-float">Teléfono* </label>
            </div>
            <app-show-errors [page]="'configurations'" [ctrl]="genericConfigForm.get('phone') | formControl"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
        </div>
      </div>

      <div class="margin-top-48">
        <div class="col-config mt-1 imgs-row">
          <div class="logo-store">
            <p class="font-size-18 font-weight-600 lh-24">Logo</p>
            <div class="flex align-items-center">
              <div class="mb-1" *ngIf="storeData.logoUrl !== null">
                <img [src]="getUrlImage(storeData.logoUrl)" alt="{{ storeData.name }}" class="store__logo"/>
              </div>
            </div>
          </div>

          <div class="header-store">
            <p class="font-size-18 font-weight-600 lh-24">
              Encabezado de página
            </p>

            <div class="container-header mt-1" *ngIf="
                storeData.headerUrl !== null
                else placeholder
              ">
              <img [src]="getUrlImage(storeData.headerUrl)" alt="{{ storeData.name }}" class="header-store thumbnail"/>
            </div>

            <ng-template #placeholder>
              <div *ngIf="!storeData.headerUrl || storeData.headerUrl === null" class="no-image-placeholder">
                <i class="far fa-file-image"></i>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="space-title password margin-top-48" *ngIf="!fromAdmin">
        <div class="col-6 offset-3 mt-5">
          <div class="flex align-items-center">
            <p class="title">Contraseña</p>
            <div class="tooltip-cursor" pTooltip="Hacé click en el botón para cambiar tu contraseña."
                 tooltipPosition="top">
              <i class="fa-light fa-circle-info"></i>
            </div>
          </div>
          <span class="mt-2">Edita tu contraseña cuando lo desees.</span>
          <a class="btn__link font-semibold cursor-pointer mt-3" (click)="openChangePasswordDialog()">Cambiar
            contraseña</a>
        </div>
      </div>

      <div class="margin-top-48">
        <div class="col-config notifications mt-3 margin-bottom-48">
          <div>
            <div class="flex align-items-center">
              <p class="text-lg text-gray-1 lh-24 font-semibold my-0 mr-2">Notificaciones</p>
            </div>
            <p class="text-base font-semibold text-gray-3 lh-24 mt-2 mb-1">Sonido de notificaciones.</p>
            <p class="text-sm text-gray-3 lh-24 mt-2 mb-1">Activa o desactiva el sonido de las notificaciones para
              pedidos nuevos.</p>
          </div>
          <div>
            <div class="switch-container notifications">
              <p-inputSwitch name="notificationSound" formControlName="notificationSound"></p-inputSwitch>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <app-save-fixed
    *ngIf="thereAreChanges && !changingHeader"
    (save)="save()"
    (cancel)="cancel()">
  </app-save-fixed>

  <p-toast class="config-message" key="config-message"></p-toast>
</form>

<p-dialog [(visible)]="passwordDialog" appendTo="body" [style]="{ width: '517px' }" header="Cambiar contraseña"
          [modal]="true">
  <ng-template pTemplate="content">
    <form [formGroup]="passwordForm" class="w-100 change-password-modal">
      <div class="p-field field__item">
        <span class="p-float-label">
          <input formControlName="oldPassword" id="oldPassword" minlength="{{ minCommercePasswordLength }}"
                 maxlength="{{ maxCommercePasswordLength }}" type="password" pInputText/>
          <label for="oldPassword" class="title-float">Contraseña actual</label>
        </span>
      </div>
      <div class="p-field field__item mt-4">
        <span class="p-float-label">
          <input formControlName="newPassword" (keyup)="testPasswords()" id="newPassword"
                 minlength="{{ minCommercePasswordLength }}" maxlength="{{ maxCommercePasswordLength }}" type="password"
                 pPassword/>
          <label for="newPassword" class="title-float">Nueva contraseña</label>
        </span>
        <p class="text-gray-1 mt-2 leyend">Debe tener mímino 6 caracteres.</p>
      </div>
      <div class="p-field field__item mt-4">
        <span class="p-float-label">
          <input formControlName="newPasswordRepeat" id="newPasswordRepeat" minlength="{{ minCommercePasswordLength }}"
                 maxlength="{{ maxCommercePasswordLength }}" type="password" pInputText/>
          <label for="newPasswordRepeat" class="title-float">Repetí la nueva contraseña</label>
        </span>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton label="Cancelar" class="btn btn__secondary btn__secondary--bold mr-3"
            (click)="hideDialog()"></button>
    <button [disabled]="!passwordForm.valid" pButton label="Cambiar contraseña"
            class="btn btn__primary btn__primary--bold" (click)="changePassword()"></button>
  </ng-template>
</p-dialog>

<p-confirmDialog key="avaibleCommerceVerificacion" [style]="{ width: '450px' }" class="store-confirmation-available-app"
                 acceptLabel="Aceptar" rejectLabel="Cancelar"
                 rejectButtonStyleClass="btn btn__secondary btn__secondary--bold mr-2"
                 acceptButtonStyleClass="btn btn__primary btn__primary--bold">
</p-confirmDialog>
