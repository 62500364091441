import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoryEventResponse } from 'src/app/core/models/CategoryEventResponse ';
import { EventComplete } from 'src/app/core/models/EventComplete';
import { ModalConfig } from 'src/app/core/models/ModalConfig';
import { ToggleBodyRequest } from 'src/app/core/models/ToggleBodyRequest';
import { VerifyEventRequest } from 'src/app/core/models/VerifyEventRequest';
import { EventsService } from 'src/app/services/events.service';
import { RequestErrorTriggerService } from 'src/app/services/request-error-trigger.service';
import { SpinnerService } from 'src/app/services/spinner.service';
@Component({
  selector: 'app-cards-event-configuration',
  templateUrl: './cards-event-configuration.component.html',
  styleUrls: ['./cards-event-configuration.component.scss'],
})
export class CardsEventConfigurationComponent implements OnInit {

  @Input() eventData: EventComplete;
  @Input() categoriesEvents: Array<CategoryEventResponse> = [];
  @Input() eventCategory: CategoryEventResponse;
  @Output() saveCategory = new EventEmitter<number>();
  @Output() refreshEventData = new EventEmitter();
  loading = false;
  selectedCategory: number;// new set category
  subs = [];
  isWriter = true;
  showModalConfirmResale = false;

  modalResaleConfig: ModalConfig;

  categoryEmpty = {
    idCategory: 0,
    name: 'Seleccioná una categoría',
    position: 0,
    showOnBillboard: false,
    quantityEvents: 0,
    tenant: 0
  };

  constructor(
    private errorService: RequestErrorTriggerService,
    private eventsSrv: EventsService,
    private spinnerSrv: SpinnerService
    ) { }


  ngOnInit(): void {
    this.checkDefaultCategory();
  }

  checkDefaultCategory() {
    if (this.eventCategory) {
      this.selectedCategory = this.eventCategory.idCategory;
    }
  }

  toggleSwitchVerified(ev): void {
    this.verifyEvent(ev.checked);
  }

  verifyEvent(verified: boolean): void {
    if (this.selectedCategory !== 0) {
      this.loading = true;
      this.spinnerSrv.loadSpinner.next(true);
      const verifyEventRequest = new VerifyEventRequest();
      verifyEventRequest.verified = verified;
      this.subs.push(
        this.eventsSrv.verifyEvent(this.eventData.idEvent, verifyEventRequest).subscribe({
          next: (r) => {
            this.loading = false;
            this.refreshEventData.emit();
            this.spinnerSrv.loadSpinner.next(false);
          },
          error: (err) => {
            this.loading = false;
            if (err.status === 404) {
              this.handleErrorMessage(err.status);
            }
          }
        })
      );
    } else {
      const message =
        'Debe seleccionar una categoría para poder verificar el evento.';
      this.errorService.updateShowError({
        showError: true,
        message,
      });
    }
  }

  async toggleSwitchResale(ev) {
    this.modalResaleConfig = {
      headerTextAfterIcon: ev.checked === true ? 'Estas a punto de habilitar la reventa de tickets para este evento.' : 'Estas a punto de deshabilitar la reventa de tickets para este evento',
      content: ev.checked === true ? 'El habilitar la reventa permitirá vender tickets en el mercado secundario.' : 'Los tickets que ya se hayan publicado no se quitarán de la reventa.',
      icon: 'fa-light fa-info-circle text-12xl text-info',
      buttons: 'confirm',
      textBtn:'Habilitar reventa'

    }

    this.showModalConfirmResale = true;
  }

  onModalResaleResponse(confirmResale: boolean) {
    this.showModalConfirmResale = false;

    confirmResale ? this.onResale() : this.eventData.allowResale = !this.eventData.allowResale;
  }

  onResale(): void {
    const toggleBodyRequest = new ToggleBodyRequest();
    toggleBodyRequest.toggleOn = this.eventData.allowResale;
    this.eventsSrv.allowResale(toggleBodyRequest, this.eventData.idEvent).subscribe({
      next: () => {
      },
      error: (err) => {
        if (err.status === 404) {
          this.handleErrorMessage(err.status);
        }
      }
    });
  }

  private handleErrorMessage(status): void {
    let message: string;
    switch (status) {
      case 404:
        message = 'Evento no encontrado.';
        break;
    }
    this.errorService.updateShowError({
      showError: true,
      message,
    });
  }

  getCategoryEvent(event: EventComplete): CategoryEventResponse {
    const categoriesForEvent = this.categoriesEvents.filter(category => category.tenant === event.storeResponse.tenantResponse.id);
    if (event.categoryId === null) {
      return this.categoryEmpty;
    } else {
      return categoriesForEvent.find((c: CategoryEventResponse) => c.idCategory === event.categoryId);
    }
  }

  onChangeCategory(e){
    this.saveCategory.emit(this.selectedCategory);
  }

  toggleSwitchBanner(ev): void {
    this.checkBanner(ev.checked);
  }

  checkBanner(isChecked: boolean) {
    this.loading = true;
    this.spinnerSrv.loadSpinner.next(true);
    const toggleBodyRequest = new ToggleBodyRequest();
    toggleBodyRequest.toggleOn = isChecked;
    this.eventsSrv.checkBanner(toggleBodyRequest, this.eventData.idEvent).subscribe({
      next: () => {
        this.spinnerSrv.loadSpinner.next(false);
        this.loading = false;
      },
      error: (err) => {
        this.spinnerSrv.loadSpinner.next(false);
        this.loading = false;
        if (err.status === 404) {
          this.handleErrorMessage(err.status);
        }
      }
    });
  }

}
