import {Component, Input, OnInit} from '@angular/core';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {Router} from '@angular/router';
import {StoreService} from 'src/app/services/store.service';
import {Subscription} from 'rxjs';
import {StoreData} from '../../../../../../../core/models/StoreData';
import {SpinnerService} from '../../../../../../../services/spinner.service';
import {AuthService} from "../../../../../../../services/auth.service";
import {Tenant} from "../../../../../../../core/models/Tenant";
import {Location} from "@angular/common";
import {ModalVerifyStoreComponent} from "../modal-verify-store/modal-verify-store.component";
import {ChangeEmailComponent} from "../../../../components/change-email/change-email.component";
import {ChangeCuitComponent} from "../../../../components/change-cuit/change-cuit.component";
import {environment} from "../../../../../../../../environments/environment";
import {VerifyStoreRequest} from "../../../../../../../core/models/VerifyStoreRequest";

@Component({
  selector: 'app-store-details',
  templateUrl: './store-details.component.html',
  styleUrls: ['./store-details.component.scss'],
  providers: [MessageService, DialogService]
})
export class StoreDetailsComponent implements OnInit {

  @Input() storeId: number;
  storeData: StoreData;
  isWriter: boolean = false;
  logo: string = '';
  name: string = '';
  address: string = '';
  phone: string = '';
  email: string = '';
  cuit: string = '';
  loading: boolean = true;
  subs: Subscription[] = [];
  storeVerified: any;
  isSuperAdmin = false;
  tenantSelected: Tenant = null;
  tenantList: Tenant[] = [];
  items: MenuItem[];

  constructor(
    private storeSrv: StoreService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private spinnerSrv: SpinnerService,
    private authSrv: AuthService,
    private location: Location
  ) {
  }

  ngOnInit(): void {
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    this.spinnerSrv.loadSpinner.next(true);
    this.getStoreData(this.storeId);
  }

  getStoreData(storeId) {
    this.storeSrv.getStoreById(storeId).subscribe({
      next: (data: StoreData) => {
        this.storeData = data;
        this.generateMenu(data);
        this.storeVerified = this.storeData.accountVerified;
        this.logo = this.storeData.logoUrl;
        this.name = this.storeData.name;
        this.getTenantList();
        this.spinnerSrv.loadSpinner.next(false);
      }
    });
  }

  getTenantList() {
    this.storeSrv.getTenantList().subscribe({
      next: (data: Tenant[]) => {
        this.tenantList = data;
        if (this.storeData && this.storeData.tenant) {
          this.tenantSelected = this.tenantList.find(te => te.id === this.storeData?.tenant);
        }
      }
    });
  }

  generateMenu(storeData: StoreData) {
    this.items = [
      {
        tabindex: "1",
        label: storeData?.accountVerified ? 'Quitar verificación' : 'Verificar comercio',
        icon: `fa-light ${storeData?.accountVerified ? 'fa-store-slash' : 'fa-check-circle'} fa-xl margin-right-16 lh-24 font-size-16`,
        styleClass: 'font-poppins font-size-14 lh-24',
        command: () => {
          this.manageVerify();
        },
      },
      {
        separator: true
      },
      {
        badgeStyleClass: 'item-delete',
        tabindex: "2",
        label: 'Eliminar comercio',
        icon: 'fa-light fa-trash-alt margin-right-16 font-size-16 lh-24 item-delete',
        styleClass: 'delete-item',
        command: () => {
          this.confirmDeleteStore();
        },
      },
    ]
  }

  openModalVerification(): void {
    const ref = this.dialogService.open(ModalVerifyStoreComponent, {
      data: {
        storeName: this.storeData.name,
        tenantList: this.tenantList,
        tenantSelected: this.tenantSelected,
        storeVerified: this.storeData.accountVerified,
        storeId: this.storeData.id
      },
      width: '552px !important',
      closable: true
    });
  }

  confirmDeleteStore() {
    const title = '¿Deseas eliminar ' + this.storeData.name + '?';
    this.confirmationService.confirm({
      message:
        ` < span
        class = "topheader" >${title} <span class = "subheader" > El
        comercio no
        podrá seguir
        operando ni
        tendrá acceso
        a su
        Backoffice
        .</span></s
        pan > `,
      icon: 'fa-light fa-triangle-exclamation text-12xl text-yellow',
      key: 'removeStore',
      rejectIcon: 'none',
      acceptIcon: 'none',
      acceptLabel: 'Cancelar',
      rejectLabel: 'Eliminar comercio'
    });
  }

  manageVerify() {
    if (this.storeData.accountVerified) {
      this.removeVerifyStore();
    } else {
      this.openModalVerification();
    }
  }

  closeModal() {
    this.confirmationService.close();
  }

  removeVerifyStore(): void {
    this.spinnerSrv.loadSpinner.next(true);
    const verifyStoreRequest = new VerifyStoreRequest();
    verifyStoreRequest.verified = false
    verifyStoreRequest.tenant = this.storeData.tenant;
    this.storeSrv.verifyStoreWithTenant(this.storeId, verifyStoreRequest).subscribe({
      next: () => {
        this.getStoreData(this.storeId)
      },
      error: () => {
        this.spinnerSrv.loadSpinner.next(false);
      }
    })
  }

  deleteStore() {
    this.spinnerSrv.loadSpinner.next(true);
    this.storeSrv.deleteStore(Number(this.storeId)).subscribe({
      next: () => {
        this.spinnerSrv.loadSpinner.next(false);
        this.router.navigate(['/stores']);
      },
      error: () => {
        this.spinnerSrv.loadSpinner.next(false);
      }
    });
  }

  unlockStore() {
    this.spinnerSrv.loadSpinner.next(true);
    this.storeSrv.unlockStore(Number(this.storeId)).subscribe({
      next: () => {
        this.getStoreData(this.storeId);
        this.confirmationService.close();
      },
      error: () => {
        this.spinnerSrv.loadSpinner.next(false);
      }
    });
  }

  getStoreVerification(store) {
    return store.accountVerified;
  }

  back(): void {
    sessionStorage.removeItem('storeId');
    this.router.navigate(['stores']);
  }

  changeEmail(store): void {
    const ref = this.dialogService.open(ChangeEmailComponent, {
      data: {
        id: store.id,
        name: store.name,
        email: store.email,
        isStore: true
      },
      header: 'Editar email',
      width: '552px !important',
      closable: true,
      styleClass: 'config-dialog__edit-email',
    });
    this.subs.push(
      ref.onClose.subscribe({
        next: (isResultOK: boolean) => {
          if (isResultOK) {
            this.messageService.add({
              severity: 'success',
              summary: 'Éxito',
              detail: 'El cambio de email se realizó correctamente.',
            });
          }
        }
      })
    );
  }

  changeCuit(store): void {
    const ref = this.dialogService.open(ChangeCuitComponent, {
      data: {
        id: store.id,
        name: store.name,
        email: store.email,
        taxIdentifier: store.taxIdentifier,
      },
      header: 'Editar cuit',
      width: '552px !important',
      closable: true,
      styleClass: 'config-dialog__edit-cuit',
    });

    this.subs.push(
      ref.onClose.subscribe({
        next: (isResultOK: boolean) => {
          if (isResultOK) {
            this.messageService.add({
              severity: 'success',
              summary: 'Éxito',
              detail: 'El cambio de cuit se realizó correctamente.',
            });
          }
        }
      })
    );
  }

  getUrlImage(url: string): string {
    return `${environment.mediaUrl}${url}`;
  }

  shortCity(address) {
    return address.split(',', 2);
  }
}
