<section class="container__payments">
  <header class="header__payments">
    <i class="fa-light fa-square-dollar text-xl text-gray-1 margin-right-16"></i>
    <p class="font-inter text-xl font-semibold text-gray-1 margin-y-0 uppercase">plataforma de pago</p>
  </header>
  <div class="content__payments" *ngIf="storeData?.tenant">
    <div class="btn__payment" (click)="mobbexConfiguration(storeData)">
      <img src="../../../../../../../../../../assets/mobbex.svg" class="logo" alt="Mobbex"/>
    </div>
    <div class="btn__payment" (click)="mercadopagoConfiguration(storeData)">
      <img src="../../../../../../../../../../assets/mp.svg" class="logo" alt="Mercado Pago"/>
    </div>
    <div class="btn__payment" (click)="pagoUnoConfiguration(storeData)">
      <img src="../../../../../../../../../../assets/pagouno.svg" class="logo" alt="Pago Uno"/>
    </div>
    <div class="btn__payment" (click)="stripeConfiguration(storeData)">
      <img src="../../../../../../../../../../assets/stripe.png" class="logo stripe" alt="Pago Uno"/>
    </div>
  </div>
  <div class="content__payments" *ngIf="storeData?.tenant === null">
    <div class="text-gray-3 font-size-18 font-weight-500">Para configurar los medios y las plataformas de pago es
      necesario asignarle una marca primero.
    </div>
  </div>
</section>
