<nav class="navbar" [ngClass]="{ 'bg-solid-dark': isHome }">
  <a href="/" target="_self">
    <img src="../../../../assets/logo-blackid-admin.svg" class="logo" alt="Blackid"/>
  </a>
  <ul class="navbar__list">
    <li class="navbar__item ml-2" *ngIf="isLogged">
      <div class="flex justify-content-between align-items-center" (click)="logout()">
        <i class="fas fa-right-from-bracket text-gray-1 mr-2"></i>
      </div>
    </li>
  </ul>
</nav><!-- Navbar -->

<p-confirmDialog key="confirmLogout" [style]="{ width: '400px' }" acceptLabel="Aceptar" rejectLabel="Cancelar"
                 acceptButtonStyleClass="btn btn__primary btn__primary--bold"
                 rejectButtonStyleClass="btn btn__secondary btn__secondary--bold">
</p-confirmDialog>
