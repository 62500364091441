import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Message, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { VipConfig } from 'src/app/core/models/Configurations';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-config-vip',
  templateUrl: './config-vip.component.html',
  styleUrls: ['./config-vip.component.scss'],
  providers: [MessageService],
})
export class ConfigVipComponent implements OnInit, OnDestroy {
  @Output() validFormState = new EventEmitter();
  @Input() vipConfigData: VipConfig = null;
  @Input() configurationId: number;
  @Input() fromAdmin: boolean;
  @Input() canWrite: boolean;

  vipForm: UntypedFormGroup;
  response: Array<Message> = [];
  thereAreChanges: boolean = false;
  originalData: VipConfig;
  tooltipMessage: string;
  switch: boolean;
  subs: Subscription[] = [];
  setLogo = false;
  changingLogo = false;
  formHasBeenSubmitted = false;
  maxVipDiscount: number;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.maxVipDiscount = environment.maxValueDiscount;
    this.vipForm = this.formBuilder.group({
      vipEnabled: [false],
      vipDiscount: [0, Validators.required],
      vipBadge: [''],
    });
  }

  ngOnInit(): void {
    if (this.vipConfigData) {
      this.loadData();
      this.originalData = this.vipConfigData;
    }

    this.subs.push(
      this.vipEnabledField.valueChanges.subscribe((val) => {
        this.getTooltipMessage(val);
        this.switch = val;
      })
    );
    this.getTooltipMessage(this.vipEnabledField.value);
  }

  getTooltipMessage(val): void {
    this.tooltipMessage = val
      ? 'Al escanear el QR de la mesa, tus clientes podrán ver el menú dinámicamente y hacer el pedido directamente desde la App'
      : 'Tus clientes podrán ver el menú dinámicamente, pero no podrán hacer el pedido desde la App.';
  }

  get vipEnabledField(): AbstractControl {
    return this.vipForm.get('vipEnabled');
  }

  loadData(): void {
    this.vipForm.patchValue({
      vipEnabled: this.vipConfigData.vipEnabled,
      vipDiscount: this.vipConfigData.vipDiscount,
      vipBadge: this.vipConfigData.vipBadge,
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => {
      s.unsubscribe();
    });
  }
}
