import {Component, Input, OnInit} from '@angular/core';
import {EventComplete} from 'src/app/core/models/EventComplete';
import {EventSalesStaticsResponse} from 'src/app/core/models/events/EventSalesStaticsResponse';
import {EventsService} from 'src/app/services/events.service';
import {SpinnerService} from "../../../../../../../services/spinner.service";

@Component({
  selector: 'app-cards-number-stats',
  templateUrl: './cards-number-stats.component.html',
  styleUrls: ['./cards-number-stats.component.scss']
})
export class CardsNumberStatsComponent implements OnInit {

  @Input() event: EventComplete;
  @Input() static: EventSalesStaticsResponse;
  ticketDescriptions = [];
  currency: string = '$';

  constructor(
  ) {
  }

  ngOnInit(): void {
    this.currency = this.event.storeResponse.currency;
  }

}
