import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  constructor() { }

  getMultiSortObject(e: LazyLoadEvent): any[] {
    const multiSortFilelds = [];
    if (e.multiSortMeta && e.multiSortMeta.length !== 0) {
      e.multiSortMeta.forEach((fieldObject) => {
        const fieldSort = { name: fieldObject.field, order: fieldObject.order };
        multiSortFilelds.push(fieldSort);
      });
    }
    return multiSortFilelds;
  }
}
