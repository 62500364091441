<div class="global-container w-100">
  <form [formGroup]="changeCuitForm" autocomplete="off">
    <div class="field__item input-mask field--modal margin-top-24 margin-bottom-24" [ngClass]="
        validateInputClass(changeCuitForm, 'taxIdentifier', formHasBeenSubmitted)
      ">
      <div class="p-float-label">
        <p-inputMask formControlName="taxIdentifier" id="cuit" mask="99-99999999-9" class="p-inputmask"></p-inputMask>
        <label for="cuit" class="title-float">Cuit</label>
        <app-show-errors
          [ctrl]="changeCuitForm.get('taxIdentifier') | formControl"
          [page]="'change-cuit'"
          [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
      </div>
    </div>
  </form>

  <div class="footer">
    <div class="col margin-right-8">
      <button pButton (click)="onClose()" class="btn btn__secondary btn__secondary--bold"
      label="Cancelar"></button>
    </div>
    <div class="col margin-left-8">
      <button pButton (click)="submitChange()" class="btn btn__primary btn__primary--bold" label="Guardar cambios"></button>
    </div>
  </div>
</div>
