import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Pageable} from '../core/models/Pageable';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private httpC: HttpClient) {
  }

  getUsersInfo(pageNumber?: number, pageSize?: number, multiSortFields?: any[], globalFilter?: string): Observable<Pageable> {
    const httpHeaders = new HttpHeaders().set('Accept', 'application/json');
    let httpParams = new HttpParams();
    let sortFields = {sortFieldsWithOrder: []};
    if (!multiSortFields || multiSortFields.length < 1) {
      const initialSortField = `${this.camelToUnderscore('idCustomer')}:DESC`;
      sortFields.sortFieldsWithOrder.push(`${initialSortField}`);
    } else {
      let i = 0;
      while (i < multiSortFields.length) {
        let nameField = multiSortFields[i]['name'];
        if (nameField === 'document') {
          nameField = 'dni'
        }
        let order = multiSortFields[i]['order'] > 0 ? 'ASC' : 'DESC';
        sortFields.sortFieldsWithOrder.push(`${this.camelToUnderscore(nameField)}:${order}`);
        i++;
      }
    }
    const pageNro = pageNumber ? pageNumber : 0;
    httpParams = httpParams.set('pageNumber', pageNro.toString());
    httpParams = httpParams.set('size', pageSize.toString());
    httpParams = httpParams.set('sortFields', sortFields.sortFieldsWithOrder.join(','));
    httpParams = httpParams.set('search', globalFilter);

    return this.httpC.get<Pageable>(
      environment.apiAGUrl + `/customers/info`,
      {headers: httpHeaders, params: httpParams}
    );
  }

  camelToUnderscore(field) {
    const result = field.replace(/([A-Z])/g, " $1");
    return result.split(' ').join('_').toLowerCase();
  }

  blockUser(id, block: boolean): Observable<void> {
    return !block
      ? this.httpC.patch<void>(
        environment.apiAGUrl + `/customers/block/${id}`,
        null
      )
      : this.httpC.patch<void>(
        environment.apiAGUrl + `/customers/unblock/${id}`,
        null
      );
  }

  sendEmailToRecoverPassword(idCustomer) {
    return this.httpC.post<void>(
      environment.apiAGUrl + `/customer/email/recover_password/${idCustomer}`, null,
    );
  }

  sendEmailToVerifiedAccount(idCustomer) {
    return this.httpC.post<void>(
      environment.apiAGUrl + `/customer/email/verify_account/${idCustomer}`, null,
    );
  }

  deleteCustomerAccount(idCustomer) {
    return this.httpC.post<void>(
      environment.apiAGUrl + `/customer/delete/${idCustomer}/from_admin`, null,
    );
  }

  closeAllSession(): Observable<void> {
    return this.httpC.get<void>(
      environment.apiAGUrl + `/notification/close-session/customer`,
    );
  }

  cleanDocument(id: number): Observable<void> {
    return this.httpC.post<void>(
      environment.apiAGUrl + `/customers/clearer-document/${id}`, null);
  }
}
