<div class="cards-event-configuration">
    <div class="card category">
        <div class="card__container">
            <div class="card__title">Categoría en cartelera</div>
        </div>
        <div class="card__dropdown">
            <p-dropdown
                id="categoriesEvents"
                [options]="categoriesEvents"
                [(ngModel)]="selectedCategory"
                (onChange)="onChangeCategory($event)"
                optionLabel="name"
                optionValue="idCategory"
                placeholder="Seleccioná una categoría"
                class="dropdown cateogories-dropdown"></p-dropdown>
        </div>
    </div>

    <div class="card banner">
        <div class="card__container">
            <div class="card__title">Destacar en banner</div>
            <p-inputSwitch
                (onChange)="toggleSwitchBanner($event)"
                [disabled]="!isWriter"
                [(ngModel)]="eventData.mainEvent"
            >
            </p-inputSwitch>
        </div>
        <div class="card__description-text">
            El evento se mostrará en el banner superior de la Cartelera.
        </div>
    </div>

    <div class="card pay">
        <div class="card__container">
            <div class="card__title">Referencia de pago</div>
        </div>
        <div class="card__description-text">
            {{eventData.splitReference}}
        </div>
    </div>

    <div class="card verification">
        <div class="card__container">
            <div class="card__title">Verificar evento</div>
            <p-inputSwitch
                [disabled]="eventData.categoryId === null || loading"
                [(ngModel)]="eventData.verified"
                (onChange)="toggleSwitchVerified($event)">
            </p-inputSwitch>
        </div>
        <div class="card__description-text">
            Es necesario verificarlo para mostrarse cartelera y a la venta.
        </div>
    </div>

    <div class="card resale">
        <div class="card__container">
            <div class="card__title">Habilitar reventa</div>
            <p-inputSwitch
                [disabled]="eventData.categoryId === null || loading"
                [(ngModel)]="eventData.allowResale"
                (onChange)="toggleSwitchResale($event)">
            </p-inputSwitch>
        </div>
        <div class="card__description-text">
            Se habilitará la reventa para  todos los accesos de este evento.
        </div>
    </div>
</div>

<app-modal
        *ngIf="showModalConfirmResale"
        [showModal]="showModalConfirmResale"
        [config]="modalResaleConfig"
        [widthModal]="'432px'"
        (modalResponse)="onModalResaleResponse($event)">
</app-modal>
