import {CommonModule, registerLocaleData} from '@angular/common';
import {ConfirmationService, MessageService} from 'primeng/api';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LOCALE_ID, NgModule} from '@angular/core';

import {
  AccessControlStatsComponent
} from './pages/home/stores/pages/store/pages/stats/pages/events-stats/components/access-control-stats/access-control-stats.component';
import {AddressPipe} from './shared/pipes/address.pipe';
import {AdministratorsComponent} from './pages/home/administrators/administrators.component';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AreaGraphicComponent} from './shared/components/stats/area-graphic/area-graphic.component';
import {
  AttributesComponent
} from './pages/home/stores/components/manage-menu/components/attributes/attributes.component';
import {
  AttributesValueComponent
} from './pages/home/stores/components/manage-menu/components/attributes/components/attributes-value/attributes-value.component';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {BarGraphicComponent} from './shared/components/stats/bar-graphic/bar-graphic.component';
import {
  BarGraphicNumericStepComponent
} from './shared/components/stats/bar-graphic-numeric-step/bar-graphic-numeric-step.component';
import {
  BillboardStatsComponent
} from './pages/home/stores/pages/store/pages/stats/pages/events-stats/components/billboard-stats/billboard-stats.component';
import {
  BookingStatsComponent
} from './pages/home/stores/pages/store/pages/stats/pages/booking-stats/booking-stats.component';
import {BookingsComponent} from './pages/home/bookings/bookings.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {ButtonModule} from 'primeng/button';
import {
  CardConfirmCancelStatsComponent
} from './pages/home/stores/pages/store/pages/stats/components/card-confirm-cancel-stats/card-confirm-cancel-stats.component';
import {
  CardDonutComponent
} from './pages/home/stores/pages/store/pages/stats/components/card-donut/card-donut.component';
import {
  CardDualStatsComponent
} from './pages/home/stores/pages/store/pages/stats/components/card-dual-stats/card-dual-stats.component';
import {
  CardTotalStatsComponent
} from './pages/home/stores/pages/store/pages/stats/components/card-total-stats/card-total-stats.component';
import {
  CardTotalStatsShortComponent
} from './pages/home/stores/pages/store/pages/stats/components/card-total-stats-short/card-total-stats-short.component';
import {
  CategoriesComponent
} from './pages/home/stores/components/manage-menu/components/categories/categories.component';
import {CategoriesEventsComponent} from './pages/home/categories-events/categories-events.component';
import {
  CategoryFormModalComponent
} from './pages/home/categories-events/components/category-form-modal/category-form-modal.component';
import {
  CategoryModalComponent
} from './pages/home/stores/components/manage-menu/components/categories/components/category-modal/category-modal.component';
import {ChangeCuitComponent} from './pages/home/stores/components/change-cuit/change-cuit.component';
import {ChangeEmailComponent} from './pages/home/stores/components/change-email/change-email.component';
import {CheckboxModule} from 'primeng/checkbox';
import {
  ConfigBookingsComponent
} from './pages/home/stores/components/store-detail/components/config-bookings/config-bookings.component';
import {
  ConfigDeliveryComponent
} from './pages/home/stores/components/store-detail/components/config-delivery/config-delivery.component';
import {
  ConfigGenericComponent
} from './pages/home/stores/components/store-detail/components/config-generic/config-generic.component';
import {
  ConfigListaEsperaComponent
} from './pages/home/stores/components/store-detail/components/config-lista-espera/config-lista-espera.component';
import {
  ConfigMesasComponent
} from './pages/home/stores/components/store-detail/components/config-mesas/config-mesas.component';
import {
  ConfigScheduleComponent
} from './pages/home/stores/components/store-detail/components/config-schedule/config-schedule.component';
import {
  ConfigTakeAwayComponent
} from './pages/home/stores/components/store-detail/components/config-take-away/config-take-away.component';
import {
  ConfigVipComponent
} from './pages/home/stores/components/store-detail/components/config-vip/config-vip.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {DonutGraphicComponent} from './shared/components/stats/donut-graphic/donut-graphic.component';
import {DropdownModule} from 'primeng/dropdown';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {ErrorInterceptor} from './core/interceptors/error.interceptor';
import {EventsComponent} from './pages/home/events/events.component';
import {
  EventsStatsComponent
} from './pages/home/stores/pages/store/pages/stats/pages/events-stats/events-stats.component';
import {FooterComponent} from './core/components/footer/footer.component';
import {GlobalTransactionsComponent} from './pages/home/global-transactions/global-transactions.component';
import {HomeComponent} from './pages/home/home.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {
  IngressChartComponent
} from './pages/home/stores/pages/store/pages/stats/pages/pedidos-stats/components/ingress-chart/ingress-chart.component';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {
  LeftGraphicComponent
} from './pages/home/stores/pages/store/pages/stats/pages/booking-stats/components/left-graphic/left-graphic.component';
import {LineGraphicComponent} from './shared/components/stats/line-graphic/line-graphic.component';
import {LoadImgCroppComponent} from './shared/components/load-img-cropp/load-img-cropp.component';
import {LoginComponent} from './pages/sign-in/login/login.component';
import {ManageMenuComponent} from './pages/home/stores/components/manage-menu/manage-menu.component';
import {
  MenuToPdfComponent
} from './pages/home/stores/components/manage-menu/components/products/menu-to-pdf/menu-to-pdf.component';
import {MesaComponent} from './pages/home/stores/components/store-detail/components/config-mesas/mesa/mesa.component';
import {ModalActionComponent} from './shared/components/modal-action/modal-action.component';
import {ModalComponent} from './shared/components/modal/modal.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {NavbarComponent} from './core/components/navbar/navbar.component';
import {
  NewCategoryModalComponent
} from './pages/home/stores/components/manage-menu/components/categories/components/new-category-modal/new-category-modal.component';
import {NotFoundComponent} from './core/components/not-found/not-found.component';
import {PaymentsComponent} from './pages/home/stores/pages/store/pages/payments/payments.component';
import {
  PaymentsMercadopagoConfigDialogComponent
} from './pages/home/stores/pages/store/pages/payments/components/payment-mercadopago-config-dialog/payments-mercadopago-config-dialog.component';
import {
  PaymentsMobbexConfigDialogComponent
} from './pages/home/stores/pages/store/pages/payments/components/payment-mobbex-config-dialog/payments-mobbex-config-dialog.component';
import {
  PaymentsPagounoConfigDialogComponent
} from './pages/home/stores/pages/store/pages/payments/components/payments-pagouno-config-dialog/payments-pagouno-config-dialog.component';
import {
  PaymentsPlatformComponent
} from './pages/home/stores/pages/store/pages/payments/components/payments-platform/payments-platform.component';
import {
  PedidosChartComponent
} from './pages/home/stores/pages/store/pages/stats/pages/pedidos-stats/components/pedidos-chart/pedidos-chart.component';
import {
  PedidosStatsComponent
} from './pages/home/stores/pages/store/pages/stats/pages/pedidos-stats/pedidos-stats.component';
import {PorcentsComponent} from './pages/home/stores/pages/store/pages/payments/components/porcents/porcents.component';
import {ProductsComponent} from './pages/home/stores/components/manage-menu/components/products/products.component';
import {
  RightGraphicComponent
} from './pages/home/stores/pages/store/pages/stats/pages/booking-stats/components/right-graphic/right-graphic.component';
import {RouterModule} from '@angular/router';
import {
  SalesChannelsComponent
} from './pages/home/stores/pages/store/pages/payments/components/sales-channels/sales-channels.component';
import {
  ShiftsListComponent
} from './pages/home/stores/components/store-detail/components/config-bookings/shifts-list/shifts-list.component';
import {ShowErrorsComponent} from './shared/components/show-errors/show-errors.component';
import {ShowQrComponent} from './shared/components/show-qr/show-qr.component';
import {SpinnerComponent} from './shared/components/spinner/spinner.component';
import {StatsComponent} from './pages/home/stores/pages/store/pages/stats/stats.component';
import {StoreComponent} from './pages/home/stores/pages/store/store.component';
import {StoreDetailComponent} from './pages/home/stores/components/store-detail/store-detail.component';
import {StoreDetailsComponent} from './pages/home/stores/pages/store/components/store-details/store-details.component';
import {StoresComponent} from './pages/home/stores/stores.component';
import {TabMenuModule} from 'primeng/tabmenu';
import {TabViewModule} from 'primeng/tabview';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {
  TransactionDialogComponent
} from './pages/home/transactions/components/transaction-dialog/transaction-dialog.component';
import {TransactionsComponent} from './pages/home/transactions/transactions.component';
import {UsersComponent} from './pages/home/users/users.component';
import {
  VipListComponent
} from './pages/home/stores/components/store-detail/components/config-vip/vip-list/vip-list.component';
import localeEsAr from '@angular/common/locales/es-AR';
import {FormControlPipe} from './shared/pipes/form-control.pipe';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {SaveFixedComponent} from './shared/components/save-fixed/save-fixed.component';
import {SalesComponent} from './pages/home/sales/sales.component';
import {CardWideComponent} from './pages/home/sales/components/card-wide/card-wide.component';
import {CardVerticalComponent} from './pages/home/sales/components/card-vertical/card-vertical.component';
import {ContextMenuModule} from "primeng/contextmenu";
import {CalendarModule} from "primeng/calendar";
import {
  ModalVerifyStoreComponent
} from './pages/home/stores/pages/store/components/modal-verify-store/modal-verify-store.component';
import {EventConfigurationComponent} from './pages/home/events/pages/event-configuration/event-configuration.component';
import {
  TicketEventComponent
} from './pages/home/events/pages/event-configuration/components/ticket-event/ticket-event.component';
import {
  CardsEventConfigurationComponent
} from './pages/home/events/pages/event-configuration/components/cards-event-configuration/cards-event-configuration.component';
import {
  CardsNumberStatsComponent
} from './pages/home/events/pages/event-configuration/components/cards-number-stats/cards-number-stats.component';
import {
  CardsGeneralsStatsComponent
} from './pages/home/events/pages/event-configuration/components/cards-generals-stats/cards-generals-stats.component';
import {
  EventDescriptionModalComponent
} from './pages/home/events/pages/event-configuration/components/event-description-modal/event-description-modal.component';
import {MenuModule} from 'primeng/menu';
import {
  RescheduleEventComponent
} from './pages/home/events/pages/event-configuration/components/reschedule-event/reschedule-event.component';
import {PasswordModule} from "primeng/password";
import {RippleModule} from "primeng/ripple";
import { PaymentsStripeConfigDialogComponent } from './pages/home/stores/pages/store/pages/payments/components/payments-stripe-config-dialog/payments-stripe-config-dialog.component';

registerLocaleData(localeEsAr, 'es-Ar');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SpinnerComponent,
    ShowErrorsComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    BookingsComponent,
    CategoriesEventsComponent,
    CategoryFormModalComponent,
    AdministratorsComponent,
    TransactionsComponent,
    TransactionDialogComponent,
    GlobalTransactionsComponent,
    UsersComponent,
    EventsComponent,
    AddressPipe,
    StoresComponent,
    StoreDetailComponent,
    ChangeEmailComponent,
    ManageMenuComponent,
    PaymentsMobbexConfigDialogComponent,
    PaymentsMercadopagoConfigDialogComponent,
    PaymentsPagounoConfigDialogComponent,
    ConfigGenericComponent,
    ModalComponent,
    ConfigDeliveryComponent,
    ConfigTakeAwayComponent,
    ConfigMesasComponent,
    MesaComponent,
    ShowQrComponent,
    ConfigBookingsComponent,
    ShiftsListComponent,
    ConfigListaEsperaComponent,
    ConfigScheduleComponent,
    ConfigVipComponent,
    VipListComponent,
    ProductsComponent,
    LoadImgCroppComponent,
    MenuToPdfComponent,
    CategoriesComponent,
    NewCategoryModalComponent,
    CategoryModalComponent,
    AttributesComponent,
    AttributesValueComponent,
    BookingStatsComponent,
    PedidosStatsComponent,
    CardConfirmCancelStatsComponent,
    BarGraphicComponent,
    LineGraphicComponent,
    IngressChartComponent,
    PedidosChartComponent,
    LeftGraphicComponent,
    RightGraphicComponent,
    BarGraphicNumericStepComponent,
    NotFoundComponent,
    StoreComponent,
    StatsComponent,
    StoreDetailsComponent,
    BillboardStatsComponent,
    AccessControlStatsComponent,
    EventsStatsComponent,
    BillboardStatsComponent,
    CardDualStatsComponent,
    CardDonutComponent,
    AreaGraphicComponent,
    DonutGraphicComponent,
    CardTotalStatsComponent,
    CardTotalStatsShortComponent,
    PaymentsComponent,
    PaymentsPlatformComponent,
    PorcentsComponent,
    SalesChannelsComponent,
    ModalActionComponent,
    ChangeCuitComponent,
    FormControlPipe,
    SaveFixedComponent,
    SalesComponent,
    CardWideComponent,
    CardVerticalComponent,
    ModalVerifyStoreComponent,
    EventConfigurationComponent,
    TicketEventComponent,
    CardsEventConfigurationComponent,
    CardsNumberStatsComponent,
    CardsGeneralsStatsComponent,
    EventDescriptionModalComponent,
    RescheduleEventComponent,
    PaymentsStripeConfigDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    ButtonModule,
    HttpClientModule,
    DialogModule,
    ConfirmDialogModule,
    InputNumberModule,
    InputTextModule,
    InputSwitchModule,
    InputMaskModule,
    InputTextareaModule,
    ToastModule,
    TableModule,
    DynamicDialogModule,
    DropdownModule,
    CheckboxModule,
    TabMenuModule,
    TabViewModule,
    ImageCropperModule,
    MultiSelectModule,
    CommonModule,
    DropdownModule,
    ClipboardModule,
    ContextMenuModule,
    CalendarModule,
    MenuModule,
    PasswordModule,
    RippleModule
  ],
  entryComponents: [StoreDetailComponent, ModalActionComponent],
  providers: [
    ConfirmationService,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'es_Ar',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
