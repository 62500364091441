import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-donut',
  templateUrl: './card-donut.component.html',
  styleUrls: ['./card-donut.component.scss']
})
export class CardDonutComponent implements OnInit {

  @Input() idChart: string;
  @Input() columns;
  @Input() colors;
  @Input() emptyChart;

  constructor() { }

  ngOnInit(): void {
  }

}
