<div class="table__container">
  <p-table #dt *ngIf="shifts" [value]="shifts" [rows]="10" [paginator]="true" selectionMode="multiple" sortOrder="-1"
    [rowHover]="true" dataKey="id" [rowsPerPageOptions]="[10,25,50]" currentPageReportTemplate="Mostrar"
    [showCurrentPageReport]="true" [globalFilterFields]="['initTime','description']" class="table__internals">
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-between pb-4">
        <div class="header-table">
          <div class="flex align-items-center justify-content-start">
            <p class="text-base text-gray-1 my-0">Listado de Turnos</p>
            <i class="fa-solid fa-arrows-rotate text-primary cursor-pointer fa-lg ml-3 mr-3" [ngClass]="{ 'spin': refresh }" (click)="onRefresh()"></i>
            <div class="box__total">
              Total {{ shifts.length }}
            </div>
          </div>
        </div>
        <div class="flex align-items-center">
          <div class="search-container">
            <span class="p-input-icon-left search">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Buscar" />
            </span>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="initTime">
          Horario
          <p-sortIcon field="initTime"></p-sortIcon>
        </th>
        <th pSortableColumn="available">
          Estado
          <p-sortIcon field="available"></p-sortIcon>
        </th>
        <th pSortableColumn="description">
          Descripción
          <p-sortIcon field="description"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-shift>
      <tr>
        <td>
          <p class="text-sm lh-16 my-0 text-gray-1 font-semibold">{{ shift.initTime }} a {{ shift.endTime }} hs.</p>
        </td>
        <td>
          <p class="text-sm lh-16 my-0 text-gray-1">{{ shift.available ? "Disponible" : "No disponible" }}</p>
        </td>
        <td>
          <p class="text-sm lh-16 my-0 text-gray-1">{{ shift.description }}</p>
        </td>
      </tr>
      <div class="my-2"></div>
    </ng-template>
  </p-table>
</div>
