import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription, forkJoin } from 'rxjs';
import { Attribute } from 'src/app/core/models/Attribute';
import { CategoryProduct } from 'src/app/core/models/CategoryProduct';
import { Menu, Section } from 'src/app/core/models/Menu';
import { Product } from 'src/app/core/models/Product';
import { StoreData } from 'src/app/core/models/StoreData';
import { AttributesService } from 'src/app/services/attributes.service';
import { AuthService } from 'src/app/services/auth.service';
import { CategoriesService } from 'src/app/services/categories.service';
import { FormService } from 'src/app/services/form.service';
import { InputValidationService } from 'src/app/services/input-validation.service';
import { MenuService } from 'src/app/services/menu.service';
import { ProductsService } from 'src/app/services/products.service';
import { RequestErrorTriggerService } from 'src/app/services/request-error-trigger.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LoadImgCroppComponent } from 'src/app/shared/components/load-img-cropp/load-img-cropp.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class ProductsComponent implements OnInit, OnDestroy {
  @Input() hideLateralMenu: boolean;
  @Input() canWrite: boolean;
  @Output() productSubmitted = new EventEmitter();
  @ViewChild('dt') table: Table;
  @ViewChild(LoadImgCroppComponent) imgCroppChild: LoadImgCroppComponent;
  selectedItem: CategoryProduct;
  newMenuData: Menu;
  editId: number;
  isEditting = false;
  productForm: UntypedFormGroup;
  uploadedFiles: any[] = [];
  productDialog: boolean;
  products: Product[];
  categories: CategoryProduct[];
  selectedProducts: Product[] = null;
  statuses: any[];
  loading = true;
  comercio: StoreData;
  submitted: boolean;
  headerPopup: string;
  selectedCategory: CategoryProduct;
  attributes: Attribute[];
  selectedAttributes: Attribute[];
  selectedAttributesIds: number[];
  currentImage: string = '';
  showMenu: boolean = true;
  productSelected: Product = null;
  productImageSelected: string;
  subs: Subscription[] = [];
  titleLoadImage: string = 'Carga imagen';
  showNewCategory: boolean = false;
  categoryForm: UntypedFormGroup;
  originalProductBackUp: Product;
  categoryEmpty = {
    createdAt: null,
    description: '',
    id: null,
    image: null,
    name: 'Seleccioná una categoría',
    storeId: null,
  };
  formHasBeenSubmitted = false;
  maxProductNameLength: number;
  maxProductDescriptionLength: number;
  maxProductPrice: number;
  maxDiscount: number;

  //this flag show the download button
  canDownloadPDF = false;

  constructor(
    private productService: ProductsService,
    private categoriesService: CategoriesService,
    private sanitizer: DomSanitizer,
    private confirmationService: ConfirmationService,
    private formBuilder: UntypedFormBuilder,
    private atrributeSrv: AttributesService,
    private errorService: RequestErrorTriggerService,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private validateInputService: InputValidationService,
    private menuService: MenuService,
    public formService: FormService
  ) {
    this.maxProductNameLength = environment.maxProductNameLength;
    this.maxProductDescriptionLength = environment.maxProductDescriptionLength;
    this.maxProductPrice = environment.maxProductPrice;
    this.maxDiscount = environment.maxValueDiscount;
    const { id } = this.authService.getLocalUserData();
    this.productForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      discount: [0],
      available: [false],
      availableDelivery: [false],
      availableTakeAway: [false],
      categoryProductId: ['', Validators.required],
      storeId: [id],
      imageUrl: [''],
      attributesIds: [null],
      image: [''],
      deliveryPrice: [0],
      tablePrice: [0],
      takeAwayPrice: [0],
    });

    this.categoryForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      storeId: [JSON.parse(localStorage.getItem('storeData')).id],
    });
  }

  ngOnInit(): void {
    if (this.hideLateralMenu) {
      this.showMenu = false;
    }
    this.products = [];
    this.comercio = JSON.parse(localStorage.getItem('storeData'));

    const init = true;
    this.loading = true;
    this.spinnerService.loadSpinner.next(true);

    this.getData(init);
  }

  getData(init: boolean): void {
    forkJoin({
      products: this.productService.getProducts(this.comercio.id),
      categories: this.categoriesService.getCategories(this.comercio.id),
      attributes: this.atrributeSrv.getAttributes(),
    }).subscribe((res) => {
      this.products = res.products;
      this.categories = res.categories;
      this.attributes = res.attributes;
      this.loading = false;
      this.spinnerService.loadSpinner.next(false);
      if (!init) {
        this.createMenu();
      }
    });
  }

  hideCategoryDialog(event?): void {
    this.showNewCategory = false;
  }

  openNewCategory(): void {
    this.showNewCategory = true;
  }

  get nameLabel(): AbstractControl {
    return this.productForm.get('name');
  }

  get descriptionLabel(): AbstractControl {
    return this.productForm.get('description');
  }

  loadProducts(init?): void {
    this.spinnerService.loadSpinner.next(true);
    this.subs.push(
      this.productService.getProducts(this.comercio.id).subscribe((data) => {
        this.products = data;
        this.loading = false;
        this.spinnerService.loadSpinner.next(false);
        if (!init) {
          this.createMenu();
        }
      })
    );
  }

  loadCategories(event?): void {
    this.subs.push(
      this.categoriesService
        .getCategories(this.comercio.id)
        .subscribe((data) => {
          this.categories = data;
        })
    );
  }

  loadAttributes(): void {
    this.subs.push(
      this.atrributeSrv.getAttributes().subscribe((data) => {
        this.attributes = data;
      })
    );
  }

  getSelectedCategoryName(categoryId): string {
    const categoria = this.categories.find((c) => c.id === categoryId);
    return categoria.name;
  }

  getStockClass(disponible: boolean): 'enstock' | 'sinstock' {
    return disponible ? 'enstock' : 'sinstock';
  }

  getSafeImage(base64Img): string {
    return `data:image/jpg;base64,${
      (this.sanitizer.bypassSecurityTrustResourceUrl(base64Img) as any)
        .changingThisBreaksApplicationSecurity
    }`;
  }

  getUrlImage(url: string): string {
    return `${environment.mediaUrl}${url}`;
  }

  openNew(): void {
    this.selectedAttributes = [];
    this.productForm.reset();
    this.headerPopup = 'Nuevo producto';
    this.submitted = false;
    this.isEditting = false;
    this.productDialog = true;
    this.titleLoadImage = 'Cargar imagen';
    this.selectedCategory = this.categoryEmpty;
    this.getData(false);
  }

  createMenu(): void {
    const sections: Section[] = [];
    this.categories.forEach((category) => {
      const relatedProducts: Product[] = this.products.filter((p) => {
        return p.categoryProductId === category.id;
      });
      const newSection: Section = {
        products: relatedProducts,
        name: category.name,
        description: category.description,
      };
      sections.push(newSection);
    });
    this.newMenuData = { sections };
  }

  onChangeCheck(e): void {}

  deleteSelectedProducts(): void {
    this.confirmationService.confirm({
      message:
        '¿Deseas eliminar los productos seleccionados?  \n <span class="subheader">Esta acción no se puede deshacer<span>',
      header: '',
      icon: 'icon-warning icon-4xl icon-yellow',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        this.spinnerService.loadSpinner.next(true);
        const allDeleteRequests = [];
        for (const product of this.selectedProducts) {
          allDeleteRequests.push(this.productService.deleteProduct(product.id));
        }
        this.subs.push(
          forkJoin(allDeleteRequests).subscribe(
            () => {
              this.loadProducts();
              this.selectedProducts = [];
            },
            (err) => {
              if (err.status === 404 || err.status === 400) {
                this.handleErrorMessage(err.status);
              }
            }
          )
        );
      },
    });
  }

  downloadPDF(): void {
    this.menuService.getMenu().subscribe((menu) => {
      const url = environment.mediaUrl + menu.menuFile;
      const anchor = document.createElement('a');
      anchor.download = 'menu ' + new Date() + '.pdf';
      anchor.href = url;
      anchor.click();
    });
  }

  removeImage(): void {
    this.productImageSelected = null;
    this.productForm.controls.image.patchValue(null);
    this.productForm.controls.imageUrl.patchValue(null);
  }

  onRemoveThumbnail(e): void {
    this.productImageSelected = null;
    this.productForm.controls.image.patchValue(null);
  }

  private handleErrorMessage(status): void {
    let message: string;
    switch (status) {
      case 404:
        message = 'Orden inexistente.';
        break;
      case 400:
        message = 'Orden invalida';
        break;
    }
    this.errorService.updateShowError({
      showError: true,
      message,
    });
  }

  changeImage(): void {
    this.imgCroppChild.changeImage();
  }

  editProduct(product: Product): void {
    this.productSelected = product;
    this.productImageSelected = product.imageUrl;
    this.originalProductBackUp = product;
    this.currentImage = this.getUrlImage(product.imageUrl);
    this.selectedAttributes = [];
    this.selectedAttributesIds = [];
    this.headerPopup = product.name;
    this.editId = product.id;
    this.isEditting = true;
    this.selectedCategory = this.categories.find(
      (c) => c.id === product.categoryProductId
    );
    if (this.productImageSelected !== null) {
      this.titleLoadImage = 'Cambiar imagen';
    } else {
      this.titleLoadImage = 'Cargar imagen';
    }

    if (product.attributesIds) {
      product.attributesIds.forEach((element) => {
        const exist = this.attributes.find((c) => c.id === element);
        if (exist) {
          this.selectedAttributes.push(exist);
          this.selectedAttributesIds.push(exist.id);
        }
      });
    }

    const {
      name,
      description,
      discount,
      available,
      categoryProductId,
      storeId,
      imageUrl,
      image,
      attributesIds,
      deliveryPrice,
      tablePrice,
      takeAwayPrice,
      availableDelivery,
      availableTakeAway,
    } = product;
    this.productForm.patchValue({
      name,
      description,
      discount,
      available,
      categoryProductId,
      attributesIds,
      storeId,
      imageUrl,
      image,
      deliveryPrice,
      tablePrice,
      takeAwayPrice,
      availableDelivery,
      availableTakeAway,
    });
    this.submitted = false;
    this.productDialog = true;
  }

  selectAttribute($event): void {
    this.selectedAttributesIds = [];
    $event.value.forEach((element) => {
      this.selectedAttributesIds.push(element.id);
    });
  }

  onImageHasBeenSelected(e): void {}

  onChange($event): void {
    this.selectedCategory = $event.value;
    if (this.selectedCategory === null || this.selectedCategory === undefined) {
      this.selectedCategory = this.categoryEmpty;
    }
  }

  deleteProduct(): void {
    this.productDialog = false;
    this.confirmationService.confirm({
      message:
        '<span>¿Deseas eliminar <span class="text-primary">' +
        this.productSelected.name +
        '</span> de tu menú?</span> \n <span class="subheader">Esta acción no se puede deshacer<span>',
      header: '',
      icon: 'icon-warning icon-4xl icon-yellow',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        this.spinnerService.loadSpinner.next(true);
        this.subs.push(
          this.productService.deleteProduct(this.productSelected.id).subscribe(
            () => {
              this.loadProducts();
              this.selectedProducts = [];
              this.productDialog = false;
              this.spinnerService.loadSpinner.next(false);
            },
            (err) => {
              if (err.status === 404 || err.status === 400) {
                this.handleErrorMessage(err.status);
                this.spinnerService.loadSpinner.next(false);
              }
            }
          )
        );
      },
    });
  }

  getValidAttributes(attributes: Attribute[]): Attribute[] {
    return attributes.filter((a) => a.attributeValues.length);
  }

  hideDialog(): void {
    this.selectedAttributes = [];
    this.productDialog = false;
    this.submitted = false;
    this.formHasBeenSubmitted = false;
    this.isEditting = false;
    this.showNewCategory = false;
    this.selectedCategory = null;
  }

  submitProduct(): void {
    this.submitted = true;
    this.formHasBeenSubmitted = true;
    if (!this.productForm.invalid) {
      this.spinnerService.loadSpinner.next(true);

      if (this.productForm.controls.discount.value === null) {
        this.productForm.controls.discount.patchValue(0);
      }

      this.productForm.patchValue({
        categoryProductId: this.selectedCategory.id,
        attributesIds: this.selectedAttributesIds,
      });
      if (!this.isEditting) {
        this.subs.push(
          this.productService
            .createProduct(this.productForm.value)
            .subscribe((id) => {
              this.loadProducts();
              this.hideDialog();
              this.spinnerService.loadSpinner.next(false);
            })
        );
      } else {
        this.subs.push(
          this.productService
            .editProduct(this.editId, this.productForm.value)
            .subscribe(
              (id) => {
                this.loadProducts();
                this.hideDialog();
                this.isEditting = false;
              },
              (err) => {
                if (err.status === 404 || err.status === 400) {
                  this.handleErrorMessage(err.status);
                }
              }
            )
        );
      }
    }
    this.createMenu();
    this.productSelected = null;
  }

  async onUpload(event): Promise<void> {
    this.uploadedFiles = [];
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
    const logoData = await new Blob([this.uploadedFiles[0]], {
      type: this.uploadedFiles[0].type,
    }).arrayBuffer();
    const logoArr = new Uint8Array(logoData);
    const byteArr = Array.from(logoArr);

    this.productForm.patchValue({
      image: byteArr,
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => {
      s.unsubscribe();
    });
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }
}
