import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ChartsService } from 'src/app/services/charts.service';

@Component({
  selector: 'app-right-graphic',
  templateUrl: './right-graphic.component.html',
  styleUrls: ['./right-graphic.component.scss']
})
export class RightGraphicComponent implements OnInit {

  @Input() dataColumns;
  @Input() stats;
  @Input() checked;
  @Input() typeWeek;

  dateLastWeek: string;
  dateWeek: string;
  dateWeekStart: string;
  dateToday: string;

  constructor(private chartService: ChartsService) { }

  ngOnInit(): void {
    this.dateLastWeek = this.chartService.dateLastWeek();
    this.dateWeek = this.chartService.dateWeek();
    this.dateWeekStart = this.chartService.dateWeekStart();
    this.dateToday = this.chartService.dateToday();
  }
}
