import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Admin} from '../core/models/Admin';
import {LoginForm} from '../core/models/LoginForm';
import {StoreData} from '../core/models/StoreData';

const roleAdmin = 'ROLE_ADMIN';
const roleSuperAdmin = 'ROLE_SUPER_ADMIN';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private canLogout = true;
  userIsLoggedIn$ = new Subject<boolean>();

  private userSubject: BehaviorSubject<StoreData> = new BehaviorSubject(
    new StoreData()
  );
  public readonly user: Observable<StoreData> = this.userSubject.asObservable();

  constructor(private http: HttpClient) {
  }

  public login(authIn: LoginForm): Observable<Admin> {
    return this.http.post<any>(`${environment.apiAGUrl}/admin/login`, authIn);
  }

  setCanLogout(state: boolean): void {
    this.canLogout = state;
  }

  getCanLogout(): boolean {
    return this.canLogout;
  }

  public isAdminRole(): boolean {
    return localStorage.getItem('role') === roleAdmin;
  }

  public isSuperAdminRole(): boolean {
    return localStorage.getItem('role') === roleSuperAdmin;
  }

  updateUser(user: StoreData): void {
    localStorage.setItem('storeData', JSON.stringify(user));
    this.userSubject.next(user);
  }

  getLocalUserData(): StoreData {
    return this.userSubject.value;
  }

  public getCurrentUser(): Observable<StoreData> {
    const {id} = this.getLocalUserData();
    return this.http.get<StoreData>(`${environment.apiAGUrl}/stores/by_id/${id}`);
  }

  /*public uploadEventImage(request: ImageUploadRequest): Observable<ImageUploadResponse> {
    return this.http.post<ImageUploadResponse>(environment.apiAGUrl + `/store/upload`, request);
  }*/
}
