import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {CategoryEventResponse} from '../core/models/CategoryEventResponse ';
import {EventForStore} from '../core/models/EventForStore';
import {Pageable} from '../core/models/Pageable';
import {VerifyEventRequest} from '../core/models/VerifyEventRequest';
import {ToggleBodyRequest} from '../core/models/ToggleBodyRequest';
import {EventComplete} from '../core/models/EventComplete';
import {GuestForSpreadSheetResponse} from '../core/models/GuestForSpreadSheetResponse';
import {EventSalesStaticsResponse} from '../core/models/events/EventSalesStaticsResponse';
import {EventRescheduleRequest} from '../core/models/events/EventRescheduleRequest';

@Injectable({
  providedIn: 'root',
})
export class EventsService {

  resetNavigate = new BehaviorSubject(null);

  constructor(private httpC: HttpClient) {
  }

  IsEventOver(event: EventComplete): boolean {
    return new Date(event.dateEnd) < new Date();
  }

  public getAllEvents(tenantId: number,
                      pageNumber?: number,
                      pageSize?: number,
                      multiSortFields?: any[],
                      globalFilter?: string
  ): Observable<Pageable> {
    const httpHeaders = new HttpHeaders().set('Accept', 'application/json');
    let httpParams = new HttpParams();
    let sortFields = {sortFieldsWithOrder: []};
    if (!multiSortFields || multiSortFields.length < 1) {
      const initialSortField = 'dateInit:DESC';
      sortFields.sortFieldsWithOrder.push(`${initialSortField}`);
    } else {
      let i = 0;
      while (i < multiSortFields.length) {
        let nameField = multiSortFields[i]['name'];
        let order = multiSortFields[i]['order'] > 0 ? 'ASC' : 'DESC';
        sortFields.sortFieldsWithOrder.push(`${nameField}:${order}`);
        i++;
      }
    }
    const pageNro = pageNumber ? pageNumber : 0;
    httpParams = httpParams.set('pageNumber', pageNro.toString());
    httpParams = httpParams.set('size', pageSize);
    httpParams = httpParams.set('sortFields', sortFields.sortFieldsWithOrder.join(','));
    httpParams = httpParams.set('search', globalFilter);
    return this.httpC.get<Pageable>(
      environment.billboardAGUrl + `/cartelera/manage-events/all/${tenantId}`,
      {headers: httpHeaders, params: httpParams}
    );
  }

  public verifyEvent(
    idEvent: number,
    verifyEventRequest: VerifyEventRequest
  ): Observable<void> {
    return this.httpC.post<void>(
      environment.billboardAGUrl + `/cartelera/manage-events/${idEvent}`,
      verifyEventRequest
    );
  }

  public setCategoryEvent(
    idEvent: number,
    categoryEvent: CategoryEventResponse
  ): Observable<void> {
    return this.httpC.post<void>(
      environment.billboardAGUrl +
      `/cartelera/categories-events/categories/${idEvent}`,
      categoryEvent
    );
  }

  public getEventById(eventId: number): Observable<EventForStore> {
    return this.httpC.get<EventForStore>(environment.billboardAGUrl + `/cartelera/events/by_id/${eventId}`);
  }


  public getEventCompleteById(eventId: number): Observable<EventComplete> {
    return this.httpC.get<EventComplete>(environment.billboardAGUrl + `/cartelera/manage-events/by_id/${eventId}`);
  }

  public getOldEvents(idStore: number): Observable<EventForStore[]> {
    return this.httpC.get<EventForStore[]>(environment.billboardAGUrl + `/cartelera/events/finished/${idStore}`);
  }

  public checkBanner(payload: ToggleBodyRequest, idEvent: number): Observable<void> {
    return this.httpC.post<void>(environment.billboardAGUrl + `/cartelera/manage-events/main_event/${idEvent}`, payload);
  }

  public allowResale(payload: ToggleBodyRequest, idEvent: number): Observable<void> {
    return this.httpC.patch<void>(environment.billboardAGUrl + `/cartelera/manage-events/allow_resale/${idEvent}`, payload);
  }

  public settle(payload: ToggleBodyRequest, idEvent: number): Observable<void> {
    return this.httpC.post<void>(environment.billboardAGUrl + `/cartelera/manage-events/main_event/${idEvent}`, payload);
  }

  public getGuestsForSpreadsheet(idEvent: number): Observable<GuestForSpreadSheetResponse[]> {
    return this.httpC.get<GuestForSpreadSheetResponse[]>(environment.billboardAGUrl + `/cartelera/manage-events/event-view/guests-spreadsheet/${idEvent}`);
  }

  public editEvent(eventId: number, payload): Observable<void> {
    return this.httpC.patch<void>(environment.billboardAGUrl + `/cartelera/events/${eventId}`, payload);
  }

  public cancelEvent(idEvent: number): Observable<void> {
    return this.httpC.delete<void>(environment.billboardAGUrl + `/cartelera/manage-events/cancel_event/${idEvent}`);
  }

  public liquidateEvent(idEvent: number): Observable<void> {
    return this.httpC.delete<void>(environment.billboardAGUrl + `/cartelera/manage-events/liquidate_event/${idEvent}`);
  }

  public getEventStatics(idEvent: number): Observable<EventSalesStaticsResponse> {
    return this.httpC.get<EventSalesStaticsResponse>(environment.billboardAGUrl + `/cartelera/manage-events/event-view/sales-statics/${idEvent}`);
  }

  public rescheduleEvent(idEvent: number, payload: EventRescheduleRequest): Observable<void> {
    return this.httpC.patch<void>(environment.billboardAGUrl + `/cartelera/manage-events/reschedule-event/${idEvent}`, payload);
  }

}
