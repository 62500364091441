import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';

import QRCodeStyling from 'qr-code-styling';

@Component({
  selector: 'app-show-qr',
  templateUrl: './show-qr.component.html',
  styleUrls: ['./show-qr.component.scss'],
})
export class ShowQrComponent implements OnInit {
  @ViewChild('qr') qr: ElementRef;

  @Input() qrDataString: string;
  @Input() type: string;
  @Input() sizeClass: string = '';
  @Input() headerTitle: string = 'QR';
  @Input() leyenda: string = '';
  qrDialog: boolean = false;

  qrCode;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.qrCode = new QRCodeStyling({
      width: 220,
      height: 220,
      data: this.qrDataString,
      image: '../../assets/logo-qr-short.png',
      qrOptions: {
        typeNumber: 0,
        mode: 'Byte',
        errorCorrectionLevel: 'Q',
      },
      imageOptions: {
        hideBackgroundDots: true,
        imageSize: 0.6,
        margin: 4,
      },
      dotsOptions: {
        type: 'dots',
        color: '#000000',
      },
      backgroundOptions: {
        color: '#ffffff',
      },
      cornersSquareOptions: {
        type: 'extra-rounded',
        color: '#000000',
      },
      cornersDotOptions: {
        type: 'dot',
        color: '#000000',
      },
    });
  }

  displayQR(): void {
    this.qrDialog = true;
    setTimeout(() => {
      this.qrCode.append(document.getElementById(this.type));
    }, 0);
  }

  hideQrDialog(): void {
    this.qrDialog = false;
  }

  printQR(): void {
    const html: any = document.querySelector('.qr-custom-code').firstChild;
    html.toBlob((blob) => {
      const contentBlobUrl = URL.createObjectURL(blob);
      const ifrm = document.createElement('iframe');
      ifrm.setAttribute('src', contentBlobUrl);
      ifrm.style.marginTop = '30px';
      ifrm.style.marginLeft = '30px';
      ifrm.style.width = '600px';
      ifrm.style.height = '400px';
      ifrm.style.display = 'none';
      document.body.appendChild(ifrm);
      ifrm.onload = () => {
        ifrm.contentWindow.print();
        this.qrDialog = false;
      };
    });
  }
}
