<section class="container__sales">
  <header class="header__sales">
    <div class="flex align-items-center justify-content-start">
      <i class="fa-light fa-laptop-mobile text-xl text-gray-1 margin-right-16"></i>
      <p class="font-inter text-xl font-semibold text-gray-1 margin-y-0 uppercase">Canal de venta</p>
    </div>
    <div class="alert__sales" *ngIf="paymentProviders?.length === 0">
      <i class="fa-light fa-circle-info text-yellow margin-right-4"></i>
      <p class="text">Configurá las plataformas de pago desde la pestaña</p>
      <a class="link" (click)="configPlatform()">Plataforma</a>
    </div>
    <div class="alert__sales"
         *ngIf="paymentProviders?.length > 0 && (!someAvailableOnWeb() || !someAvailableOnMobile())">
      <i class="fa-light fa-circle-info text-yellow  margin-right-4"></i>
      <p class="text" *ngIf="!someAvailableOnWeb() && someAvailableOnMobile()">No hay Plataforma de Pago seleccionada
        para Cartelera Web.</p>
      <p class="text" *ngIf="!someAvailableOnMobile() && someAvailableOnWeb()">No hay Plataforma de Pago seleccionada
        para Cartelera App.</p>
      <p class="text" *ngIf="!someAvailableOnWeb() && !someAvailableOnMobile()">No hay Plataforma de Pago seleccionada
        para Cartelera Web y App.</p>
    </div>
  </header>
  <div class="content__sales">
    <div class="card__sales margin-right-12">
      <div class="card__header">
        <div class="flex align-items-center justify-content-start">
          <i class="fa-light fa-desktop"></i>
          <p class="text-base font-semibold font-poppins lh-24 text-gray-1 margin-y-0">Cartelera Web</p>
        </div>
        <div class="flex align-items-center justify-content-end">
          <div class="tag">DESKTOP</div>
          <div class="tag">MOBILE</div>
        </div>
      </div>
      <div class="card__body">
        <div class="flex flex-column align-items-center justify-content-center  margin-right-8  margin-bottom-16 payment-card">
          <div class="btn__payment"
               [ngClass]="[!mobbexWeb ? 'off' : 'on', (mpWeb || pagoUnoWeb || stripeWeb) || !mobbexAvailable ? 'disabled' : '']">
            <img src="../../../../../../../../../../assets/mobbex.svg" class="logo__mobbex"/>
          </div>
          <p-inputSwitch (onChange)="setMobbexForWeb($event)" value="mobbexWeb"
                         [disabled]="(mpWeb || pagoUnoWeb || stripeWeb) || !mobbexAvailable" [ngModel]="mobbexWeb"></p-inputSwitch>
        </div>
        <div class="flex flex-column align-items-center justify-content-center  margin-right-8  margin-bottom-16 payment-card">
          <div class="btn__payment"
               [ngClass]="[!mpWeb ? 'off' : 'on', (mobbexWeb || pagoUnoWeb|| stripeWeb) || !mpAvailable ? 'disabled' : '']">
            <img src="../../../../../../../../../../assets/mp.svg" class="logo__mp"/>
          </div>
          <p-inputSwitch (onChange)="setMercadoPagoForWeb($event)" value="mpWeb"
                         [disabled]="(mobbexWeb || pagoUnoWeb || stripeWeb) || !mpAvailable"
                         [ngModel]="mpWeb"></p-inputSwitch>
        </div>
        <div class="flex flex-column align-items-center justify-content-center  margin-right-8  margin-bottom-16 payment-card">
          <div class="btn__payment disabled"
               [ngClass]="[!pagoUnoWeb ? 'off' : 'on', (mobbexWeb || mpWeb || stripeWeb) || !pagoUnoAvailable ? 'disabled' : '']">
            <img src="../../../../../../../../../../assets/pagouno.svg" class="logo__pagouno"/>
          </div>
          <p-inputSwitch (onChange)="setPagoUnoForWeb($event)" value="pagounoWeb"
                         [disabled]="(mobbexWeb || mpWeb || stripeWeb) || !pagoUnoAvailable || true"
                         [ngModel]="pagoUnoWeb"></p-inputSwitch>
        </div>
        <div class="flex flex-column align-items-center justify-content-center  margin-right-8  margin-bottom-16 payment-card">
          <div class="btn__payment"
               [ngClass]="[!stripeWeb ? 'off' : 'on', (mobbexWeb || mpWeb || pagoUnoWeb) || !stripeAvailable ? 'disabled' : '']">
            <img src="../../../../../../../../../../assets/stripe.png" class="logo__stripe"/>
          </div>
          <p-inputSwitch (onChange)="setStripeForWeb($event)" value="stripeWeb"
                         [disabled]="(mobbexWeb || mpWeb || pagoUnoWeb) || !stripeAvailable"
                         [ngModel]="stripeWeb"></p-inputSwitch>
        </div>
      </div>
    </div>
    <div class="card__sales margin-left-12">
      <div class="card__header">
        <div class="flex align-items-center justify-content-start">
          <i class="fa-light fa-mobile text-gray-1"></i>
          <p class="text-base font-semibold font-poppins lh-24 text-gray-1 margin-y-0">Cartelera App</p>
        </div>
        <div class="flex align-items-center justify-content-end">
          <div class="tag">MOBILE</div>
        </div>
      </div>
      <div class="card__body">
        <div class="flex flex-column align-items-center justify-content-center  margin-right-8  margin-bottom-16 payment-card">
          <div class="btn__payment disabled"
               [ngClass]="[!mobbexMobile ? 'off' : 'on', (mpMobile || pagoUnoMobile || stripeMobile) || !mobbexAvailable ? 'disabled' : '']">
            <img src="../../../../../../../../../../assets/mobbex.svg" class="logo__mobbex"/>
          </div>
          <p-inputSwitch (onChange)="setMobbexForMobile($event)" value="mobbexMobile"
                         [disabled]="(mpMobile || pagoUnoMobile || stripeMobile) || !mobbexAvailable || true"
                         [ngModel]="mobbexMobile"></p-inputSwitch>
        </div>
        <div class="flex flex-column align-items-center justify-content-center  margin-right-8  margin-bottom-16 payment-card">
          <div class="btn__payment"
               [ngClass]="[!mpMobile ? 'off' : 'on', (mobbexMobile || pagoUnoMobile || stripeMobile) || !mpAvailable ? 'disabled' : '']">
            <img src="../../../../../../../../../../assets/mp.svg" class="logo__mp"/>
          </div>
          <p-inputSwitch (onChange)="setMercadoPagoForMobile($event)" value="mpMobile"
                         [disabled]="(mobbexMobile || pagoUnoMobile || stripeMobile) || !mpAvailable"
                         [ngModel]="mpMobile"></p-inputSwitch>
        </div>
        <div class="flex flex-column align-items-center justify-content-center  margin-right-8  margin-bottom-16 payment-card">
          <div class="btn__payment"
               [ngClass]="[!pagoUnoMobile ? 'off' : 'on', (mobbexMobile || mpMobile || stripeMobile) || !pagoUnoAvailable ? 'disabled' : '']">
            <img src="../../../../../../../../../../assets/pagouno.svg" class="logo__pagouno"/>
          </div>
          <p-inputSwitch (onChange)="setPagoUnoForMobile($event)" value="pagounoMobile"
                         [disabled]="(mobbexMobile || mpMobile || stripeMobile) || !pagoUnoAvailable"
                         [ngModel]="pagoUnoMobile"></p-inputSwitch>
        </div>
        <div class="flex flex-column align-items-center justify-content-center  margin-right-8  margin-bottom-16 payment-card">
          <div class="btn__payment"
               [ngClass]="[!stripeMobile ? 'off' : 'on', (mobbexMobile || mpMobile || pagoUnoMobile) || !stripeAvailable ? 'disabled' : '']">
            <img src="../../../../../../../../../../assets/stripe.png" class="logo__stripe"/>
          </div>
          <p-inputSwitch (onChange)="setStripeForMobile($event)" value="stripeMobile"
                         [disabled]="(mobbexMobile || mpMobile || pagoUnoMobile) || !stripeAvailable"
                         [ngModel]="stripeMobile"></p-inputSwitch>
        </div>
      </div>
    </div>
  </div>
</section>

<app-modal [config]="modalConfig" [widthModal]="'450px'" (modalResponse)="onModalResponse($event)" [showModal]="showModalConfirm">
</app-modal>
