import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SecretData} from '../core/models/SecretData';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MobbexCredentialRequest} from '../core/models/MobbexCredentialRequest';
import {MercadoPagoCredentialRequest} from '../core/models/MercadoPagoCredentialRequest';
import {PaymentProviders} from '../core/models/PaymentProviders';
import {SetProviderForChannelRequest} from '../core/models/SetProviderForChannelRequest';
import {CredentialDto} from "../core/models/CredentialDto";
import { StripeCredentialRequest } from '../core/models/StripeCredentialRequest';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private httpC: HttpClient
  ) {
  }

  public setStorePagoUnoSecrets(commerceId: number, secretData: SecretData): Observable<void> {
    return this.httpC.put<void>(
      environment.apiAGUrl + `/payments/provider/pg/store/${commerceId}`,
      secretData
    );
  }

  public getStorePagoUnoSecrets(commerceId: number): Observable<SecretData> {
    return this.httpC.get<SecretData>(
      environment.apiAGUrl + `/payments/provider/pg/store/${commerceId}`
    );
  }

  public setStoreCuitSecrets(storeId: number, mobbexCredentialRequest: MobbexCredentialRequest): Observable<void> {
    return this.httpC.put<void>(
      environment.apiAGUrl + `/payments/provider/mobbex/store/${storeId}`,
      mobbexCredentialRequest
    );
  }

  public getStoreCuitSecrets(storeId: number): Observable<MobbexCredentialRequest> {
    return this.httpC.get<MobbexCredentialRequest>(
      environment.apiAGUrl + `/payments/provider/mobbex/store/${storeId}`
    );
  }

  public getStoreMPSecrets(storeId: number): Observable<MercadoPagoCredentialRequest> {
    return this.httpC.get<MercadoPagoCredentialRequest>(
      environment.apiAGUrl + `/payments/provider/mp/store/${storeId}`
    );
  }

  public setStoreMPSecrets(storeId: number, payload: MercadoPagoCredentialRequest): Observable<void> {
    return this.httpC.put<void>(
      environment.apiAGUrl + `/payments/provider/mp/store/${storeId}`,
      payload
    );
  }

  public getStripeCredentials(storeId: number): Observable<StripeCredentialRequest> {
    return this.httpC.get<StripeCredentialRequest>(
      environment.apiAGUrl + `/payments/provider/stripe/store/${storeId}`
    );
  }

  public setStripeCredentials(storeId: number, payload: StripeCredentialRequest ) {  
    return this.httpC.put<void>(
      environment.apiAGUrl + `/payments/provider/stripe/store/${storeId}`,
      payload
    );
  }

  public getPaymentProvidersConfiguration(storeId: number): Observable<PaymentProviders[]> {
    return this.httpC.get<PaymentProviders[]>(
      environment.apiAGUrl + `/payments/provider/store/${storeId}`
    );
  }

  public setPaymentProviderForMobile(storeId: number, setProviderForChannelRequest: SetProviderForChannelRequest): Observable<void> {
    return this.httpC.patch<void>(
      environment.apiAGUrl + `/payments/provider/mobile/store/${storeId}`, setProviderForChannelRequest
    );
  }

  public setPaymentProviderForWeb(storeId: number, setProviderForChannelRequest: SetProviderForChannelRequest): Observable<void> {
    return this.httpC.patch<void>(
      environment.apiAGUrl + `/payments/provider/web/store/${storeId}`, setProviderForChannelRequest
    );
  }

  public getPaymentProvidersCollectors(storeId: number, provider: string): Observable<CredentialDto[]> {
    return this.httpC.get<CredentialDto[]>(
      environment.apiAGUrl + `/payments/provider/collector/${storeId}/${provider}`
    );
  }
}
