<section class="margin-top-16 transatcions-container">
    <app-card-total-stats class="p-col card-total-stats margin-right-16" [icon]="'fa-regular fa-money-bill-transfer'"
                          [description]="'Total transacciones'"
                          [value]="stats?.totalTransactions"
                          [class]="'justify-content-between'"
                          ></app-card-total-stats>
    <app-card-total-stats class="p-col card-total-stats" [icon]="'fa-regular fa-badge-dollar'"
                          [description]="'Monto total'" [prefixValue]="'$'"
                          [value]="stats?.totalAmount"
                          [valueClass]="'text-success'"
                          [class]="'justify-content-between'"
                          ></app-card-total-stats>
  </section>

  <section class="tickets-table-container margin-top-48">
    <div class="column">
      <span class="row-title margin-bottom-4"><i class="fa-solid fa-ticket margin-right-8"></i>Tickets</span>
      <app-card-dual-stats [elements]="ticketsData"></app-card-dual-stats>
    </div>
    <div class="column margin-left-16">
      <span class="row-title margin-bottom-4"><i class="fa-solid fa-champagne-glasses margin-right-8"></i>Combos / Mesas</span>
      <app-card-dual-stats [elements]="tablesData"></app-card-dual-stats>
    </div>
  </section>
