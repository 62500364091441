<div class="save-fixed">
    <div class="save-fixed__container">
      <div  class="save-fixed__label">
        <i class="fa-solid fa-triangle-exclamation text-warning"></i><span>Tenés cambios sin guardar</span>
      </div>
      <div class="save-fixed__buttons">
        <button pButton class="btn btn__secondary btn__secondary--bold btn__size--sm mr-3" (click)="onCancel()" label="No guardar"></button>
        <button pButton class="btn btn__primary btn__size--sm" (click)="onSave()" label="Guardar cambios"></button>
      </div>
    </div>
  </div>