import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AuthService} from 'src/app/services/auth.service';
import {ChangeEmailForm} from 'src/app/core/models/ChangeEmailForm';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {ModalConfig} from 'src/app/core/models/ModalConfig';
import {SpinnerService} from 'src/app/services/spinner.service';
import {StoreService} from 'src/app/services/store.service';
import {Subscription} from 'rxjs';
import {UserService} from 'src/app/services/user.service';
import {UpdateCustomerEmail} from "../../../../../core/models/UpdateCustomerEmail";

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss'],
  providers: [DialogService]
})
export class ChangeEmailComponent implements OnInit, OnDestroy {
  id: number;
  name: string;
  email: string;
  isStore: boolean;
  changeEmailForm: UntypedFormGroup;
  submitting = false;
  changeEmailDetails: object[] = [];
  confirmDialog: boolean;
  modalConfig: ModalConfig;
  showModalConfirm = false;
  changeEmailSub: Subscription = null;
  isSuperAdmin: boolean;
  isReader: boolean;
  isWriter: boolean;
  formHasBeenSubmitted: boolean = false;
  emailError = false;
  emailErrorText: string;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: UntypedFormBuilder,
    private spinnerService: SpinnerService,
    private authSrv: AuthService,
    private userSrv: UserService,
    private storeSrv: StoreService,
    private validateInputService: InputValidationService
  ) {
    this.changeEmailForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      newEmail: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
    });
  }

  ngOnInit(): void {
    this.id = this.config.data.id;
    this.name = this.config.data.name;
    this.email = this.config.data.email;
    this.isStore = this.config.data.isStore;
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    this.isReader = this.userSrv.isReader();
    this.isWriter = this.userSrv.isWriter();
    this.loadData();
    this.checkIsWriter();

    this.changeEmailForm.controls.newEmail.valueChanges.subscribe(() => {
      this.emailError = false;
      this.emailErrorText = '';
      this.formHasBeenSubmitted = false;
    })
  }

  ngOnDestroy(): void {
    if (this.changeEmailSub) {
      this.changeEmailSub.unsubscribe();
    }
  }

  checkIsWriter(): void {
    if (!this.isWriter) {
      this.changeEmailForm.controls.newEmail.disable();
    }
  }

  loadData(): void {
    this.changeEmailForm.patchValue({
      name: this.name,
      email: this.email,
      newEmail: null,
    });
  }

  confirmNewEmail(): void {
    this.changeEmailDetails = [];
    Object.keys(this.changeEmailForm.controls).forEach((key: string) => {
      this.changeEmailDetails.push({
        key,
        value: this.changeEmailForm.controls[key].value,
      });
    });
    this.confirmDialog = true;
  }

  onClose(): void {
    this.ref.close(false);
  }

  submitChange(): void {
    this.formHasBeenSubmitted = true;
    this.confirmDialog = false;
    this.submitting = true;
    this.spinnerService.loadSpinner.next(true);
    if (this.isStore) {
      this.preparePayloadStoreAndSend();
    } else {
      this.preparePayloadCustomerAndSend();
    }
  }

  preparePayloadStoreAndSend() {
    const form: ChangeEmailForm = {
      idStore: this.id,
      newEmail: this.changeEmailForm.get('newEmail').value === '' ? this.email : this.changeEmailForm.get('newEmail').value
    };

    if (this.validForm()) {
      this.storeSrv.changeEmail(form).subscribe({
        next: () => {
          this.spinnerService.loadSpinner.next(false);
          this.ref.close(true);
          window.location.reload();
        },
        error: (err) => {
          this.emailError = true;
          if (err.status === 409) {
            this.emailErrorText =
              'El nuevo email ingresado es igual al actual.';
          } else if (err.status === 400) {
            this.emailErrorText =
              'Ya existe una cuenta registrada con el email ingresado.';
          } else {
            this.emailErrorText =
              'Error al intentar cambiar el email, vuelva a intentarlo.';
          }
          this.changeEmailForm.controls['newEmail'].setErrors({incorrect: true});

          this.submitting = false;
          this.spinnerService.loadSpinner.next(false);
        }
      });
    } else {
      this.spinnerService.loadSpinner.next(false);
      this.emailError = true;

      this.changeEmailForm.controls['newEmail'].setErrors({incorrect: true});
      this.emailErrorText = 'El nuevo email debe ser diferente del actual.';
      this.submitting = false;
    }
  }

  preparePayloadCustomerAndSend() {
    const payload = new UpdateCustomerEmail();
    payload.idCustomer = this.id;
    payload.newEmail = this.changeEmailForm.get('newEmail').value === '' ? this.email : this.changeEmailForm.get('newEmail').value;

    if (this.validForm()) {
      this.userSrv.changeEmail(payload).subscribe({
        next: () => {
          this.spinnerService.loadSpinner.next(false);
          this.ref.close(true);
          // window.location.reload();
        },
        error: (err) => {
          this.emailError = true;
          if (err.status === 409) {
            this.emailErrorText =
              'El nuevo email ingresado es igual al actual.';
          } else if (err.status === 400) {
            this.emailErrorText =
              'Ya existe una cuenta registrada con el email ingresado.';
          } else {
            this.emailErrorText =
              'Error al intentar cambiar el email, vuelva a intentarlo.';
          }
          this.changeEmailForm.controls['newEmail'].setErrors({incorrect: true});

          this.submitting = false;
          this.spinnerService.loadSpinner.next(false);
        }
      });
    } else {
      this.spinnerService.loadSpinner.next(false);
      this.emailError = true;

      this.changeEmailForm.controls['newEmail'].setErrors({incorrect: true});
      this.emailErrorText = 'El nuevo email debe ser diferente del actual.';
      this.submitting = false;
    }
  }

  onModalResponse(e): void {
    this.showModalConfirm = false;
  }

  validForm(): boolean {
    const {email, newEmail} = this.changeEmailForm.controls;
    return email.value !== newEmail.value;
  }

  displayProperty(prop): boolean {
    const valid = ['email', 'newEmail'];
    return valid.includes(prop);
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }
}
