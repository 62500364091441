<div class="internal-body">
  <p-toast></p-toast>
  <div class="table__container">
    <p-table #dt [value]="attributes" [rows]="10" [paginator]="true" dataKey="name" sortField="name" sortOrder="1"
      [globalFilterFields]="[
        'name',
        'description',
        'createdAt',
        'valueName',
        'valueDescription'
      ]" rowExpandMode="single" [(selection)]="selectedAttributes" [rowHover]="true" [loading]="loading"
      [rowsPerPageOptions]="[10,25,50]" currentPageReportTemplate="Mostrar" [showCurrentPageReport]="true"
      class="table-custom">
      <ng-template pTemplate="caption">
        <div class="flex align-items-start md:align-items-center justify-content-between table-header first-line pb-3">
          <div class="flex justify-content-start align-items-end">
            <i class="fa-light fa-french-fries fa-2x"></i>
            <p class="my-0 text-xl ml-3">
              OPCIONALES
              <span class="ml-2 text-xs font-normal text-primary">
                {{ attributes ? attributes.length : 0 }} opcionales en total
              </span>
            </p>
          </div>
          <div class="flex mt-3 md:mt-0">
            <button pButton label="Agregar" icon="fa-regular fa-circle-plus fa-lg"
              class="btn btn__primary btn__primary--bold btn__size--xs mr-2" (click)="openNew()"
              [disabled]="canWrite !== undefined && !canWrite"></button>
            <button pButton label="Borrar" icon="fa-light fa-trash-can fa-lg" class="btn btn__secondary btn__size--xs"
              (click)="deleteSelectedAttributes()" [disabled]="
                !selectedAttributes ||
                !selectedAttributes.length ||
                (canWrite !== undefined && !canWrite)
              "></button>
          </div>
        </div>
        <div class="table-header pt-3 pb-0">
          <div class="flex align-items-start md:align-items-center">
            <p-tableHeaderCheckbox [disabled]="canWrite !== undefined && !canWrite"></p-tableHeaderCheckbox>
            <p class="text-sm font-normal text-gray-3 lh-16 my-0 mt-1 ml-2">
              Seleccionar todo
            </p>
          </div>
          <div class="search-container mt-3 md:mt-0">
            <div class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Buscar" />
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 1% !important; min-width: 10% !important">
          </th>
          <th pSortableColumn="name" style="width: 30%" class="text-xs lh-24 text-gray-2">
            Nombre
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="createdAt" style="width: 30%" class="text-xs lh-24 text-gray-2">
            Fecha creación
            <p-sortIcon field="createdAt"></p-sortIcon>
          </th>
          <th style="width: 25%"></th>
          <th style="width: 5%"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-attribute let-expanded="expanded">
        <tr class="table-row" [ngClass]="expanded ? 'expanded' : ''">
          <td>
            <p-tableCheckbox [value]="attribute" [disabled]="canWrite !== undefined && !canWrite">
            </p-tableCheckbox>
          </td>
          <td [pRowToggler]="attribute" class="text-sm lh-16 font-semibold text-gray-1">
            {{ attribute.name }}
            <span class="text-xs lh-16 font-normal text-primary ml-2">{{ attribute.attributeValues.length }}
              ítems</span>
          </td>
          <td [pRowToggler]="attribute" class="text-sm lh-16 font-normal text-gray-1">
            {{ attribute.createdAt | date: "dd/MM/yyyy HH:mm" }}
          </td>
          <td></td>
          <td>
            <div class="flex justify-content-end align-items-center">
              <div class="flex justify-content-end">
                <button pButton iconOnly class="mr-2 text-primary" icon="fa fa-light fa-plus-circle"
                  (click)="addAttributeValue(attribute)" [disabled]="canWrite !== undefined && !canWrite"></button>
                <button pButton iconOnly class="mr-2 text-primary" icon="fa fa-light fa-edit"
                  (click)="editAttribute(attribute)" [disabled]="canWrite !== undefined && !canWrite"></button>
                <button pButton iconOnly icon="fa fa-light fa-trash-alt" class="text-primary"
                  (click)="deleteSelectedAttributes(attribute)"
                  [disabled]="canWrite !== undefined && !canWrite"></button>
              </div>
              <div class="flex justify-content-end ml-4">
                <button type="button" pButton [pRowToggler]="attribute" class="p-button-text p-button-plain" [icon]="
                    expanded
                      ? 'fa fa-light fa-chevron-up'
                      : 'fa fa-light fa-chevron-down'
                  "></button>
              </div>
            </div>
          </td>
        </tr>
        <div class="my-2"></div>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-attribute #dt>
        <tr class="sub-table-container">
          <td colspan="7">
            <div class="col-12 sub-table-col">
              <app-attributes-value [canWrite]="canWrite" [attribute]="attribute"
                (editAttributeValueEmitter)="onEditAttributeValue($event)"
                (deleteAttributeValueEmitter)="onDeleteAttributeValue($event)"></app-attributes-value>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-dialog [(visible)]="attributeDialog" [style]="{ width: '450px' }" header="{{ headerPopup }}" [modal]="true" [draggable]="false">
    <ng-template pTemplate="content">
      <form class="w-100" [formGroup]="attributeForm" (ngSubmit)="submitAttribute()">
        <div class="field__item field--modal pt-3" [ngClass]="
            validateInputClass(attributeForm, 'name', formHasBeenSubmitted)
          ">
          <div class="p-float-label">
            <input formControlName="name" id="name" maxlength="{{ maxAttributeNameLength }}" type="text" pInputText />
            <label for="name" class="title-float">Nombre*</label>
            <app-show-errors [page]="'attributes'" [ctrl]="attributeForm.get('name')"
              [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
        </div>

        <div class="field__item field--modal" [ngClass]="
            validateInputClass(
              attributeForm,
              'description',
              formHasBeenSubmitted
            )
          ">
          <div class="p-float-label">
            <textarea pInputTextarea formControlName="description" id="description"
              maxlength="{{ maxAttributeDescriptionLength }}" type="text" cols="30" rows="3"></textarea>
            <label for="description" class="title-float">Descripción</label>
            <app-show-errors [page]="'attributes'" [ctrl]="attributeForm.get('description')"
              [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
          <div *ngIf="attributeForm.get('description').valid || attributeForm.get('description').value == null"
            class="field__item--info">
            <span>{{ descriptionField.value ? descriptionField.value.length : 0 }}/{{ maxAttributeDescriptionLength
              }}</span>
          </div>
        </div>

        <div class="field__item">
          <p class="config-options-title">Configuración de opcionales</p>
          <!--<p-radioButton name="attributeGroupName" label="Cantidad fija" value="FIXED" formControlName="attributeType">
          </p-radioButton>-->
          <div class="radio-item">
            <div class="radio-container">
              <input type="radio" id="fixed" name="attributeType" value="FIXED" formControlName="attributeType">
              <label for="fixed">Cantidad fija</label>
            </div>
          </div>

          <div *ngIf="attributeForm.controls.attributeType.value === 'FIXED'">
            <div class="field__item field--modal field--number__atribute" [ngClass]="
                validateInputClass(
                  attributeForm,
                  'fixedAmount',
                  formHasBeenSubmitted
                )
              ">
              <div class="p-float-label">
                <input id="fixedAmount" class="input-number" type="number" formControlName="fixedAmount" pInputText />
                <label for="fixedAmount" class="title-float">Cantidad</label>
              </div>
            </div>
          </div>
          <!--<p-radioButton name="attributeGroupName" label="Rango fijo" value="MIN_AND_MAX" formControlName="attributeType">
          </p-radioButton>-->
          <div class="radio-item">
            <div class="radio-container">
              <input type="radio" id="minAndMax" name="attributeType" value="MIN_AND_MAX" formControlName="attributeType">
              <label for="minAndMax">Rango fijo</label>
            </div>
          </div>

          <div *ngIf="attributeForm.controls.attributeType.value === 'MIN_AND_MAX'" class="flex align-items-center">
            <div class="field__item field--modal field--number__atribute mr-3" [ngClass]="
                validateInputClass(
                  attributeForm,
                  'minSelectAmount',
                  formHasBeenSubmitted
                )
              ">
              <div class="p-float-label">
                <input id="minSelectAmount" class="input-number" type="number" formControlName="minSelectAmount"
                  pInputText />
                <label for="minSelectAmount" class="title-float">Mín.</label>
              </div>
            </div>
            <div class="field__item field--modal field--number__atribute" [ngClass]="
                validateInputClass(
                  attributeForm,
                  'maxSelectAmount',
                  formHasBeenSubmitted
                )
              ">
              <div class="p-float-label">
                <input id="maxSelectAmount" class="input-number" type="number" formControlName="maxSelectAmount"
                  pInputText />
                <label for="maxSelectAmount" class="title-float">Máx.</label>
              </div>
            </div>
          </div>
          <!--<p-radioButton name="attributeGroupName" label="Una por opción" value="ONE_PER_OPT"
            formControlName="attributeType"></p-radioButton>-->
          <div class="radio-item">
            <div class="radio-container">
              <input type="radio" id="onePerOpt" name="attributeType" value="ONE_PER_OPT" formControlName="attributeType">
              <label for="onePerOpt">Una por opción</label>
            </div>
          </div>

          <app-show-errors [page]="'attributes'" [ctrl]="attributeForm.get('attributeType')"
            [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>

        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton label="Cancelar" class="btn btn__secondary btn__secondary--bold mr-3"
        (click)="hideDialog()"></button>
      <button pButton label="Guardar" class="btn btn__primary" [class.spinner]="loading"
        (click)="submitAttribute()"></button>
    </ng-template>
  </p-dialog>
  <p-dialog [(visible)]="attributeValueDialog" [style]="{ width: '450px' }" header="{{ headerPopupValue }}"
    [modal]="true" [draggable]="false">
    <ng-template pTemplate="content">
      <div class="field__item field--modal" *ngIf="!isEditting">
        <div class="p-float-label">
          <input disabled [(ngModel)]="attributeSelected.name" id="name" type="text" pInputText />
          <label for="name" class="title-float">Atributo</label>
        </div>
      </div>
      <form [formGroup]="attributeValueForm" (ngSubmit)="submitAttributeValue()" class="w-100">
        <div class="field__item field--modal pt-3" [ngClass]="
            validateInputClass(
              attributeValueForm,
              'valueName',
              formHasBeenSubmitted
            )
          ">
          <div class="p-float-label">
            <input formControlName="valueName" id="valueName" type="text" maxlength="{{ maxAttributeNameLength }}"
              pInputText />
            <label for="valueName" class="title-float">Nombre</label>
          </div>
          <div class="field__item--info">
            <span>
              {{ nameValueField.value ? nameValueField.value.length : 0 }}/{{
              maxAttributeNameLength
              }}</span>
          </div>
        </div>
        <div class="field__item field--modal" [ngClass]="
            validateInputClass(
              attributeValueForm,
              'valueDescription',
              formHasBeenSubmitted
            )
          ">
          <div class="p-float-label">
            <textarea pInputTextarea formControlName="valueDescription" id="valueDescription"
              maxlength="{{ maxAttributeDescriptionLength }}" type="text" cols="30" rows="2"></textarea>
            <label for="valueDescription" class="title-float">Descripción</label>
          </div>
          <div class="field__item--info">
            <span>
              {{
              descriptionValueField.value
              ? descriptionValueField.value.length
              : 0
              }}/{{ maxAttributeDescriptionLength }}</span>
          </div>
        </div>

        <div class="w-100">
          <p>Impacto en precio del producto</p>
          <!--<p-radioButton name="attributeValueType" label="Sin valor adicional" value="FREE"
            formControlName="attributeValueType"></p-radioButton>
          <p-radioButton name="attributeValueType" label="Adiciona valor al precio final" value="ADD_PRICE"
            formControlName="attributeValueType"></p-radioButton>-->
            <div class="radio-item">
              <div class="radio-container">
                <input type="radio" id="free" name="attributeValueType" value="FREE" formControlName="attributeValueType">
                <label for="free">Sin valor adicional</label>
              </div>
            </div>
            <div class="radio-item">
              <div class="radio-container">
                <input type="radio" id="addPrice" name="attributeValueType" value="ADD_PRICE" formControlName="attributeValueType">
                <label for="addPrice">Adiciona valor al precio final</label>
              </div>
            </div>
          <div *ngIf="
              attributeValueForm.controls.attributeValueType.value ===
              'ADD_PRICE'
            ">
            <div class="field__item field--number field--number__atribute field--modal" [ngClass]="
                validateInputClass(
                  attributeValueForm,
                  'price',
                  formHasBeenSubmitted
                )
              ">
              <div class="p-float-label">
                <p-inputNumber prefix="$ " formControlName="price" max="{{ maxPriceAtributte }}" mode="decimal"
                  [minFractionDigits]="2" [maxFractionDigits]="2" class="input-number-attribute"></p-inputNumber>
                <label for="price" class="title-float">Precio final</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton label="Cancelar" class="btn btn__secondary btn__secondary--bold mr-3"
        (click)="hideDialog()"></button>
      <button [disabled]="!attributeValueForm.valid" pButton label="Guardar" class="btn btn__primary"
        [class.spinner]="loading" (click)="submitAttributeValue()"></button>
    </ng-template>
  </p-dialog>
  <p-confirmDialog key="confirmDelete" [style]="{ width: '450px' }" acceptLabel="Eliminar" rejectLabel="Cancelar"
    acceptButtonStyleClass="btn btn__primary" rejectButtonStyleClass="btn btn__secondary btn__secondary--bold">
  </p-confirmDialog>
  <p-confirmDialog key="confirmDeleteWithProduct" [style]="{ width: '450px' }" acceptLabel="Eliminar"
    rejectLabel="Cancelar" acceptButtonStyleClass="btn btn__primary"
    rejectButtonStyleClass="btn btn__secondary btn__secondary--bold"></p-confirmDialog>
</div>
