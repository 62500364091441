import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { ModalConfig } from 'src/app/core/models/ModalConfig';
import { StripeCredentialRequest } from 'src/app/core/models/StripeCredentialRequest';
import { AuthService } from 'src/app/services/auth.service';
import { InputValidationService } from 'src/app/services/input-validation.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-payments-stripe-config-dialog',
  templateUrl: './payments-stripe-config-dialog.component.html',
  styleUrls: ['./payments-stripe-config-dialog.component.scss']
})
export class PaymentsStripeConfigDialogComponent implements OnInit {

  stripeForm: UntypedFormGroup;
  idStore: number;
  formHasBeenSubmitted = false;
  isWriter: boolean;
  subs = [];
  modalConfig: ModalConfig = {
    headerText: 'Ha ocurrido un error en la carga de datos',
    content: 'Por favor intente mas tarde',
    buttons: 'single',
  };
  modalNoTenantCredentialConfig: ModalConfig = {
    headerText: 'Error al cargar cuentas originantes',
    content: 'Verificar las credenciales asociadas a la marca.',
    buttons: 'single',
  };
  showModalConfirm = false;
  currentCredential: StripeCredentialRequest = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private spinnerService: SpinnerService,
    public ref: DynamicDialogRef,
    private paymentSrv: PaymentService,
    private messageService: MessageService,
    private validateInputService: InputValidationService,
    private userSrv: UserService
  ) {
    this.stripeForm = this.formBuilder.group({
      publicKey: ['', Validators.required],
      secretKey: ['', Validators.required]
    });
  }

  get publicKeyField(): AbstractControl {
    return this.stripeForm.controls.publicKey;
  }

  get secretKeyField(): AbstractControl {
    return this.stripeForm.controls.secretKey;
  }

  ngOnInit(): void {
    this.idStore = Number(sessionStorage.getItem('storeId'));
    this.isWriter = this.userSrv.isWriter();
    this.getCredential();
    this.checkIsWriter();
  }

  getCredential() {
    this.subs.push(
      this.paymentSrv.getStripeCredentials(this.idStore).subscribe({
          next: res => {
            this.currentCredential = res;
            this.stripeForm.patchValue({
              publicKey: res.publicKey,
              secretKey: res.secretKey
            });
          },
          error: err => {
            if (err.status === 404) {
              this.stripeForm.reset();
            }
            this.spinnerService.loadSpinner.next(false);
          }
        }
      )
    );
  }

  checkIsWriter(): void {
    if (!this.isWriter) {
      this.stripeForm.controls.publicKey.disable();
      this.stripeForm.controls.secretKey.disable();
    }
  }

  cancel() {
    this.ref.close(false);
  }

  submitChange(): void {
    this.formHasBeenSubmitted = true;
    this.spinnerService.loadSpinner.next(true);
    const stripeCredentialRequest: StripeCredentialRequest = {
      publicKey: this.publicKeyField.value,
      secretKey: this.secretKeyField.value,
    };
    this.subs.push(
      this.paymentSrv.setStripeCredentials(this.idStore, stripeCredentialRequest).subscribe({
        next: v => {
          this.spinnerService.loadSpinner.next(false);
          this.showMessageOk();
          this.ref.close(true);
        },
        error: err => {
          if (err.status === 400) {
            this.modalConfig = {
              headerText: 'Error',
              buttons: 'single',
              content:
                'La configuración del comercio no es válida. Por favor configurar el comercio y reintentar.',
            };
          } else if (err.status === 409) {
            this.modalConfig = {
              headerText: 'Error',
              buttons: 'single',
              content:
                'En este momento no se puede enviar el email de autorización, intente más tarde.',
            };
          }
          this.showModalConfirm = true;
          this.spinnerService.loadSpinner.next(false);
        }
      })
    );
  }

  onModalResponse(e: boolean): void {
    this.showModalConfirm = false;
  }

  onModalNoTenantCredentialResponse(e: boolean): void {
    this.cancel();
  }

  showMessageOk(): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Credenciales guardadas con éxito.',
    });
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

}
