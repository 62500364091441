<div class="container-main">
  <div *ngIf="loading">
    <p-tabView>
      <p-tabPanel header="Productos">
        <app-products [hideLateralMenu]="hideLateralMenu" [canWrite]="isWriter"></app-products>
      </p-tabPanel>
      <p-tabPanel header="Categorías">
        <app-categories [hideLateralMenu]="hideLateralMenu" [canWrite]="isWriter"></app-categories>
      </p-tabPanel>
      <p-tabPanel header="Opcionales">
        <app-attributes [hideLateralMenu]="hideLateralMenu" [canWrite]="isWriter"></app-attributes>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>