<article class="box-chart">
    <header class="header-chart">
      <div class="type-col">
        <i class="fa-solid fa-calendar-clock font-s-14 text-gray-1"></i>
        <p class="lh-24 text-gray-1 font-s-16 font-poppins my-0 margin-left-8">Total reservas recibidas</p>
      </div>
      <div class="date-col">
        <div
          class="display-flex align-item-center hidden-data"
          [ngClass]="checked ? 'active' : ''"
        >
          <i class="fa fa-ellipsis fa-xl text-gray-4"></i>
          <p class="text-xs lh-16 text-gray-3 my-0 margin-left-8 margin-right-16">
            {{ this.dateLastWeek + " - " + this.dateToday }}
          </p>
        </div>

        <div *ngIf="checked" class="flex align-item-center">
          <i class="fa fa-hyphen fa-xl text-primary"></i>
          <p class="text-xs lh-16 text-gray-3 my-0 margin-left-8">
            {{ this.dateWeekStart + " - " + this.dateToday }}
          </p>
        </div>
        <div *ngIf="!checked" class="flex align-item-center">
          <i class="fa fa-hyphen fa-xl text-primary"></i>
          <p *ngIf="typeWeek === 'lastWeek'" class="text-xs lh-16 text-gray-3 my-0 margin-left-8">
            {{ this.dateLastWeek + " - " + this.dateWeek }}
          </p>
          <p *ngIf="typeWeek === 'currentWeek'" class="text-xs lh-16 text-gray-3 my-0 margin-left-8">
            {{ this.dateWeekStart + " - " + this.dateToday }}
          </p>
        </div>
        <!---->
        <!--<i class="fa fa-hyphen fa-xl text-primary"></i>
        <p class="text-xs lh-16 text-gray-3 my-0 margin-left-8">
          {{ this.dateWeekStart + " - " + this.dateToday }}
        </p>-->
      </div>
    </header>

    <app-area-graphic [idChart]="'chart3'" [columns]="dataColumns" [categories]="dataCategories"></app-area-graphic>
  </article>