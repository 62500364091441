<div class="container-main">
  <div class="config-container" *ngIf="loading">
    <p-tabView>
      <p-tabPanel header="Generales">
        <app-config-generic
          [genericConfig]="storeConfiguration.genericConfiguration"
          [configurationId]="storeConfiguration.id"
          [fromAdmin]="true"
          [canWrite]="isWriter"
        ></app-config-generic>
      </p-tabPanel>

      <p-tabPanel header="Mesas">
        <app-config-mesas
          [configurationId]="storeConfiguration.id"
          [tableConfigData]="storeConfiguration.tableConfiguration"
          [fromAdmin]="true"
        ></app-config-mesas>
      </p-tabPanel>

      <p-tabPanel header="Reservas">
        <app-config-bookings
          [storeMaxCapacity]="storeConfiguration.genericConfiguration.maxCapacity"
          [configurationId]="storeConfiguration.id"
          [bookingConfigData]="storeConfiguration.bookingConfiguration"
          [fromAdmin]="true"
        ></app-config-bookings>
      </p-tabPanel>

      <p-tabPanel header="Lista de espera">
        <app-config-lista-espera
          [configurationId]="storeConfiguration.id"
          [waitingListConfData]="storeConfiguration.waitingListConfiguration"
          [fromAdmin]="true"
        ></app-config-lista-espera>
      </p-tabPanel>

      <p-tabPanel header="Horarios">
        <app-config-schedule
          [scheduleConfigData]="storeConfiguration.scheduleConfiguration"
          [configurationId]="storeConfiguration.id"
          [fromAdmin]="true"
        ></app-config-schedule>
      </p-tabPanel>

      <p-tabPanel header="VIP">
        <app-config-vip
          [vipConfigData]="storeConfiguration.vipConfiguration"
          [configurationId]="storeConfiguration.id"
          [fromAdmin]="true"
        ></app-config-vip>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
