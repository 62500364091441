<div class="modal__body">
  <div *ngIf="accountTenant">
    <div class="account_collector-option">
      <label [for]="accountTenant.id" class="display-flex">
        <p
          class="text-base font-weight-600 font-poppins lh-24 text-gray-2 align-self-center my-0">
          {{accountTenant?.accountCollectorName}}
        </p>
        <p class="text-sm font-weight-400 lh-20 text-gray-35 align-self-center my-0 ml-3">
          #{{accountTenant?.pagounoMerchantCodeGroup}}
        </p>
      </label>
    </div>
    <p class="text-xs font-weight-400 lh-20 text-gray-4 mb-2">
      Cuenta utilizada para generar todas las operaciones.
    </p>
  </div>
  <div class="display-flex w-full justify-content-between margin-top-24">
    <div class="display-flex">
      <i class="fas fa-door-open mr-2 align-self-center"></i>
      <span class="text-base lh-24 font-poppins font-weight-600 text-gray-2 align-self-center">
          Splitear operaciones
        </span>
    </div>
    <div>
      <p-inputSwitch (onChange)="toggleSwitchSplitTransactions($event)"
                     [disabled]="!isWriter"
                     [(ngModel)]="splitTransactions">
      </p-inputSwitch>
    </div>
  </div>
  <div *ngIf="splitTransactions" class="margin-top-8">
    <div *ngIf="splitTransactions && accountCollectors?.length > 0">
      <div *ngFor="let accountCol of accountCollectors" class="account_collector-option"
           [ngClass]="accountCol?.pagounoMerchantCodeGroup === accountSelected?.pagounoMerchantCodeGroup ? 'active' : ''">
        <p-checkbox binary="true" class="field-checkbox-rounded cursor-pointer"
                    [ngModel]="valueCheck(accountCol)" (onChange)="checkAccount($event, accountCol)"></p-checkbox>
        <label [for]="accountCol.id" class="display-flex">
          <p
            class="text-base font-weight-600 font-poppins lh-24 text-gray-2 align-self-center my-0 ml-3">
            {{accountCol?.accountCollectorName}}
          </p>
          <p class="text-sm font-weight-400 lh-20 text-gray-35 align-self-center my-0 ml-3">
            #{{accountCol?.pagounoMerchantCodeGroup}}
          </p>
        </label>

      </div>
    </div>
    <form [formGroup]="secretsForm" autocomplete="off" class="w-100">
      <div class="col-12 margin-top-16 p-0">
        <div class="field__item field--modal" [ngClass]="
          validateInputClass(secretsForm, 'merchantCodeGroup', formHasBeenSubmitted)
        ">
          <div class="p-float-label">
            <input #merchantCodeGroup formControlName="merchantCodeGroup" id="merchantCodeGroup" type="text"
                   pInputText/>
            <label for="merchantCodeGroup" class="title-float">Código de comercio*</label>
            <app-show-errors [page]="'pagoUnoCredential'" [ctrl]="secretsForm.get('merchantCodeGroup')"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
        </div>
      </div>

      <div class="col-12 p-0">
        <div class="field__item field--modal" [ngClass]="
          validateInputClass(secretsForm, 'sellerDescription', formHasBeenSubmitted)
        ">
          <div class="p-float-label">
            <input #sellerDescription formControlName="sellerDescription" id="sellerDescription" type="text"
                   pInputText/>
            <label for="sellerDescription" class="title-float">Nombre de comercio*</label>
            <app-show-errors [page]="'paymentsCredentials'" [ctrl]="secretsForm.get('sellerDescription')"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
        </div>
      </div>

      <div class="col-12 p-0">
        <div class="field__item field--modal" [ngClass]="
          validateInputClass(secretsForm, 'publicKey', formHasBeenSubmitted)
        ">
          <div class="p-float-label">
            <input
              *ngIf="secretsForm.controls['publicKey'].value !== 'XXXXXXXXXX' && secretsForm.controls['publicKey'].value !== undefined"
              #publicKey formControlName="publicKey" id="publicKey" type="text" pInputText/>
            <input
              *ngIf="secretsForm.controls['publicKey'].value === 'XXXXXXXXXX' || secretsForm.controls['publicKey'].value === undefined"
              #publicKey formControlName="publicKey" id="publicKey" type="password" pInputText disabled/>
            <label for="publicKey" class="title-float">Clave pública</label>
            <app-show-errors [page]="'paymentsCredentials'" [ctrl]="secretsForm.get('publicKey')"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
        </div>
      </div>

      <div class="col-12 p-0">
        <div class="field__item field--modal" [ngClass]="
          validateInputClass(secretsForm, 'privateKey', formHasBeenSubmitted)
        ">
          <div class="p-float-label">
            <input
              *ngIf="secretsForm.controls['privateKey'].value !== 'XXXXXXXXXX' && secretsForm.controls['privateKey'].value !== undefined"
              #privateKey formControlName="privateKey" id="privateKey" type="text" pInputText/>
            <input
              *ngIf="secretsForm.controls['privateKey'].value === 'XXXXXXXXXX' || secretsForm.controls['privateKey'].value === undefined"
              #privateKey formControlName="privateKey" id="privateKey" type="password" pInputText disabled/>
            <label for="privateKey" class="title-float">Clave privada</label>
            <app-show-errors [page]="'paymentsCredentials'" [ctrl]="secretsForm.get('privateKey')"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="footer">
  <div class="col p-0 mr-2">
    <button pButton class="btn btn__secondary btn__secondary--bold w-100" (click)="cancel()"
            label="Cancelar"></button>
  </div>
  <div class="col p-0 ml-2">
    <button pButton (click)="submitChange()" [disabled]="!secretsForm.valid || !isWriter"
            class="btn btn__primary btn__primary--bold w-100" label="Guardar"></button>
  </div>
</div>

<app-modal [config]="modalConfig" [widthModal]="'450px'" (modalResponse)="onModalResponse($event)"
           [showModal]="showModalConfirm">
</app-modal>

<app-modal [config]="modalNoTenantCredentialConfig" [widthModal]="'552px'"
           (modalResponse)="onModalNoTenantCredentialResponse($event)"
           [showModal]="tenantDontHaveCredentials">
</app-modal>
