<div class="header">
  <p class="subtitle">Editar pertenencia</p>
  <p class="title">{{storeName}}</p>
</div>

<div class="modal__body">
  <div class="flex flex-column align-items-start w-full">
    <p-dropdown [options]="tenantList" id="tenantList" class="w-full"
                optionLabel="name" (onChange)="onChangeTenant($event)"
                placeholder="Seleccioná a que cartelera pertenece"
                [(ngModel)]="tenantSelected" [disabled]="isTenantOwner || config.data.tenantSelected !== null">
    </p-dropdown>
    <p class="text-xs lh-16 text-gray-4 font-weight-400 margin-top-8">Los eventos del comercio se listarán en la
      cartelera seleccionada.</p>
  </div>

</div>

<div class="footer">
  <div class="col p-0 mr-2">
    <button pButton class="btn btn__secondary btn__secondary--bold w-100" (click)="cancel()"
            label="Cancelar"></button>
  </div>
  <div class="col p-0 ml-2">
    <button pButton (click)="verify()"
            [disabled]="!tenantSelected || !isWriter"
            class="btn btn__primary btn__primary--bold w-100" label="Verificar comercio"></button>
  </div>
</div>
