import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import * as c3 from 'c3';

@Component({
  selector: 'app-area-graphic',
  templateUrl: './area-graphic.component.html',
  styleUrls: ['./area-graphic.component.scss']
})
export class AreaGraphicComponent implements OnInit {

  @Input() idChart: string;
  @Input() columns;
  @Input() categories;
  graphisData = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  //this method update the graphic when columns data change
  ngOnChanges(changes: SimpleChanges) {
    this.generateGraphic();
  }

  ngAfterViewInit() {
    this.generateGraphic();
  }

  generateGraphic() {
    setTimeout(() => {
      c3.generate({
        bindto: `#${this.idChart}`,
        data: {
          columns: this.columns,
          types: {
            [this.columns[0][0]]: 'area-spline',
          },
          colors: {
            [this.columns[0][0]]: '#10C9BE',
          },
        },
        grid: {
          x: {
            show: false,
          },
          y: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        axis: {
          x: {
            type: 'category',
            categories: this.categories,
          },
          y: {
            type: 'linear',
            tick: {
              format: function(d) {
                return (parseInt(d.toString()) == d) ? d : null;
              }
            },
          },
        },
      });
    }, 100);
  }

}
