import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Configurations} from 'src/app/core/models/Configurations';
import {AuthService} from 'src/app/services/auth.service';
import {ConfigurationService} from 'src/app/services/configuration.service';
import {StoreService} from 'src/app/services/store.service';
import {UserService} from 'src/app/services/user.service';
import {StoreData} from "../../../../../core/models/StoreData";

@Component({
  selector: 'app-store-detail',
  templateUrl: './store-detail.component.html',
  styleUrls: ['./store-detail.component.scss'],
})
export class StoreDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  loading = false;
  storeConfiguration: Configurations;
  idStore: number;
  subs: Subscription[] = [];
  isSuperAdmin: boolean;
  isReader: boolean;
  isWriter: boolean;

  constructor(
    private configurationSrv: ConfigurationService,
    private storeSrv: StoreService,
    private userSrv: UserService,
    private authSrv: AuthService,
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    this.isReader = this.userSrv.isReader();
    this.isWriter = this.userSrv.isWriter();
    const storeId = sessionStorage.getItem('storeId');

    if (storeId) {
      this.storeSrv.getStoreById(Number(storeId)).subscribe({
        next: (data: StoreData) => {
          this.authSrv.updateUser(data);
          this.loadGenericConfig(storeId);
        }
      })
    }
  }

  loadGenericConfig(storeId): void {
    this.subs.push(
      this.configurationSrv.getConfigurations(storeId).subscribe({
        next: (data: Configurations) => {
          this.storeConfiguration = data;
          this.loading = true;
        }
      })
    );
  }

  createDataToQR(): string {
    return JSON.stringify({
      idStore: this.idStore,
      type: 'waiting-list',
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }
}
