import { Injectable } from '@angular/core';
import jspdf from 'jspdf';
import domtoimage from 'dom-to-image';

@Injectable({
  providedIn: 'root',
})
export class PdfHandlerService {
  constructor() {}

  public htmlToPdf(html): Promise<any[]> {
    return new Promise<Array<any>>(async (resolve, reject) => {
      try {
        const options = { background: 'white', height: 200, width: 200 };
        const contentDataURL = await domtoimage.toPng(html, options);

        const pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
        pdf.addImage(contentDataURL, 'PNG', 0, 0, 210, 340);

        pdf.save('newPDF.pdf');

        // Convert pdf into byte array
        const pdfBuffer = pdf.output('arraybuffer');
        const pdfU8 = new Uint8Array(pdfBuffer);
        const pdfByteArr = Array.from(pdfU8);
        resolve(pdfByteArr);
      } catch (err) {
        reject(err);
      }
    });
  }
}
