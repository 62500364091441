<div class="internal-body">
  <p-toast></p-toast>

  <div class="container-table">
    <p-table #dt [value]="categories" [rows]="10" [paginator]="true" sortField="name" sortOrder="1"
      [globalFilterFields]="['name', 'id', 'description', 'createdAt']" [(selection)]="selectedCategories"
      [rowHover]="true" dataKey="id" [loading]="loading" [rowsPerPageOptions]="[10,25,50]"
      currentPageReportTemplate="Mostrar" [showCurrentPageReport]="true" class="table">
      <ng-template pTemplate="caption">
        <div class="flex align-items-start md:align-items-center justify-content-between table-header first-line pb-3">
          <div class="flex justify-content-start align-items-end">
            <i class="fa-light fa-clipboard-list-check fa-2x"></i>
            <p class="my-0 text-xl ml-3">
              CATEGORÍAS
              <span class="ml-2 text-xs font-normal text-primary">
                {{ categories ? categories.length : 0 }} categorías en total
              </span>
            </p>
          </div>
          <div class="flex justify-content-end align-items-center mt-3 md:mt-0">
            <button pButton label="Agregar" icon="fa-regular fa-circle-plus fa-lg"
              class="btn btn__primary btn__primary--bold btn__size--xs mr-2" (click)="openNew()"
              [disabled]="canWrite !== undefined && !canWrite"></button>
            <button pButton label="Borrar" icon="fa-light fa-trash-can fa-lg" class="btn btn__secondary btn__size--xs"
              (click)="deleteSelectedCategories()" [disabled]="
                !selectedCategories ||
                !selectedCategories.length ||
                (canWrite !== undefined && !canWrite)
              "></button>
          </div>
        </div>
        <div class="table-header pt-3 pb-0">
          <div class="flex align-items-center justify-content-start">
            <p-tableHeaderCheckbox [disabled]="canWrite !== undefined && !canWrite"></p-tableHeaderCheckbox>
            <p class="text-sm font-normal text-gray-3 lh-16 my-0 mt-1 ml-2">
              Seleccionar todo
            </p>
          </div>
          <div class="search-container mt-3 md:mt-0">
            <div class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Buscar" />
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 1% !important; min-width: 10% !important"></th>
          <th pSortableColumn="name">
            Nombre
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="description">
            Descripción
            <p-sortIcon field="description"></p-sortIcon>
          </th>
          <th pSortableColumn="createdAt">
            Fecha creación
            <p-sortIcon field="createdAt"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-category>
        <tr class="table-row">
          <td>
            <p-tableCheckbox [value]="category" [disabled]="canWrite !== undefined && !canWrite">
            </p-tableCheckbox>
          </td>
          <td (click)="editCategory(category)" class="text-sm lh-16 font-semibold text-gray-1">
            {{ category.name }}
          </td>
          <td (click)="editCategory(category)" class="text-sm lh-16 font-normal text-gray-1">
            {{ category.description }}
          </td>
          <td (click)="editCategory(category)" class="text-sm lh-16 font-normal text-gray-1">
            {{ category.createdAt | date: "dd/MM/yyyy HH:mm" }}
          </td>
          <td>
            <div class="flex justify-content-end align-items-center">
              <button pButton iconOnly icon="fa fa-light fa-edit" class="mr-2 text-primary"
                (click)="editCategory(category)" [disabled]="canWrite !== undefined && !canWrite"></button>
              <button pButton iconOnly icon="fa fa-light fa-trash-alt" class="text-primary"
                (click)="deleteCategory(category)" [disabled]="canWrite !== undefined && !canWrite"></button>
            </div>
          </td>
        </tr>
        <div class="my-2"></div>
      </ng-template>
    </p-table>
  </div>


  <app-category-modal (loadCategories)="loadCategories($event)" [isEditting]="isEditting"
    [categoryDialog]="categoryDialog" [headerPopup]="headerPopup" [categoryForm]="categoryForm" [editId]="editId"
    (delete)="onDeleteCategory()" (categoryDialogOut)="hideDialog($event)" styleClass="w-100"></app-category-modal>
</div>
