<div class="flex align-items-center justify-content-between">
  <div *ngIf="idStore"
       class="text-base lh-24 text-primary font-semibold flex align-items-center mr-16">
    <div>
      <p-dropdown [options]="monthsOrdered" [(ngModel)]="monthSelected"
                  [placeholder]="monthSelected ? monthSelected : 'Service charge: Mes'"
                  optionLabel="name" optionValue="id"
                  class="dropdown month-dropdown"></p-dropdown>
    </div>
    <div class="flex">
      <div [ngClass]="!monthSelected ? 'button-disabled' : 'cursor-pointer'" (click)="downloadExcel()">
        <i class="fal fa-download text-primary margin-right-8"></i>
        <span class="text-xs lh-16 text-primary font-weight-400">SC</span>
      </div>
      <div class=" margin-left-16" [ngClass]="!monthSelected ? 'button-disabled' : 'cursor-pointer'"
           (click)="downloadExcelTransactions()">
        <i class="fal fa-download text-primary margin-right-8"></i>
        <span class="text-xs lh-16 text-primary font-weight-400">Transacciones</span>
      </div>
    </div>
  </div>
</div>

<div class="table__container">
  <div class="internal-body">
    <p-toast></p-toast>
    <p-table #dt [value]="transactions" [lazy]="true" (onLazyLoad)="loadTransactions($event, tenantIdSelected)"
             [paginator]="true" stateStorage="session" stateKey="transactions-table"
             [rows]="pageSize" [totalRecords]="totalRecords" sortMode="multiple" dataKey="id" expandableRows="true"
             [rowsPerPageOptions]="[10,25,50,100]" currentPageReportTemplate="Mostrar" [loading]="loading"
             [showCurrentPageReport]="true" class="table" rowExpandMode="single" [rowHover]="true">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between table-header first-line">
          <div class="flex justify-content-start align-items-center">
            <i class="fa-light fa-magnifying-glass-dollar fa-lg"></i>
            <p class="my-0 text-xl ml-3 font-semibold">TRANSACCIONES</p>
            <i class="fa-solid fa-arrows-rotate text-primary cursor-pointer fa-lg ml-3 mr-3"
               [ngClass]="{ 'spin': refresh }" (click)="onRefresh()"></i>
            <div class="box__total mr-2">
              Total <span class="text-primary">{{ totalRecords ? totalRecords : 0 | number:'1.0-2' }}</span>
            </div>
          </div>
          <div class="search-container">
            <div class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                     placeholder="Buscar transacción" [(ngModel)]="searchTerm"/>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width:15%">
            <div class="inline-flex text-xs text-gray-2 lh-24">Cliente</div>
          </th>
          <th style="width:18%">
            <div class="inline-flex text-xs text-gray-2 lh-24">Comercio</div>
          </th>
          <th style="width:10%">
            <div class="inline-flex text-xs text-gray-2 lh-24">Proveedor</div>
          </th>
          <th style="min-width:10%">
            <div class="inline-flex text-xs text-gray-2 lh-24">Monto</div>
          </th>
          <th pSortableColumn="created_at" style="width:15%">
            <div class="inline-flex text-xs text-gray-2 lh-24">
              Creada
              <p-sortIcon field="created_at"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="updated_at" style="width:15%">
            <div class="inline-flex text-xs text-gray-2 lh-24">
              Actualizada
              <p-sortIcon field="updated_at"></p-sortIcon>
            </div>
          </th>
          <th pSortableColumn="state" style="width:12%">
            <div class="inline-flex text-xs text-gray-2 lh-24">
              Status
              <p-sortIcon field="state"></p-sortIcon>
            </div>
          </th>
          <th style="max-width:5%"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-trx let-expanded="expanded">
        <tr class="table-row" [ngClass]="{ 'row-order-open': expanded }">
          <td [pRowToggler]="trx" class="text-sm font-normal">
            {{trx.customerData.lastName}}, {{trx.customerData.name}}
          </td>
          <td [pRowToggler]="trx" class="text-sm font-normal">
            {{trx.storeData.name}}</td>
          <td [pRowToggler]="trx" class="text-sm font-normal">
            <img src="../../../../assets/mobbex.svg" *ngIf="trx.provider === 'mobbex'" class="logo__mobbex" alt=""/>
            <img src="../../../../assets/mp.svg" *ngIf="trx.provider === 'mercadoPago'" class="logo__mp" alt=""/>
            <img src="../../../../assets/pagouno.svg" *ngIf="trx.provider === 'pagoUno'" class="logo__pagouno" alt=""/>
            <img src="../../../../assets/stripe.png" *ngIf="trx.provider === 'stripe'" class="logo__stripe" alt=""/>
          </td>
          <td [pRowToggler]="trx" class="text-sm font-normal"
              style="width:15%">
            <span
              *ngIf="trx.originData.totalPrice">{{trx.storeData.currency}}{{trx.originData.totalPrice | number: '1.0-2'}}</span>
            <span *ngIf="!trx.originData.totalPrice">-</span>
          </td>
          <td [pRowToggler]="trx" class="text-sm font-normal">
            {{trx.created_at | date:'dd/MM/yyyy - HH:mm'}}
          </td>
          <td [pRowToggler]="trx" class="text-sm font-normal">
            {{trx.updated_at | date:'dd/MM/yyyy - HH:mm'}}
          </td>
          <td>
            <span *ngIf="!trx.refound">
              <span *ngIf="trx.state === 'ERROR'" class="bid-badge bid-badge--red">ERROR</span>
              <span *ngIf="trx.state === 'DENIED'" class="bid-badge bid-badge--red">DENEGADA</span>
              <span *ngIf="trx.state === 'INVALID_AMOUNT'" class="bid-badge bid-badge-white">MONTO INVÁLIDO</span>
              <span *ngIf="trx.state === 'REJECTED'" class="bid-badge bid-badge--red">RECHAZADA</span>
              <span *ngIf="trx.state === 'REFOUND'" class="bid-badge bid-badge--cyan">DEVUELTA</span>
              <span *ngIf="trx.state === 'WARNING'" class="bid-badge bid-badge--red">RECHAZADA</span>
              <span *ngIf="trx.state === 'PROVIDER_NOT_RESPONSE'"
                    class="bid-badge bid-badge--orange">PROVEEDOR NO RESPONDE</span>
              <span *ngIf="trx.state === 'APPROVED'" class="bid-badge bid-badge--green">APROBADA</span>
              <span *ngIf="trx.state === 'PENDING' && !isUnderRevision(trx)"
                    class="bid-badge bid-badge--orange">PENDIENTE</span>
              <span *ngIf="trx.state === 'CANCELLED'" class="bid-badge bid-badge--red">CANCELADA</span>
              <span *ngIf="trx.state === 'EXPIRED'" class="bid-badge bid-badge--red">EXPIRADA</span>
              <span *ngIf="trx.state === 'NETWORK_PROBLEMS'"
                    class="bid-badge bid-badge--red">PROBLEMAS DE CONEXIÓN</span>
              <span *ngIf="trx.state === 'INVALID_PAYMENT_METHOD'"
                    class="bid-badge bid-badge--red">METODO PAGO INVÁLIDO</span>
              <span *ngIf="trx.state === 'AUTHORIZATION_REQUIRED'"
                    class="bid-badge bid-badge--orange">AUTORIZACIÓN REQUERIDA</span>
              <span *ngIf="trx.state === 'INSUFFICIENT_FOUNDS'"
                    class="bid-badge bid-badge--red">FONDOS INSUFICIENTES</span>
            </span>
            <span *ngIf="trx.refound">
              <span class="bid-badge bid-badge--cyan">DEVUELTA</span>
            </span>
            <span *ngIf="isUnderRevision(trx)">
              <span class="bid-badge bid-badge--orange">EN REVISIÓN</span>
            </span>
          </td>

          <td [ngClass]="{ 'expanded-row': expanded }" style="max-width:10%">
            <div class="flex justify-content-end align-items-center">
              <button type="button" pButton pRipple [pRowToggler]="trx"
                      class="p-button-text p-button-plain table-drop-button"
                      [icon]="expanded ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"></button>
            </div>
          </td>
        </tr>
        <div class="my-2"></div>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-trx #dt>
        <tr class="sub-table-container">
          <td colspan="10">
            <div class="row-container-expanded">
              <div class="container-cards">
                <div class="card-info card-transaction">
                  <div class="card-info-header flex justify-content-between align-items-center">
                    <div class="flex align-items-center">
                      <i class="fa-solid fa-magnifying-glass-dollar fa-lg text-gray-3"></i>
                      <p class="text-base lh-16 text-gray-3 font-semibold my-0 ml-2">
                        TRANSACCIÓN
                      </p>
                    </div>
                    <div>
                      <button pButton
                              class="btn btn__secondary btn__secondary--bold btn__secondary--red btn__size--sm text-sm no-border"
                              (click)="refundTrx(trx)" *ngIf="canTrxRefund(trx)"
                              icon="fa-light fa-trash-alt fa-lg mr-2" label="Cancelar transacción"></button>
                    </div>
                  </div>
                  <div class="card-info-body">
                    <div class="card-row-info with-border">
                      <div class="mx-col-15">
                        <span class="font-xs text-gray-35">ID #</span>
                      </div>
                      <div class="mx-col-85 pl-16">
                        <span class="font-sm text-gray-1">{{trx.id}}</span>
                      </div>
                    </div>
                    <div class="card-row-info with-border">
                      <div class="mx-col-15">
                        <span class="font-xs text-gray-35">Tipo</span>
                      </div>
                      <div class="mx-col-85 pl-16">
                        <span class="font-sm text-gray-1">{{getType(trx.originData.type)}}</span>
                      </div>
                    </div>
                    <div class="card-row-info with-border">
                      <div class="mx-col-15">
                        <span class="font-xs text-gray-35">Status</span>
                      </div>
                      <div class="mx-col-85 pl-16 flex align-items-center">
                        <div>
                          <span *ngIf="!trx.refound">
                            <span *ngIf="trx.state === 'ERROR'" class="bid-badge bid-badge--bg-red">ERROR</span>
                            <span *ngIf="trx.state === 'DENIED'" class="bid-badge bid-badge--bg-red">DENEGADA</span>
                            <span *ngIf="trx.state === 'INVALID_AMOUNT'"
                                  class="bid-badge bid-badge--bg-white">MONTO INVÁLIDO</span>
                            <span *ngIf="trx.state === 'REJECTED'" class="bid-badge bid-badge--bg-red">RECHAZADA</span>
                            <span *ngIf="trx.state === 'REFOUND'"
                                  class="bid-badge bid-badge--bg-primary">DEVUELTA</span>
                            <span *ngIf="trx.state === 'WARNING'" class="bid-badge bid-badge--bg-red">RECHAZADA</span>
                            <span *ngIf="trx.state === 'PROVIDER_NOT_RESPONSE'" class="bid-badge bid-badge--bg-orange">PROVEEDOR NO RESPONDE</span>
                            <span *ngIf="trx.state === 'APPROVED'" class="bid-badge bid-badge--bg-green">APROBADA</span>
                            <span *ngIf="trx.state === 'PENDING' && !isUnderRevision(trx)"
                                  class="bid-badge bid-badge--bg-orange">PENDIENTE</span>
                            <span *ngIf="trx.state === 'CANCELLED'" class="bid-badge bid-badge--bg-red">CANCELADA</span>
                            <span *ngIf="trx.state === 'EXPIRED'" class="bid-badge bid-badge--bg-red">EXPIRADA</span>
                            <span *ngIf="trx.state === 'NETWORK_PROBLEMS'"
                                  class="bid-badge bid-badge--bg-red">PROBLEMAS DE CONEXIÓN</span>
                            <span *ngIf="trx.state === 'INVALID_PAYMENT_METHOD'" class="bid-badge bid-badge--bg-red">METODO PAGO INVÁLIDO</span>
                            <span *ngIf="trx.state === 'AUTHORIZATION_REQUIRED'" class="bid-badge bid-badge--bg-orange">AUTORIZACIÓN REQUERIDA</span>
                            <span *ngIf="trx.state === 'INSUFFICIENT_FOUNDS'" class="bid-badge bid-badge--bg-red">FONDOS INSUFICIENTES</span>
                          </span>
                          <span *ngIf="trx.refound">
                            <span class="bid-badge bid-badge--bg-primary">DEVUELTA</span>
                          </span>
                          <span *ngIf="isUnderRevision(trx)">
                            <span class="bid-badge bid-badge--bg-orange">EN REVISION</span>
                          </span>

                        </div>
                        <div *ngIf="showMotive(trx)" class="margin-left-8">
                          <span class="font-sm text-gray-1">{{trx.message}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="card-row-info">
                      <div class="mx-col-15">
                        <span class="font-xs text-gray-35">Creación</span>
                      </div>
                      <div class="mx-col-85 pl-16">
                        <span class="font-sm text-gray-1">{{trx.created_at | date: 'dd MMM yy - HH:mm'}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-info card-commerce">
                  <div class="card-info-header flex justify-content-start align-items-center">
                    <i class="fa-solid fa-store fa-lg text-gray-3"></i>
                    <p class="my-0 text-base text-gray-3 font-semibold ml-2">
                      COMERCIO
                    </p>
                  </div>
                  <div class="card-info-body">
                    <div class="card-row-info with-border">
                      <div class="mx-col-15">
                        <span class="font-xs text-gray-35">Nombre</span>
                      </div>
                      <div class="mx-col-85 pl-16">
                        <span class="font-sm text-gray-1">{{trx.storeData.name}}</span>
                      </div>
                    </div>
                    <div class="card-row-info with-border">
                      <div class="mx-col-15">
                        <span class="font-xs text-gray-35">CUIT</span>
                      </div>
                      <div class="mx-col-85 pl-16">
                        <span class="font-sm text-gray-1">{{trx.storeData.taxIdentifier}}</span>
                      </div>
                    </div>
                    <div class="card-row-info with-border">
                      <div class="mx-col-15">
                        <span class="font-xs text-gray-35">Dirección</span>
                      </div>
                      <div class="mx-col-85 pl-16">
                        <span class="font-sm text-gray-1">{{trx.storeData.address}}</span>
                      </div>
                    </div>
                    <div class="card-row-info">
                      <div class="mx-col-15">
                        <span class="font-xs text-gray-35">Ciudad</span>
                      </div>
                      <div class="mx-col-85 pl-16">
                        <span class="font-sm text-gray-1">{{trx.storeData.city}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-info card-client">
                  <div class="card-info-header flex justify-content-start align-items-center">
                    <i class="fa-solid fa-user fa-lg text-gray-3"></i>
                    <p class="my-0 text-base text-gray-3 font-semibold ml-2">
                      CLIENTE
                    </p>
                  </div>
                  <div class="card-info-body">
                    <div class="card-row-info with-border">
                      <div class="mx-col-15">
                        <span class="font-xs text-gray-35">Nombre</span>
                      </div>
                      <div class="mx-col-85 pl-16">
                        <span class="font-sm text-gray-1">{{trx.customerData.lastName}}
                          , {{trx.customerData.name}}</span>
                      </div>
                    </div>
                    <div class="card-row-info with-border">
                      <div class="mx-col-15">
                        <span class="font-xs text-gray-35">DNI</span>
                      </div>
                      <div class="mx-col-85 pl-16">
                        <span class="font-sm text-gray-1">{{trx.customerData.dni}}</span>
                      </div>
                    </div>
                    <div class="card-row-info with-border">
                      <div class="mx-col-15">
                        <span class="font-xs text-gray-35">Teléfono</span>
                      </div>
                      <div class="mx-col-85 pl-16">
                        <span class="font-sm text-gray-1">{{trx.customerData.phone}}</span>
                      </div>
                    </div>
                    <div class="card-row-info">
                      <div class="mx-col-15">
                        <span class="font-xs text-gray-35">Email</span>
                      </div>
                      <div class="mx-col-85 pl-16">
                        <span class="font-sm text-gray-1">{{trx.customerData.email}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-info card-function" *ngIf="trx.originData.originId !== null">
                  <div class="card-info-header flex justify-content-start align-items-center">
                    <i class="fa-solid fa-receipt fa-lg text-gray-3"></i>
                    <p class="my-0 text-base text-gray-3 font-semibold ml-2">
                      {{getType(trx.originData.type)}} <span class="text-primary ml-2">#{{trx.originData.originId}}
                    </span>
                    </p>
                  </div>
                  <div class="card-info-body">
                    <div class="card-row-info with-border">
                      <div class="mx-col-15">
                        <span class="font-xs text-gray-35">Tipo de orden</span>
                      </div>
                      <div class="mx-col-85 pl-16">
                        <div class="method-payment">
                          <span class="font-sm text-gray-1">{{trx.originData.subType | uppercase}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="card-row-info with-border">
                      <div class="mx-col-15">
                        <span *ngIf="!trx.originData.eventName" class="font-xs text-gray-35">Status</span>
                        <span *ngIf="trx.originData.eventName" class="font-xs text-gray-35">Evento</span>
                      </div>
                      <div class="mx-col-85 pl-16">
                        <div [ngSwitch]="trx.originData.status" *ngIf="!trx.originData.eventName">
                          <span *ngSwitchCase="statuses.OPEN" class="bid-badge bid-badge--bg-white">Iniciado</span>
                          <span *ngSwitchCase="statuses.CONFIRMED"
                                class="bid-badge bid-badge--bg-white">Confirmado</span>
                          <span *ngSwitchCase="statuses.COMPLETED"
                                class="bid-badge bid-badge--bg-green">Completado</span>
                          <span *ngSwitchCase="statuses.CANCELLED" class="bid-badge bid-badge--bg-red">Cancelado</span>
                          <span *ngSwitchCase="statuses.READY" class="bid-badge">Listo</span>
                          <span *ngSwitchCase="statuses.ON_THE_WAY" class="bid-badge">En camino</span>
                        </div>
                        <span class="text-gray-1 font-semibold" *ngIf="trx.originData.eventName">
                        {{trx.originData.eventName}}
                      </span>
                      </div>
                    </div>
                    <div class="card-row-info with-border">
                      <div class="mx-col-15">
                        <span class="font-xs text-gray-35">Total</span>
                      </div>
                      <div class="mx-col-85 pl-16">
                        <span
                          class="font-sm text-gray-1">{{trx.storeData.currency}}{{trx.originData.totalPrice | number : '1.0-2'}}</span>
                      </div>
                    </div>
                    <div class="card-row-info">
                      <div class="mx-col-15">
                        <span class="font-xs text-gray-35">Método de pago</span>
                      </div>
                      <div class="mx-col-85 pl-16">
                        <div class="method-payment" [ngSwitch]="trx.originData.methodPayment">
                          <span class="text-gray-1" *ngSwitchCase="'CASH'">Efectivo</span>
                          <span class="text-gray-1" *ngSwitchCase="'DEBIT_CARD'">Tarjeta de débito</span>
                          <span class="text-gray-1" *ngSwitchCase="'CREDIT_CARD'">Tarjeta de crédito</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-info card-function" *ngIf="trx.originData.originId === null">
                  <div class="flex align-items-center justify-content-center h-100">
                    <p class="text-lg">NO HAY INFORMACIÓN DE LA ORDEN</p>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <div class="my-2"></div>
      </ng-template>
    </p-table>
  </div>
</div>

<p-toast></p-toast>


<p-dialog [(visible)]="showRefundDialog" appendTo="body" [style]="{ width: '432px' }"
          [modal]="true">
  <ng-template pTemplate="content">
    <div class="flex flex-direction-column justify-content-center align-items-center">
      <div>
        <i class="fa-light fa-warning text-yellow text-12xl"></i>
      </div>
      <div class="mt-24">
        <span class="font-poppins text-xl text-gray-1 font-weight-500 lh-26">Estás por eliminar:</span>
      </div>
      <div class="margin-top-8 text-center">
        <span class="text-sm text-gray-3 font-weight-400 lh-20">
          Compra de
          <span class="font-semibold">{{trxToRefund.originData.subType}}</span>
          para
          <span class="font-semibold">{{trxToRefund.originData.eventName}}</span>
          por parte de
          <span class="font-semibold">{{trxToRefund.customerData.name}} {{trxToRefund.customerData.lastName}}</span>
        </span>
      </div>
      <div class="w-full flex .align-items-center justify-content-center margin-top-32">
        <div class="flex flex-direction-column pl-16 pr-16 pb-16 mr-16"
             [ngClass]="isFullRefund ? 'refund-option selected' : 'refund-option'">
          <p-checkbox binary="true" class="field-checkbox-rounded margin-bottom-20" [(ngModel)]="isFullRefund"
                      (onChange)="checkFull($event)" [disabled]="disableRefundOptions"></p-checkbox>
          <span class="font-poppins text-base text-gray-1 font-weight-400 lh-18">Devolución Total</span>
          <span
            class="font-size-12 text-gray-35 font-normal lh-18 mt-8p">Valor de compra y costo de Service charge.</span>
        </div>
        <div class="flex flex-direction-column pl-16 pr-16 pb-16"
             [ngClass]="isPartialRefund ? 'refund-option selected' : 'refund-option'">
          <p-checkbox binary="true" class="field-checkbox-rounded margin-bottom-20" [(ngModel)]="isPartialRefund"
                      (onChange)="checkPartial($event)" [disabled]="disableRefundOptions"></p-checkbox>
          <span class="font-poppins text-base text-gray-1 font-weight-400 lh-18">Devolución Parcial</span>
          <span class="font-size-12 text-gray-35 font-normal lh-18 mt-8p">Únicamente el valor de la compra.</span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="w-full flex align-items-center justify-content-center">
      <button pButton label="Eliminar transacción"
              class="btn btn__secondary btn__secondary--red mr-16" (click)="cancelTransaction()"
              [disabled]="!canRefund()"></button>

      <button pButton label="Cancelar" class="btn btn__primary btn__primary--bold mr-0"
              (click)="hideRefundDialog()"></button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="showSuccessRefund" appendTo="body" [style]="{ width: '432px' }" class="dialog-success-refund"
          [modal]="true">
  <ng-template pTemplate="content" class="overflow-hidden">
    <div class="flex flex-direction-column justify-content-center align-items-center">
      <div>
        <img src="../../../../assets/icon/circle-check-light.svg" class="text-12xl" alt=""/>
      </div>
      <div class="mt-24 text-center">
        <span
          class="font-poppins text-xl text-gray-1 font-weight-500 lh-26 text-center">La transacción fue eliminada con devolución
          <ng-container>{{isPartialRefund ? 'parcial' : 'total'}}</ng-container>
        </span>

      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="w-full flex align-items-center justify-content-center">
      <button pButton label="Aceptar" class="btn btn__primary btn__primary--bold mr-0 button-success-accept"
              (click)="hideRefundSuccessDialog()"></button>
    </div>
  </ng-template>
</p-dialog>

<p-confirmDialog header="" [style]="{ width: '432px' }" acceptLabel="Confirmar" rejectLabel="Cancelar" rejectIcon="" acceptIcon=""
                     rejectButtonStyleClass="btn btn__secondary btn__secondary--bold" key="confirmRefund"
                     acceptButtonStyleClass="btn btn__primary">
</p-confirmDialog>
