<div class="container-main">
  <div class="internal-body">
    <p-toast></p-toast>

    <div class="flex align-items-center">
      <div class="text-base lh-24 text-primary font-semibold flex align-items-center cursor-pointer" (click)="back()">
        <i class="fa-xl fa-regular fa-arrow-left text-primary mr-2"></i> Volver a Home
      </div>
    </div>

    <div class="table__container">
      <p-table #dt [value]="bookings" [lazy]="true" (onLazyLoad)="loadBookings($event)" [paginator]="true"
               selectionMode="single" [loading]="loading" sortMode="multiple" [rowHover]="true" dataKey="id"
               [totalRecords]="totalRecords" [globalFilterFields]="fieldsFilter" [rows]="pageSize"
               [rowsPerPageOptions]="[10,25,50,100]" currentPageReportTemplate="Mostrar" [showCurrentPageReport]="true"
               class="table" stateStorage="session" stateKey="bookings-table">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-between table-header first-line">
            <div class="flex justify-content-start align-items-center">
              <i class="fa-light fa-calendar-alt fa-lg"></i>
              <p class="my-0 text-xl ml-3 font-semibold">RESERVAS</p>
              <i class="fa-solid fa-arrows-rotate text-primary cursor-pointer fa-lg ml-3 mr-3"
                 [ngClass]="{ 'spin': refresh }" (click)="onRefresh()"></i>
              <div class="box__total">
                Total <span class="text-primary">{{ totalRecords | number:'1.0-2' }}</span>
              </div>
            </div>
            <div class="search-container">
              <div class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                       placeholder="Buscar por comercio" [(ngModel)]="searchTerm"/>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 5%">
            </th>
            <th pSortableColumn="name" style="width: 10%">
              <div class="inline-flex text-xs text-gray-2 lh-24">
                Comercio
                <p-sortIcon field="storeData.name"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="reservationDate" style="width: 8%">
              <div class="inline-flex text-xs text-gray-2 lh-24">
                Fecha y hora
                <p-sortIcon field="reservationDate"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="email" style="width: 6%">
              <div class="inline-flex text-xs text-gray-2 lh-24">
                Personas
                <p-sortIcon field="amountOfPeople"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="bookingStatus" style="width: 10%">
              <div class="inline-flex text-xs text-gray-2 lh-24">
                Estado
                <p-sortIcon field="bookingStatus"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="createdAt" style="width: 8%">
              <div class="inline-flex text-xs text-gray-2 lh-24">
                Creada
                <p-sortIcon field="createdAt"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="updatedAt" style="width: 8%">
              <div class="inline-flex text-xs text-gray-2 lh-24">
                Actualizada
                <p-sortIcon field="updatedAt"></p-sortIcon>
              </div>
            </th>
            <th style="width: 3%"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-booking>
          <tr class="table-row">
            <td><img class="store-img" [src]="getImageUrl(booking.storeData.logo)" alt=""/></td>
            <td>{{ booking.storeData.name }}</td>
            <td>{{ booking.reservationDate | date:'dd MMM yyyy HH:mm' }}</td>
            <td>{{ booking.amountOfPeople }}</td>
            <td>
              <span *ngIf="booking.bookingStatus === statuses.CONFIRMED"
                    class="bid-badge bid-badge--green">CONFIRMADA</span>
              <span *ngIf="booking.bookingStatus === statuses.CANCELED"
                    class="bid-badge bid-badge--red">CANCELADA</span>
              <span *ngIf="booking.bookingStatus === statuses.PENDING"
                    class="bid-badge bid-badge--orange">PENDIENTE</span>
              <span *ngIf="booking.bookingStatus === statuses.EXPIRED" class="bid-badge bid-badge--grey">EXPIRADA</span>
              <span *ngIf="booking.bookingStatus === statuses.USING" class="bid-badge bid-badge--orange">EN USO</span>
              <span *ngIf="booking.bookingStatus === statuses.USED" class="bid-badge bid-badge--green">UTILIZADA</span>
            </td>
            <td>{{ booking.createdAt | date:'dd MMM yyyy HH:mm' }}</td>
            <td>
              <span *ngIf="booking.createdAt === booking.updatedAt" class="bid-badge bid-badge--orange">SIN ACTUALIZACIÓN</span>
              <span
                *ngIf="booking.createdAt !== booking.updatedAt">{{ booking.updatedAt | date:'dd MMM yyyy HH:mm' }}</span>

            </td>
            <td class="option-table">
              <button pButton icon="fa-light fa-info-circle fa-xl" class="text-primary"
                      (click)="showBookingDetails(booking)"></button>
            </td>
          </tr>
          <div class="my-2"></div>
        </ng-template>
      </p-table>
    </div>
  </div>

  <p-confirmDialog key="notAuthorized" styleClass="confirm-dialog-custom" [style]="{width: '424px'}" (onHide)="back()"
                   icon="pi pi-exclamation-triangle"
                   acceptLabel="Regresar"></p-confirmDialog>
</div>

<p-dialog [(visible)]="bookingDetailsDialog" appendTo="body" [style]="{ width: '500px' }" [modal]="true">
  <ng-template pTemplate="header">
    <div class="flex flex-column justify-content-center align-items-center">
      <p class="text-xl font-semibold mt-3">Detalles de reserva</p>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div *ngIf="bookingSelected.bookingStatus === 'CANCELED'" class="header-canceled">
      <p class="text-sm font-bold lh-24 my-0">CANCELADA</p>
    </div>
    <div *ngIf="bookingSelected.bookingStatus === 'PENDING'" class="header-pending">
      <p class="text-sm font-bold lh-24 my-0">SIN CONFIRMAR</p>
    </div>
    <div *ngIf="bookingSelected.bookingStatus === 'CONFIRMED'" class="header-confirmed">
      <p class="text-sm font-bold lh-24 my-0">CONFIRMADA</p>
    </div>
    <div *ngIf="bookingSelected.bookingStatus === 'USING'" class="header-confirmed">
      <p class="text-sm font-bold lh-24 my-0">UTILIZANDOSE</p>
    </div>
    <div *ngIf="bookingSelected.bookingStatus === 'EXPIRED'" class="header-expired">
      <p class="text-sm font-bold lh-24 my-0">EXPIRADA</p>
    </div>
    <div *ngIf="bookingSelected.bookingStatus === 'USED'" class="header-used">
      <p class="text-sm font-bold lh-24 my-0">UTILIZADA</p>
    </div>
    <ul class="content-booking">
      <li class="flex align-items-center pt-24 pb-24">
        <span class="icon-reservas icon-xs icon-gray" *ngIf="bookingSelected.bookingStatus === 'EXPIRED'"></span>
        <span class="icon-reservas icon-xs icon-orange" *ngIf="bookingSelected.bookingStatus === 'PENDING'"></span>
        <span class="icon-reservas icon-xs icon-green" *ngIf="bookingSelected.bookingStatus === 'CONFIRMED'"></span>
        <span class="icon-reservas icon-xs icon-red" *ngIf="bookingSelected.bookingStatus === 'CANCELED'"></span>
        <span class="icon-reservas icon-xs icon-green" *ngIf="bookingSelected.bookingStatus === 'USED'"></span>
        <span class="icon-reservas icon-xs icon-orange" *ngIf="bookingSelected.bookingStatus === 'USING'"></span>
        <h5 class="font-semibold text-lg my-0 ml-3 capitalize">
          {{ bookingSelected.reservationDate | date: "EEEE d MMMM, y" }}
        </h5>
      </li>
      <li class="flex align-items-center mb-3">
        <span class="icon-time icon-xs icon-gray" *ngIf="bookingSelected.bookingStatus === 'EXPIRED'"></span>
        <span class="icon-time icon-xs icon-orange" *ngIf="bookingSelected.bookingStatus === 'PENDING'"></span>
        <span class="icon-time icon-xs icon-green" *ngIf="bookingSelected.bookingStatus === 'CONFIRMED'"></span>
        <span class="icon-time icon-xs icon-red" *ngIf="bookingSelected.bookingStatus === 'CANCELED'"></span>
        <span class="icon-time icon-xs icon-green" *ngIf="bookingSelected.bookingStatus === 'USED'"></span>
        <span class="icon-time icon-xs icon-orange" *ngIf="bookingSelected.bookingStatus === 'USING'"></span>
        <h5 class="font-semibold text-lg my-0 ml-3">
          {{ bookingSelected.reservationDate | date: "HH:mm" }} hs
        </h5>
      </li>
      <li class="flex align-items-center mb-3">
        <span class="icon-persons icon-xs icon-gray" *ngIf="bookingSelected.bookingStatus === 'EXPIRED'"></span>
        <span class="icon-persons icon-xs icon-orange" *ngIf="bookingSelected.bookingStatus === 'PENDING'"></span>
        <span class="icon-persons icon-xs icon-green" *ngIf="bookingSelected.bookingStatus === 'CONFIRMED'"></span>
        <span class="icon-persons icon-xs icon-red" *ngIf="bookingSelected.bookingStatus === 'CANCELED'"></span>
        <span class="icon-persons icon-xs icon-green" *ngIf="bookingSelected.bookingStatus === 'USED'"></span>
        <span class="icon-persons icon-xs icon-orange" *ngIf="bookingSelected.bookingStatus === 'USING'"></span>
        <h5 class="font-semibold text-lg my-0 ml-3">
          {{ bookingSelected.amountOfPeople }} personas
        </h5>
      </li>
      <li class="flex justify-content-start align-items-start mt-4 mb-3">
        <span *ngIf="
            bookingSelected.customerData &&
            bookingSelected.customerData.image !== null
          ">
          <img class="user-img" [src]="getImageUrl(bookingSelected.customerData.image)" alt=""/>
        </span>
        <span *ngIf="
            bookingSelected.customerData === null ||
            bookingSelected.customerData.image === null
          ">
          <i class="fa-light fa-badge-percent text-gray-55" *ngIf="bookingSelected.bookingStatus === 'EXPIRED'"></i>
          <i class="fa-light fa-badge-percent text-yellow" *ngIf="bookingSelected.bookingStatus === 'PENDING'"></i>
          <i class="fa-light fa-badge-percent text-success" *ngIf="bookingSelected.bookingStatus === 'CONFIRMED'"></i>
          <i class="fa-light fa-badge-percent text-error" *ngIf="bookingSelected.bookingStatus === 'CANCELED'"></i>
          <i class="fa-light fa-badge-percent text-success" *ngIf="bookingSelected.bookingStatus === 'USED'"></i>
          <i class="fa-light fa-badge-percent text-yellow" *ngIf="bookingSelected.bookingStatus === 'USING'"></i>
        </span>
        <div class="ml-3">
          <p class="text-sm mb-3">
            {{ bookingSelected.customerData.name }}
            {{ bookingSelected.customerData.lastName }}
          </p>
          <p class="text-sm mb-3">
            {{ bookingSelected.customerData.email }}
          </p>
          <p class="text-sm mb-3">
            {{ bookingSelected.customerData.phone }}
          </p>
          <p class="text-sm mb-3 booking-notes">
            {{ bookingSelected.notes }}
          </p>
          <p class="text-sm mb-3">
            {{ bookingSelected.reasonCancellation }}
          </p>
        </div>
      </li>
    </ul>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="w-100 flex">
      <button pButton label="Cerrar" class="btn btn__primary" (click)="closeDialog()"></button>
    </div>
  </ng-template>
</p-dialog>
