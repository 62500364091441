<article *ngIf="elements && elements.length > 0">
  <div class="display-flex justify-content-between box__porcent first">
    <div class="display-flex align-item-center justify-content-start margin-bottom-24">
      <div class="circle-icon--big">
        <i class="fa-light font-w-300 {{ elements[0].icon }} fa-xl text-gray-1"></i>
      </div>
    </div>
    <p
      class="margin-bottom-16 font-size-28 lh-24 font-semibold middle-value {{ elements[0].valueClass ? elements[0].valueClass : '' }}">
      <span class="mr-2"
            *ngIf="elements[0].prefixValue">{{ elements[0].prefixValue }}</span>{{ elements[0].value | number: '1.0-0' }}
    </p>
    <label class="font-size-18 lh-24" for="">{{ elements[0].label }}</label>
  </div>

  <div class="display-flex justify-content-between box__porcent second ">
    <div class="display-flex align-item-center justify-content-start margin-bottom-24">
      <div class="circle-icon--big">
        <i class="fa-light font-w-300 {{ elements[1].icon }} fa-xl text-gray-1"></i>
      </div>
    </div>
    <p
      class="margin-bottom-16 font-size-28 lh-24 font-semibold middle-value {{ elements[1].valueClass ? elements[1].valueClass : '' }}">
      <span class="mr-2"
            *ngIf="elements[1].prefixValue">{{ elements[1].prefixValue }}</span>{{ elements[1].value | number: '1.0-0' }}
    </p>
    <label class="font-size-18 lh-24" for="">{{ elements[1].label }}</label>
  </div>

</article>
