import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Admin} from '../core/models/Admin';
import {AuthService} from "./auth.service";
import {ChangeEmailForm} from "../core/models/ChangeEmailForm";
import {UpdateCustomerEmail} from "../core/models/UpdateCustomerEmail";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  admin: Admin;

  constructor(
    private httpC: HttpClient,
    private authSrv: AuthService
  ) {
  }

  public isReader(): boolean {
    this.getAdmin();
    if (this.authSrv.isAdminRole()) {
      return this.admin.reader;
    } else if (this.authSrv.isSuperAdminRole()) {
      return true;
    } else {
      return false;
    }
  }

  public isWriter(): boolean {
    this.getAdmin();
    if (this.authSrv.isAdminRole()) {
      return this.admin.writer;
    } else if (this.authSrv.isSuperAdminRole()) {
      return true;
    } else {
      return false;
    }
  }

  public getAdmin(): void {
    if (JSON.parse(localStorage.getItem('adminData'))) {
      this.admin = JSON.parse(localStorage.getItem('adminData'));
    }
  }

  public updateAdmin(admin: Admin): void {
    localStorage.setItem('adminData', JSON.stringify(admin));
  }

  public getAdmins(): Observable<Admin[]> {
    return this.httpC.get<Admin[]>(environment.apiAGUrl + '/admins');
  }

  public editAdmin(idAdmin, form: Admin): Observable<void> {
    return this.httpC.put<void>(environment.apiAGUrl + `/admins/${idAdmin}`, form);
  }

  public createAdmin(form: Admin): Observable<void> {
    return this.httpC.post<void>(environment.apiAGUrl + `/admins`, form);
  }

  public deleteAdmin(idAdmin: number): Observable<void> {
    return this.httpC.delete<void>(environment.apiAGUrl + `/admins/${idAdmin}`);
  }

  public changeEmail(form: UpdateCustomerEmail): Observable<void> {
    return this.httpC.patch<void>(`${environment.apiAGUrl}/admin/customers/update-email`, form);
  }
}
