<p-table class="attributes-data-table" [value]="attribute.attributeValues" dataKey="id">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="valueName">
        Nombre <p-sortIcon field="valueName"></p-sortIcon>
      </th>
      <th pSortableColumn="valueDescription">
        Descripción
        <p-sortIcon field="valueDescription"></p-sortIcon>
      </th>
      <th>Precio</th>
      <th style="width: 8rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-attributeValues>
    <tr class="subtable-row my-3">
      <td class="text-sm lh-16 font-semibold text-gray-1">
        {{ attributeValues.valueName }}
      </td>
      <td class="text-sm lh-16 font-normal text-gray-1">
        {{ attributeValues.valueDescription }}
      </td>
      <td class="text-sm lh-16 font-normal text-gray-1">$ {{ attributeValues.price }}</td>
      <td>
        <div class="flex justify-content-end align-items-center">
          <button pButton iconOnly class="mr-2 text-primary" icon="fa fa-light fa-edit"
            (click)="editAttributeValue(attributeValues)" [disabled]="canWrite !== undefined && !canWrite"></button>
          <button pButton iconOnly icon="fa fa-light fa-trash-alt" class="text-primary"
            (click)="deleteAttributeValue(attributeValues, attribute)"
            [disabled]="canWrite !== undefined && !canWrite"></button>
        </div>
      </td>
    </tr>
    <div class="my-2"></div>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr class="subtable-row">
      <td colspan="6" class="mb-3">
        No hay valores de atributos por el momento
      </td>
    </tr>
    <div class="my-2"></div>
  </ng-template>
</p-table>
