import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationService } from 'primeng/api';
import { Subscription, forkJoin } from 'rxjs';
import {
  CategoryProduct,
  NewCategory,
} from 'src/app/core/models/CategoryProduct';
import { StoreData } from 'src/app/core/models/StoreData';
import { AuthService } from 'src/app/services/auth.service';
import { CategoriesService } from 'src/app/services/categories.service';
import { RequestErrorTriggerService } from 'src/app/services/request-error-trigger.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit, OnDestroy {
  editId: number;
  isEditting = false;
  categoryForm: UntypedFormGroup;
  categories: CategoryProduct[];
  selectedCategories: CategoryProduct[];
  category: NewCategory;
  categorySelected: CategoryProduct;
  comercio: StoreData;
  uploadedFiles: any[] = [];
  loading = false;
  categoryDialog: boolean;
  submitted: boolean;
  headerPopup: string;
  idStore: number;
  displayLoading = false;
  @Input() hideLateralMenu: boolean;
  @Input() canWrite: boolean;
  showMenu: boolean = true;
  subs: Subscription[] = [];

  constructor(
    private categoriesService: CategoriesService,
    private sanitizer: DomSanitizer,
    private confirmationService: ConfirmationService,
    private formBuilder: UntypedFormBuilder,
    private errorService: RequestErrorTriggerService,
    private authService: AuthService,
    private spinnerService: SpinnerService
  ) {
    const { id } = this.authService.getLocalUserData();
    this.idStore = id;

    this.categoryForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      storeId: [this.idStore],
    });
  }

  ngOnInit(): void {
    if (this.hideLateralMenu) {
      this.showMenu = false;
    }
    this.loading = true;
    this.categories = [];
    this.comercio = JSON.parse(localStorage.getItem('storeData'));
    this.loadCategories();
  }

  get descriptionField(): AbstractControl {
    return this.categoryForm.get('description');
  }

  loadCategories(event?): void {
    this.spinnerService.loadSpinner.next(true);
    this.subs.push(
      this.categoriesService
        .getCategories(this.comercio.id)
        .subscribe((data) => {
          this.categories = data;
          this.loading = false;
          this.spinnerService.loadSpinner.next(false);
        })
    );
  }

  getSafeImage(base64Img): string {
    return `data:image/jpg;base64,${
      (this.sanitizer.bypassSecurityTrustResourceUrl(base64Img) as any)
        .changingThisBreaksApplicationSecurity
    }`;
  }

  openNew(): void {
    this.headerPopup = 'Nueva categoría';
    this.isEditting = false;
    this.categoryForm.reset();
    this.submitted = false;
    this.categoryDialog = true;
  }

  deleteSelectedCategories(): void {
    this.confirmationService.confirm({
      message:
        '¿Deseas eliminar las categorías seleccionadas? <span class="subheader">Esta acción no se puede deshacer.</span>',
      header: '',
      icon: 'icon-warning icon-4xl icon-yellow',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        this.spinnerService.loadSpinner.next(true);
        const allDeleteRequests = [];
        for (const category of this.selectedCategories) {
          allDeleteRequests.push(
            this.categoriesService.deleteCategory(this.idStore, category.id)
          );
        }
        this.subs.push(
          forkJoin(allDeleteRequests).subscribe(
            () => {
              this.loadCategories();
              this.selectedCategories = [];
            },
            (err) => {
              if (
                err.status === 400 ||
                err.status === 404 ||
                err.status === 409
              ) {
                this.handleErrorMessageMultiple(err.status);
                this.spinnerService.loadSpinner.next(false);
              }
            }
          )
        );
      },
    });
  }

  private handleErrorMessage(status): void {
    let message: string;
    switch (status) {
      case 400:
        message = 'Categoria no valido.';
        break;
      case 404:
        message = 'Categoria inexistente.';
        break;
      case 409:
        message =
          'No se puede borrar porque hay productos asociados a esta categoría.';
        break;
    }
    this.errorService.updateShowError({
      showError: true,
      message,
    });
  }

  private handleErrorMessageMultiple(status): void {
    let message: string;
    switch (status) {
      case 400:
        message = 'Una o más categorias seleccionadas ya no es válida.';
        break;
      case 404:
        message = 'Una o más categorias seleccionadas son inexistentes.';
        break;
      case 409:
        message =
          'No se puede borrar porque hay productos asociados a una o más de las categorías seleccionadas.';
        break;
    }
    this.errorService.updateShowError({
      showError: true,
      message,
    });
  }

  editCategory(category: CategoryProduct): void {
    this.editId = category.id;
    this.categorySelected = category;
    this.isEditting = true;
    this.headerPopup = category.name;
    const { name, description } = category;
    this.categoryForm.patchValue({
      name,
      description,
    });
    this.submitted = false;
    this.categoryDialog = true;
  }

  onDeleteCategory(): void {
    const category = this.categorySelected;
    this.deleteCategory(category);
  }

  deleteCategory(category: CategoryProduct): void {
    this.editId = category.id;
    this.categorySelected = category;

    this.confirmationService.confirm({
      message:
        '¿Deseas eliminar <span class="text-primary">' +
        this.categorySelected.name +
        '</span> de las categorias? <span class="subheader">Esta acción no se puede deshacer.</span>',
      header: '',
      icon: 'icon-warning icon-4xl icon-yellow',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        this.categoryDialog = false;
        this.spinnerService.loadSpinner.next(true);
        this.subs.push(
          this.categoriesService
            .deleteCategory(this.idStore, this.editId)
            .subscribe(
              () => {
                this.loadCategories();
              },
              (err) => {
                if (
                  err.status === 400 ||
                  err.status === 404 ||
                  err.status === 409
                ) {
                  this.handleErrorMessage(err.status);
                  this.spinnerService.loadSpinner.next(false);
                }
              }
            )
        );
      },
    });
  }

  hideDialog(event?): void {
    this.categoryDialog = false;
    this.submitted = false;
    this.isEditting = false;
  }

  submitCategory(): void {
    this.submitted = true;

    if (this.categoryForm.valid) {
      this.displayLoading = true;
      this.spinnerService.loadSpinner.next(true);
      if (!this.isEditting) {
        this.subs.push(
          this.categoriesService
            .createCategory(this.categoryForm.value)
            .subscribe((id) => {
              this.loadCategories();
              this.hideDialog();
              this.spinnerService.loadSpinner.next(false);
            })
        );
      } else {
        this.subs.push(
          this.categoriesService
            .editCategory(this.editId, this.categoryForm.value)
            .subscribe(
              (id) => {
                this.loadCategories();
                this.hideDialog();
                this.isEditting = false;
                this.spinnerService.loadSpinner.next(false);
              },
              (err) => {
                if (err.status === 400 || err.status === 404) {
                  this.handleErrorMessage(err.status);
                  this.spinnerService.loadSpinner.next(false);
                }
              }
            )
        );
      }
    }
  }

  async onUpload(event): Promise<void> {
    this.uploadedFiles = [];
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
    const logoData = await new Blob([this.uploadedFiles[0]], {
      type: this.uploadedFiles[0].type,
    }).arrayBuffer();
    const logoArr = new Uint8Array(logoData);
    const byteArr = Array.from(logoArr);

    this.categoryForm.patchValue({
      image: byteArr,
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => {
      s.unsubscribe();
    });
  }
}
