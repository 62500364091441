<div class="container-main">
  <div class="internal-body">
    <p-toast></p-toast>

    <div class="flex align-items-center">
      <div class="text-base lh-24 text-primary font-semibold flex align-items-center cursor-pointer" (click)="back()">
        <i class="fa-xl fa-regular fa-arrow-left text-primary mr-2"></i> Volver a Home
      </div>
    </div>

    <div class="table__container">
      <p-table #dt [value]="admins" [rows]="10" [paginator]="true" selectionMode="single" [loading]="loading"
               sortField="name" sortOrder="1" [rowHover]="true" dataKey="id" [globalFilterFields]="fieldsFilter"
               [rowsPerPageOptions]="[10,25,50]" currentPageReportTemplate="Mostrar" [showCurrentPageReport]="true"
               class="table">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-between table-header first-line">
            <h5 class="m-0 flex align-items-center">
              <i class="fa-light fa-users-gear fa-lg"></i>
              <p class="my-0 text-xl ml-3 font-semibold">ADMINISTRADORES</p>
              <i class="fa-solid fa-arrows-rotate text-primary cursor-pointer fa-lg ml-3 mr-3"
                 [ngClass]="{ 'spin': refresh }" (click)="refreshAdmins()"></i>
              <div class="box__total">
                Total <span class="text-primary">{{ admins?.length | number:'1.0-2' }}</span>
              </div>
            </h5>

            <div class="flex align-items-center">
              <div>
                <button pButton class="btn btn__primary btn__primary--bold btn__size--sm mr-3"
                        (click)="openNew()" label="Nuevo administrador" icon="fa-regular fa-circle-plus"></button>
              </div>

            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="id_admin">
              <div class="flex justify-content-between align-items-center">
                ID
                <p-sortIcon field="id_admin"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="lastName">
              <div class="flex justify-content-between align-items-center">
                Apellido
                <p-sortIcon field="lastName"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="name">
              <div class="flex justify-content-between align-items-center">
                Nombre
                <p-sortIcon field="name"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="email">
              <div class="flex justify-content-between align-items-center">
                Email
                <p-sortIcon field="email"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="username">
              <div class="flex justify-content-between align-items-center">
                Usuario
                <p-sortIcon field="username"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="reader">
              <div class="flex justify-content-between align-items-center">
                Lectura
                <p-sortIcon field="reader"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="writer">
              <div class="flex justify-content-between align-items-center">
                Escritura
                <p-sortIcon field="writer"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="lastLogin">
              <div class="flex justify-content-between align-items-center">
                Último ingreso
                <p-sortIcon field="lastLogin"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="updatedAt">
              <div class="flex justify-content-between align-items-center">
                Actualizado
                <p-sortIcon field="updatedAt"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="deleted">
              <div class="flex justify-content-between align-items-center">
                Eliminado
                <p-sortIcon field="deleted"></p-sortIcon>
              </div>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-admin>
          <tr class="table-row">
            <td>
              <p class="text-sm text-gray-1 lh-16 my-0">{{ admin.id_admin }}</p>
            </td>
            <td>
              <p class="text-sm text-gray-1 lh-16 my-0">{{ admin.lastName }}</p>
            </td>
            <td>
              <p class="text-sm text-gray-1 lh-16 my-0">{{ admin.name }}</p>
            </td>
            <td>
              <p class="text-sm text-gray-1 lh-16 my-0">{{ admin.email }}</p>
            </td>
            <td>
              <p class="text-sm text-gray-1 lh-16 my-0">{{ admin.username }}</p>
            </td>
            <td>
              <i *ngIf="admin.reader" class="fas fa-circle-check text-success fa-lg"></i>
              <i *ngIf="!admin.reader" class="fas fa-circle-xmark text-danger fa-lg"></i>
            </td>
            <td>
              <i *ngIf="admin.writer" class="fas fa-circle-check text-success fa-lg"></i>
              <i *ngIf="!admin.writer" class="fas fa-circle-xmark text-danger fa-lg"></i>
            </td>
            <td>{{ admin.lastLogin | date: "dd/MM/yyyy - HH:mm" }}</td>
            <td>{{ admin.updatedAt | date: "dd/MM/yyyy - HH:mm" }}</td>
            <td>
              <p-checkbox binary="true" value="admin.id" [ngModel]="admin.deleted" disabled></p-checkbox>
            </td>
            <td>
              <button [disabled]="admin.deleted" pButton iconOnly icon="fa fa-light fa-edit" class="text-primary"
                      (click)="editAdmin(admin)"></button>
              <button [disabled]="admin.deleted" pButton iconOnly icon="fa fa-light fa-trash-can" class="text-primary"
                      (click)="deleteAdmin(admin)"></button>
            </td>
          </tr>
          <div class="my-2"></div>
        </ng-template>
      </p-table>
    </div>
  </div>

  <p-dialog [(visible)]="adminDialog" [style]="{ width: '550px' }" header="{{ headerPopup }}" [modal]="true">
    <ng-template pTemplate="content">
      <form [formGroup]="adminForm" (ngSubmit)="submitAdmin()">
        <div class="field__item field--modal" [ngClass]="
            validateInputClass(adminForm, 'name', formHasBeenSubmitted)
          ">
          <div class="p-float-label">
            <input formControlName="name" id="name" type="text" maxlength="{{ maxPeopleNameLength }}" pInputText/>
            <label for="name" class="title-float">Nombre</label>
            <app-show-errors [page]="'admin'" [ctrl]="adminForm.get('name')"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
          <div *ngIf="formHelper.shoudShowInputCount(adminForm, 'name', formHasBeenSubmitted)"
               class="field__item--info">
            <span>
              {{ nameValueField.value ? nameValueField.value.length : 0 }}/{{
              maxPeopleNameLength
              }}
            </span>
          </div>
        </div>

        <div class="field__item field--modal" [ngClass]="
            validateInputClass(adminForm, 'lastName', formHasBeenSubmitted)
          ">
          <div class="p-float-label">
            <input pInputText formControlName="lastName" id="lastName" maxlength="{{ maxPeopleLastNameLength }}"
                   type="text"/>
            <label for="lastName" class="title-float">Apellido</label>
            <app-show-errors [page]="'admin'" [ctrl]="adminForm.get('lastName')"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
          <div *ngIf="formHelper.shoudShowInputCount(adminForm, 'lastName', formHasBeenSubmitted)"
               class="field__item--info">
            <span>
              {{
              lastNameValueField.value ? lastNameValueField.value.length : 0
              }}/{{ maxPeopleLastNameLength }}
            </span>
          </div>
        </div>

        <div class="field__item field--modal" [ngClass]="
            validateInputClass(adminForm, 'email', formHasBeenSubmitted)
          ">
          <div class="p-float-label">
            <input pInputText formControlName="email" id="email" type="text"/>
            <label for="email" class="title-float">Email</label>
            <app-show-errors [page]="'admin'" [ctrl]="adminForm.get('email')"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
        </div>

        <div class="field__item field--modal" [ngClass]="
            validateInputClass(adminForm, 'username', formHasBeenSubmitted)
          ">
          <div class="p-float-label">
            <input pInputText formControlName="username" id="username" maxlength="{{ maxUsernameLength }}"
                   type="text"/>
            <label for="username" class="title-float">Usuario</label>
            <app-show-errors [page]="'admin'" [ctrl]="adminForm.get('username')"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
          <div *ngIf="formHelper.shoudShowInputCount(adminForm, 'username', formHasBeenSubmitted)"
               class="field__item--info">
            <span>
              {{
              usernameValueField.value ? usernameValueField.value.length : 0
              }}/{{ maxUsernameLength }}
            </span>
          </div>
        </div>

        <div class="field__item field--modal"
             [ngClass]="validateInputClass(adminForm, 'password', formHasBeenSubmitted)" *ngIf="!isEditing">
          <div class="p-float-label">
            <input pInputText formControlName="password" id="password" type="pass" minlength="{{ minPasswordLength }}"
                   maxlength="{{ maxPasswordLength }}" type="text" required/>
            <label for="password" class="title-float">Contraseña</label>
            <app-show-errors [page]="'admin'" [ctrl]="adminForm.get('password')"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
          <div
            *ngIf="!adminForm.get('password').valid"
            class="field__item--info">
            <span>Debe tener mínino
              {{ minPasswordLength }} caracteres.</span>
          </div>
        </div>

        <div class="col-12 p-0">
          <p>Permisos</p>
          <div class="items-privileges">
            <div class="content-permission mr-2">
              <span>Lectura</span>
              <p-inputSwitch [formControl]="adminForm.controls['reader']" (onChange)="checkReader($event)">
              </p-inputSwitch>
            </div>
            <div class="content-permission">
              <span>Escritura</span>
              <p-inputSwitch [formControl]="adminForm.controls['writer']" (onChange)="checkWriter($event)">
              </p-inputSwitch>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <button pButton class="btn btn__secondary btn__secondary--bold mr-3" (click)="onCloseModal()"
              label="Cancelar"></button>
      <button pButton class="btn btn__primary btn__primary--bold" [class.spinner]="loading"
              (click)="submitAdmin()" label="Guardar"></button>
    </ng-template>
  </p-dialog>

  <p-confirmDialog key="confirmDelete" [style]="{ width: '450px' }" acceptLabel="Confirmar" rejectLabel="Cancelar"
                   rejectButtonStyleClass="btn btn__secondary btn__secondary--bold"
                   acceptButtonStyleClass="btn btn__primary">
  </p-confirmDialog>
</div>
