import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit, AfterViewInit {
  @ViewChild('spinner') spinner: ElementRef;
  @Input() type: number = 1;
  @Input() showSpinner: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.spinner) {
      this.spinner.nativeElement.setAttribute('data-version', this.type);
    }
  }
}
