import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

import {AuthGuard} from 'src/app/core/guards/auth.guard';
import {AuthService} from 'src/app/services/auth.service';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {ModalConfig} from 'src/app/core/models/ModalConfig';
import {SpinnerService} from 'src/app/services/spinner.service';
import {StoreService} from 'src/app/services/store.service';
import {Subscription} from 'rxjs';
import {UserService} from 'src/app/services/user.service';
import {MobbexCredentialRequest} from '../../../../../core/models/MobbexCredentialRequest';
import { UpdateStoreTaxIdentifier } from 'src/app/core/models/UpdateStoreTaxIdentifier';

@Component({
  selector: 'app-change-cuit',
  templateUrl: './change-cuit.component.html',
  styleUrls: ['./change-cuit.component.scss'],
})
export class ChangeCuitComponent implements OnInit, OnDestroy {
  idStore: number;
  nameStore: string;
  currentTaxIdentifier: string;
  changeCuitForm: UntypedFormGroup;
  submitting = false;
  changeCuitDetails: object[] = [];
  confirmDialog: boolean;
  modalConfig: ModalConfig;
  showModalConfirm = false;
  changeCuitSub: Subscription = null;
  isSuperAdmin: boolean;
  isReader: boolean;
  isWriter: boolean;
  formHasBeenSubmitted: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: UntypedFormBuilder,
    private spinnerService: SpinnerService,
    private authSrv: AuthService,
    private userSrv: UserService,
    private storeSrv: StoreService,
    private validateInputService: InputValidationService
  ) {
    this.changeCuitForm = this.formBuilder.group({
      taxIdentifier: ['', [Validators.required, this.cuitValidator()]],
    });
  }

  ngOnInit(): void {
    this.idStore = this.config.data.id;
    this.currentTaxIdentifier = this.config.data.taxIdentifier;
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    this.isReader = this.userSrv.isReader();
    this.isWriter = this.userSrv.isWriter();
    this.loadData();
    this.checkIsWriter();

    this.changeCuitForm.controls.taxIdentifier.valueChanges.subscribe(() => {
      this.formHasBeenSubmitted = false;
    });
  }

  get taxIdentifierField(): AbstractControl {
    return this.changeCuitForm.get('taxIdentifier')
  }

  cuitValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const cuit: string = control.value.toString().replaceAll('_', '').split('-').join('');
      return control.value && !this.storeSrv.validCuit(cuit)
        ? {cuitValidator: true}
        : null;
    };
  }

  ngOnDestroy(): void {
    if (this.changeCuitSub) {
      this.changeCuitSub.unsubscribe();
    }
  }

  checkIsWriter(): void {
    if (!this.isWriter) {
      this.changeCuitForm.controls.cuit.disable();
    }
  }

  loadData(): void {
    this.changeCuitForm.patchValue({
      taxIdentifier: this.currentTaxIdentifier,
    });
  }

  confirmNewEmail(): void {
    this.changeCuitDetails = [];
    Object.keys(this.changeCuitForm.controls).forEach((key) => {
      this.changeCuitDetails.push({
        key,
        value: this.changeCuitForm.controls[key].value,
      });
    });
    this.confirmDialog = true;
  }

  onClose(): void {
    this.ref.close(false);
  }

  submitChange(): void {
    this.formHasBeenSubmitted = true;
    this.confirmDialog = false;
    this.submitting = true;

    if (this.changeCuitForm.valid) {
      if (this.validForm()) {
        this.spinnerService.loadSpinner.next(true);
        const payload: UpdateStoreTaxIdentifier = {
          taxIdentifier: this.taxIdentifierField.value.toString().replaceAll('-', '')
        }

        this.changeCuitSub = this.storeSrv.changeCuit(payload, this.idStore).subscribe({
          next: (d) => {
            const data = 'successfulEmailChange';
            this.spinnerService.loadSpinner.next(false);
            this.ref.close(true);
            window.location.reload();
          },
          error: (err) => {
  
            if (err.status === 409) {
              this.taxIdentifierField.setErrors({'sameCuit': true});
            } else if (err.status === 400) {
              this.taxIdentifierField.setErrors({'cuitAlreadyExist': true});
            } else {
              this.taxIdentifierField.setErrors({'cuitGeneralError': true});
            }
            this.taxIdentifierField.setErrors({'cuitValidator': true});

            this.submitting = false;
            this.spinnerService.loadSpinner.next(false);
          }
        }
        );
      } else {
        this.spinnerService.loadSpinner.next(false);
        this.taxIdentifierField.setErrors({'sameCuit': true});
        this.submitting = false;
      }
    } else {
      this.taxIdentifierField.setErrors({'cuitValidator': true});
    }
  }

  onModalResponse(e): void {
    this.showModalConfirm = false;
  }

  validForm(): boolean {
    return this.taxIdentifierField.value.toString().replaceAll('-', '') !== this.currentTaxIdentifier ? true : false;
  }

  displayProperty(prop): boolean {
    const valid = ['email', 'newEmail'];
    return valid.includes(prop);
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }
}
