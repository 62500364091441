import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Shift } from 'src/app/core/models/Shitf';

@Component({
  selector: 'app-shifts-list',
  templateUrl: './shifts-list.component.html',
  styleUrls: ['./shifts-list.component.scss'],
})
export class ShiftsListComponent implements OnInit {
  @Input() shifts: Shift[] = [];
  @Input() fromAdmin: boolean;
  @Output() reloadShifts: EventEmitter<void> = new EventEmitter<void>();
  refresh: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  onRefresh(): void {
    this.reloadShifts.emit();
    this.refresh = true;
    setTimeout(() => {
      this.refresh = false;
    }, 2000);
  }
}
