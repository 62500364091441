import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from 'src/app/services/user.service';
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  isSuperAdmin: boolean;
  isReader: boolean;
  isWriter: boolean;

  constructor(
    private router: Router,
    private userSrv: UserService,
    private authSrv: AuthService
  ) {
  }

  ngOnInit(): void {
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    this.isReader = this.userSrv.isReader();
    this.isWriter = this.userSrv.isWriter();
  }

  goToStores(): void {
    this.router.navigate(['/stores']);
  }

  goToCustomers(): void {
    this.router.navigate(['/users']);
  }

  goToTransactions(): void {
    this.router.navigate(['/transactions']);
  }

  goToEvents(): void {
    this.router.navigate(['/events']);
  }

  goToReservations(): void {
    this.router.navigate(['/bookings']);
  }

  goToCategories(): void {
    this.router.navigate(['/categories']);
  }

  goToAdmins(): void {
    this.router.navigate(['/administrators']);
  }

  goToSales(): void {
    this.router.navigate(['/sales']);
  }
}
