import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {StoreData} from 'src/app/core/models/StoreData';
import {AuthService} from 'src/app/services/auth.service';
import {StoreService} from 'src/app/services/store.service';
import {UserService} from 'src/app/services/user.service';

@Component({
  selector: 'app-manage-menu',
  templateUrl: './manage-menu.component.html',
  styleUrls: ['./manage-menu.component.scss'],
})
export class ManageMenuComponent implements OnInit, OnDestroy {
  loading = false;
  hideLateralMenu = true;
  idStore: number;
  store: StoreData;
  getStoreSub: Subscription = null;
  isSuperAdmin: boolean;
  isReader: boolean;
  isWriter: boolean;

  constructor(
    private storeSrv: StoreService,
    private authSrv: AuthService,
    private userSrv: UserService
  ) {
  }

  ngOnInit(): void {
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    this.isReader = this.userSrv.isReader();
    this.isWriter = this.userSrv.isWriter();

    this.idStore = Number(sessionStorage.getItem('storeId'));

    this.storeSrv.getStoreById(this.idStore).subscribe((data) => {
      this.store = data;
      this.authSrv.updateUser(this.store);
      this.loading = true;
    });
  }

  ngOnDestroy(): void {
    if (this.getStoreSub) {
      this.getStoreSub.unsubscribe();
    }
  }
}
