import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Pageable} from '../core/models/Pageable';
import {RefundTrxPayload} from '../core/models/RefundTrxPayload';
import {TransactionFeeOutput} from '../core/models/TransactionFeeOutput';
import {TransactionOutputForExport} from "../core/models/TransactionOutputForExport";
import {ExportTransactionsRequest} from "../core/models/events/ExportTransactionsRequest";

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(private httpC: HttpClient) {
  }

  public getStoreTransactions(
    tenant: number,
    idStore: number,
    pageNumber?: number,
    multiSortFields?: any[],
    pageSize?: number,
    globalFilter?: string
  ): Observable<Pageable> {
    const httpHeaders = new HttpHeaders().set('Accept', 'application/json');
    let httpParams = new HttpParams();
    let sortFields = {sortFieldsWithOrder: []};
    if (!multiSortFields || multiSortFields.length < 1) {
      const initialSortField = 'created_at:DESC';
      sortFields.sortFieldsWithOrder.push(`${initialSortField}`);
    } else {
      let i = 0;
      while (i < multiSortFields.length) {
        let nameField = multiSortFields[i]['name'];
        let order = multiSortFields[i]['order'] > 0 ? 'ASC' : 'DESC';
        sortFields.sortFieldsWithOrder.push(`${nameField}:${order}`);
        i++;
      }
    }
    const pageNro = pageNumber ? pageNumber : 0;
    httpParams = httpParams.set('pageNumber', pageNro.toString());
    httpParams = httpParams.set('size', pageSize != undefined ? pageSize : 10);
    httpParams = httpParams.set('sortFields', sortFields.sortFieldsWithOrder.join(','));
    httpParams = httpParams.set('search', globalFilter);
    return this.httpC.get<Pageable>(
      environment.apiAGUrl + `/transactions-by-store/${idStore}/${tenant}`,
      {headers: httpHeaders, params: httpParams}
    );
  }

  public getAllTransactions(
    tenant: number,
    pageNumber?: number,
    multiSortFields?: any[],
    pageSize?: number,
    globalFilter?: string
  ): Observable<Pageable> {
    const httpHeaders = new HttpHeaders().set('Accept', 'application/json');
    let httpParams = new HttpParams();
    let sortFields = {sortFieldsWithOrder: []};
    if (!multiSortFields || multiSortFields.length < 1) {
      const initialSortField = 'created_at:DESC';
      sortFields.sortFieldsWithOrder.push(`${initialSortField}`);
    } else {
      let i = 0;
      while (i < multiSortFields.length) {
        let nameField = multiSortFields[i]['name'];
        let order = multiSortFields[i]['order'] > 0 ? 'ASC' : 'DESC';
        sortFields.sortFieldsWithOrder.push(`${nameField}:${order}`);
        i++;
      }
    }
    const pageNro = pageNumber ? pageNumber : 0;
    httpParams = httpParams.set('pageNumber', pageNro.toString());
    httpParams = httpParams.set('size', pageSize != undefined ? pageSize : 10);
    httpParams = httpParams.set('sortFields', sortFields.sortFieldsWithOrder.join(','));
    httpParams = httpParams.set('search', globalFilter);
    return this.httpC.get<Pageable>(
      environment.apiAGUrl + `/transactions/paginated/${tenant}`,
      {headers: httpHeaders, params: httpParams}
    );
  }

  public getTransactionsFeeByMonthAndYearAndCommerceId(month: number, year: number, commerceId: number, tenant: number): Observable<TransactionFeeOutput[]> {
    const httpHeaders = new HttpHeaders().set('Accept', 'application/json');
    let httpParams = new HttpParams();
    if (commerceId) {
      httpParams = httpParams.set('storeId', commerceId);
    }
    httpParams = httpParams.set('month', month);
    httpParams = httpParams.set('year', year);
    return this.httpC.get<TransactionFeeOutput[]>(
      environment.apiAGUrl + `/transactions_fee/export/${tenant}`,
      {headers: httpHeaders, params: httpParams}
    );
  }

  public getTransactionsByMonthAndYearAndCommerceId(month: number, year: number, commerceId: number, tenant: number): Observable<TransactionOutputForExport[]> {
    const httpHeaders = new HttpHeaders().set('Accept', 'application/json');
    let httpParams = new HttpParams();
    if (commerceId) {
      httpParams = httpParams.set('storeId', commerceId);
    }
    httpParams = httpParams.set('month', month);
    httpParams = httpParams.set('year', year);
    return this.httpC.get<TransactionOutputForExport[]>(
      environment.apiAGUrl + `/transactions/export/${tenant}`,
      {headers: httpHeaders, params: httpParams}
    );
  }

  public getTransactionsByEventId(exportTransactionsRequest: ExportTransactionsRequest): Observable<TransactionOutputForExport[]> {
    return this.httpC.post<TransactionOutputForExport[]>(
      environment.apiAGUrl + `/transactions/by_event/export`,
      exportTransactionsRequest
    );
  }

  public cancelTransaction(payload: RefundTrxPayload, tenant: number): Observable<any> {
    return this.httpC.patch<any>(
      environment.apiAGUrl + `/transactions/refund/${tenant}`,
      payload
    );
  }
}
