import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import JSONStrings from 'src/app/core/internalization/es.json';

@Component({
  selector: 'app-show-errors',
  templateUrl: './show-errors.component.html',
  styleUrls: ['./show-errors.component.scss'],
})
export class ShowErrorsComponent implements OnInit {
  @Input() formHasBeenSubmitted = false;
  @Input() page = 'generic';
  @Input() ctrl: UntypedFormControl;

  /* THIS CONFIG IS FOR CUSTOM TRANSLATE */
  @Input() customString = false;
  @Input() customKey = '';

  constructor() {}

  ngOnInit(): void {}

  shouldShowErrors(): boolean {
    return (
      (this.ctrl && this.ctrl.invalid && this.formHasBeenSubmitted) ||
      (this.ctrl.touched && this.ctrl.dirty && this.ctrl.invalid)
    );
  }

  listOfErrors(): string[] {
    if (this.customString) {
      if (this.customKey) {
        return JSONStrings[this.page][this.customKey];
      } else {
        console.error('This component needs customKey when custom is TRUE');
      }
    } else {
      return Object.keys(this.ctrl.errors).map((err) => {
        return JSONStrings[this.page][err];
      });
    }
  }
}
