<form [formGroup]="mesasForm">
  <div class="header">
    <div class="flex align-items-center justify-content-start">
      <p class="text-xl font-semibold text-gray-1 lh-16 my-0">MESAS</p>
      <div class="box__total ml-16">
        Total {{ tableNumer }}
      </div>
    </div>

    <div class="flex justify-content-end">
      <!--*ngIf="accountVerified"-->
      <div class="switch-wrapper">
        <div class="flex align-items-center">
          <div class="tooltip-cursor" pTooltip="Habilitado: Al escanear el QR de la mesa, tus clientes podrán ver el menú
            dinámicamente y hacer el pedido directamente desde la App. Deshabilitado: Tus clientes podrán ver el menú
            dinámicamente, pero no podrán hacer el pedido desde la App." tooltipPosition="top">
            <i class="fa-light fa-circle-info"></i>
          </div>
          <label for="tableAvailable" class="label-switch mr-2">Habilitar</label>
        </div>
        <div class="switch-container">
          <p-inputSwitch
            name="tableAvailable"
            formControlName="tableAvailable"
            [disabled]="fromAdmin"
          ></p-inputSwitch>
        </div>
      </div>
    </div>
  </div>

  <app-mesa [fromAdmin]="fromAdmin" (tableNumer)="onTableNumerChange($event)"></app-mesa>

</form>
