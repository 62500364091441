<p-dialog [(visible)]="showModal" [style]="{ width: widthModal }" [header]="config.headerText ? config.headerText : ''" [modal]="true"
  [draggable]="false" (onHide)="onHide()">
  <ng-template pTemplate="content">
    <i *ngIf="config.icon && config.icon === 'warning'" class="icon-warning icon-yellow icon-3xl mb-3"></i>
    <i *ngIf="config.icon && config.icon !== 'warning'" class="{{ config.icon }}"></i>
    <p class="header-after-text" *ngIf="config.headerTextAfterIcon">{{ config.headerTextAfterIcon }}</p>
    <p class="subtitle">{{ config.content }}</p>
  </ng-template>

  <ng-template pTemplate="footer" *ngIf="config.buttons === 'single'">
    <button pButton [label]="config.textBtn ? config.textBtn : 'Confirmar'" class="btn btn__primary"
      (click)="onAccept()"></button>
  </ng-template>

  <ng-template pTemplate="footer" *ngIf="config.buttons === 'confirm'">
    <button pButton label="Cancelar" class="btn btn__secondary btn__secondary--bold mr-3"
      (click)="onCancel()"></button>
    <button pButton  [label]="config.textBtn ? config.textBtn : 'Confirmar'" class="btn btn__primary btn__secondary--bold" (click)="onAccept()"></button>
  </ng-template>
</p-dialog>
