<div class="ui active centered inline loader" *ngIf="loading"></div>

<div class="container" #myDiv>
  <div class="form__container">
    <div class="form__header">
      <img src="../../../../assets/logo-blackid-admin.svg" class="logo" alt="BLACK iD"/>
    </div>

    <div class="form__wrapper">
      <div class="form__title">
        <p class="text-gray-1 text-lg lh-24 font-semibold my-0">
          Iniciar sesión
        </p>
      </div>

      <form class="form" [formGroup]="loginForm" (ngSubmit)="submitLogin()" autocomplete="off">
        <div class="field__item" [ngClass]="
            validateInputClass(loginForm, 'username', formHasBeenSubmitted)
          ">
          <div class="p-float-label">
            <input #username formControlName="username" id="username" type="text" pInputText autocomplete="off"
                   [readonly]="isReadOnly"/>
            <label for="username" class="title-float">Usuario</label>
            <app-show-errors [page]="'login'" [ctrl]="loginForm.get('username')"
                             [formHasBeenSubmitted]="formHasBeenSubmitted"></app-show-errors>
          </div>
        </div>

        <div class="field__item" [ngClass]="
            validateInputClass(loginForm, 'password', formHasBeenSubmitted)
          ">
          <div class="p-float-label">
            <input formControlName="password" [type]="hashPassword ? 'password' : 'text'" id="password" pInputText/>
            <label for="password" class="title-float">Contraseña</label>
          </div>
          <div class="view--password">
            <i class="fa-light fa-eye-slash text-gray-35" *ngIf="hashPassword" (click)="hashPassword = false"></i>
            <i class="fa-light fa-eye text-gray-35" *ngIf="!hashPassword" (click)="hashPassword = true"></i>
          </div>
        </div>

        <div class="w-100 flex justify-content-center">
          <button pButton type="submit" [disabled]="!loginForm.valid || loading" label="Ingresar"
                  class="btn btn__primary"></button>
        </div>

        <div class="error__login mt-4" *ngIf="showError">
          <div class="icon__warning">
            <i class="fa-solid fa-triangle-exclamation fa-lg text-error mr-2"></i>
          </div>
          <span>{{ contentError }}</span>
          <div class="close" (click)="close()">
            <i class="fa-light fa-xmark fa-xs text-gray-1"></i>
          </div>
        </div>
      </form>
    </div>
  </div>

  <p-dialog header="Atención" [(visible)]="displayDialog" [style]="{ width: '400px' }">
    {{ contentDialog }}
  </p-dialog>
</div>
