import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {UserService} from "../../../services/user.service";
import {SpinnerService} from "../../../services/spinner.service";
import {StoreService} from "../../../services/store.service";
import {Subscription} from "rxjs";
import {SalesAnalyticsRequest} from "../../../core/models/SalesAnalyticsRequest";
import {SalesService} from "../../../services/sales.service";
import {SalesInfo} from "../../../core/models/SalesInfo";
import {StoreOutputShortForAdmin} from "../../../core/models/StoreOutputShortForAdmin";
import {SalesInfoSpreadsheet} from "../../../core/models/SalesInfoSpreadsheet";
import * as FileSaver from 'file-saver';
import {Tenant} from 'src/app/core/models/Tenant';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit {
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    let keyPressed = event.code;
    if (keyPressed === 'Escape') {
      this.showMenuFilters = false;
    }
  }

  showMenuFilters = false;
  storeName = 'BLACK iD';
  isSuperAdmin: boolean;
  isReader: boolean;
  isWriter: boolean;
  stores: StoreOutputShortForAdmin[] = null;
  subs: Subscription[] = [];
  tenantStore: StoreOutputShortForAdmin = null;
  storeSelected: number;
  now: Date;
  isSelectedApproved = false;
  isSelectedRefund = false;
  salesAnalyticsRequest: SalesAnalyticsRequest = null;
  status = null;
  salesInfo: SalesInfo = null;
  loading = false;
  minDate: Date;
  initialDate: Date;
  salesInfoSpreadsheet: SalesInfoSpreadsheet = null;
  from: Date = null;
  to: Date = null;
  accountOriginatingSelected = false;
  accountCollector1Selected = false;
  accountCollector2Selected = false;
  accountSelected = null;

  tenantIdSelected: number = null;
  tenantSelected: Tenant = null;
  tenantList: Tenant[] = [];

  constructor(
    private router: Router,
    private authSrv: AuthService,
    private userSrv: UserService,
    private spinnerSrv: SpinnerService,
    private storeSrv: StoreService,
    private salesSrv: SalesService
  ) {
    this.now = new Date();
    this.minDate = new Date(2021, 10, 1);
    this.initialDate = new Date(this.now.getTime() - 2629800000)
  }

  ngOnInit(): void {
    this.spinnerSrv.loadSpinner.next(true);
    this.loading = true;
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    this.isReader = this.userSrv.isReader();
    this.isWriter = this.userSrv.isWriter();
    this.getTenantList();
  }

  getTenantList() {
    this.storeSrv.getTenantList().subscribe({
      next: (data: Tenant[]) => {
        this.tenantList = data;
        this.tenantSelected = this.tenantList[0];
        this.tenantIdSelected = this.tenantList[0].id;
        this.tenantStore = new StoreOutputShortForAdmin(this.tenantSelected.storeOwner !== null ? this.tenantSelected.storeOwner : 0, this.tenantSelected.name);
        this.storeSelected = this.tenantStore.idStore != null ? this.tenantStore.idStore : 0;
        this.getAllStores(this.tenantIdSelected);
      }
    });
  }

  getAllStores(tenantId: number): void {
    this.stores = null;
    if (this.isReader === true || this.isSuperAdmin) {
      this.spinnerSrv.loadSpinner.next(true);
      this.subs.push(
        this.storeSrv.getStoresShort(tenantId).subscribe({
          next: (data: StoreOutputShortForAdmin[]) => {
            this.stores = data;
            this.storeSelected = this.tenantStore.idStore != null ? this.tenantStore.idStore : 0;
            this.stores = this.stores.filter(st => st.idStore !== this.tenantStore.idStore);
            this.stores.unshift(this.tenantStore);
            this.applyFilter();
          },
          error: err => {
            this.spinnerSrv.loadSpinner.next(false);
          }
        })
      );
    } else {
      // this.alertNotAuthorized();
    }
  }

  back(): void {
    this.router.navigate(['home']);
  }

  openFilters() {
    this.showMenuFilters = !this.showMenuFilters;
  }

  selectStore(event) {
    this.storeSelected = event.value;
  }

  removeFilter(filter) {
    if (filter === 'date') {
      this.from = null;
      this.to = null;
      this.applyFilter();
    } else if (filter === 'store') {
      this.storeSelected = this.tenantStore.idStore != null ? this.tenantStore.idStore : 0;
      this.applyFilter();
    } else if (filter === 'status') {
      this.isSelectedApproved = false;
      this.isSelectedRefund = false;
      this.status = null;
    } else if (filter === 'account') {
      this.accountOriginatingSelected = false;
      this.accountCollector1Selected = false;
      this.accountCollector2Selected = false;
      this.accountSelected = null;
    }
    this.applyFilter();
  }

  selectAccount(event, account) {
    if (account === 'ao') {
      this.accountOriginatingSelected = event.checked;
      if (event.checked === true) {
        this.accountSelected = 0;
        this.accountCollector1Selected = false;
        this.accountCollector2Selected = false;
      }
    } else if (account === 'ac1') {
      this.accountCollector1Selected = event.checked;
      if (event.checked === true) {
        this.accountSelected = 1;
        this.accountOriginatingSelected = false;
        this.accountCollector2Selected = false;
      }
    } else if (account === 'ac2') {
      this.accountCollector2Selected = event.checked;
      if (event.checked === true) {
        this.accountSelected = 2;
        this.accountOriginatingSelected = false;
        this.accountCollector1Selected = false;
      }
    }
    if (event.checked === false) {
      this.accountSelected = null;
    }
  }

  checkApproved(event) {
    this.isSelectedApproved = event.checked;
    this.isSelectedRefund = false;
    if (!event.checked) {
      this.status = null;
    } else {
      this.status = 'APPROVED';
    }
  }

  checkRefund(event) {
    this.isSelectedRefund = event.checked;
    this.isSelectedApproved = false;
    if (!event.checked) {
      this.status = null;
    } else {
      this.status = 'REFUND';
    }
  }

  applyFilter() {
    if (this.stores) {
      this.spinnerSrv.loadSpinner.next(true);
      this.showMenuFilters = false
      this.salesAnalyticsRequest = this.buildPayloadFilters();
      this.salesSrv.getSalesAnalytics(this.salesAnalyticsRequest).subscribe({
          next: (data: SalesInfo) => {
            this.salesInfo = data;
            this.spinnerSrv.loadSpinner.next(false);
            this.loading = false;
          }
        }
      )
    }
  }

  buildPayloadFilters() {
    const salesAnalyticsRequest = new SalesAnalyticsRequest();
    salesAnalyticsRequest.store = this.storeSelected;
    salesAnalyticsRequest.from = this.from !== null ? this.from : this.initialDate;
    salesAnalyticsRequest.to = this.to !== null ? this.to : this.now;
    salesAnalyticsRequest.transactionStatus = this.status;
    salesAnalyticsRequest.accountCollector = this.accountSelected;
    salesAnalyticsRequest.tenantId = this.tenantIdSelected;
    this.salesAnalyticsRequest = salesAnalyticsRequest;
    return salesAnalyticsRequest;
  }

  closeFilters() {
    this.storeSelected = this.tenantStore.idStore != null ? this.tenantStore.idStore : 0;
    this.from = null;
    this.to = null;
    this.isSelectedApproved = false;
    this.isSelectedRefund = false;
    this.showMenuFilters = false;
    this.status = null;
  }

  getStoreNameSelected() {
    if (this.stores) {
      const storeFound = this.stores.find(s => s.idStore === this.storeSelected);
      if (storeFound) {
        return storeFound.name;
      }
    }
  }

  downloadExcel() {
    this.loading = true;
    this.spinnerSrv.loadSpinner.next(true);
    this.salesAnalyticsRequest = this.buildPayloadFilters();
    this.salesSrv.getSalesAnalyticsForSpreadsheet(this.salesAnalyticsRequest).subscribe({
      next: (res: SalesInfoSpreadsheet) => {
        this.salesInfoSpreadsheet = res;
        this.exportExcel();
        this.spinnerSrv.loadSpinner.next(false);
        this.loading = false;
      },
      error: (err) => {
        this.spinnerSrv.loadSpinner.next(false);
        this.loading = false;
      }
    })
  }

  formatDateToName(date: Date) {
    const day = date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate()
    const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1).toString() : date.getMonth() + 1
    return date.getFullYear().toString() + month.toString() + day.toString();
  }

  exportExcel() {
    const from = this.from !== null ? this.formatDateToName(this.from) : this.formatDateToName(this.minDate);
    const to = this.to !== null ? this.formatDateToName(this.to) : this.formatDateToName(this.now);
    const fileName = 'BlackID_Sales_' + from + '-' + to;
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.salesInfoSpreadsheet.daySalesInfo);
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer: any = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      this.saveAsExcelFile(excelBuffer, fileName);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  changeTenant(event) {
    this.tenantIdSelected = event.value;
    this.tenantSelected = this.tenantList.find(te => te.id === this.tenantIdSelected);
    this.tenantStore = new StoreOutputShortForAdmin(this.tenantSelected.storeOwner !== null ? this.tenantSelected.storeOwner : 0, this.tenantSelected.name);
    this.stores.slice(0);
    this.stores.unshift(this.tenantStore);
    this.getAllStores(this.tenantIdSelected);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

}
