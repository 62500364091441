import {Component, Input, OnInit} from '@angular/core';

import {DialogService} from 'primeng/dynamicdialog';
import {
  PaymentsMercadopagoConfigDialogComponent
} from '../payment-mercadopago-config-dialog/payments-mercadopago-config-dialog.component';
import {
  PaymentsMobbexConfigDialogComponent
} from '../payment-mobbex-config-dialog/payments-mobbex-config-dialog.component';
import {
  PaymentsPagounoConfigDialogComponent
} from '../payments-pagouno-config-dialog/payments-pagouno-config-dialog.component';
import {StoreData} from 'src/app/core/models/StoreData';
import { PaymentsStripeConfigDialogComponent } from '../payments-stripe-config-dialog/payments-stripe-config-dialog.component';

@Component({
  selector: 'app-payments-platform',
  templateUrl: './payments-platform.component.html',
  styleUrls: ['./payments-platform.component.scss'],
  providers: [DialogService],
})
export class PaymentsPlatformComponent implements OnInit {
  showOpt: boolean;
  storeId: number;
  @Input() storeData: StoreData;

  constructor(
    private dialogService: DialogService
  ) {
  }

  ngOnInit(): void {
    this.storeId = Number(sessionStorage.getItem('storeId'));
  }

  pagoUnoConfiguration(store: StoreData): void {
    this.showOpt = false;
    const ref = this.dialogService.open(PaymentsPagounoConfigDialogComponent, {
      header: 'Configurar Pago Uno',
      data: {
        storeData: store,
      },
      width: '552px !important',
      closable: true,
      styleClass: 'config-dialog__pago-uno',
    });
  }

  mobbexConfiguration(store: StoreData): void {
    this.showOpt = false;
    const ref = this.dialogService.open(PaymentsMobbexConfigDialogComponent, {
      header: 'Configurar Mobbex',
      data: {
        storeData: store,
      },
      width: '552px !important',
      closable: true,
      styleClass: 'config-dialog__mobbex',
    });
  }

  mercadopagoConfiguration(store: StoreData): void {
    this.showOpt = false;
    const ref = this.dialogService.open(PaymentsMercadopagoConfigDialogComponent, {
      header: 'Configurar Mercado Pago',
      data: {
        storeData: store,
      },
      width: '552px !important',
      closable: true,
      styleClass: 'config-dialog__mercadopago',
    });
  }

  stripeConfiguration(store: StoreData): void {
    this.showOpt = false;
    const ref = this.dialogService.open(PaymentsStripeConfigDialogComponent, {
      header: 'Configurar Stripe',
      data: {
        storeData: store,
      },
      width: '552px !important',
      closable: true,
      styleClass: 'config-dialog__mercadopago',
    });
  }


}
