import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CategoryEventResponse} from 'src/app/core/models/CategoryEventResponse ';
import {DomSanitizer} from '@angular/platform-browser';
import {EventsService} from 'src/app/services/events.service';
import {RequestErrorTriggerService} from 'src/app/services/request-error-trigger.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SpinnerService} from 'src/app/services/spinner.service';
import {Subscription} from 'rxjs';
import {VerifyEventRequest} from 'src/app/core/models/VerifyEventRequest';
import {environment} from 'src/environments/environment';
import {UserService} from 'src/app/services/user.service';
import {ConfirmationService, LazyLoadEvent} from 'primeng/api';
import {PaginationService} from 'src/app/services/pagination.service';
import {Pageable} from '../../../core/models/Pageable';
import {Clipboard} from "@angular/cdk/clipboard";
import {AuthService} from "../../../services/auth.service";
import {Tenant} from "../../../core/models/Tenant";
import {StoreService} from "../../../services/store.service";
import {EventStatus} from "../../../shared/enums/EventStatus";
import {EventForAdminListComplete} from 'src/app/core/models/EventForAdminListComplete';
import {Table} from "primeng/table";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit, OnDestroy {
  @ViewChild('dt') table: Table;
  loading: boolean;
  events: EventForAdminListComplete[] = [];
  subs: Subscription[] = [];
  showModalImage = false;
  headerPopup = null;
  imageEventShow = null;
  selectedCategory: CategoryEventResponse;
  categoryEmpty = {
    idCategory: 0,
    name: 'Seleccioná una categoría',
    position: 0,
    showOnBillboard: false,
    quantityEvents: 0,
    tenant: 0
  };
  categoriesEvents: CategoryEventResponse[] = [];
  categoriesForEvent: CategoryEventResponse[] = [];
  isLoading = true;
  refresh: boolean;
  isSuperAdmin: boolean;
  isReader: boolean;
  isWriter: boolean;
  eventTable = null;
  totalRecords: number;
  pageSize: number = 50;
  pageNumber: number = 0;
  tenantList: Tenant[] = [];
  showModalResale = false;
  eventSelected = null;
  EventStatus = EventStatus;
  tenantIdSelected: number = 1;
  tenantSelected: Tenant = null;
  searchTerm = null;

  constructor(
    private eventsSrv: EventsService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private spinnerSrv: SpinnerService,
    private errorService: RequestErrorTriggerService,
    private authSrv: AuthService,
    private userSrv: UserService,
    private confirmationService: ConfirmationService,
    private paginationSrv: PaginationService,
    private clipboard: Clipboard,
    private storeSrv: StoreService,
    private route: ActivatedRoute
  ) {
    this.selectedCategory = this.categoryEmpty;
  }

  ngOnInit(): void {
    this.tenantIdSelected = sessionStorage.getItem('tenant-events') ? Number(sessionStorage.getItem('tenant-events')) : 1;
    this.getTenantList();
    this.isSuperAdmin = this.authSrv.isSuperAdminRole();
    this.isReader = this.userSrv.isReader();
    this.isWriter = this.userSrv.isWriter();
  }

  loadEvents(e: LazyLoadEvent, tenantId: number): void {
    this.loading = true;
    if (this.isReader === true || this.isSuperAdmin) {
      this.spinnerSrv.loadSpinner.next(true);
      this.eventTable = e;
      this.searchTerm = e.globalFilter;
      this.pageNumber = e.first / e.rows;
      this.pageSize = e.rows;
      const multiSortFields = this.paginationSrv.getMultiSortObject(e);
      this.subs.push(
        this.eventsSrv.getAllEvents(tenantId, this.pageNumber, this.pageSize, multiSortFields, e.globalFilter).subscribe({
          next: (data: Pageable) => {
            this.events = data.content;
            this.totalRecords = data.totalElements;
            this.loading = false;
            this.spinnerSrv.loadSpinner.next(false);
          },
          error: err => {
            this.loading = false;
            this.spinnerSrv.loadSpinner.next(false);
          }
        })
      );
    } else {
      this.alertNotAuthorized();
    }
  }

  getTenantList() {
    this.storeSrv.getTenantList().subscribe({
      next: (data: Tenant[]) => {
        this.tenantList = data;
      }
    });
  }

  changeTenant(event) {
    this.tenantIdSelected = event.value;
    this.tenantSelected = this.tenantList.find(te => te.id === this.tenantIdSelected);
    sessionStorage.removeItem('events-table');
    this.table.reset();
    this.searchTerm = null;
    sessionStorage.setItem('tenant-events', event.value);
    this.loadEvents(this.eventTable, this.tenantIdSelected);
  }

  getTenantName(event: EventForAdminListComplete) {
    return event.storeResponse.tenantResponse.name;
  }

  goToEventDetail(event) {
    this.router.navigate(['event-configuration', event.idEvent])
  }

  copySplitReference(reference) {
    this.clipboard.copy(reference);
  }

  alertNotAuthorized(): void {
    this.confirmationService.confirm({
      message:
        'Lo sentimos, no dispones de los permisos para ver esta información.',
      icon: 'fa-light fa-warning text-yellow text-12xl',
      rejectVisible: false,
      key: 'notAuthorized',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        this.back();
      },
    });
  }

  onRefresh(): void {
    this.loadEvents(this.eventTable, this.tenantIdSelected);
    this.refresh = true;
    setTimeout(() => {
      this.refresh = false;
    }, 2000);
  }

  onHide(): void {
    this.showModalImage = false;
    this.headerPopup = null;
    this.imageEventShow = null;
  }

  back(): void {
    this.router.navigate(['home']);
  }

  getSafeImage(base64Img): string {
    return `data:image/jpg;base64,${
      (this.sanitizer.bypassSecurityTrustResourceUrl(base64Img) as any)
        .changingThisBreaksApplicationSecurity
    }`;
  }

  getUrlImage(url: string): string {
    return `${environment.mediaUrl}${url}`;
  }

  toggleSwitchVerified(ev, event: EventForAdminListComplete): void {
    this.verifyEvent(event, ev.checked);
  }

  verifyEvent(event: EventForAdminListComplete, verified: boolean): void {
    if (this.selectedCategory.idCategory !== 0) {
      this.loading = true;
      this.spinnerSrv.loadSpinner.next(true);
      const verifyEventRequest = new VerifyEventRequest();
      verifyEventRequest.verified = verified;
      this.subs.push(
        this.eventsSrv.verifyEvent(event.idEvent, verifyEventRequest).subscribe({
          next: () => {
            this.loading = false;
            this.spinnerSrv.loadSpinner.next(false);
            this.onRefresh();
          },
          error: (err) => {
            this.loading = false;
            if (err.status === 404) {
              this.handleErrorMessage(err.status);
            }
          }
        })
      );
    } else {
      const message =
        'Debe seleccionar una categoría para poder verificar el evento.';
      this.errorService.updateShowError({
        showError: true,
        message,
      });
    }
  }

  private handleErrorMessage(status): void {
    let message: string;
    switch (status) {
      case 404:
        message = 'Evento no encontrado.';
        break;
    }
    this.errorService.updateShowError({
      showError: true,
      message,
    });
  }

  copy(textToCopy: string) {
    this.clipboard.copy(textToCopy);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }
}
