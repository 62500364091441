import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CategoryEventResponse } from 'src/app/core/models/CategoryEventResponse ';
import { EventForStore } from 'src/app/core/models/EventForStore';
import { EventStatus } from 'src/app/shared/enums/EventStatus';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-event-description-modal',
  templateUrl: './event-description-modal.component.html',
  styleUrls: ['./event-description-modal.component.scss'],
})
export class EventDescriptionModalComponent implements OnInit {

  event: EventForStore;
  statuses = EventStatus;
  eventCategory: CategoryEventResponse;

  constructor(private config: DynamicDialogConfig, private ref: DynamicDialogRef) { }

  ngOnInit(): void {
    this.event = this.config.data.event;
    this.eventCategory = this.config.data.eventCategory;
  }

  getUrl(url) {
    return `${environment.mediaUrl}${url}`;
  }

  getLabelStatus(eventData: EventForStore) {

    const now = new Date();
    const dateInit = new Date(eventData.dateInit);
    const dateEnd = new Date(eventData.dateEnd);
    const status: string = eventData.status;

    if (!eventData.verified) {
      if (status === EventStatus.CANCELED) {
        return `<div class="bid-badge bid-badge--big bid-badge--red bid-badge--full-width">CANCELADO</div>`;
      } else {
        return `<div class="bid-badge bid-badge--big bid-badge--orange bid-badge--full-width">SIN VERIFICAR</div>`;
      }
    } else {
      if (status === EventStatus.CANCELED) {
        return `<div class="bid-badge bid-badge--big bid-badge--red bid-badge--full-width">CANCELADO</div>`;
      } else {
        if (now < dateInit) {
          return `<div class="bid-badge bid-badge--big bid-badge--orange bid-badge--full-width">PENDIENTE</div>`;
        } else if (dateInit < now && now < dateEnd) {
          return `<div class="bid-badge bid-badge--big bid-badge--green bid-badge--full-width">EN CURSO</div>`;
        } else if (now > dateEnd) {
          return `<div class="bid-badge bid-badge--big bid-badge--grey bid-badge--full-width">FINALIZADO</div>`;
        }
      }
    }
  }

  shortAddress(address) {
    return address.split(',', 2);
  }

  hideDescription() {
    this.ref.close();
  }

}
