import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalConfig } from 'src/app/core/models/ModalConfig';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Output() modalResponse = new EventEmitter();
  @Input() showModal = false;
  @Input() widthModal: string;
  @Input() config: ModalConfig = {
    headerText: 'Header Text',
    content: 'Content default',
    buttons: 'single',
  };

  constructor() {}

  ngOnInit(): void {
    if (!this.widthModal) {
      this.widthModal = '700px';
    }
  }

  onAccept(): void {
    this.modalResponse.emit(true);
  }

  onCancel(): void {
    this.modalResponse.emit(false);
  }

  onHide(): void {
    this.modalResponse.emit(false);
  }
}
