import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  constructor() { }

  dateLastWeek(): string {
    const lastWeek = this.getLastWeek();
    const lastWeekMonth = this.monthName(lastWeek.getMonth() + 1);
    const lastWeekDay = lastWeek.getDate();

    const lastWeekDisplay = lastWeekMonth + ' ' + lastWeekDay;
    return lastWeekDisplay;
  }

  getLastWeek(): Date {
    const today = new Date();
    const lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 14
    );
    return lastWeek;
  }

  monthName(monthNumber): string {
    const monthNames = [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ];
    return monthNames[monthNumber - 1];
  }

  dateWeek(): string {
    const lastWeek = this.getWeek();
    const lastWeekMonth = this.monthName(lastWeek.getMonth() + 1);
    const lastWeekDay = lastWeek.getDate() - 1;

    const lastWeekDisplay = lastWeekMonth + ' ' + lastWeekDay;

    return lastWeekDisplay;
  }

  getWeek(): Date {
    const today = new Date();
    const lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 7
    );
    return lastWeek;
  }

  getToday(): Date {
    const today = new Date();
    const lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 1
    );
    return lastWeek;
  }

  dateWeekStart(): string {
    const lastWeek = this.getWeek();
    const lastWeekMonth = this.monthName(lastWeek.getMonth() + 1);
    const lastWeekDay = lastWeek.getDate();

    const lastWeekDisplay = lastWeekMonth + ' ' + lastWeekDay;

    return lastWeekDisplay;
  }

  dateToday(): string {
    const lastWeek = this.getToday();
    const lastWeekMonth = this.monthName(lastWeek.getMonth() + 1);
    const lastWeekDay = lastWeek.getDate();

    const todayWeekDisplay = lastWeekMonth + ' ' + lastWeekDay;

    return todayWeekDisplay;
  }
}
