import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { WaitingListConfig } from 'src/app/core/models/Configurations';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-config-lista-espera',
  templateUrl: './config-lista-espera.component.html',
  styleUrls: ['./config-lista-espera.component.scss'],
})
export class ConfigListaEsperaComponent implements OnInit, OnDestroy {
  @Output() validFormState = new EventEmitter();
  @Input() waitingListConfData: WaitingListConfig = null;
  @Input() configurationId: number;
  @Input() fromAdmin: boolean;
  @Input() canWrite: boolean;

  listaEsperaForm: UntypedFormGroup;
  idStore: string;
  thereAreChanges = false;
  originalData: WaitingListConfig;
  qrData: string;
  switch: boolean;
  subs: Subscription[] = [];
  formHasBeenSubmitted = false;
  maxCapacityPerTable: number;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.maxCapacityPerTable = environment.maxCapacityPerTable;
    this.listaEsperaForm = this.formBuilder.group({
      waitingListAvailable: [false],
      maximumPerTable: [{ value: 1, disabled: true }, Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.waitingListConfData) {
      this.loadData();
      this.originalData = this.waitingListConfData;
    }
  }

  loadData(): void {
    this.listaEsperaForm.patchValue({
      waitingListAvailable: this.waitingListConfData.waitingListAvailable,
      maximumPerTable: this.waitingListConfData.maximumPerTable,
    });
  }

  createDataToQR(): string {
    return JSON.stringify({
      idStore: this.idStore,
      type: 'waiting-list',
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => {
      s.unsubscribe();
    });
  }
}
