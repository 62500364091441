import {Component, OnInit} from '@angular/core';
import {StoreData} from "../../../../../../../core/models/StoreData";
import {StoreService} from "../../../../../../../services/store.service";
import {SpinnerService} from "../../../../../../../services/spinner.service";

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  index: number = 0;
  storeId = null;
  storeData: StoreData = null;

  constructor(
    private storeSrv: StoreService,
    private spinnerSrv: SpinnerService
  ) {
  }

  ngOnInit(): void {
    this.storeId = Number(sessionStorage.getItem('storeId'));
    this.getStoreData(this.storeId);
  }

  getStoreData(storeId) {
    this.spinnerSrv.loadSpinner.next(true);
    this.storeSrv.getStoreById(storeId).subscribe({
      next: (data: StoreData) => {
        this.storeData = data;
        this.spinnerSrv.loadSpinner.next(false);
      }
    });
  }

  handleChange(index) {
    this.index = index;
  }

  changeTab() {
    this.handleChange(0);
  }

}
