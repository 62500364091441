<div class="event">
    <div class="header">
        <div class="event__info-store">
            <div class="event__image">
                <img *ngIf="event.imageUrl" src="{{ getUrl(event.imageUrl) }}" alt="">
                <img *ngIf="!event.imageUrl" src="src/assets/event-placeholder.jpg" alt="">
            </div>
        
            <div>
                <h2 class="font-size-24 text-gray-1 lh-24 font-weight-600 p-my-0 word-break margin-bottom-24">{{ event.eventName }}</h2>
                <div class="event__info-event">
                    <i class="fa-solid fa-store margin-right-8"></i> <span class="font-poppins font-size-14 font-weight-600">Cervecería Antares Tandil</span>
                </div>
                <div class="event__info-event lh-16 font-size-14 text-gray-3">
                    <i class="fa-solid fa-calendar-clock margin-right-8"></i>
                    <p class="text__date">{{ event.dateInit | date: "dd MMM - HH:mm" }} a {{ event.dateEnd | date: "dd MMM - HH:mm" }}</p>
                </div>
                <div class="event__info-event lh-16 font-size-14 text-gray-3">
                    <i class="fa-sharp fa-solid fa-location-dot margin-right-8"></i>
                    <p class="text__date">{{ shortAddress(event.address) }}</p>
                </div>
            </div>
        </div>

        <div class="event__state">
            <div class="margin-right-24 status" [innerHTML]="getLabelStatus(event)"></div>
            <div class="font-size-14"><i class="fa-solid fa-list-ol margin-right-8 font-size-12 text-gray-35"></i> {{eventCategory.name}}</div>
        </div>
    </div>

    <hr>

    <div class="event__description">
        {{ event.eventDescription }}
    </div>

    <div class="event__footer">
        <button pButton label="Aceptar" class="btn btn__primary" (click)="hideDescription()"></button>
    </div>
</div>