<div class="category-form-container w-100">
  <form [formGroup]="categoriesEventForm" class="w-100">
    <div class="field__item field--modal" [ngClass]="
         validateInputClass(categoriesEventForm, 'name', formHasBeenSubmitted)
       ">
      <div class="p-float-label">
        <input formControlName="name" id="name" type="text" maxlength="{{ maxNameLength }}" pInputText />
        <label for="name" class="title-float">Nombre de la categoría* </label>
      </div>
      <div *ngIf="
           categoriesEventForm.get('name').valid ||
           categoriesEventForm.get('name').untouched
         " class="field__item--info">
        <span>
          {{ nameField.value ? nameField.value.length : 0 }}/{{
          maxNameLength
          }}
        </span>
      </div>
    </div>

    <div class="field__item field--modal" [ngClass]="
         validateInputClass(categoriesEventForm, 'position', formHasBeenSubmitted)
         ">
      <div class="p-float-label">

        <div class="field__item field--dropdown field--modal" *ngIf="isEditing">
          <div class="p-float-label">
            <p-dropdown [options]="positions" id="position" placeholder="Posición en cartelera"
              formControlName="position">
            </p-dropdown>
            <label for="name" class="title-float">
              Ubicación en cartelera
            </label>
          </div>
        </div>

        <div class="p-float-label" *ngIf="!isEditing">
          <input formControlName="position" id="position" type="number" disabled pInputText />
          <label for="name" class="title-float">Ubicación en cartelera* </label>
        </div>


      </div>
      <div *ngIf="
         categoriesEventForm.get('position').valid ||
         categoriesEventForm.get('position').untouched
       " class="field__item--info">
      </div>

    </div>

    <div class="show-in-billboard mb-10">
      <span>Mostrar categoría en cartelera</span>
      <p-inputSwitch formControlName="showOnBillboard" (onChange)="checkShowOnBillboard($event)"></p-inputSwitch>
    </div>

  </form>

  <div class="w-100 flex justify-content-between align-items-center pb-24">
    <button pButton (click)="closeModal()" class="btn btn__secondary btn__secondary--bold mr-3 w-100"
      label="Cancelar"></button>
    <button pButton label="Guardar categoría" (click)="submitCategory()" class="btn btn__primary w-100"
      [disabled]="categoriesEventForm.invalid"></button>
  </div>
</div>
