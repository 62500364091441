<section class="container__porcents">
  <header class="header__porcents">
    <i class="fa-light fa-badge-percent text-xl text-gray-1 margin-right-16"></i>
    <p class="font-inter text-xl font-semibold text-gray-1 margin-y-0 uppercase">Porcentajes y split</p>
  </header>
  <div class="content__porcents">
    <div class="wrapper">
      <p-toast position="bottom-center"></p-toast>
      <div class="form__porcents">
        <div class="row__cost">
          <div class="display-flex align-items-center justify-content-start margin-bottom-16">
            <i class="fa-solid fa-circle-dollar text-gray-1 margin-right-8"></i>
            <p class="text-base font-poppins lh-24 text-gray-1 font-semibold margin-y-0">Costo del servicio</p>
          </div>
          <div class="display-flex align-items-center justify-content-between margin-bottom-16">
            <div>
              <p class="text-sm font-poppins lh-24 text-gray-1 font-semibold mb-1">Accesos</p>
              <div class="field__item">
                <p-inputNumber [(ngModel)]="accessPercentage"
                  suffix="%" placeholder="%" [min]="0" [max]="100"
                  (onInput)="onPercentageForAccessChange($event)"
                  mode="decimal">
                </p-inputNumber>
              </div>
            </div>
            <div>
              <p class="text-sm font-poppins lh-24 text-gray-1 font-semibold mb-1">Combos/Mesas</p>
              <div class="field__item">
                <p-inputNumber [(ngModel)]="tablePercentage"
                  suffix="%" placeholder="%" [min]="0" [max]="100"
                  (onInput)="onPercentageForTableChange($event)"
                  mode="decimal">
                </p-inputNumber>
              </div>
            </div>
          </div>


        </div>
        <div class="split__container">
          <div class="flex align-items-center justify-content-between">
            <div class="flex align-items-center justify-content-start">
              <i class="fa-solid fa-split text-gray-1 margin-right-8"></i>
              <p class="font-poppins text-gray-1 font-semibold text-base lh-24 margin-y-0">Split de comisión</p>
            </div>
            <p-inputSwitch (onChange)="splitChange($event)" value="splitModel" [ngModel]="splitModel"></p-inputSwitch>
          </div>
          <p class="split__description" [ngClass]="!splitModel ? 'margin-bottom-0' : ''">Estando activo, el porcentaje de comisión se dividirá entre Black id y el operador. De lo contrario, el 100% de la comisión será para Black id.</p>
          <div class="divisor" *ngIf="splitModel"></div>
          <div class="split__commission" *ngIf="splitModel">
            <div class="column__commission">
              <p class="font-poppins text-sm lh-16 text-gray-1 font-medium margin-y-0 margin-bottom-16">Comisión Operador</p>
              <div class="field__item">
                <p-inputNumber [(ngModel)]="percentageForStore"
                  suffix="%" placeholder="%" [min]="0" [max]="100"
                  (onInput)="onPercentageStoreChange($event)"
                  mode="decimal">
                </p-inputNumber>
              </div>
            </div>
            <div class="column__commission">
              <p class="font-poppins text-sm lh-16 text-gray-1 font-medium margin-y-0 margin-bottom-16">Comisión Black iD</p>
              <div class="box__commission">{{percentageForUs}}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <button pButton class="btn btn__secondary btn__secondary--bold mr-3" (click)="cancel()"
      label="Cancelar"></button>
    <button pButton class="btn btn__primary" (click)="save()" label="Guardar cambios"></button>
  </div>
</section>


