import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {EventsService} from 'src/app/services/events.service';
import {EventForStore} from 'src/app/core/models/EventForStore';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {

  selectedBtn = '';
  storeId: number;
  event: EventForStore[] = [];

  constructor(
    private router: Router,
    private eventSrv: EventsService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (params: ParamMap) => {
        this.storeId = Number(params.get('storeId'));
        sessionStorage.setItem('storeId', this.storeId.toString(10));
      }
    });
    this.setSelectedBtn(this.router.url.slice(9));
  }

  setSelectedBtn(url) {
    switch (url) {
      case url = 'configurations':
        this.selectedBtn = 'configurations';
        break;
      case url = 'stats':
        this.selectedBtn = 'stats';
        break;
      case url = 'menu':
        this.selectedBtn = 'menu';
        break;
      case url = 'transactions':
        this.selectedBtn = 'transactions';
        break;
      case url = 'payments':
        this.selectedBtn = 'payments';
        break;
      default:
        this.selectedBtn = 'configurations';
        break;
    }
  }

  goTo(route) {
    this.selectedBtn = route;
    if (this.selectedBtn === 'stats') {
      this.router.navigate(['store', this.storeId, route, 'event']);
    } else {
      this.router.navigate(['store', this.storeId, route]);
    }
  }

}
