import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { CategoriesService } from 'src/app/services/categories.service';
import { FormService } from 'src/app/services/form.service';
import { InputValidationService } from 'src/app/services/input-validation.service';
import { RequestErrorTriggerService } from 'src/app/services/request-error-trigger.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-category-modal',
  templateUrl: './new-category-modal.component.html',
  styleUrls: ['./new-category-modal.component.scss'],
})
export class NewCategoryModalComponent implements OnInit {
  @Input() showNewCategory: boolean = false;
  @Input() isEditting: boolean;
  @Input() headerPopup: string = 'Nueva Categoria';
  @Input() categoryForm: UntypedFormGroup;
  @Input() editId;

  @Output() displayLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() categoryDialogOut: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() loadCategories: EventEmitter<void> = new EventEmitter<void>();

  loading: boolean;
  subs: Subscription[] = [];
  submitted: boolean;
  categoryFormHasBeenSubmitted = false;
  maxCategoryNameLength: number;
  maxCategoryDescriptionLength: number;

  constructor(
    private sanitizer: DomSanitizer,
    private spinnerService: SpinnerService,
    private categoriesService: CategoriesService,
    private errorService: RequestErrorTriggerService,
    private validateInputService: InputValidationService,
    public formService: FormService
  ) {
    this.maxCategoryNameLength = environment.maxCategoryNameLength;
    this.maxCategoryDescriptionLength =
      environment.maxCategoryDescriptionLength;
  }

  get nameField(): AbstractControl {
    return this.categoryForm.get('name');
  }

  get descriptionField(): AbstractControl {
    return this.categoryForm.get('description');
  }

  ngOnInit(): void {}

  hideDialog(): void {
    this.categoryFormHasBeenSubmitted = false;
    this.showNewCategory = false;
    this.submitted = false;
    this.isEditting = false;
    this.categoryDialogOut.emit(false);
  }

  submitCategory(): void {
    this.submitted = true;
    this.categoryFormHasBeenSubmitted = true;

    if (this.categoryForm.valid) {
      this.displayLoading.emit(true);
      this.spinnerService.loadSpinner.next(true);

      if (!this.isEditting) {
        this.subs.push(
          this.categoriesService
            .createCategory(this.categoryForm.value)
            .subscribe((id) => {
              this.loadCategories.emit();
              this.hideDialog();
              this.categoryForm.reset();
              this.spinnerService.loadSpinner.next(false);
            })
        );
      } else {
        this.subs.push(
          this.categoriesService
            .editCategory(this.editId, this.categoryForm.value)
            .subscribe(
              (id) => {
                this.loadCategories.emit();
                this.hideDialog();
                this.isEditting = false;
                this.categoryForm.reset();
                this.spinnerService.loadSpinner.next(false);
              },
              (err) => {
                if (err.status === 400 || err.status === 404) {
                  this.handleErrorMessage(err.status);
                }
              }
            )
        );
      }
    }
  }

  private handleErrorMessage(status): void {
    let message: string;
    switch (status) {
      case 400:
        message = 'Categoria no valido.';
        break;
      case 404:
        message = 'Categoria inexistente.';
        break;
      case 409:
        message =
          'No se puede borrar porque hay productos asociados a esta categoría';
        break;
    }
    this.errorService.updateShowError({
      showError: true,
      message,
    });
  }

  getSafeImage(base64Img): string {
    return `data:image/jpg;base64,${
      (this.sanitizer.bypassSecurityTrustResourceUrl(base64Img) as any)
        .changingThisBreaksApplicationSecurity
    }`;
  }

  validateInputClass(
    form: UntypedFormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }
}
