<ng-container *ngIf="loading else finishLoad">
  <div class="skeleton__header"></div>
  <div class="skeleton__container margin-top-48">
    <div class="skeleton__long margin-right-16"></div>
    <div class="skeleton__long"></div>
  </div>
  <div class="skeleton__container margin-top-64">
    <div class="w-100 margin-right-16">
      <div class="skeleton__tini margin-bottom-16"></div>
      <div class="skeleton__long"></div>
    </div>
    <div class="w-100">
      <div class="skeleton__tini margin-bottom-16"></div>
      <div class="skeleton__long"></div>
    </div>
  </div>
</ng-container>
<ng-template #finishLoad>
  <section>
    <span class="row-title mb-4"><i class="fa-solid fa-rectangle-history margin-right-8"></i>Histórico de reservas</span>
    <div class="flex">
      <app-card-total-stats class="p-col card-total-stats margin-right-16 w-100" [icon]="'far fa-calendar-day'"
            [description]="'Total reservas'"
            [value]="stats?.bookingsStats.total"></app-card-total-stats>
      <app-card-total-stats class="p-col card-total-stats w-100" [icon]="'far fa-calendar-exclamation'"
            [description]="'Confirmaciones pendientes'"
            [value]="stats?.bookingsStats.pending"></app-card-total-stats>
    </div>
  </section>

  <section class="flex margin-top-24">
    <app-card-total-stats-short class="p-col card-confirm-canceled-stats margin-right-16 w-100" [icon]="'far fa-calendar-check'"
                                [label]="'Confirmadas'" [value]="stats?.bookingsStats.confirmed"
                                [colorClass]="'text-success'"></app-card-total-stats-short>

    <app-card-total-stats-short class="p-col card-confirm-canceled-stats margin-right-16 w-100" [icon]="'far fa-calendar-xmark'"
                                [label]="'Canceladas'" [value]="stats?.bookingsStats.canceled"
                                [colorClass]="'text-error'"></app-card-total-stats-short>

    <app-card-total-stats-short class="p-col card-confirm-canceled-stats w-100" [icon]="'far fa-calendar-minus'"
                                [label]="'Expiradas'" [value]="stats?.bookingsStats.expired"
                                [colorClass]="'text-gray-35'"></app-card-total-stats-short>
  </section>
  <section class="section__chart">
    <div class="header-chart">
      <span class="row-title"><i class="fa-solid fa-chart-mixed margin-right-8"></i>Tendencia semanal</span>
      <div class="header-options">
        <div class="container-segment ml-segment">
          <div class="segmented-control">
            <input type="radio" name="segment" id="currentWeek" value="currentWeek" checked
                   (change)="segmentChanged($event)"/>
            <input type="radio" name="segment" id="lastWeek" value="lastWeek"
                   (change)="segmentChanged($event)"/>
            <label for="currentWeek" data-value="Semana actual"><span>Semana actual</span></label>
            <label for="lastWeek" data-value="Semana anterior"><span>Semana anterior</span></label>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section-charts">
    <app-left-graphic *ngIf="stats" [stats]="stats" [checked]="checked" [typeWeek]="type"
                      [dataColumns]="leftDataColumns" [dataCategories]="leftDataCategories">
    </app-left-graphic>
    <app-right-graphic *ngIf="stats" class="margin-left-16" [stats]="stats" [checked]="checked" [typeWeek]="type"
                       [dataColumns]="rightDataColumns">
    </app-right-graphic>
  </section>
</ng-template>
