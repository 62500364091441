import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import c3 from 'c3';

@Component({
  selector: 'app-bar-graphic-numeric-step',
  templateUrl: './bar-graphic-numeric-step.component.html',
  styleUrls: ['./bar-graphic-numeric-step.component.scss']
})
export class BarGraphicNumericStepComponent implements OnInit {

  @Input() idChart: string;
  @Input() columns;

  constructor() { }

  ngOnInit(): void { }

  //this method update the graphic when columns data change
  ngOnChanges(changes: SimpleChanges) {
    this.generateGraphic();
  }

  ngAfterViewInit() {
    this.generateGraphic();
  }

  generateGraphic() {

    setTimeout(() => {
      c3.generate({
        bindto: `#${this.idChart}`,
        data: {
          columns: this.columns,
          type: 'bar',
          colors: {
            [this.columns[0][0]]: '#5046B8'
          }
        },
        grid: {
          x: {
            show: false,
          },
          y: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        axis: {
          x: {
            type: 'category',
            categories: [
              '1',
              '2',
              '3',
              '4',
              '5',
              '6 o más'
            ],
          },
          y: {
            type: 'linear',
            tick: {
              format: function (d) {
                return (parseInt(d.toString()) == d) ? d : null;
              }
            },
          },
        },
      });
    }, 500);
  }

  dayDisplay(number) {
    const lastWeek = this.historyDay(number);
    const lastWeekMonth = lastWeek.getMonth() + 1;
    const lastWeekDay = lastWeek.getDate();

    const dateDay = lastWeekMonth + '/' + lastWeekDay;

    return dateDay;
  }

  historyDay(number) {
    const today = new Date();
    const lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - number
    );
    return lastWeek;
  }

}
