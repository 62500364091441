<div class="container-main">
  <div class="config-container">
    <p-tabView (onChange)="handleChange($event.index)" [(activeIndex)]="index">
      <p-tabPanel header="Plataforma de Pago">
        <app-payments-platform *ngIf="index === 0" [storeData]="storeData"></app-payments-platform>
      </p-tabPanel>

      <p-tabPanel header="Porcentajes" [disabled]="!storeData?.tenant">
        <app-porcents *ngIf="index === 1"></app-porcents>
      </p-tabPanel>

      <p-tabPanel header="Canal de venta" [disabled]="!storeData?.tenant">
        <app-sales-channels *ngIf="index === 2" (changeTabEmitter)="changeTab()"></app-sales-channels>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
