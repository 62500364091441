export enum OrderStatus {
    OPEN = 'OPEN',
    CONFIRMED = 'CONFIRMED',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
    READY = 'READY',
    ON_THE_WAY = 'ON_THE_WAY',
    CLOSING = 'CLOSING',
    CLOSING_WITH_CREDIT = 'CLOSING_WITH_CREDIT'
}
