<ng-container *ngIf="loading else finishLoad">
  <div class="skeleton__header"></div>
  <div class="skeleton__container margin-top-48">
    <div class="skeleton__long margin-right-16"></div>
    <div class="skeleton__long"></div>
  </div>
  <div class="skeleton__container margin-top-64">
    <div class="w-100 margin-right-16">
      <div class="skeleton__tini margin-bottom-16"></div>
      <div class="skeleton__long"></div>
    </div>
    <div class="w-100">
      <div class="skeleton__tini margin-bottom-16"></div>
      <div class="skeleton__long"></div>
    </div>
  </div>
</ng-container>
<ng-template #finishLoad>
  <header *ngIf="idEventSelected !== null">
    <div class="font-size-32 lh-40 font-weight-600 font-poppins event-name">
      <span *ngIf="eventName">{{eventName}}</span>
    </div>
    <div>
      <div class="tab-menu-container">
        <ul>
          <li (click)="show(EVENT_STATS_TABS.BILLBOARD)"
              [ngClass]="{'selected': tabSelected === EVENT_STATS_TABS.BILLBOARD}">Cartelera
          </li>
          <li (click)="show(EVENT_STATS_TABS.ACCESS_CONTROL)"
              [ngClass]="{'selected': tabSelected === EVENT_STATS_TABS.ACCESS_CONTROL}">Control de acceso
          </li>
        </ul>
      </div>
    </div>
  </header>

  <div *ngIf="idEventSelected === null" class="container__empty-stats">
    <div class="container__empty-stats-wrapper">
      <i class="fal fa-calendar-star text-12xl mb-6 text-gray-4 font-thin"></i>
      <span
        class="text-gray-4 text-lg lh-24 mb-8">Todavía no tiene eventos creados o los mismos no han finalizado.</span>

    </div>
  </div>

  <div *ngIf="tabSelected === EVENT_STATS_TABS.BILLBOARD && idEventSelected !== null">
    <app-billboard-stats [stats]="stats"></app-billboard-stats>
  </div>

  <div *ngIf="tabSelected === EVENT_STATS_TABS.ACCESS_CONTROL && idEventSelected !== null">
    <app-access-control-stats [stats]="stats"></app-access-control-stats>
  </div>

</ng-template>
