import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }

  shoudShowInputCount(form: UntypedFormGroup, input: string, formHasBeenSubmitted: boolean) {
    return form.get(input).valid || !formHasBeenSubmitted || (formHasBeenSubmitted && form.valid);
  }
}
